import { createContext, useState, useContext } from 'react';

interface SidebarContext {
  sidebarToggle: boolean;
  toggleSidebar: () => void;
}

const SidebarContext = createContext<SidebarContext>({} as SidebarContext);

export const SidebarProvider: React.FC = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  return (
    <SidebarContext.Provider value={{ sidebarToggle, toggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};

export function useSideBar(): SidebarContext {
  const context = useContext(SidebarContext);

  return context;
}
