import { useMemo } from 'react';
import { Button, Modal } from '@mui/material';
import { camelCase, get } from 'lodash';

import { DoughnutChart } from '../DoughnutChart';
import { HistoryChart } from '../HistoryChart';

import { aboutInfo } from '../../../../utils/aboutQuestionnaire';
import { scoreRangesText, RangeDataProps } from '../../../../utils/scoreRanges';

import { SectionQuestionnaireData } from '../CategoryStats';

import {
  Container,
  ModalBody,
  ModalHistory,
  ModalInfo,
  ModalWrapper,
  ScoreRanges,
  RangeCard,
  RangeLine
} from './styles';

interface QuestionnaireModalProps {
  modalData: SectionQuestionnaireData;
  isModalOpen: boolean;
  handleCloseModal: () => void;
  isLife?: boolean;
}

interface AboutQuestionnaireProps {
  type: 'text' | 'video' | 'title';
  content: string;
}

export const QuestionnaireModal: React.FC<QuestionnaireModalProps> = ({
  modalData,
  handleCloseModal,
  isModalOpen,
  isLife = false
}) => {
  const { scoreRanges, modalDescription } = useMemo(() => {
    const camelCaseQuestionnaire = camelCase(modalData.questionnaire);
    const aboutQuestionnaire = aboutInfo as Record<
      string,
      AboutQuestionnaireProps[]
    >;

    const aboutArray = [] as string[];

    aboutQuestionnaire[isLife ? 'life' : camelCaseQuestionnaire].map(
      ({ type, content }) => {
        if (type !== 'text') return;
        aboutArray.push(content);
      }
    );

    const ranges = get(
      scoreRangesText,
      camelCaseQuestionnaire,
      [] as Array<RangeDataProps>
    );

    return { scoreRanges: ranges, modalDescription: aboutArray.join(' | ') };
  }, [modalData, isLife]);

  return (
    <Container>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <ModalWrapper>
          <div style={{ textAlign: 'end' }}>
            <Button onClick={handleCloseModal} title="Close modal">
              X
            </Button>
          </div>

          <ModalBody>
            <div style={{ maxWidth: '30%' }}>
              <DoughnutChart data={modalData} />
            </div>

            <ModalInfo>
              <h1>{modalData.questionnaire}</h1>

              <p>
                <ScoreRanges multipleColumns={scoreRanges.length > 1}>
                  {scoreRanges.map(({ title, red, orange, green }) => (
                    <RangeCard id={title}>
                      <h3>{title}</h3>

                      <RangeLine red>{red}</RangeLine>
                      <RangeLine orange>{orange}</RangeLine>
                      <RangeLine green>{green}</RangeLine>
                    </RangeCard>
                  ))}
                </ScoreRanges>

                {modalDescription}
              </p>
            </ModalInfo>
          </ModalBody>

          <ModalHistory>
            <HistoryChart
              questionnaire={
                isLife
                  ? String(modalData.questionnaireIndex)
                  : modalData.questionnaire
              }
            />
          </ModalHistory>
        </ModalWrapper>
      </Modal>
    </Container>
  );
};
