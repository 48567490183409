import { useEffect, useState } from 'react';
import {
  getUserAlertStatus,
  getUserAnswersAlerts
} from '../../../../../../functions/hearingVisionAlerts';
import { useHealthCheck } from '../../../../../../hooks/healthCheck';
import { useOrganisation } from '../../../../../../hooks/organisations';
import { HearingVisionAlertsColor } from '../../../../../../models/jobHealthHazards/hearingVisionAlert';
import { getQuestionnaireAnswers } from '../../../../../../utils/getQuestionnaireAnswers';
import { organizationHasHearingVisionV2Access } from '../../../../../../utils/updateUserAnswersAndScores';
import { Hearing } from './hearing';
import { HearingScreen } from './hearingScreen';
import { Vision } from './vision';
import { VisionScreen } from './visionScreen';

const DEFAULT_ALERT_COLOR: HearingVisionAlertsColor = {
  hearing: '',
  vision: ''
};

export const JobHealthHazardsScore = () => {
  const { currentOrganisation } = useOrganisation();
  const { untouchedUser } = useHealthCheck();

  const [hearingVisionColorAlerts, setHearingVisionColorAlerts] =
    useState<HearingVisionAlertsColor>(DEFAULT_ALERT_COLOR);

  const [
    userAnswersHearingVisionColorAlerts,
    setUserAnswersHearingVisionColorAlerts
  ] = useState<HearingVisionAlertsColor>(DEFAULT_ALERT_COLOR);

  useEffect(() => {
    if (!organizationHasHearingVisionV2Access(currentOrganisation)) return;

    getUserAlertStatus(untouchedUser).then(setHearingVisionColorAlerts);
    getUserAnswersAlerts(untouchedUser.id).then(
      setUserAnswersHearingVisionColorAlerts
    );
  }, [currentOrganisation, untouchedUser]);

  return (
    <>
      {organizationHasHearingVisionV2Access(currentOrganisation) && (
        <>
          <HearingScreen
            alertColor={hearingVisionColorAlerts.hearing}
            answers={getQuestionnaireAnswers({
              category: 'body',
              section: 'jobHealthHazards',
              questionnaire: 'HearingScreen',
              user: untouchedUser
            })}
          />
          <VisionScreen
            alertColor={hearingVisionColorAlerts.vision}
            answers={getQuestionnaireAnswers({
              category: 'body',
              section: 'jobHealthHazards',
              questionnaire: 'VisionScreen',
              user: untouchedUser
            })}
          />
          <Hearing
            alertColor={userAnswersHearingVisionColorAlerts.hearing}
            answers={getQuestionnaireAnswers({
              category: 'body',
              section: 'jobHealthHazards',
              questionnaire: 'hearingQuestionnaire',
              user: untouchedUser
            })}
          />
          <Vision
            alertColor={userAnswersHearingVisionColorAlerts.vision}
            answers={getQuestionnaireAnswers({
              category: 'body',
              section: 'jobHealthHazards',
              questionnaire: 'visionQuestionnaire',
              user: untouchedUser
            })}
          />
        </>
      )}
    </>
  );
};
