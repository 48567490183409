import { useMemo } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
  ScatterDataPoint,
  BubbleDataPoint
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { CategoryDataProps } from '../CategoryStats';

ChartJS.register(ArcElement, Tooltip, Legend);

interface DoughnutChartProps {
  data: CategoryDataProps;
}

export const DoughnutChart: React.FC<DoughnutChartProps> = ({ data }) => {
  const { doughnutChartData, doughnutOptions } = useMemo(() => {
    const { green, orange, red } = data;

    const totalUsers = green + orange + red;
    const greenPercent = Math.round((green / totalUsers) * 100);
    const orangePercent = Math.round((orange / totalUsers) * 100);
    const redPercent = Math.round((red / totalUsers) * 100);

    const greenFormatted = `(${greenPercent}%) Green`;
    const orangeFormatted = `(${orangePercent}%) Orange`;
    const redFormatted = `(${redPercent}%) Red`;

    const doughnutData: ChartData<
      'doughnut',
      (number | ScatterDataPoint | BubbleDataPoint | null)[],
      unknown
    > = {
      labels: [greenFormatted, orangeFormatted, redFormatted],
      datasets: [
        {
          data: [green, orange, red],
          backgroundColor: ['#5dc45d', '#ffa500 ', '#fc6161'],
          hoverBackgroundColor: ['#5dc45d', '#ffa500 ', '#fc6161'],
          hoverOffset: 2
        }
      ]
    };

    const options = {
      plugins: {
        legend: {
          display: false
        }
      }
    };

    return {
      doughnutChartData: doughnutData,
      doughnutOptions: options
    };
  }, [data]);

  return (
    <Doughnut
      style={{ maxHeight: '140px', maxWidth: '140px' }}
      data={doughnutChartData}
      options={doughnutOptions}
    />
  );
};
