import { isEmpty, last } from 'lodash';
import { useEffect, useState } from 'react';
import { FileExamsGallery } from '../../../../../../../components/FileExamsGallery';
import { UploadFileField } from '../../../../../../../components/UploadFileField';
import { getUploadedImagesUrl } from '../../../../../../../functions/jobHealthHazards/getUploadedExams';
import { useHealthCheck } from '../../../../../../../hooks/healthCheck';
import { JobHealthHazardsHealthCheck } from '../../../../../../../models/jobHealthHazards/JobHealthHazardsHealthCheck';
import { QuestionsWrapper } from '../styles';

export const StructuralHealthExams = () => {
  const { jobHealthHazardsHealthCheck, setJobHealthHazardsHealthCheck } =
    useHealthCheck();

  const [exams, setExams] = useState([] as File[]);
  const [uploadedExams, setUploadedExams] = useState(
    [] as Record<string, string>[]
  );
  const [isFetchingExams, setIsFetchingExams] = useState(false);
  const [isDisabledUpload, setIsDisabledUpload] = useState(false);

  useEffect(() => {
    if (
      !isEmpty(jobHealthHazardsHealthCheck?.structuralHealth?.uploadedExams)
    ) {
      setIsDisabledUpload(true);
      const uploadExamsPaths =
        jobHealthHazardsHealthCheck?.structuralHealth?.uploadedExams;
      if (!isFetchingExams) {
        setIsFetchingExams(true);
        setUploadedExams([]);
        uploadExamsPaths?.forEach((path) => {
          getUploadedImagesUrl(path).then((result) => {
            const fileName = last(path.split('/')) || '';
            setUploadedExams((prev) => [
              ...prev,
              { name: fileName, url: result }
            ]);
          });
        });
      }

      return;
    }

    setExams(jobHealthHazardsHealthCheck?.structuralHealth?.exams || []);
  }, [isFetchingExams, jobHealthHazardsHealthCheck?.structuralHealth]);

  return (
    <QuestionsWrapper>
      {isDisabledUpload ? (
        <FileExamsGallery
          componentId="structural-health-exams-file-gallery"
          uploadedExams={uploadedExams}
        />
      ) : (
        <UploadFileField
          componentId="structuralHealth-exams"
          buttonTitle="Add Exam"
          updateUploadedFiles={(value) =>
            setJobHealthHazardsHealthCheck((prev) => {
              const updatedExams = {
                ...prev,
                structuralHealth: {
                  ...prev.structuralHealth,
                  exams: value
                }
              } as JobHealthHazardsHealthCheck;

              return updatedExams;
            })
          }
          uploadedFilesUrls={exams}
          isDisabledUpload={isDisabledUpload}
        />
      )}
    </QuestionsWrapper>
  );
};
