import {
  QuestionnaireHeader,
  QuestionnaireIcon,
  QuestionnaireTitle,
  QuestionnaireWrapper,
  QuestionsWrapper
} from './styles';
import restingHeartRate from '../../../../../../assets/heart-rate.svg';
import { StructuralHealthExams } from './fields/StructuralHealthExams';

export const StructuralHealth = () => {
  return (
    <QuestionnaireWrapper>
      <QuestionnaireHeader>
        <QuestionnaireIcon>
          <img src={restingHeartRate} alt="Structural Health Icon" />
        </QuestionnaireIcon>

        <QuestionnaireTitle>Structural Health</QuestionnaireTitle>
      </QuestionnaireHeader>

      <QuestionsWrapper>
        <StructuralHealthExams />
      </QuestionsWrapper>
    </QuestionnaireWrapper>
  );
};
