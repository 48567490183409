import styled from 'styled-components';

export const Container = styled.main`
  height: 100vh;
  width: 100vw;
  position: relative;

  margin: -8px;
  padding: 0;

  background-color: rgba(0, 0, 0, 0.8);
`;

export const ErrorContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  margin: auto auto;
  padding: 10px;
  height: fit-content;
  width: 500px;

  font-family: Arial, sans-serif;
  color: #333333aa;
  border-radius: 10px;
  background-color: #f2f5f9;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 700px) {
    width: 250px;
  }

  h3 {
    margin: 0;

    @media (max-width: 700px) {
      font-size: 16px;
    }
  }

  p {
    text-align: center;
    font-weight: 500;
    font-size: 14px;
  }

  svg {
    height: 75px;
    width: 75px;
  }

  a {
    border-radius: 10px;
    padding: 5px 15px;
    border: none;

    background-color: #32c1b9;
    color: #fff;
    font-weight: bold;
    font-size: 16px;

    @media (max-width: 700px) {
      font-size: 14px;
    }

    text-decoration: none;
    cursor: pointer;

    transition: transform 0.2s;

    &:hover {
      transform: scale(1.03);
    }
  }
`;
