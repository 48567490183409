import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import LogRocket from 'logrocket';

import { App } from './App';
import { ErrorBoundary } from './components/ErrorBoundary';
import { reportWebVitals } from './reportWebVitals';
import { isProduction } from './services/firebase';

if (isProduction) {
  Sentry.init({
    dsn: 'https://9f293aab2448429c9da1f929f96614de@o1152774.ingest.sentry.io/6231089',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0
  });

  LogRocket.init('ef5kyk/kynd-clinician');
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
