import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Typography,
  Container
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { useAuth } from '../../hooks/auth';

import Logo from '../../assets/logo.png';

interface FormLoginProps {
  email: string;
  password: string;
}

export const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const { loading, signIn, userIsLogged } = useAuth();

  const [form, setForm] = useState<FormLoginProps>({
    email: '',
    password: ''
  });
  const [rememberMe, setRememberMe] = useState(false);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!userIsLogged) await signIn({ ...form });
  };

  useEffect(() => {
    if (userIsLogged) navigate('/dashboard');
  }, [userIsLogged, navigate]);

  useEffect(() => {
    const email = localStorage.getItem('@KyndClinicianEmail');

    if (email) {
      setForm({
        email,
        password: ''
      });
      setRememberMe(true);
    }
  }, [rememberMe]);

  const handleRememberMe = useCallback(() => {
    if (rememberMe) {
      localStorage.removeItem('@KyndClinicianEmail');
    }

    setRememberMe(!rememberMe);
  }, [rememberMe]);

  useEffect(() => {
    if (rememberMe) {
      localStorage.setItem('@KyndClinicianEmail', form.email);
    }
  }, [form.email, rememberMe]);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <img src={Logo} alt="Logo KYND" />

        <h2>CLINICIAN APP</h2>

        <Typography component="h1" variant="h5">
          Sign in
        </Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={form.email}
            onChange={(e) => setForm({ ...form, email: e.target.value })}
            autoComplete="email"
            autoFocus
          />

          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={form.password}
            onChange={(e) => setForm({ ...form, password: e.target.value })}
            autoComplete="current-password"
          />

          <FormControlLabel
            checked={rememberMe}
            control={
              <Checkbox
                checked={rememberMe}
                color="primary"
                onChange={handleRememberMe}
              />
            }
            label="Remember me"
          />

          <LoadingButton
            type="submit"
            fullWidth
            loading={loading}
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 3 }}
          >
            Sign In
          </LoadingButton>

          <Grid container>
            <Grid item xs>
              {/* <Link href="#" variant="body2">
                Forgot password?
              </Link> */}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};
