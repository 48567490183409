export const getNewQuestionnairesAnswered = (
  currentAnsweredList: Record<string, boolean>,
  answersSnapshot: Record<string, boolean>
) => {
  const questionnairesKeys = Object.keys(currentAnsweredList);

  const questionnairesAnswered = [] as string[];

  questionnairesKeys.forEach((key) => {
    const currentAnswered = currentAnsweredList[key];
    const snapshotAnswered = answersSnapshot[key];

    if (!snapshotAnswered && currentAnswered) {
      questionnairesAnswered.push(key);
    }
  });

  return questionnairesAnswered;
};
