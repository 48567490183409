import { formatDate, getUnixSeconds } from './formatDate';

import { HealthCheck } from '../models/user';

export interface MessageContent {
  message: string;
  submissionDate: string;
}

interface HealthCheckMessages {
  clinicalObservations: MessageContent[];
  userMessages: MessageContent[];
}

export const getHealthCheckMessages = (
  userHealthChecks: HealthCheck[]
): HealthCheckMessages => {
  const clinicalObservations = [] as MessageContent[];
  const userMessages = [] as MessageContent[];

  userHealthChecks.sort((checkA, checkB) => {
    const aUnix = getUnixSeconds(checkA.createdAt);
    const bUnix = getUnixSeconds(checkB.createdAt);

    return aUnix > bUnix ? 1 : -1;
  });

  userHealthChecks.forEach(
    ({ clinicalObservation, userMessage, createdAt }) => {
      const checkFormattedDate = formatDate({
        date: createdAt
      });

      if (clinicalObservation) {
        clinicalObservations.push({
          message: clinicalObservation,
          submissionDate: checkFormattedDate
        });
      }

      if (userMessage) {
        userMessages.push({
          message: userMessage,
          submissionDate: checkFormattedDate
        });
      }
    }
  );

  return {
    clinicalObservations,
    userMessages
  };
};
