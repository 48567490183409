import { isEmpty } from 'lodash';
import { JobHealthHazardsHealthCheck } from '../models/jobHealthHazards/JobHealthHazardsHealthCheck';

export function hasJobHealthHazardFilesToUpload(
  exams: JobHealthHazardsHealthCheck
) {
  const questionnairesExams = Object.values(exams);

  return questionnairesExams.some(
    (questionnaire) => !isEmpty(questionnaire?.exams)
  );
}
