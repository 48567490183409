import { cloneDeep, get, isEmpty, unset } from 'lodash';
import { User } from '../models/user';

interface FilterUserAnsweredQuestionnairesProps {
  category: string;
  user: User;
  questionnairesToAdd: string[];
}

export const filterUserAnsweredQuestionnaires = ({
  category,
  user,
  questionnairesToAdd
}: FilterUserAnsweredQuestionnairesProps) => {
  const copyUser = cloneDeep(user);
  const userAnswers = get(copyUser, `answers.${category}`);

  const sections = Object.keys(bodySections);

  sections.forEach((section) => {
    const sectionAnswers = get(userAnswers, section, {});

    const sectionQuestionnaires: string[] = get(bodySections, section);

    sectionQuestionnaires.forEach((questionnaire) => {
      if (!questionnairesToAdd.includes(questionnaire)) {
        unset(copyUser, `answers.${category}.${section}.${questionnaire}`);
        unset(copyUser, `scores.${questionnaire}`);
      }
    });

    if (isEmpty(sectionAnswers)) {
      unset(copyUser, `answers.${category}.${section}`);
    }
  });

  if (isEmpty(get(copyUser, `answers.${category}`))) {
    unset(copyUser, `answers.${category}`);
  }

  return copyUser;
};

const bodySections = {
  bloodPressure: ['systolicDiastolic'],
  hbA1C: ['hbA1C'],
  lungFunction: ['lungFunction'],
  physical: ['weight', 'height', 'waistCircumference'],
  restingHeartRate: ['restingHeartRate'],
  smokingAndAlcohol: ['smoking', 'alcohol'],
  jobHealthHazards: [
    'visionScreen',
    'criticalRole',
    'hearingScreen',
    'hearingSurvey',
    'eyesightSurvey',
    'hearingCheck',
    'visionCheck',
    'noiseLevelMonitor'
  ],
  cholesterol: [
    'hdl',
    'totalCholesterol',
    'ldl',
    'ratioCholesterolHdl',
    'triglycerides'
  ]
};
