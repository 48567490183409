import React from 'react';

import { AuthProvider } from './auth';
import { HealthCheckProvider } from './healthCheck';
import { OrganisationProvider } from './organisations';
import { QuestionsProvider } from './questions';
import { SidebarProvider } from './sidebar';
import { ToastProvider } from './toast';

export const AppProvider: React.FC = ({ children }) => (
  <ToastProvider>
    <AuthProvider>
      <OrganisationProvider>
        <QuestionsProvider>
          <SidebarProvider>
            <HealthCheckProvider>{children}</HealthCheckProvider>
          </SidebarProvider>
        </QuestionsProvider>
      </OrganisationProvider>
    </AuthProvider>
  </ToastProvider>
);
