import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const UserName = styled.h2`
  position: relative;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333cc;

  div {
    display: flex;

    p {
      margin: 0 3px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media (max-width: 500px) {
      margin-top: 50px;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;

    background: transparent;
    border: none;
    border-radius: 5px;
    height: 100%;

    transition: background-color 0.2s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    svg {
      pointer-events: none;
    }
  }

  button:first-child {
    left: 0;
    width: 60px;
  }

  button:last-child {
    right: 0;
    width: fit-content;
    padding: 0 3px;
  }
`;

export const UserData = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const UserDataColumn = styled.div`
  margin-bottom: 10px;

  @media (max-width: 600px) {
    margin-bottom: 0;
  }
`;

export const DataRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;

  color: #333333aa;
  max-width: 100%;
  border-radius: 5px;
  padding: 3px;
  margin: 2px 0 2px;
  background-color: rgba(0, 0, 0, 0.05);

  transition: background-color 0.1s;

  @media (max-width: 600px) {
    margin: 2px 0;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  strong {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #333333aa;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const ScoreTitle = styled.h4`
  margin: 0 0 3px;
  color: #333333cc;
`;

export const ScoresWrapper = styled.footer`
  margin-bottom: 10px;

  display: grid;
  gap: 10px;
  grid-template-areas:
    'a b b b'
    'a c c c';

  @media (max-width: 700px) {
    grid-template-areas:
      'a'
      'b'
      'c';
  }
`;

export const ScoreBlock = styled.div`
  display: flex;
  gap: 10px;
  flex: 1;

  @media (max-width: 700px) {
    flex-direction: column;
  }

  &.area-1 {
    grid-area: a;
  }

  &.area-2 {
    grid-area: b;
  }

  &.area-3 {
    grid-area: c;
  }
`;

export const ScoreData = styled.div`
  position: relative;
  overflow: hidden;

  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 3px 3px 6px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 10px;

  transition: transform 0.1s, background-color 0.1s;

  &.average {
    flex-direction: column;
    justify-content: center;
  }

  &:hover {
    transform: scale(1.03);
    background-color: rgba(0, 0, 0, 0.03);
  }

  img {
    height: 40px;
    width: 40px;
    opacity: 0.5;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    p {
      margin: 0;
    }

    strong {
      color: #333333aa;
      font-size: 30px;
      font-weight: bold;
    }
  }
`;

interface ColorLabelProps {
  backgroundColor: string;
}

export const ColorLabel = styled.span<ColorLabelProps>`
  position: absolute;
  bottom: 0px;
  right: -15px;

  background-color: ${(props) => props.backgroundColor};

  height: 15px;
  width: 40px;
  transform: rotate(-45deg);
`;

interface CriticalDataLineProps {
  addExtraGridColumn: boolean;
}

export const CriticalDataLine = styled.div<CriticalDataLineProps>`
  width: 100%;
  display: grid;
  column-gap: 10px;
  row-gap: 2.5px;
  grid-template-columns: repeat(
    ${({ addExtraGridColumn }) => (addExtraGridColumn ? 5 : 4)},
    1fr
  );

  margin-bottom: 8px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

export const CriticalData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  gap: 5px;

  padding: 3px;
  border-radius: 5px;

  background-color: rgba(0, 0, 0, 0.05);

  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  strong {
    color: #333333cc;
    font-weight: 500;
  }

  svg {
    pointer-events: none;
    color: #3dd4cb;
  }
`;

export const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  margin: 10px 0;
`;

export const CategoryTitle = styled.h4`
  margin: 0 0 3px;
  color: #333333cc;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 3px;
`;

export const MessagesWrapper = styled.footer`
  padding: 10px;
  color: #333333cc;

  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
`;

export const MessageHeader = styled.h2`
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid #737373;

  display: flex;
  align-items: center;
  gap: 20px;
`;

interface MessageWrapperProps {
  isCurrentCheck?: boolean;
}

export const MessageWrapper = styled.div<MessageWrapperProps>`
  background-color: ${(props) =>
    props.isCurrentCheck ? '#9ae6e1' : '#f2f5f9'};
  border-radius: 10px;

  width: 100%;
  padding: 10px;
  margin-top: 10px;

  h4 {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 5px;
    border-radius: 5px;
  }

  p {
    padding: 5px;
  }
`;

export const UserActivation = styled.div`
  margin: 0;
  padding: 0;
  cursor: help;

  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    height: 1.25rem;
    width: 1.25rem;
  }

  transition: transform 0.2s;

  &:hover {
    transform: scale(1.15);
  }
`;
