import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { getUploadedImagesUrl } from '../../../../../../functions/jobHealthHazards/getUploadedExams';
import { useHealthCheck } from '../../../../../../hooks/healthCheck';
import { Gallery } from '../../../../../Gallery';
import { Title } from './styles';

export function Hearing() {
  const { jobHealthHazardsHealthCheck, untouchedUser, isUserDetailsPage } =
    useHealthCheck();

  const [audiograms, setAudiograms] = useState([] as string[]);
  const [isFetchingExams, setIsFetchingExams] = useState(false);

  useEffect(() => {
    if (!isEmpty(audiograms) || isFetchingExams) return;

    const imageExams =
      jobHealthHazardsHealthCheck?.hearing?.uploadedExams || [];

    // If we are in the User Details page, we must show the files stored inside the user.
    if (isUserDetailsPage || !isEmpty(imageExams)) {
      const uploadedExams = isUserDetailsPage
        ? untouchedUser.exams?.hearing?.uploadedExams
        : imageExams;

      if (isEmpty(uploadedExams)) return;

      setIsFetchingExams(true);
      uploadedExams?.forEach((path) => {
        getUploadedImagesUrl(path).then((result) => {
          setAudiograms((prev) => [...prev, result]);
        });
      });
      return;
    }

    // If it is an ongoing health with new uploaded images, we must show the images that are being uploaded;
    if (jobHealthHazardsHealthCheck?.hearing?.exams) {
      setAudiograms(jobHealthHazardsHealthCheck?.hearing?.exams || []);
    }
  }, [
    isFetchingExams,
    isUserDetailsPage,
    jobHealthHazardsHealthCheck?.hearing,
    untouchedUser.exams?.hearing?.uploadedExams,
    audiograms
  ]);

  return (
    <>
      {!isEmpty(audiograms) && (
        <>
          <Title>Hearing</Title>
          <Gallery title="Audiograms" imageList={audiograms} />
        </>
      )}
    </>
  );
}
