import { CancelOutlined } from '@mui/icons-material';
import { Modal } from '@mui/material';
import { get } from 'lodash';
import Markdown from 'markdown-to-jsx';
import { useEffect, useState } from 'react';

import { formatDate, getUnixSeconds } from '../../../utils/formatDate';
import {
  HazardAction,
  HearingVisionAlert
} from '../../../models/jobHealthHazards/hearingVisionAlert';

import {
  Container,
  CloseButton,
  DismissButton,
  MessageBody,
  ModalContent,
  ModalFooter,
  SubjectLine,
  ActionsWrapper,
  ActionWrapper,
  ActionAuthor,
  ActionMessage,
  ActionStatus,
  ActionTimestamp
} from './actionsModalStyles';

interface ActionsModalProps {
  alert: HearingVisionAlert;
  alertType: string;
  isModalOpen: boolean;
  handleCloseModal: () => void;
}

export const ActionsModal = ({
  alert,
  alertType,
  isModalOpen,
  handleCloseModal
}: ActionsModalProps) => {
  const [userName, setUserName] = useState('');
  const [status, setStatus] = useState('Open');
  const [actions, setActions] = useState([] as HazardAction[]);

  useEffect(() => {
    const alertActions = get(alert, 'actions', []) as HazardAction[];

    const filteredActions = alertActions.filter(
      ({ questionnaire }) => questionnaire === alertType
    );

    const sorttedActions = filteredActions.sort((a, b) => {
      const unixA = getUnixSeconds(a.timestamp);
      const unixB = getUnixSeconds(b.timestamp);

      return unixA > unixB ? 1 : -1;
    });

    const userFisrtName = get(alert, 'user.firstName', '');
    const userLastName = get(alert, 'user.lastName', '');

    const lastIndex = sorttedActions.length - 1;
    const lastStatus = get(sorttedActions, `[${lastIndex}].status`, '');

    setStatus(lastStatus);
    setActions(sorttedActions);
    setUserName(`${userFisrtName} ${userLastName}`);
  }, [alert, alertType]);

  return (
    <Container>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <ModalContent>
          <h3>Actions taken for {userName}</h3>

          <CloseButton
            title="Close actions modal"
            type="button"
            onClick={handleCloseModal}
          >
            <CancelOutlined />
          </CloseButton>

          <SubjectLine>
            <strong>Questionnaire: {alertType}</strong>
          </SubjectLine>

          <SubjectLine>
            <strong>Status: {status}</strong>
          </SubjectLine>

          <MessageBody>
            <strong>Actions taken</strong>

            <ActionsWrapper>
              {actions.map(
                ({ actionUser, timestamp, status: alertStatus, comment }) => (
                  <ActionWrapper>
                    <ActionAuthor>
                      Action taken by:{' '}
                      {`${actionUser.firstName} ${actionUser.lastName}`}
                    </ActionAuthor>

                    <ActionTimestamp>
                      Date: {formatDate({ date: timestamp as any })}
                    </ActionTimestamp>

                    <ActionStatus>Status: {alertStatus}</ActionStatus>

                    <ActionMessage>
                      <Markdown>{comment}</Markdown>
                    </ActionMessage>
                  </ActionWrapper>
                )
              )}
            </ActionsWrapper>
          </MessageBody>

          <ModalFooter>
            <DismissButton type="button" onClick={handleCloseModal}>
              Dismiss
            </DismissButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};
