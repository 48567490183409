import styled from 'styled-components';

export const QuestionWrapper = styled.div``;

export const QuestionTitle = styled.div`
  margin-top: 5px;
  font-weight: 500;
`;

export const OptionsAnswerContainer = styled.div`
  padding-top: 10px;
  padding-left: 10px;
`;

export const ContainerRadio = styled.label`
  display: flex;
  gap: 5px;
  margin-bottom: 0.7rem;
`;
