import { concat, orderBy, trim } from 'lodash';

import { useMemo } from 'react';
import { Column } from 'react-table';

import { Table } from '../../../../components/Table';
import { useQuestions } from '../../../../hooks/questions';
import { SectionProps, SectionQuestionnaireData } from '../CategoryStats';

import { ColorCard, MeasurementButton } from './styles';

interface TableViewProps {
  sections: SectionProps[];
  isLife?: boolean;
  onRowClick: (
    data: SectionQuestionnaireData,
    questionnaireIndex: number
  ) => void;
}

interface TableRows {
  measurement: JSX.Element;
  completed: number;
  red: JSX.Element;
  orange: JSX.Element;
  green: JSX.Element;
}

export const TableView: React.FC<TableViewProps> = ({
  sections,
  isLife,
  onRowClick
}) => {
  const { lifeQuestions } = useQuestions();
  const tableColumns: Column<TableRows>[] = useMemo(
    () => [
      {
        Header: `Measurement`,
        accessor: 'measurement'
      },
      {
        Header: 'Completed',
        accessor: 'completed'
      },
      {
        Header: 'Green',
        accessor: 'green'
      },
      {
        Header: 'Orange',
        accessor: 'orange'
      },
      {
        Header: 'Red',
        accessor: 'red'
      }
    ],
    []
  );

  const tableData = useMemo(() => {
    const tableRows = [] as TableRows[];

    let questionnaires: any[] = [];
    sections.map(({ questionnairesData }) => {
      questionnaires = concat(questionnaires, questionnairesData);
    });

    questionnaires.map((data, questionnaireIndex) => {
      questionnaires[questionnaireIndex].questionnaire = isLife
        ? trim(lifeQuestions[questionnaireIndex].label)
        : trim(data.questionnaire);
    });

    questionnaires = orderBy(questionnaires, ['questionnaire'], ['asc']);

    questionnaires.map((data, questionnaireIndex) => {
      const tableRow = {
        measurement: (
          <MeasurementButton
            title="Click to see more info"
            type="button"
            onClick={() => onRowClick(data, questionnaireIndex)}
          >
            <img
              src={
                /* eslint-disable */
                require(`../../../../assets/${
                  data.icon || 'life'
                }.svg`).default
                /* eslint-enable */
              }
              alt={data.icon}
            />
            <p>{data.questionnaire}</p>
          </MeasurementButton>
        ),
        completed: data.totalUsers,
        green: <ColorCard color="#5dc45d">{data.green}</ColorCard>,
        orange: <ColorCard color="#ffa500">{data.orange}</ColorCard>,
        red: <ColorCard color="#fc6161">{data.red}</ColorCard>
      };

      tableRows.push(tableRow);
    });

    return tableRows;
  }, [sections, onRowClick, isLife, lifeQuestions]);

  return <Table columns={tableColumns} data={tableData} rowHeight={35} />;
};
