import firebase from 'firebase/compat/app';
import moment from 'moment';
import { cloneDeep, get, isEmpty, set, startsWith, unset } from 'lodash';
import * as Sentry from '@sentry/react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

import {
  firestore,
  isProduction,
  realtimeDatabase
} from '../services/firebase';

import { HealthCheck, OngoingHealthCheck, User } from '../models/user';
import { ClinicianNotification, Notification } from '../models/notification';
import {
  AnswerHistoryProps,
  ScoreHistoryProps
} from '../utils/generateUserHistories';
import { JobHealthHazardsHealthCheck } from '../models/jobHealthHazards/JobHealthHazardsHealthCheck';

const collectionUsers = firestore.collection('users');
const collectionHealthChecks = firestore.collection('healthChecks');

const answersHistoriesCollection = firestore.collection(
  'usersAnswersHistories'
);
const scoresHistoriesCollection = firestore.collection('usersScoresHistories');

export const updateUser = async (user: User) => {
  const userCopy = cloneDeep(user);
  try {
    unset(userCopy, 'healthChecks');
    unset(userCopy, 'clients');
    unset(userCopy, 'notifications');
    unset(userCopy, 'notificationsSent');

    await collectionUsers.doc(user.id).set(userCopy, { merge: true });

    return user;
  } catch (error) {
    console.error(error);
    if (isProduction) {
      Sentry.captureException(error);
    }

    throw new Error('Fail to update user');
  }
};

export const updateUserExams = async (
  userExams: JobHealthHazardsHealthCheck,
  user: User
) => {
  try {
    if (isEmpty(userExams)) return;

    const examsToUpdate: any =
      cloneDeep(user?.exams) || ({} as JobHealthHazardsHealthCheck);

    Object.keys(userExams).forEach((key) => {
      const healthCheckExams = get(
        userExams,
        `${key}.uploadedExams`,
        []
      ) as string[];
      const currentExams = get(examsToUpdate, `${key}.uploadedExams`, []);
      const newExams = healthCheckExams.filter(
        (exam) => !currentExams.includes(exam)
      );

      if (!isEmpty(newExams)) {
        const updatedExams = currentExams.concat(newExams);
        set(examsToUpdate, `${key}.uploadedExams`, updatedExams);
      }
    });

    await collectionUsers.doc(user.id).update({ exams: examsToUpdate });
    return examsToUpdate;
  } catch (error) {
    console.error(error);
    if (isProduction) {
      Sentry.captureException(error);
    }

    throw new Error('Fail to update user exams');
  }
};

export const updateUserAnswersAndScores = async (
  user: User,
  categories: string[]
) => {
  const catogoriesToUpdate = {} as any;

  try {
    categories.forEach((category) => {
      const answersPath = `answers.${category}`;
      const scoresPath = `scores.${category}`;

      catogoriesToUpdate[answersPath] = get(user, answersPath, 0);
      catogoriesToUpdate[scoresPath] = get(user, scoresPath, 0);
    });

    catogoriesToUpdate[`scores.averageScore`] = get(
      user,
      `scores.averageScore`,
      {}
    );

    await collectionUsers.doc(user.id).update(catogoriesToUpdate);

    return user;
  } catch (error) {
    console.error(error);
    if (isProduction) {
      Sentry.captureException(error);
    }

    throw new Error('Fail to update user');
  }
};

export const updateClinicianClients = async (
  clinicianId: string,
  userId: string,
  healthChecks: HealthCheck[]
) => {
  const lastItemIndex = healthChecks.length - 1;

  try {
    await collectionUsers
      .doc(clinicianId)
      .collection('clients')
      .doc(userId)
      .set(
        {
          healthChecks: firebase.firestore.FieldValue.arrayUnion(
            healthChecks[lastItemIndex]
          )
        },
        { merge: true }
      );

    return healthChecks;
  } catch (error) {
    console.error(error);
    if (isProduction) {
      Sentry.captureException(error);
    }

    throw new Error('Fail to update clinician clients');
  }
};

export const updateUserHealthChecks = async (
  clinician: User,
  userId: string,
  healthChecks: HealthCheck[]
) => {
  const lastItemIndex = healthChecks.length - 1;

  try {
    if (!clinician || !clinician.id) {
      console.log({ clinician });
      throw new Error('#Missing clinician');
    }

    if (!userId) {
      console.log({ userId });
      throw new Error('#Missing userId');
    }

    if (!healthChecks || !healthChecks[lastItemIndex]) {
      console.log({ healthChecks, lastItemIndex });
      throw new Error('#Missing Health Checks');
    }

    await collectionUsers
      .doc(userId)
      .collection('healthChecks')
      .doc(clinician.id)
      .set(
        {
          healthChecks: firebase.firestore.FieldValue.arrayUnion(
            healthChecks[lastItemIndex]
          )
        },
        { merge: true }
      );

    await collectionHealthChecks.add({
      ...healthChecks[lastItemIndex],
      user: {
        ...healthChecks[lastItemIndex].user,
        // eslint-disable-next-line prettier/prettier
        lowerFirstName:
          healthChecks[lastItemIndex].user.firstName.toLowerCase(),
        lowerLastName: healthChecks[lastItemIndex].user.lastName.toLowerCase()
      },
      index: lastItemIndex,
      clinician
    });

    return healthChecks;
  } catch (error: any) {
    const typedError = error as Error;

    console.error(error);
    if (isProduction) {
      Sentry.captureException(error);
    }

    if (startsWith(typedError.message, '#')) {
      throw new Error(error.message);
    }

    throw new Error('Fail to update user health checks');
  }
};

export const getClinicianClients = async (clinicianId: string) => {
  const clientsFormatted: Record<string, HealthCheck[]> = {};

  try {
    const clients = await collectionUsers
      .doc(clinicianId)
      .collection('clients')
      .get();

    clients.docs.forEach((client) => {
      const clientData = client.data() as Record<string, HealthCheck[]>;
      clientsFormatted[client.id] = { ...clientData.healthChecks };
    });

    return clientsFormatted;
  } catch (error) {
    console.error(error);

    if (isProduction) {
      Sentry.captureException(error);
    }

    throw new Error('Fail to get clinician clients');
  }
};

export const getUsers = async () => {
  const usersFormatted: User[] = [];

  try {
    const users = await collectionUsers.get();

    users.docs.forEach((user) => {
      const userData = user.data() as Omit<User, 'id'>;

      usersFormatted.push({
        id: user.id,
        ...userData
      });
    });

    return usersFormatted;
  } catch (error) {
    console.error(error);
    if (isProduction) {
      Sentry.captureException(error);
    }

    throw new Error('Fail to get users');
  }
};

interface HealthCheckProps {
  clinicianId: string;
  healthChecks: HealthCheck[];
}

export const getUserHealthChecks = async (userId: string) => {
  const healthChecksFormatted: HealthCheckProps[] = [];

  try {
    const healthChecksSnapshot = await collectionUsers
      .doc(userId)
      .collection('healthChecks')
      .get();

    healthChecksSnapshot.docs.forEach((check) => {
      const checkData = check.data() as Omit<HealthCheckProps, 'clinicianId'>;

      healthChecksFormatted.push({
        clinicianId: check.id,
        ...checkData
      });
    });

    return healthChecksFormatted;
  } catch (error) {
    console.error(error);
    if (isProduction) {
      Sentry.captureException(error);
    }

    throw new Error('Fail to get user health checks');
  }
};

export const getFilteredUsers = async (organisation?: string) => {
  const usersFormatted: User[] = [];

  try {
    const users = await collectionUsers
      .where('organisation.name', '==', organisation)
      .get();
    users.docs.forEach((user) => {
      const userData = user.data() as Omit<User, 'id'>;

      usersFormatted.push({
        id: user.id,
        ...userData
      });
    });

    return usersFormatted;
  } catch (error) {
    console.error(error);
    if (isProduction) {
      Sentry.captureException(error);
    }

    throw new Error('Fail to get filtered users');
  }
};

export const getUserData = async (id: string): Promise<User | undefined> => {
  try {
    const user = await collectionUsers.doc(id).get();

    return user.data() as User;
  } catch (error) {
    console.error(error);
    if (isProduction) {
      Sentry.captureException(error);
    }

    throw new Error('Fail to get user data');
  }
};

export const addClinicianNotification = async (
  clinicianId: string,
  notification: ClinicianNotification
) => {
  try {
    await collectionUsers
      .doc(clinicianId)
      .collection('notificationsSent')
      .add(notification);

    return notification;
  } catch (error) {
    console.error(error);
    if (isProduction) {
      Sentry.captureException(error);
    }

    throw new Error('Fail to add clinician notification');
  }
};

export const updateUserOngoingHealthCheck = async (
  userId: string,
  ongoingHealthCheck: OngoingHealthCheck
) => {
  try {
    if (!userId) {
      throw new Error('#Missing user id updating ongoing health check');
    }

    const ongoingHealthChecksCollection = firestore.collection(
      'ongoingHealthChecks'
    );

    const checkCopy = cloneDeep(ongoingHealthCheck);
    unset(checkCopy, 'id');

    await ongoingHealthChecksCollection
      .doc(userId)
      .set({ ...checkCopy }, { merge: true });

    console.log('Ongoing Health Check added for userId: ', userId);

    return ongoingHealthCheck;
  } catch (error: any) {
    const typedError = error as Error;

    console.log('Failed saving ongoing health check for userId: ', userId);
    console.log({ userId, ongoingHealthCheck });
    console.log(error);

    if (isProduction) {
      Sentry.captureException(error);
    }

    const errorMessage = startsWith(typedError.message, '#')
      ? typedError.message
      : 'Fail to update user ongoing health check';

    throw new Error(errorMessage);
  }
};

export const getOrgOngoingHealthChecks = async (orgId: string) => {
  if (!orgId) {
    throw new Error('#Missing org id searching for health checks');
  }

  const ongoingHealthChecksCollection = firestore.collection(
    'ongoingHealthChecks'
  );

  const ongoingHealthCheckSnaptshot = await ongoingHealthChecksCollection
    .where('user.organisation.id', '==', orgId)
    .where('submitted', '==', false)
    .get();

  const ongoingHealthChecks = ongoingHealthCheckSnaptshot.docs.map((data) => {
    return {
      id: data.id,
      ...data.data()
    };
  });

  return ongoingHealthChecks as OngoingHealthCheck[];
};

export const getUserHistories = async (userId: string) => {
  const formattedHistories = {
    answers: [] as AnswerHistoryProps[],
    scores: [] as ScoreHistoryProps[]
  };

  const answersSnapshot = await answersHistoriesCollection
    .where('userId', '==', userId)
    .get();
  const scoresSnapshot = await scoresHistoriesCollection
    .where('userId', '==', userId)
    .get();

  const answers = answersSnapshot.docs;
  const scores = scoresSnapshot.docs;

  answers.map((answerData) => {
    const answer = answerData.data() as AnswerHistoryProps;
    formattedHistories.answers.push(answer);
  });

  scores.map((scoreData) => {
    const score = scoreData.data() as ScoreHistoryProps;
    formattedHistories.scores.push(score);
  });

  return formattedHistories;
};

export const getClinicianNotifications = async (
  clinicianId: string,
  organisationId: string
) => {
  try {
    const formattedNotifications = [] as ClinicianNotification[];

    const notificationsSnapshot = await collectionUsers
      .doc(clinicianId)
      .collection('notificationsSent')
      .where('organisationId', '==', organisationId)
      .orderBy('createdAt', 'desc')
      .get();

    const rawNotifications = notificationsSnapshot.docs;

    rawNotifications.map((notification) => {
      const notData = notification.data() as Omit<ClinicianNotification, 'id'>;

      formattedNotifications.push({
        id: notification.id,
        ...notData
      });
    });

    return formattedNotifications;
  } catch (error) {
    console.error(error);
    if (isProduction) {
      Sentry.captureException(error);
    }

    throw new Error('Fail clinician notifications');
  }
};

export const addUserNotification = async (
  userId: string,
  notification: Pick<
    Notification,
    'title' | 'body' | 'clinicianName' | 'clinicianId'
  >
) => {
  try {
    const newNotification = {
      seen: false,
      createdAt: moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
      isFromClinician: true,
      ...notification
    };

    await collectionUsers
      .doc(userId)
      .collection('notifications')
      .add(newNotification);

    return newNotification;
  } catch (error) {
    console.error(error);
    if (isProduction) {
      Sentry.captureException(error);
    }

    throw new Error('Fail to add user notification');
  }
};

export const getUserClinicalNotifications = async (userId: string) => {
  try {
    const notificationsSnaptshot = await collectionUsers
      .doc(userId)
      .collection('notifications')
      .where('isFromClinician', '==', true)
      .get();

    const notifications = [] as Notification[];

    notificationsSnaptshot.docs.map((doc) => {
      const notificationObject = {
        id: doc.id,
        ...doc.data()
      } as Notification;

      notifications.push(notificationObject);
    });

    return notifications;
  } catch (error) {
    console.log('Error: ', error);
    if (isProduction) {
      Sentry.captureException(error);
    }

    return [] as Notification[];
  }
};

export const checkIfUserIsActive = async (userId: string) => {
  try {
    const userSnaptshot = await collectionUsers.doc(userId).get();
    const user = userSnaptshot.data() as User;

    return user.isActive;
  } catch (error) {
    console.log('Error: ', error);
    if (isProduction) {
      Sentry.captureException(error);
    }
  }
};

export const resetUserPassword = async (userEmail: string) => {
  try {
    const firebaseAuth = getAuth();

    await sendPasswordResetEmail(firebaseAuth, userEmail);

    return userEmail;
  } catch (error) {
    console.error(error);
    if (isProduction) {
      Sentry.captureException(error);
    }
  }
};

export const updateMobileUserTrigger = async (userId: string) => {
  try {
    await realtimeDatabase.ref(`/usersUpdated/${userId}`).update({
      shouldUpdateMobileUser: true
    });

    return true;
  } catch (error) {
    console.error(error);

    if (isProduction) {
      Sentry.captureException(error);
    }

    return false;
  }
};
