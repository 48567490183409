import * as Sentry from '@sentry/react';
import { firestore } from '../services/firebase';
import { Question } from '../models/question';

const collectionQuestions = firestore.collection('questions');

export const getQuestions = async () => {
  const questionsFormatted: Question[] = [];

  try {
    const questions = await collectionQuestions.get();

    questions.docs.forEach((question) => {
      const questionData = question.data() as Omit<Question, 'id'>;

      questionsFormatted.push({
        id: question.id,
        ...questionData
      });
    });

    return questionsFormatted;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw Error('Fail to get questions');
  }
};

export const getQuestionData = async (
  questionId: string
): Promise<Question | undefined> => {
  try {
    const question = await collectionQuestions.doc(questionId).get();

    return question.data() as Question;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error('Fail to get question data');
  }
};
