import Markdown from 'markdown-to-jsx';
import { useState } from 'react';
import { ClinicianNotification } from '../../../../models/notification';
import { formatDate } from '../../../../utils/formatDate';
import {
  Container,
  HeaderTopic,
  NotificationBody,
  NotificationHeader
} from './styles';

interface NotificationCardProps {
  notification: ClinicianNotification;
}

export const NotificationCard = ({ notification }: NotificationCardProps) => {
  const [isSeeingNotificationDetails, setIsSeeingNotificationDetails] =
    useState(false);

  return (
    <Container
      onClick={() =>
        setIsSeeingNotificationDetails(!isSeeingNotificationDetails)
      }
      style={{ height: isSeeingNotificationDetails ? 'max-content' : '136px' }}
      title={
        isSeeingNotificationDetails
          ? 'Click to collapse notification details'
          : 'Click to expand notification details'
      }
    >
      <NotificationHeader>
        <HeaderTopic>
          <strong>User</strong>
          <p>{notification.userName}</p>
        </HeaderTopic>

        <HeaderTopic>
          <strong>Sent</strong>
          <p>
            {formatDate({
              date: notification.createdAt as any
            })}
          </p>
        </HeaderTopic>

        <HeaderTopic>
          <strong>Title</strong>
          <p>{notification.title}</p>
        </HeaderTopic>
      </NotificationHeader>

      <NotificationBody>
        <Markdown>{notification.body}</Markdown>
      </NotificationBody>
    </Container>
  );
};
