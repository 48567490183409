import { CancelOutlined } from '@mui/icons-material';
import { Modal } from '@mui/material';
import { get, set } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

import { AlertStatusFilter } from './AlertStatusFilter';
import { updateAlert } from '../../../functions/hearingVisionAlerts';
import {
  HazardAction,
  HearingVisionAlert
} from '../../../models/jobHealthHazards/hearingVisionAlert';

import {
  Container,
  ModalContent,
  CloseButton,
  SubjectLine,
  MessageBody,
  SubmitButton,
  ModalFooter
} from './updateStatusModalStyles';

interface UpdateStatusModalProps {
  isModalOpen: boolean;
  handleCloseModal: (reloadAlerts?: boolean) => Promise<void>;
  alert: HearingVisionAlert;
  currentAlertType: 'Hearing Screen' | 'Vision Screen';
}

interface AlertDataProps {
  status: 'Open' | 'In Progress' | 'Resolved' | undefined;
  currentAction: string;
}

export const UpdateStatusModal = ({
  isModalOpen,
  handleCloseModal,
  alert,
  currentAlertType
}: UpdateStatusModalProps) => {
  const { addToast } = useToast();
  const { user: authUser } = useAuth();

  const [alertData, setAlertData] = useState({} as AlertDataProps);
  const [isSubmiting, setIsSubmiting] = useState(false);

  useEffect(() => {
    setAlertData({
      status:
        currentAlertType === 'Hearing Screen'
          ? alert?.hearingStatus
          : alert?.visionStatus,
      currentAction: ''
    });
  }, [alert, currentAlertType]);

  const submitAlertAction = useCallback(async () => {
    if (!authUser) return;

    setIsSubmiting(true);

    const { status, currentAction } = alertData;

    const actionTaken = {
      status,
      actionUser: {
        id: authUser.id,
        firstName: authUser.firstName,
        lastName: authUser.lastName,
        email: authUser.email,
        phone: authUser.phone,
        division: authUser.division,
        organisation: authUser.organisation
      },
      comment: currentAction.replace(/(?:\r\n|\r|\n)/g, ' <br/> '),
      timestamp: new Date(),
      questionnaire: currentAlertType
    } as HazardAction;

    const hearingStatus =
      currentAlertType === 'Hearing Screen'
        ? status
        : get(alert, 'hearingStatus');

    const visionStatus =
      currentAlertType === 'Vision Screen'
        ? status
        : get(alert, 'visionStatus');

    const hasHearingResolved =
      hearingStatus === 'Resolved' || hearingStatus === undefined;
    const hasVisionResolved =
      visionStatus === 'Resolved' || visionStatus === undefined;
    const hasAllResolved = hasHearingResolved && hasVisionResolved;

    const overallStatus = hasAllResolved ? 'Resolved' : 'Open';

    const updateProps = {
      status: overallStatus,
      actionTaken
    };

    if (hearingStatus !== undefined) {
      set(updateProps, 'hearingStatus', hearingStatus);
    }
    if (visionStatus !== undefined) {
      set(updateProps, 'visionStatus', visionStatus);
    }

    try {
      await updateAlert(alert.id, updateProps);
      setIsSubmiting(false);

      addToast({
        title: 'Alert updated',
        type: 'success'
      });

      handleCloseModal(true);
    } catch (error) {
      console.log(error);

      setIsSubmiting(false);
      addToast({
        title: 'Error updating alert',
        description: 'Contact support',
        type: 'error'
      });
    }
  }, [
    authUser,
    alertData,
    currentAlertType,
    alert,
    handleCloseModal,
    addToast
  ]);

  return (
    <Container>
      <Modal open={isModalOpen} onClose={() => handleCloseModal()}>
        <ModalContent>
          <h3>
            {`Update alert for ${get(alert, 'user.firstName', '')} ${get(
              alert,
              'user.lastName',
              ''
            )}`}
          </h3>

          <CloseButton
            title="Close update status modal"
            type="button"
            onClick={() => handleCloseModal()}
          >
            <CancelOutlined />
          </CloseButton>

          <SubjectLine>
            <strong>Status</strong>
            <AlertStatusFilter
              showLabel={false}
              selectedValue={alertData.status ?? 'Open'}
              handleChange={(value) =>
                setAlertData((prev) => ({
                  ...prev,
                  status: value
                }))
              }
            />
          </SubjectLine>

          <MessageBody>
            <strong>Action</strong>
            <textarea
              value={get(alertData, 'currentAction', '').replace(
                /<br\s?\/?>/g,
                '\n'
              )}
              onChange={(e) =>
                setAlertData((prev) => ({
                  ...prev,
                  currentAction: e.target.value
                }))
              }
              rows={4}
            />
          </MessageBody>
          <ModalFooter>
            <SubmitButton
              disabled={isSubmiting}
              type="button"
              onClick={submitAlertAction}
            >
              {isSubmiting ? 'Updating Alert...' : 'Update Alert'}
            </SubmitButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};
