import { isNumber } from 'lodash';
import { QuestionnaireProps } from '../hooks/questions';
import { ScoreProps } from '../models/user';
import { formatDate } from './formatDate';

interface UserScoreProps {
  color: string;
  score?: string;
  currentScore: string;
  userAdvice: string;
  clinicianAdvice: string;
  calculatedAt: string;
}

export const populateScores = (
  categoryScore: Record<string, ScoreProps> | undefined,
  questionnaires: QuestionnaireProps[]
) => {
  const categoryScoreObject = {} as Record<string, UserScoreProps>;

  questionnaires.map(({ camelCaseQuestionnaire }) => {
    const questionnaireScore = {
      color: 'transparent',
      currentScore: '',
      userAdvice: '',
      clinicianAdvice: '',
      calculatedAt: ''
    };

    if (categoryScore && categoryScore[camelCaseQuestionnaire]) {
      const {
        currentScore,
        score,
        color,
        userAdvice,
        clinicianAdvice,
        calculatedAt
      } = categoryScore[camelCaseQuestionnaire];

      const scoreValue = isNumber(currentScore) ? currentScore : score;

      questionnaireScore.color = color;
      questionnaireScore.currentScore = String(scoreValue);
      questionnaireScore.userAdvice = userAdvice;
      questionnaireScore.clinicianAdvice = clinicianAdvice;
      questionnaireScore.calculatedAt = formatDate({
        date: calculatedAt,
        showHour: true
      });
    }

    categoryScoreObject[camelCaseQuestionnaire] = questionnaireScore;
  });

  return categoryScoreObject;
};
