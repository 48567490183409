import { CancelOutlined } from '@mui/icons-material';
import { Modal } from '@mui/material';

import {
  AnswerCardsWrapper,
  CloseButton,
  ModalContent,
  ModalTitle,
  WhiteLine
} from './styles';
import { UserQuestionnaireAnswer } from '../../../../../models/user';
import { AnswerCard } from '../AnswerCard';

interface AnswersModalProps {
  isAnswersModalOpen: boolean;
  setIsAnswersModalOpen: (value: React.SetStateAction<boolean>) => void;
  questionnaire: string;
  answers: UserQuestionnaireAnswer[];
}

export const AnswersModal = ({
  isAnswersModalOpen,
  setIsAnswersModalOpen,
  questionnaire,
  answers
}: AnswersModalProps) => {
  return (
    <Modal
      open={isAnswersModalOpen}
      onClose={() => setIsAnswersModalOpen(false)}
    >
      <ModalContent>
        <ModalTitle>
          <h2>{questionnaire} answers</h2>

          <CloseButton
            title="Close answers"
            type="button"
            onClick={() => setIsAnswersModalOpen(false)}
          >
            <CancelOutlined />
          </CloseButton>
        </ModalTitle>

        <WhiteLine />

        <AnswerCardsWrapper>
          {answers?.map(({ answer, question }, questionIndex) => (
            <AnswerCard
              answer={answer}
              question={question}
              questionIndex={questionIndex}
            />
          ))}
        </AnswerCardsWrapper>
      </ModalContent>
    </Modal>
  );
};
