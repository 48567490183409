import styled, { css } from 'styled-components';

interface ContainerProps {
  isShowingMessage: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  right: 0;
  top: 100px;
  padding-bottom: 40px;
  padding-right: 20px;
  overflow: hidden;
  z-index: 2;

  ${(props) =>
    !props.isShowingMessage &&
    css`
      visibility: hidden;
    `}
`;
