import * as Sentry from '@sentry/react';
import { get } from 'lodash';
import { firebaseFunctions, isProduction } from '../../services/firebase';

export async function getUploadedImagesUrl(path: string): Promise<string> {
  const getFileDownloadLinkTemporary = firebaseFunctions.httpsCallable(
    'getFileDownloadLinkTemporary'
  );

  const result = await getFileDownloadLinkTemporary({
    filePath: path
  });

  if (get(result, 'data.code') !== 200) {
    console.error('Fail to get files from storage. ', result);
    if (isProduction) {
      Sentry.captureException(
        `Fail to get files from getFileDownloadLinkTemporary ${get(
          result,
          'data.message'
        )}`
      );
    }
    return '';
  }

  return (result?.data?.data as string) || '';
}
