import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 10px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 10px;
  grid-template-areas:
    'hint hint hint'
    'a a a'
    'b b c'
    'd e g'
    'f f f'
    'f f f'
    'h h h';

  @media (max-width: 1600px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  @media (max-width: 350px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 3px;
  }

  @media (max-width: 1000px) {
    grid-template-areas:
      'hint hint'
      'a a'
      'b b'
      'c c'
      'd d'
      'e e'
      'g g'
      'f f'
      'f f'
      'f f'
      'h h';
  }

  h6 {
    grid-area: hint;
    margin: 0;
  }
`;

export const QuestionnairesStatus = styled.div`
  box-shadow: 2px 2px 10px;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;

  & h3 {
    background-color: rgba(0, 0, 0, 0.05);
    text-align: center;
    border-radius: 5px;
    margin-bottom: 10px;
  }
`;

export const AnsweredQuestionnaires = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  gap: 3px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
`;

interface QuestionnaireTitleProps {
  isComplete: boolean;
}

export const QuestionnaireTitle = styled.p<QuestionnaireTitleProps>`
  display: flex;
  align-items: center;
  font-weight: bold;

  svg {
    height: 16px;
  }

  color: ${({ isComplete }) => (isComplete ? '#3DD4CB' : '#33333399')};
`;
