import { useCallback, useMemo } from 'react';
import { camelCase, cloneDeep, get } from 'lodash';

import { useHealthCheck } from '../../../../hooks/healthCheck';
import { QuestionnaireProps } from '../../../../hooks/questions';
import { AnswerCard } from '../AnswerCard';

import {
  Container,
  SectionTitle,
  QuestionnairesWrapper,
  QuestionnaireWrapper,
  QuestionnaireHeader,
  QuestionnaireIcon,
  QuestionnaireTitle,
  QuestionsWrapper,
  RatioValue
} from './styles';

interface SectionProps {
  sectionData: QuestionnaireProps[];
  children?: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
}

export function Section({ sectionData, children }: SectionProps) {
  const { bodyAnswers, answeredBodyQuestions } = useHealthCheck();

  const userCholesterolRatio = useMemo(() => {
    const bodyAnswersCopy = cloneDeep(bodyAnswers);
    const ratioAnswers = get(bodyAnswersCopy, 'ratioCholesterolHdl', []);

    const cholesterolValue = Number(ratioAnswers[0]);
    const hdlValue = Number(ratioAnswers[1]);

    const ratioValue = cholesterolValue / hdlValue;

    if (Number.isNaN(ratioValue) || !ratioValue || !Number.isFinite(ratioValue))
      return '-';

    const ratio = Math.round(ratioValue * 100) / 100;

    return ratio;
  }, [bodyAnswers]);

  const section = useMemo(() => {
    const sectionPascalCase = get(sectionData, '[0].section', '');
    const sectionCamelCase = camelCase(get(sectionData, '[0].section', ''));

    return {
      sectionPascalCase,
      sectionCamelCase
    };
  }, [sectionData]);

  const orderedSection = useMemo(() => {
    const sortedSection = sectionData.sort((a, b) =>
      a.questionnaire > b.questionnaire ? -1 : 1
    );

    return sortedSection;
  }, [sectionData]);

  const isQuestionnaireComplete = useCallback(
    (questionnaire: string) => {
      const isComplete = get(
        answeredBodyQuestions,
        camelCase(questionnaire),
        false
      );

      return isComplete;
    },
    [answeredBodyQuestions]
  );

  return (
    <Container className={section.sectionCamelCase}>
      <SectionTitle>{section.sectionPascalCase}</SectionTitle>

      <QuestionnairesWrapper>
        {orderedSection.map(({ questionnaire, icon, questions }) => (
          <QuestionnaireWrapper
            key={questionnaire}
            isComplete={isQuestionnaireComplete(questionnaire)}
          >
            <QuestionnaireHeader>
              <QuestionnaireIcon>
                <img
                  src={
                    // eslint-disable-next-line import/no-dynamic-require, global-require, @typescript-eslint/no-var-requires
                    require(`../../../../assets/${icon || 'life'}.svg`).default
                  }
                  alt={questionnaire}
                />
              </QuestionnaireIcon>

              <QuestionnaireTitle>{questionnaire}</QuestionnaireTitle>
            </QuestionnaireHeader>

            {questionnaire === 'Ratio (Cholesterol/HDL)' ? (
              <RatioValue>
                <p>Calculated automatically</p>
                <h1>{userCholesterolRatio}</h1>
              </RatioValue>
            ) : (
              <QuestionsWrapper>
                {questions
                  .sort((a, b) => (a.order > b.order ? 1 : -1))
                  .map((question, questionIndex) => (
                    <AnswerCard
                      key={question.id}
                      answers={bodyAnswers}
                      questionIndex={questionIndex}
                      question={question}
                    />
                  ))}
              </QuestionsWrapper>
            )}
            {children}
          </QuestionnaireWrapper>
        ))}
      </QuestionnairesWrapper>
    </Container>
  );
}
