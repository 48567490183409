import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import lodash from 'lodash';

import { PageTitleWrapper } from '../../../components/PageTitleWrapper';
import { OrganisationFilter } from '../../../components/OrganisationFilter';
import { PageTitle } from '../../../components/PageTitle';
import { BodyStats } from '../components/BodyStats';

import { useOrganisation } from '../../../hooks/organisations';
import { useQuestions } from '../../../hooks/questions';

import { Container } from '../styles';

export const Body: React.FC = () => {
  const { currentOrganisation, chosenDivisionId } = useOrganisation();
  const { bodyQuestionnaires } = useQuestions();

  const { body } = useMemo(() => {
    const orgAverageScore = lodash.get(
      currentOrganisation,
      `scores.${chosenDivisionId}`
    );

    if (orgAverageScore) {
      return orgAverageScore;
    }

    return {
      body: {}
    };
  }, [currentOrganisation, chosenDivisionId]);

  return (
    <Container>
      <OrganisationFilter hideUserSearch />

      <Helmet>
        <title>Body summary</title>
      </Helmet>

      <PageTitleWrapper>
        <PageTitle heading="Body summary" />
      </PageTitleWrapper>

      <BodyStats
        categoryData={body}
        categoryQuestionnaires={bodyQuestionnaires}
      />
    </Container>
  );
};
