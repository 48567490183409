import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import { differenceInYears } from 'date-fns';
import { Check, Close, Visibility, VisibilityOff } from '@mui/icons-material';
import { isEmpty, get, cloneDeep } from 'lodash';
import {
  SmokingRoomsOutlined,
  LocalBarOutlined,
  InvertColorsOutlined,
  FavoriteBorderOutlined,
  EngineeringOutlined
} from '@mui/icons-material';

import {
  formatDate,
  getDay,
  getMonthAsNumber,
  getYear
} from '../../utils/formatDate';
import { calculateCategoriesCompleteness } from '../../utils/calculateScore';
import { useHealthCheck } from '../../hooks/healthCheck';
import { useOrganisation } from '../../hooks/organisations';
import { OrganisationFilter } from '../../components/OrganisationFilter';
import { PageTitle } from '../../components/PageTitle';
import { HealthCheck, User, UserQuestionnaireAnswer } from '../../models/user';

import {
  Container,
  UserCardsWrapper,
  DataRow,
  UserData,
  UserDataColumn,
  UserName,
  ScoresWrapper,
  ScoreData,
  ScoreTitle,
  ScoreBlock,
  FooterButton,
  ToggleScoreButton,
  ColorLabel,
  CriticalDataLine,
  CriticalData,
  ButtonsRow,
  DatesRow,
  UserActivation
} from './styles';

import averageScoreIcon from '../../assets/logo-small.png';
import bodyIcon from '../../assets/body-icon.png';
import mindIcon from '../../assets/mind-icon.png';
import lifeIcon from '../../assets/life-icon.png';
import depressionIcon from '../../assets/depression.svg';
import anxietyIcon from '../../assets/anxiety.svg';
import stressIcon from '../../assets/stress.svg';
import { JobHealthHazardsHealthCheck } from '../../models/jobHealthHazards/JobHealthHazardsHealthCheck';

export const Users: React.FC = () => {
  const navigate = useNavigate();

  const {
    users,
    fetchSearchedUsers,
    orgOngoingHealthChecks,
    organizationHasHearingVisionV2Access
  } = useOrganisation();
  const { setUser, setUntouchedUser, setJobHealthHazardsHealthCheck } =
    useHealthCheck();

  const [isShowingUserScores, setIsShowingUserScores] = useState(false);
  const [userHasOngoingChecks, setUserHasOngoingChecks] = useState(
    [] as boolean[]
  );

  useEffect(() => {
    setJobHealthHazardsHealthCheck([] as JobHealthHazardsHealthCheck);
    fetchSearchedUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewCheck = (client: User) => {
    const deepUserCopy = cloneDeep(client);
    deepUserCopy.answers = {};
    deepUserCopy.scores = { averageScore: {} };

    const userHealthCheck = client.ongoingHealthCheck;

    if (userHealthCheck && userHealthCheck.user) {
      const exams =
        userHealthCheck.user?.exams ||
        ({} as [] as JobHealthHazardsHealthCheck);

      setJobHealthHazardsHealthCheck(exams);
      setUser(userHealthCheck.user);
    } else {
      setUser(deepUserCopy);
    }

    const deepUntouchedCopy = cloneDeep(client);
    setUntouchedUser(deepUntouchedCopy);
    navigate('/new-health-check');
  };

  const handleNavigateToDetails = (client: User) => {
    navigate('details', {
      state: {
        client
      }
    });
  };

  const handleNavigateToUserHealthChecks = (client: User) => {
    if (client.healthChecks) {
      const allUserHealthChecks = [].concat(
        ...(Object.values(client.healthChecks) as never[])
      ) as HealthCheck[];

      navigate('health-checks', {
        state: {
          client,
          healthChecks: allUserHealthChecks
        }
      });
    } else {
      window.alert('User does not have any health check yet');
    }
  };

  const userScores = useCallback((cardUser: User) => {
    let score = get(cardUser, 'scores.averageScore');

    if (!score) {
      score = {};
    }

    const averageScore = score.averageScore || '-';
    const body = score.body || '-';
    const mind = score.mind || '-';
    const life = score.life || '-';

    const dummyScore = {
      currentScore: '-',
      color: 'transparent'
    };

    const dasScore =
      get(cardUser, 'scores.mind.depressionAnxietyAndStress') || '-';

    const depression = get(dasScore, 'depressionScore', dummyScore);
    const anxiety = get(dasScore, 'anxietyScore', dummyScore);
    const stress = get(dasScore, 'stressScore', dummyScore);

    const scoresCompleteness = {
      bodyCompleteness: '0 %',
      mindCompleteness: '0 %',
      lifeCompleteness: '0 %',
      totalCompleteness: '0 %'
    };

    const generalScores = get(cardUser, 'scores') as any;

    if (generalScores) {
      const completeness = calculateCategoriesCompleteness(generalScores);

      scoresCompleteness.bodyCompleteness = completeness.body + ' %';
      scoresCompleteness.mindCompleteness = completeness.mind + ' %';
      scoresCompleteness.lifeCompleteness = completeness.life + ' %';
      scoresCompleteness.totalCompleteness = completeness.total + ' %';
    }

    return {
      averageScore,
      body,
      mind,
      life,
      depression,
      anxiety,
      stress,
      ...scoresCompleteness
    };
  }, []);

  const criticAnswers = useCallback((cardUser: User) => {
    const smokingAnswer = get(
      cardUser,
      'answers.body.smokingAndAlcohol.smoking.0',
      {}
    ) as UserQuestionnaireAnswer;
    const alcoholAnswer = get(
      cardUser,
      'answers.body.smokingAndAlcohol.alcohol.0',
      {}
    ) as UserQuestionnaireAnswer;
    const heartAnswer = get(
      cardUser,
      'answers.body.restingHeartRate.restingHeartRate',
      []
    ) as UserQuestionnaireAnswer[];
    const diabetesAnswer = get(
      cardUser,
      'answers.body.hbA1C.hbA1C',
      []
    ) as UserQuestionnaireAnswer[];
    const criticalRoleAnswer = get(
      cardUser,
      'answers.body.jobHealthHazards.criticalRole',
      []
    ) as UserQuestionnaireAnswer[];

    let smoker = '?';
    let drinksNumber = '?';
    let isHypertensive = '?';
    let hasDiabetes = '?';
    let isCriticalRole = '?';

    if (smokingAnswer) {
      smoker = smokingAnswer.answer.value as string;
    }

    if (alcoholAnswer) {
      drinksNumber = alcoholAnswer.answer.value + ' drinks/week';
    }

    if (heartAnswer) {
      const secondQuestion = heartAnswer['1'];
      const thirdQuestion = heartAnswer['2'];

      const hypertension = secondQuestion.question.label.includes('BETA')
        ? thirdQuestion
        : secondQuestion;

      if (hypertension && hypertension.answer) {
        isHypertensive =
          hypertension.answer.value === 'Yes'
            ? 'Hypertensive'
            : 'Non-Hypertensive';
      }
    }

    if (diabetesAnswer) {
      const firstQuestion = diabetesAnswer['0'];
      const secondQuestion = diabetesAnswer['1'];

      const diabetesQuestion =
        firstQuestion.question.type === 'Number'
          ? secondQuestion
          : firstQuestion;

      hasDiabetes =
        diabetesQuestion.answer.value === 'Yes'
          ? 'Has diabetes'
          : 'Non-diabetic';
    }

    if (criticalRoleAnswer) {
      isCriticalRole =
        criticalRoleAnswer[0].answer.value === 'Yes'
          ? 'Is in a Critical Role'
          : 'Is not in a Critical Role';
    }

    return {
      smoker,
      drinksNumber,
      isHypertensive,
      hasDiabetes,
      isCriticalRole
    };
  }, []);

  const userAgeAsString = useCallback((birthDate: any) => {
    const birthDay = Number(getDay(birthDate));
    const birthMonth = Number(getMonthAsNumber(birthDate));
    const birthYear = Number(getYear(birthDate));

    const userBirthDate = new Date(birthYear, birthMonth - 1, birthDay);

    const userAge = differenceInYears(new Date(), userBirthDate);

    return userAge;
  }, []);

  const toggleShowUserScores = useCallback(() => {
    setIsShowingUserScores(!isShowingUserScores);
  }, [isShowingUserScores]);

  useEffect(() => {
    const ongoingChecksArray = new Array(users.length) as boolean[];
    ongoingChecksArray.fill(false);

    users.forEach((_user, userIndex) => {
      const userHasCheck = orgOngoingHealthChecks.some(
        ({ id, submitted }) => id === _user.id && !submitted
      );

      ongoingChecksArray[userIndex] =
        userHasCheck || !isEmpty(_user.ongoingHealthCheck);
    });

    setUserHasOngoingChecks(ongoingChecksArray);
  }, [orgOngoingHealthChecks, users]);

  return (
    <Container>
      <OrganisationFilter hideDivision />

      <Helmet>
        <title>Users</title>
      </Helmet>

      <PageTitle heading="Users" />

      {!users.length && (
        <h2>No users found. Type in the search bar to find users</h2>
      )}

      {!!users.length && (
        <h3>
          {users.length} user{users.length > 1 && 's'} found
        </h3>
      )}

      <ToggleScoreButton
        type="button"
        onClick={toggleShowUserScores}
        title={
          isShowingUserScores
            ? 'Click to hide users scores'
            : 'Click to show users scores'
        }
      >
        {isShowingUserScores ? <Visibility /> : <VisibilityOff />}
      </ToggleScoreButton>

      <UserCardsWrapper>
        {users.map((_user: User, userIndex) => {
          return (
            <Card key={_user.id}>
              <CardContent>
                <UserName>
                  <UserActivation
                    title={_user.isActive ? 'Active user' : 'Inactive user'}
                  >
                    {_user.isActive ? (
                      <Check color="success" />
                    ) : (
                      <Close color="error" />
                    )}
                  </UserActivation>
                  <p>{_user.firstName.toUpperCase()}</p>
                  <p>{_user.lastName.toUpperCase()}</p>
                  <p>({userAgeAsString(_user.birthDate)} yrs)</p>
                </UserName>

                <UserData>
                  <UserDataColumn>
                    <DataRow>
                      <p>Organisation</p>
                      <strong>{_user.organisation?.name}</strong>
                    </DataRow>

                    <DataRow>
                      <p>Division</p>
                      <strong>{_user.division?.name}</strong>
                    </DataRow>

                    <DataRow>
                      <p>Role</p>
                      <strong>{_user.role}</strong>
                    </DataRow>
                  </UserDataColumn>

                  <UserDataColumn>
                    <DataRow>
                      <p>Contact</p>
                      <strong>{_user.email || _user.phone}</strong>
                    </DataRow>

                    <DataRow>
                      <p>Birth date</p>
                      <strong>
                        {formatDate({ date: _user.birthDate, showHour: false })}
                      </strong>
                    </DataRow>

                    <DataRow>
                      <p>Sex</p>
                      <strong>{_user.sex}</strong>
                    </DataRow>
                  </UserDataColumn>
                </UserData>

                {isShowingUserScores && (
                  <>
                    <CriticalDataLine>
                      <CriticalData>
                        <SmokingRoomsOutlined />
                        <strong>{criticAnswers(_user).smoker}</strong>
                      </CriticalData>

                      <CriticalData>
                        <LocalBarOutlined />
                        <strong>{criticAnswers(_user).drinksNumber}</strong>
                      </CriticalData>

                      <CriticalData>
                        <InvertColorsOutlined />
                        <strong>{criticAnswers(_user).hasDiabetes}</strong>
                      </CriticalData>

                      <CriticalData>
                        <FavoriteBorderOutlined />
                        <strong>{criticAnswers(_user).isHypertensive}</strong>
                      </CriticalData>

                      {organizationHasHearingVisionV2Access && (
                        <CriticalData>
                          <EngineeringOutlined />
                          <strong>{criticAnswers(_user).isCriticalRole}</strong>
                        </CriticalData>
                      )}
                    </CriticalDataLine>

                    <ScoreTitle>SCORES</ScoreTitle>

                    <ScoresWrapper>
                      <ScoreBlock className="area-1">
                        <ScoreData className="average">
                          <img
                            src={averageScoreIcon}
                            alt="average score icon"
                          />
                          <div>
                            <p>KYND Score</p>
                            <strong>{userScores(_user).averageScore}</strong>
                            <h6>{userScores(_user).totalCompleteness}</h6>
                          </div>
                        </ScoreData>
                      </ScoreBlock>

                      <ScoreBlock className="area-2">
                        <ScoreData>
                          <img src={bodyIcon} alt="body icon" />
                          <div>
                            <p>Body</p>
                            <strong>{userScores(_user).body}</strong>
                            <h6>{userScores(_user).bodyCompleteness}</h6>
                          </div>
                        </ScoreData>

                        <ScoreData>
                          <img src={mindIcon} alt="mind icon" />
                          <div>
                            <p>Mind</p>
                            <strong>{userScores(_user).mind}</strong>
                            <h6>{userScores(_user).mindCompleteness}</h6>
                          </div>
                        </ScoreData>

                        <ScoreData>
                          <img src={lifeIcon} alt="life icon" />
                          <div>
                            <p>Life</p>
                            <strong>{userScores(_user).life}</strong>
                            <h6>{userScores(_user).lifeCompleteness}</h6>
                          </div>
                        </ScoreData>
                      </ScoreBlock>

                      <ScoreBlock className="area-3">
                        <ScoreData>
                          <img src={depressionIcon} alt="depression icon" />
                          <div>
                            <p>Depression</p>
                            <strong>
                              {userScores(_user).depression.currentScore}
                            </strong>
                          </div>
                          <ColorLabel
                            title="Score color"
                            backgroundColor={userScores(_user).depression.color}
                          />
                        </ScoreData>

                        <ScoreData>
                          <img src={anxietyIcon} alt="anxiety icon" />
                          <div>
                            <p>Anxiety</p>
                            <strong>
                              {userScores(_user).anxiety.currentScore}
                            </strong>
                          </div>
                          <ColorLabel
                            title="Score color"
                            backgroundColor={userScores(_user).anxiety.color}
                          />
                        </ScoreData>

                        <ScoreData>
                          <img src={stressIcon} alt="stress icon" />
                          <div>
                            <p>Stress</p>
                            <strong>
                              {userScores(_user).stress.currentScore}
                            </strong>
                          </div>
                          <ColorLabel
                            title="Score color"
                            backgroundColor={userScores(_user).stress.color}
                          />
                        </ScoreData>
                      </ScoreBlock>
                    </ScoresWrapper>
                  </>
                )}

                <ButtonsRow>
                  <FooterButton
                    onClick={() => handleNewCheck(_user)}
                    hasOngoingHealthCheck={userHasOngoingChecks[userIndex]}
                  >
                    {userHasOngoingChecks[userIndex]
                      ? 'Continue Health Check'
                      : '+ New Health Check'}
                  </FooterButton>

                  <FooterButton onClick={() => handleNavigateToDetails(_user)}>
                    Details
                  </FooterButton>

                  {_user.healthChecks && (
                    <FooterButton
                      onClick={() => handleNavigateToUserHealthChecks(_user)}
                    >
                      Health Checks
                    </FooterButton>
                  )}
                </ButtonsRow>

                <DatesRow>
                  <p>Created at: {formatDate({ date: _user.createdAt })}</p>
                  <p>Updated at: {formatDate({ date: _user.updatedAt })}</p>
                </DatesRow>
              </CardContent>
            </Card>
          );
        })}
      </UserCardsWrapper>
    </Container>
  );
};
