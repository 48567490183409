import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import { PureLightTheme } from '../../../styles/schemes/PureLightTheme';

interface ContainerProps {
  type?: 'success' | 'error' | 'info';
  hasdescription: number;
  duration: number;
}

const toastTypeVariations = {
  info: css`
    background: #dbe8ff;
    color: ${PureLightTheme.colors.secondary.main};
  `,
  success: css`
    background: ${PureLightTheme.colors.primary.main};
    color: #fff;
  `,
  error: css`
    background: #fddede;
    color: ${PureLightTheme.colors.error.main};
  `
};

const leftLabelVariations = {
  info: css`
    background: ${PureLightTheme.colors.secondary.main};
    border-color: #dbe8ff;
  `,
  success: css`
    background: #fff;
    border-color: ${PureLightTheme.colors.primary.main};
  `,
  error: css`
    background: ${PureLightTheme.colors.error.main};
    border-color: #fddede;
  `
};

export const Container = styled(animated.div)<ContainerProps>`
  width: 340px;

  position: relative;
  padding: 4px 15px 4px 4px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: box-shadow 0.2s;

  display: flex;

  &:hover {
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
  }

  & + div {
    margin-top: 8px;
  }

  ${(props) => toastTypeVariations[props.type || 'info']}

  > span:first-child {
    background-color: black;
    width: 8px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    border: 1px solid;
    border-right: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    ${(props) => leftLabelVariations[props.type || 'info']}
  }

  > span:last-child {
    position: absolute;
    bottom: 1px;
    right: 5%;
    left: 5%;
    height: 1.6px;
    border-radius: 10px;

    transition: width ${(props) => props.duration + 'ms'};
    transition-timing-function: linear;

    ${(props) => leftLabelVariations[props.type || 'info']}
  }

  > svg {
    margin: 4px 8px 4px 8px;
  }

  div {
    flex: 1;

    p {
      font-size: 16px;
      margin: 0;
    }
  }

  button {
    position: absolute;
    right: 16px;
    top: 19px;
    opacity: 0.6;
    border: 0;
    background: transparent;
    color: inherit;
  }

  ${(props) =>
    !props.hasdescription &&
    css`
      align-items: center;

      svg {
        margin-top: 0;
      }
    `}
`;
