import { useTable, Column, TableOptions } from 'react-table';

import { Container, TableContainer, TableTitle } from './styles';

interface TableProps extends TableOptions<Record<string, unknown>> {
  columns: Column<any>[];
  data: Record<string, any>[];
  handleRowClick?: (rowValue: Record<string, unknown>) => void;
  tableTitle?: string;
  rowTitle?: string;
  rowHeight?: number;
  containerStyle?: Record<string, unknown>;
}

export const Table = ({
  columns,
  data,
  handleRowClick,
  tableTitle,
  rowTitle,
  containerStyle = {},
  rowHeight,
  ...rest
}: TableProps) => {
  const tableInstance = useTable({
    columns,
    data,
    ...rest
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <Container style={containerStyle}>
      {tableTitle && <TableTitle>{tableTitle}</TableTitle>}

      <TableContainer {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => handleRowClick && handleRowClick(row.values)}
                title={rowTitle || ''}
                style={{
                  cursor: handleRowClick ? 'pointer' : 'default',
                  height: rowHeight ? `${rowHeight}px` : 'initial'
                }}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </TableContainer>
    </Container>
  );
};
