import { useMemo } from 'react';
import GaugeChart from 'react-gauge-chart';

interface GaugeScoreChartProps {
  value: number;
  totalLights: Record<string, number>;
}

export const GaugeScoreChart: React.FC<GaugeScoreChartProps> = ({
  value,
  totalLights
}) => {
  const { lights, colors } = useMemo(() => {
    const { red, orange, green } = totalLights;

    if (!red && !orange && !green) {
      return {
        colors: ['#333333aa', '#333333aa', '#333333aa'],
        lights: {
          red: 1,
          orange: 1,
          green: 1
        }
      };
    }

    return {
      colors: ['#fc6161', '#ffa500', '#5dc45d'],
      lights: totalLights
    };
  }, [totalLights]);

  return (
    <GaugeChart
      colors={colors}
      arcWidth={0.15}
      percent={value ? value / 100 : 0}
      textColor="#333333aa"
      needleColor="#33333333"
      needleBaseColor="#333333dd"
      formatTextValue={(text) => text}
      style={{ width: '100%', margin: '0 auto' }}
      arcsLength={[lights.red, lights.orange, lights.green]}
    />
  );
};
