import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback
} from 'react';
import moment from 'moment';
import {
  camelCase,
  cloneDeep,
  get,
  isArray,
  isEmpty,
  isNumber,
  last,
  set,
  startsWith,
  unset
} from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { v4 as uuid } from 'uuid';

import { useAuth } from './auth';
import { useToast } from './toast';
import { useOrganisation } from './organisations';
import { QuestionnaireProps, useQuestions } from './questions';

import { Spinner } from '../components/Spinner';
import {
  addClinicianNotification,
  updateUserExams,
  addUserNotification,
  getUserData,
  updateMobileUserTrigger,
  updateUserAnswersAndScores,
  updateUserOngoingHealthCheck
} from '../functions/users';
import { firebaseFunctions } from '../services/firebase';

import {
  AverageScore,
  HealthCheck,
  ScoreProps,
  User,
  UserQuestionnaireAnswer
} from '../models/user';
import { Question } from '../models/question';

import { generateUserScoreObject } from '../utils/generateUserScoreObject';
import {
  organizationHasHearingVisionV2Access,
  updatedUserAnswersAndScores
} from '../utils/updateUserAnswersAndScores';
import { getUnixSeconds } from '../utils/formatDate';
import { ClinicianNotification } from '../models/notification';
import { generateUserHistories } from '../utils/generateUserHistories';
import { JobHealthHazardsHealthCheck } from '../models/jobHealthHazards/JobHealthHazardsHealthCheck';
import { uploadJobHealthHazardsExams } from '../functions/jobHealthHazards/uploadJobHealthHazardsExams';
import { updateHaringVisionAlert } from '../functions/hearingVisionAlerts';
import { filterUserAnsweredQuestionnaires } from '../utils/filterUserAnsweredQuestionnaires';
import { getNewQuestionnairesAnswered } from '../utils/getNewQuestionnairesAnswered';
import { hasJobHealthHazardFilesToUpload } from '../utils/hasJobHealthHazardFilesToUpload';
import { checkUserHasUploadedExams } from '../utils/checkUserHasUploadedExams';

interface HealthCheckContextProps {
  user: User;
  setUser: (user: User) => void;
  setUntouchedUser: (user: User) => void;
  untouchedUser: User;
  currentHeaderTab: string;
  currentMindTab: string;
  setCurrentHeaderTab: (tab: string) => void;
  setCurrentMindTab: (tab: string) => void;
  bodyAnswers: Record<string, Array<string | number>>;
  mindAnswers: Record<string, Array<string | number>>;
  lifeAnswers: Record<string, Array<string | number>>;
  currentHealthChecks: Array<HealthCheck>;
  currentOngoingHealthChecks: HealthCheck[];
  handleChangeAnswers: (
    category: string,
    questionnaire: string,
    answers: Record<string, Array<string | number>>,
    question: Question
  ) => void;
  handleGoBack: () => void;
  handleSubmitHealthCheck: () => void;
  userMessageSubject: string;
  setUserMessageSubject: (subject: string) => void;
  userMessage: string;
  setUserMessage: (userMessage: string) => void;
  clinicalObservation: string;
  setClinicalObservation: (observation: string) => void;
  answeredBodyQuestions: Record<string, boolean>;
  answeredMindQuestions: Record<string, boolean>;
  answeredLifeQuestions: Record<string, boolean>;
  handleSaveHealthCheck: () => void;
  isOngoingHealthCheck: boolean;
  disableMindQuestions: boolean;
  disableLifeQuestions: boolean;
  isSafetyCriticalRole: boolean;
  setIsSafetyCriticalRole: (status: boolean) => void;
  jobHealthHazardsHealthCheck: JobHealthHazardsHealthCheck;
  setJobHealthHazardsHealthCheck: React.Dispatch<
    React.SetStateAction<JobHealthHazardsHealthCheck>
  >;
  answersSnapshot: Record<string, boolean>;
  isUserDetailsPage: boolean;
  setIsUserDetailsPage: React.Dispatch<React.SetStateAction<boolean>>;
}

const HealthCheckContext = createContext<HealthCheckContextProps>(
  {} as HealthCheckContextProps
);

export const HealthCheckProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { addToast } = useToast();
  const {
    groupedQuestionnaires,
    bodyQuestionnaires,
    mindQuestionnaires,
    lifeQuestionnaires
  } = useQuestions();
  const {
    user: clinicianUser,
    updateUser: updateLoggedClinician,
    userIsLogged
  } = useAuth();
  const {
    users,
    setUsers,
    orgOngoingHealthChecks,
    setOrgOngoingHealthChecks,
    currentOrganisation
  } = useOrganisation();

  const [isUserDetailsPage, setIsUserDetailsPage] = useState(false);
  const [user, setUser] = useState({} as User);

  const [isProcessingAnswers, setIsProcessingAnswers] = useState(false);
  const [untouchedUser, setUntouchedUser] = useState({} as User);
  const [currentHeaderTab, setCurrentHeaderTab] = useState('body');
  const [currentMindTab, setCurrentMindTab] = useState('mindfulness');
  const [currentHealthChecks, setCurrentHealthChecks] = useState(
    [] as Array<HealthCheck>
  );
  const [currentOngoingHealthChecks, setCurrentOngoingHealthChecks] = useState(
    [] as HealthCheck[]
  );
  const [answeredBodyQuestions, setAnsweredBodyQuestions] = useState(
    {} as Record<string, boolean>
  );
  const [answeredMindQuestions, setAnsweredMindQuestions] = useState(
    {} as Record<string, boolean>
  );
  const [answeredLifeQuestions, setAnsweredLifeQuestions] = useState(
    {} as Record<string, boolean>
  );

  const [bodyAnswers, setBodyAnswers] = useState<
    Record<string, Array<string | number>>
  >({});
  const [mindAnswers, setMindAnswers] = useState<
    Record<string, Array<string | number>>
  >({});
  const [lifeAnswers, setLifeAnswers] = useState<
    Record<string, Array<string | number>>
  >({});

  const [isSavingCheck, setIsSavingHealthCheck] = useState(false);
  const [userMessageSubject, setUserMessageSubject] = useState('');
  const [userMessage, setUserMessage] = useState('');
  const [clinicalObservation, setClinicalObservation] = useState('None');
  const [isSafetyCriticalRole, setIsSafetyCriticalRole] = useState(false);
  const [jobHealthHazardsHealthCheck, setJobHealthHazardsHealthCheck] =
    useState({} as JobHealthHazardsHealthCheck);

  const [isOngoingHealthCheck, setIsOngoingHealthCheck] = useState(false);
  const [disableMindQuestions, setDisableMindQuestions] = useState(false);
  const [disableLifeQuestions, setDisableLifeQuestions] = useState(false);

  const [isAnswersLoaded, setIsAnswersLoaded] = useState(false);
  const [lockAnswersSnapshot, setLockAnswersSnapshot] = useState(false);
  const [answersSnapshot, setAnswersSnapshot] = useState(
    {} as Record<string, boolean>
  );

  useEffect(() => {
    setUserMessageSubject('');
    setUserMessage('');
    setClinicalObservation('None');
    setLockAnswersSnapshot(false);
    setIsAnswersLoaded(false);

    if (location.pathname !== '/new-health-check') {
      setIsOngoingHealthCheck(false);
      return;
    }

    const ongoingHealthCheck = orgOngoingHealthChecks.find(
      ({ user: { id } }) => id === untouchedUser.id
    );

    if (ongoingHealthCheck) {
      const ongoingHealthCheckUser = cloneDeep(ongoingHealthCheck.user);
      setIsOngoingHealthCheck(true);
      setUserMessageSubject(ongoingHealthCheck.userMessageSubject || '');
      setUserMessage(ongoingHealthCheck.userMessage || '');
      setClinicalObservation(ongoingHealthCheck.clinicalObservation || '');
      setUser(ongoingHealthCheckUser);
      setJobHealthHazardsHealthCheck(ongoingHealthCheck.exams || {});
    } else {
      setIsOngoingHealthCheck(false);
    }
  }, [untouchedUser, orgOngoingHealthChecks, location]);

  useEffect(() => {
    const generateAnswers = (
      category: 'body' | 'mind' | 'life',
      questionnaireData: QuestionnaireProps[]
    ) => {
      if (!untouchedUser) return {};
      if (!questionnaireData.length) return {};

      let ongoingHealthCheck = orgOngoingHealthChecks.find(
        ({ user: { id } }) => id === untouchedUser.id
      );

      if (!ongoingHealthCheck && !isEmpty(untouchedUser.ongoingHealthCheck)) {
        ongoingHealthCheck = untouchedUser.ongoingHealthCheck;
      }

      if (ongoingHealthCheck && ongoingHealthCheck[`${category}Answers`]) {
        return ongoingHealthCheck[`${category}Answers`];
      }

      const defaultAnswersArray = {} as Record<string, Array<string | number>>;

      questionnaireData.forEach((data) => {
        const questionnaire = camelCase(data?.questionnaire);

        defaultAnswersArray[questionnaire] = new Array(data.questions.length);

        defaultAnswersArray[questionnaire].fill('');

        data.questions.forEach((question, questionIndex) => {
          if (question.type === 'Percentage range') {
            defaultAnswersArray[questionnaire][questionIndex] = 50;
          }
        });
      });

      return defaultAnswersArray;
    };

    setBodyAnswers(generateAnswers('body', bodyQuestionnaires));
    setMindAnswers(generateAnswers('mind', mindQuestionnaires));
    setLifeAnswers(generateAnswers('life', lifeQuestionnaires));
  }, [
    bodyQuestionnaires,
    mindQuestionnaires,
    lifeQuestionnaires,
    untouchedUser,
    orgOngoingHealthChecks
  ]);

  useEffect(() => {
    const generateCategoryAnsweredQuestions = (
      category: string,
      categoryQuestionnaire: QuestionnaireProps[]
    ) => {
      const categoryQuestionnairesObject = {} as Record<string, boolean>;
      categoryQuestionnaire.forEach(
        ({ camelCaseQuestionnaire, section, questions }) => {
          categoryQuestionnairesObject[camelCaseQuestionnaire] = false;

          const camelCaseSection = camelCase(section);

          const userHasAnswers = get(
            user,
            // eslint-disable-next-line prettier/prettier
            `answers.${category}.${camelCaseSection}.${camelCaseQuestionnaire}`
          );

          if (!userHasAnswers) return;

          if (category === 'body') {
            const answersObject = cloneDeep(userHasAnswers);
            unset(answersObject, 'score');
            const answersArray = Object.values(answersObject) as any[];

            const hasInvalidAnswer = answersArray.some(
              ({ answer, question }) => {
                const answerValue = answer.value;

                if (question.type === 'Text answer') return false;

                const isValidAnswer =
                  isNumber(answerValue) || Boolean(answerValue);
                return isValidAnswer === false;
              }
            );

            if (
              !hasInvalidAnswer &&
              checkUserHasUploadedExams(
                user,
                jobHealthHazardsHealthCheck,
                camelCaseQuestionnaire
              )
            )
              categoryQuestionnairesObject[camelCaseQuestionnaire] = true;
          } else {
            const questionType: string = get(questions, '[0].type', '');
            const numberOfAnswers = Object.keys(userHasAnswers).length - 1;

            const checkAnswersArray: boolean[] = new Array(numberOfAnswers);
            checkAnswersArray.fill(false);

            const answersArray: Array<Record<string, any>> =
              Object.values(userHasAnswers);

            answersArray.forEach((answerObject, answerIndex) => {
              if (answerObject.calculatedAt || !answerObject.answer) return;
              const answer = answerObject.answer.value;

              if (questionType !== 'Percentage range' && answer !== '') {
                checkAnswersArray[answerIndex] = true;
              } else if (answer === 50) {
                checkAnswersArray[answerIndex] = true;
              }
            });

            if (questionType !== 'Percentage range') {
              const hasBlankAnswer = checkAnswersArray.some(
                (answerBoolean) => answerBoolean === false
              );

              if (!hasBlankAnswer) {
                categoryQuestionnairesObject[camelCaseQuestionnaire] = true;
              }
            } else {
              const hasChangedOneAnswer = checkAnswersArray.some(
                (answerBoolean) => answerBoolean === false
              );

              if (hasChangedOneAnswer) {
                categoryQuestionnairesObject[camelCaseQuestionnaire] = true;
              }
            }
          }
        }
      );

      return categoryQuestionnairesObject;
    };

    setAnsweredBodyQuestions(
      generateCategoryAnsweredQuestions('body', bodyQuestionnaires)
    );
    setAnsweredMindQuestions(
      generateCategoryAnsweredQuestions('mind', mindQuestionnaires)
    );
    setAnsweredLifeQuestions(
      generateCategoryAnsweredQuestions('life', lifeQuestionnaires)
    );

    if (!lockAnswersSnapshot) setIsAnswersLoaded(true);
  }, [
    bodyQuestionnaires,
    mindQuestionnaires,
    lifeQuestionnaires,
    user,
    isOngoingHealthCheck,
    lockAnswersSnapshot,
    jobHealthHazardsHealthCheck
  ]);

  useEffect(() => {
    if (!isAnswersLoaded || lockAnswersSnapshot) return;

    const answersSnapshotObject = cloneDeep(answeredBodyQuestions);
    setAnswersSnapshot(answersSnapshotObject);
    setLockAnswersSnapshot(true);
  }, [answeredBodyQuestions, isAnswersLoaded, lockAnswersSnapshot]);

  function setQuestionnaireAnswers(
    userInstance: User,
    setAnswers: React.Dispatch<
      React.SetStateAction<Record<string, (string | number)[]>>
    >,
    category: string,
    setDisableQuestions: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    const userAnswers = get(userInstance, `answers.${category}`) as Record<
      string | 'score',
      UserQuestionnaireAnswer | ScoreProps
    >;

    if (userAnswers) {
      const questionnairesObject = {};
      const sections = Object.values(userAnswers);

      sections.forEach((section) => {
        const keys = Object.keys(section);
        const values = Object.values(section);

        keys.forEach((questionnaire, index) => {
          const questionnaireValues = values[index];
          unset(questionnaireValues, 'score');
          set(questionnairesObject, questionnaire, values[index]);
        });
      });

      const questionnaires = Object.keys(questionnairesObject);
      const answersArray = Object.values(questionnairesObject) as any[];

      questionnaires.forEach((questionnaire, index) => {
        const questionnaireValues = answersArray[index];

        const questionnaireAnswers = Object.values(
          questionnaireValues
        ) as any[];

        setAnswers((oldState) => {
          const copyOldState = cloneDeep(oldState);

          const currentQuestionnaireAnswers = get(copyOldState, questionnaire);

          questionnaireAnswers.forEach(({ answer: { value } }, answerIndex) => {
            currentQuestionnaireAnswers[answerIndex] = value;
          });

          set(copyOldState, questionnaire, currentQuestionnaireAnswers);

          return copyOldState;
        });
      });

      setDisableQuestions(true);
    }
  }

  useEffect(() => {
    setQuestionnaireAnswers(
      untouchedUser,
      setMindAnswers,
      'mind',
      setDisableMindQuestions
    );
    setQuestionnaireAnswers(
      untouchedUser,
      setLifeAnswers,
      'life',
      setDisableLifeQuestions
    );
  }, [untouchedUser]);

  const handleChangeAnswers = useCallback(
    (
      category: string,
      questionnaire: string,
      answers: Record<string, Array<string | number>>,
      question: Question
    ) => {
      let cloneUser = cloneDeep(user);

      if (questionnaire === 'hdl' || questionnaire === 'totalCholesterol') {
        const questionnaireData = bodyQuestionnaires.find(
          (data) => data.camelCaseQuestionnaire === 'ratioCholesterolHdl'
        );

        const totalCholesterolAnswers = [...bodyAnswers.totalCholesterol];
        const hdlAnswers = [...bodyAnswers.hdl];

        const totalCholesterol = Number(totalCholesterolAnswers[0]);
        const hdl = Number(hdlAnswers[0]);

        if ((totalCholesterol >= 0 || hdl >= 0) && questionnaireData) {
          const { questions } = questionnaireData;
          const bodyAnswersCopy = cloneDeep(bodyAnswers);

          // eslint-disable-next-line no-param-reassign
          answers.ratioCholesterolHdl = [totalCholesterol, hdl];

          bodyAnswersCopy.ratioCholesterolHdl = [totalCholesterol, hdl];

          const removeQuestionnaire = totalCholesterol === 0 || hdl === 0;

          cloneUser = generateUserScoreObject(
            user,
            questions,
            bodyAnswersCopy.ratioCholesterolHdl,
            'body',
            'cholesterol',
            'ratioCholesterolHdl',
            removeQuestionnaire
          );
        }
      }

      const questionnaireData = groupedQuestionnaires.find(
        (data) => data.camelCaseQuestionnaire === questionnaire
      );

      if (!questionnaireData) {
        console.error({
          questionnaireData,
          groupedQuestionnaires,
          questionnaire
        });
        throw new Error('Missing questionnaire data');
      }

      const { questions } = questionnaireData;

      const copyAnswers = [...answers[questionnaire]];

      if (questionnaire === 'obstructiveSleepApnea') {
        copyAnswers.pop();
      }

      const { section } = question;
      const camelCaseSection = camelCase(section);

      const removeQuestionnaire =
        category === 'body' && isEmpty(get(copyAnswers, '0'));

      const updatedUser = generateUserScoreObject(
        cloneUser,
        questions,
        copyAnswers,
        category,
        camelCaseSection,
        questionnaire,
        removeQuestionnaire
      );

      setUser(updatedUser);

      if (category === 'body') setBodyAnswers(answers);
      if (category === 'mind') setMindAnswers(answers);
      if (category === 'life') setLifeAnswers(answers);
    },
    [user, groupedQuestionnaires, bodyAnswers, bodyQuestionnaires]
  );

  const handleGoBack = useCallback(
    (ignoreConfirm = false) => {
      if (!ignoreConfirm) {
        const confirm = window.confirm(
          'Are you sure you want to leave this page?'
        );

        if (!confirm) return;
      }

      setJobHealthHazardsHealthCheck({} as JobHealthHazardsHealthCheck);
      setCurrentHeaderTab('body');
      setCurrentMindTab('mindfulness');
      navigate(-1);
    },
    [navigate]
  );

  const sendUserMessage = useCallback(async () => {
    if (!userMessage || userMessage === 'None') return;

    const tokens: string[] = user.token ? [user.token] : [];

    const formattedText = userMessage.replace(/(?:\r\n|\r|\n)/g, ' <br/> ');

    try {
      await addUserNotification(user.id as string, {
        title: userMessageSubject,
        body: formattedText
      });

      const notificationObject = {
        title: userMessageSubject || 'Blank title',
        body: formattedText,
        userId: user.id,
        organisationId: user.organisation.id,
        divisionId: user.division.id,
        createdAt: moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
        userName: user.email
          ? `${user.firstName} ${user.lastName} - ${user.email}`
          : `${user.firstName} ${user.lastName} - ${user.phone}`
      } as ClinicianNotification;

      await addClinicianNotification(clinicianUser.id, notificationObject);

      const sendNotification =
        firebaseFunctions.httpsCallable('sendNotification');

      const message = {
        notification: {
          title: userMessageSubject,
          body: userMessage
        },
        tokens
      };

      await sendNotification(message);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      throw new Error('Fail to send user message');
    }
  }, [user, userMessageSubject, userMessage, clinicianUser]);

  const handleSubmitHealthCheck = useCallback(async () => {
    // ---> GET ALL ANSWERS
    const allAnsweredQuestions = {
      ...answeredBodyQuestions,
      ...answeredMindQuestions,
      ...answeredLifeQuestions
    };

    const allAnsweredCopy = { ...allAnsweredQuestions };
    delete allAnsweredCopy.weight;
    delete allAnsweredCopy.height;

    const arrayOfBodyBooleans = Object.values(answeredBodyQuestions);
    const hasNotAnsweredAllBodyQuestions = arrayOfBodyBooleans.some(
      (value) => value === false
    );

    // Has not answered any body questionnaire. Proceed?
    if (hasNotAnsweredAllBodyQuestions) {
      const confirmSubmit = window.confirm(
        'There are body questionnaires not fully completed yet, and those are not sent to the user. Do you want to proceed?'
      );

      if (!confirmSubmit) {
        return;
      }
    }

    const confirmSubmitHealthCheck = window.confirm(
      'Confirm health check submission?'
    );

    if (!confirmSubmitHealthCheck) {
      return;
    }

    setIsProcessingAnswers(true);

    try {
      const allKeys = [
        ...Object.keys(bodyAnswers),
        ...Object.keys(mindAnswers),
        ...Object.keys(lifeAnswers)
      ];

      const allValues = [
        ...Object.values(bodyAnswers),
        ...Object.values(mindAnswers),
        ...Object.values(lifeAnswers)
      ];

      // Format messages to user and clinician notes
      const formattedClinicalObservation = clinicalObservation.replace(
        /(?:\r\n|\r|\n)/g,
        ' <br/> '
      );

      const formattedUserMessage = userMessage.replace(
        /(?:\r\n|\r|\n)/g,
        ' <br/> '
      );

      if (!clinicianUser || !clinicianUser.id) {
        console.error({ clinicianUser });
        throw new Error('#Missing clinician identifier. Contact support.');
      }

      // Process user answers and scores
      const hasAnsweredBodyQuestions = Object.values(
        answeredBodyQuestions
      ).some((boolean) => boolean);

      const hasAnsweredMindQuestions = Object.values(
        answeredMindQuestions
      ).some((boolean) => boolean);

      const hasAnsweredLifeQuestions = Object.values(
        answeredLifeQuestions
      ).some((boolean) => boolean);

      const mostRecentUser = await getUserData(untouchedUser.id);

      if (mostRecentUser) {
        set(mostRecentUser, 'id', untouchedUser.id);
      }

      const includeMindAnswers = !hasAnsweredMindQuestions;
      const includeLifeAnswers = !hasAnsweredLifeQuestions;
      const { updatedUser, updatedClinician, clientHealthCheck } =
        updatedUserAnswersAndScores(
          user,
          clinicianUser,
          mostRecentUser || untouchedUser,
          allKeys,
          allValues,
          groupedQuestionnaires,
          allAnsweredQuestions,
          formattedClinicalObservation,
          formattedUserMessage,
          includeMindAnswers,
          includeLifeAnswers
        );

      //  Update hearing/vision alerts
      if (
        organizationHasHearingVisionV2Access(currentOrganisation) &&
        hasJobHealthHazardFilesToUpload(jobHealthHazardsHealthCheck)
      ) {
        await updateHaringVisionAlert(user, clinicianUser);

        const healthCheckFolderPath = `${user.id}/${uuid()}`;
        const healthCheckExams = await uploadJobHealthHazardsExams(
          healthCheckFolderPath,
          jobHealthHazardsHealthCheck
        );

        // Update user exams
        const userExams = await updateUserExams(
          healthCheckExams,
          untouchedUser
        );

        const currentHealthCheck = last(clientHealthCheck) as HealthCheck;
        set(
          currentHealthCheck,
          'user.healthCheckFolderPath',
          healthCheckFolderPath
        );
        set(currentHealthCheck, 'user.exams', healthCheckExams);
        set(updatedUser, 'exams', userExams);

        // This is needed to clear the list of uploaded images;
        setJobHealthHazardsHealthCheck(healthCheckExams);
      }

      // Verify data integrity
      if (!updatedUser || !updatedUser.id) {
        console.error({ updatedUser, clientHealthCheck });
        throw new Error('#Missing user identifier. Contact support.');
      }

      if (!updatedClinician || !updatedClinician.id) {
        console.error({ updatedClinician, clientHealthCheck });
        throw new Error(
          '#Missing updated clinician identifier. Contact support.'
        );
      }

      if (isEmpty(clientHealthCheck)) {
        console.error({ updatedUser, clientHealthCheck });
        throw new Error('#Missing client health check. Contact support.');
      }

      const clinicianCopy = cloneDeep(updatedClinician);
      unset(clinicianCopy, 'clients');

      // Submit data to database
      const cloudFunctionParams = {
        updatedClinician: clinicianCopy,
        updatedUser,
        clientHealthChecks: clientHealthCheck
      };

      const submitHealthCheck =
        firebaseFunctions.httpsCallable('submitHealthCheck');

      const result = await submitHealthCheck(cloudFunctionParams);

      if (get(result, 'data.status', 500) !== 200) {
        console.error({ result, cloudFunctionParams });
        throw Error(get(result, 'data.error', ''));
      }

      // Start filtering answers to update user
      const questionnairesToAdd = getNewQuestionnairesAnswered(
        answeredBodyQuestions,
        answersSnapshot
      );

      if (questionnairesToAdd.length > 0) {
        const filteredUser = filterUserAnsweredQuestionnaires({
          category: 'body',
          user,
          questionnairesToAdd
        });

        const historiesToUpdate = await generateUserHistories(
          filteredUser,
          updatedUser
        );

        const categoriesToUpdate = [];

        if (hasAnsweredBodyQuestions) categoriesToUpdate.push('body');
        if (hasAnsweredMindQuestions) categoriesToUpdate.push('mind');
        if (hasAnsweredLifeQuestions) categoriesToUpdate.push('life');

        await updateUserAnswersAndScores(updatedUser, categoriesToUpdate);

        const hasUpdatedMobileUser = await updateMobileUserTrigger(
          updatedUser.id
        );

        if (!hasUpdatedMobileUser) {
          addToast({
            title: 'Failed to sync user data with mobile app',
            description:
              'Ask user to refresh app before answering a questionnaire',
            duration: 10000,
            type: 'info'
          });
        }

        const updateUserHistories = firebaseFunctions.httpsCallable(
          'updateUserHistories'
        );

        const updateHistoryResponse = await updateUserHistories(
          historiesToUpdate
        );

        if (get(updateHistoryResponse, 'data.status', 500) === 500) {
          throw Error(get(updateHistoryResponse, 'data.error', ''));
        }
      }

      const updateUserUpdatedAt = firebaseFunctions.httpsCallable(
        'updateUserUpdatedAt'
      );
      await updateUserUpdatedAt(updatedUser.id);

      const scheduleOrgScoreCalculation = firebaseFunctions.httpsCallable(
        'scheduleOrgScoreCalculation'
      );

      scheduleOrgScoreCalculation({
        organisationId: user.organisation.id,
        divisionId: user.division.id
      });

      sendUserMessage();

      // Frontend updates
      const currentUserIndex = users.findIndex(
        (currentUser) => currentUser.id === updatedUser.id
      );
      const userIndex = users.findIndex(
        (userData) => userData.id === updatedUser.id
      );

      const currentUsersCopy = [...users];
      currentUsersCopy[currentUserIndex] = { ...updatedUser };

      const usersCopy = [...users];
      usersCopy[userIndex] = { ...updatedUser };

      setUsers(usersCopy);
      updateLoggedClinician(updatedClinician);

      setOrgOngoingHealthChecks((oldState) => {
        const stateCopy = cloneDeep(oldState);
        const checkIndex = stateCopy.findIndex(({ id }) => user.id === id);

        if (checkIndex < 0) return stateCopy;
        stateCopy.splice(checkIndex, 1);
        return stateCopy;
      });

      setIsProcessingAnswers(false);

      addToast({
        title: 'Health check submitted',
        type: 'success'
      });

      handleGoBack(true);
    } catch (error: any) {
      const typedError = error as Error;

      console.error(error);
      setIsProcessingAnswers(false);

      const errorMessage = startsWith(typedError.message, '#')
        ? typedError.message
        : 'Contact support';

      addToast({
        title: 'Something went wrong',
        description: errorMessage,
        type: 'error'
      });

      Sentry.captureException(error);
    }
  }, [
    addToast,
    answeredBodyQuestions,
    answeredLifeQuestions,
    answeredMindQuestions,
    answersSnapshot,
    bodyAnswers,
    clinicalObservation,
    clinicianUser,
    currentOrganisation,
    groupedQuestionnaires,
    handleGoBack,
    jobHealthHazardsHealthCheck,
    lifeAnswers,
    mindAnswers,
    sendUserMessage,
    setOrgOngoingHealthChecks,
    setUsers,
    untouchedUser,
    updateLoggedClinician,
    user,
    userMessage,
    users
  ]);

  useEffect(() => {
    if (!userIsLogged) return;

    const currentUsersIds = users.map((currentUser) => currentUser.id);
    const clientsIds = Object.keys(clinicianUser.clients || {});

    const healthChecksArray = [] as HealthCheck[];
    const ongoingHealthChecksArray = [] as HealthCheck[];

    users.forEach((_user) => {
      const ongoingUserCheck = _user.ongoingHealthCheck;

      if (ongoingUserCheck && ongoingUserCheck.user) {
        const averageScoreObject = {} as AverageScore;

        const checkUser = ongoingUserCheck.user as any;

        const { averageScore, body, mind, life, averageCalculatedAt } =
          checkUser.scores.averageScore as AverageScore;

        averageScoreObject.averageCalculatedAt = averageCalculatedAt;
        averageScoreObject.averageScore = averageScore;
        averageScoreObject.body = body;
        averageScoreObject.mind = mind;
        averageScoreObject.life = life;
        const averageCalculatedAtDate = new Date(
          getUnixSeconds(averageCalculatedAt)
        );
        ongoingHealthChecksArray.push({
          user: _user,
          averageScore: averageScoreObject,
          answers: {},
          clinicalObservation,
          createdAt: moment
            .utc(averageCalculatedAtDate)
            .format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
        });
      }
    });
    const filteredClientsIds = clientsIds.filter((clientId) => {
      if (currentUsersIds.includes(clientId)) return clientId;
    });

    filteredClientsIds.forEach((clientId) => {
      if (!isArray(clinicianUser.clients[clientId])) {
        clinicianUser.clients[clientId] = Object.values(
          clinicianUser.clients[clientId]
        );
      }

      clinicianUser.clients[clientId].forEach((healthCheck) => {
        healthChecksArray.push(healthCheck);
      });
    });

    healthChecksArray.sort((checkA, checkB) =>
      getUnixSeconds(checkA.createdAt as any) >
      getUnixSeconds(checkB.createdAt as any)
        ? -1
        : 1
    );

    setCurrentHealthChecks(healthChecksArray);
    setCurrentOngoingHealthChecks(ongoingHealthChecksArray);
  }, [users, clinicianUser, userIsLogged, clinicalObservation]);

  const handleSaveHealthCheck = useCallback(async () => {
    const allAnsweredQuestions = {
      ...answeredBodyQuestions,
      ...answeredMindQuestions,
      ...answeredLifeQuestions
    };

    const allAnsweredCopy = { ...allAnsweredQuestions };
    delete allAnsweredCopy.weight;
    delete allAnsweredCopy.height;

    const arrayOfBodyBooleans = Object.values(answeredBodyQuestions);
    const hasNotAnsweredAllBodyQuestions = arrayOfBodyBooleans.some(
      (value) => value === false
    );

    if (hasNotAnsweredAllBodyQuestions) {
      const confirmSubmit = window.confirm(
        'There are body questionnaires not fully completed yet, and those are not sent to the user. Do you want to proceed?'
      );

      if (!confirmSubmit) {
        return;
      }
    }

    if (!isOngoingHealthCheck) {
      const confirmSaveHealthCheck = window.confirm(
        'After saving the health check, you will not be able to change questionnares that have already been completed. Are you sure you want to proceed?'
      );

      if (!confirmSaveHealthCheck) {
        return;
      }
    }

    setIsSavingHealthCheck(true);

    try {
      const allKeys = [
        ...Object.keys(bodyAnswers),
        ...Object.keys(mindAnswers),
        ...Object.keys(lifeAnswers)
      ];

      const allValues = [
        ...Object.values(bodyAnswers),
        ...Object.values(mindAnswers),
        ...Object.values(lifeAnswers)
      ];

      const formattedClinicalObservation = clinicalObservation.replace(
        /(?:\r\n|\r|\n)/g,
        ' <br/> '
      );

      const formattedUserMessage = userMessage.replace(
        /(?:\r\n|\r|\n)/g,
        ' <br/> '
      );

      if (!clinicianUser || !clinicianUser.id) {
        console.error({ clinicianUser });
        throw new Error(
          '#Save: Missing clinician identifier. Contact support.'
        );
      }

      const ongoingHealthCheck = {
        bodyAnswers,
        mindAnswers,
        lifeAnswers,
        userMessageSubject,
        userMessage,
        clinicalObservation,
        user: cloneDeep(user),
        submitted: false,
        id: user.id,
        createdAt: moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
      };

      const { updatedUser, clientHealthCheck } = updatedUserAnswersAndScores(
        user,
        clinicianUser,
        untouchedUser,
        allKeys,
        allValues,
        groupedQuestionnaires,
        allAnsweredQuestions,
        formattedClinicalObservation,
        formattedUserMessage
      );

      if (
        organizationHasHearingVisionV2Access(currentOrganisation) &&
        hasJobHealthHazardFilesToUpload(jobHealthHazardsHealthCheck)
      ) {
        await updateHaringVisionAlert(user, clinicianUser);

        const healthCheckFolderPath = `${user.id}/${uuid()}`;
        // This exams list has only the exams added in this health check
        const healthCheckExams = await uploadJobHealthHazardsExams(
          healthCheckFolderPath,
          jobHealthHazardsHealthCheck
        );
        // This exams list contains the exams that user had before the health check
        // plus the new ones added through this health check
        const userExams = await updateUserExams(
          healthCheckExams,
          untouchedUser
        );

        set(ongoingHealthCheck, 'exams', healthCheckExams);
        set(
          ongoingHealthCheck,
          'user.healthCheckFolderPath',
          healthCheckFolderPath
        );
        set(ongoingHealthCheck, 'user.exams', healthCheckExams);
        set(updatedUser, 'exams', userExams);

        // This is needed to clear the list of uploaded images;
        setJobHealthHazardsHealthCheck(healthCheckExams);
      }

      if (!updatedUser || !updatedUser.id) {
        console.error({ updatedUser, clientHealthCheck });
        throw new Error('#Save: Missing user identifier. Contact support.');
      }

      if (isEmpty(clientHealthCheck)) {
        console.error({ updatedUser, clientHealthCheck });
        throw new Error('#Save: Missing client health check. Contact support.');
      }

      const hasAnsweredBodyQuestions = Object.values(
        answeredBodyQuestions
      ).some((boolean) => boolean);

      const hasAnsweredMindQuestions = Object.values(
        answeredMindQuestions
      ).some((boolean) => boolean);

      const hasAnsweredLifeQuestions = Object.values(
        answeredLifeQuestions
      ).some((boolean) => boolean);

      const categoriesToUpdate = [];

      if (hasAnsweredBodyQuestions) categoriesToUpdate.push('body');
      if (hasAnsweredMindQuestions) categoriesToUpdate.push('mind');
      if (hasAnsweredLifeQuestions) categoriesToUpdate.push('life');

      await updateUserAnswersAndScores(updatedUser, categoriesToUpdate);

      const hasUpdatedMobileUser = await updateMobileUserTrigger(
        updatedUser.id
      );

      if (!hasUpdatedMobileUser) {
        addToast({
          title: 'Failed to sync user data with mobile app',
          description:
            'Ask user to refresh app before answering a questionnaire',
          duration: 10000,
          type: 'info'
        });
      }

      const updateUserUpdatedAt = firebaseFunctions.httpsCallable(
        'updateUserUpdatedAt'
      );
      await updateUserUpdatedAt(updatedUser.id);

      await updateUserOngoingHealthCheck(user.id, ongoingHealthCheck);

      const questionnairesToAdd = getNewQuestionnairesAnswered(
        answeredBodyQuestions,
        answersSnapshot
      );

      if (questionnairesToAdd.length > 0) {
        const filteredUser = filterUserAnsweredQuestionnaires({
          category: 'body',
          user,
          questionnairesToAdd
        });

        const historiesToUpdate = await generateUserHistories(
          filteredUser,
          updatedUser
        );

        const updateUserHistories = firebaseFunctions.httpsCallable(
          'updateUserHistories'
        );

        const updateHistoryResponse = await updateUserHistories({
          ...historiesToUpdate
        });

        if (get(updateHistoryResponse, 'data.status', 500) === 500) {
          throw Error(get(updateHistoryResponse, 'data.error', ''));
        }
      }

      setOrgOngoingHealthChecks((oldState) => {
        const stateCopy = cloneDeep(oldState);

        const userCheckIndex = stateCopy.findIndex(
          ({ id }) => id === untouchedUser.id
        );

        if (userCheckIndex < 0) {
          stateCopy.push(ongoingHealthCheck);
        } else {
          stateCopy[userCheckIndex] = ongoingHealthCheck;
        }

        return stateCopy;
      });

      const userIndex = users.findIndex((_user) => _user.id === user.id);
      const currentUsersCopy = cloneDeep(users);

      currentUsersCopy[userIndex].ongoingHealthCheck = ongoingHealthCheck;
      currentUsersCopy[userIndex].exams = updatedUser.exams;
      setUsers(currentUsersCopy);

      setIsSavingHealthCheck(false);

      setIsOngoingHealthCheck(true);

      addToast({
        title: 'Health Check saved',
        type: 'success'
      });
    } catch (error: any) {
      const typedError = error as Error;

      console.error(error);
      setIsSavingHealthCheck(false);

      const errorMessage = startsWith(typedError.message, '#')
        ? typedError.message
        : 'Contact support';

      addToast({
        title: 'Something went wrong',
        description: errorMessage,
        type: 'error'
      });

      Sentry.captureException(error);
    }
  }, [
    answeredBodyQuestions,
    answeredMindQuestions,
    answeredLifeQuestions,
    isOngoingHealthCheck,
    bodyAnswers,
    mindAnswers,
    lifeAnswers,
    clinicalObservation,
    userMessage,
    clinicianUser,
    userMessageSubject,
    user,
    untouchedUser,
    groupedQuestionnaires,
    currentOrganisation,
    answersSnapshot,
    setOrgOngoingHealthChecks,
    users,
    setUsers,
    addToast,
    jobHealthHazardsHealthCheck
  ]);

  return (
    <HealthCheckContext.Provider
      value={{
        user,
        setUser,
        setUntouchedUser,
        untouchedUser,
        currentHeaderTab,
        currentMindTab,
        setCurrentHeaderTab,
        setCurrentMindTab,
        bodyAnswers,
        mindAnswers,
        lifeAnswers,
        handleChangeAnswers,
        handleGoBack,
        handleSubmitHealthCheck,
        currentHealthChecks,
        currentOngoingHealthChecks,
        userMessageSubject,
        setUserMessageSubject,
        userMessage,
        setUserMessage,
        clinicalObservation,
        setClinicalObservation,
        answeredBodyQuestions,
        answeredMindQuestions,
        answeredLifeQuestions,
        handleSaveHealthCheck,
        isOngoingHealthCheck,
        disableMindQuestions,
        disableLifeQuestions,
        isSafetyCriticalRole,
        setIsSafetyCriticalRole,
        jobHealthHazardsHealthCheck,
        setJobHealthHazardsHealthCheck,
        answersSnapshot,
        isUserDetailsPage,
        setIsUserDetailsPage
      }}
    >
      {isProcessingAnswers && <Spinner message="Processing health check" />}
      {isSavingCheck && <Spinner message="Saving health check" />}
      {children}
    </HealthCheckContext.Provider>
  );
};

export function useHealthCheck(): HealthCheckContextProps {
  const context = useContext(HealthCheckContext);

  return context;
}
