import { useCallback } from 'react';
import { camelCase, get } from 'lodash';

import { useHealthCheck } from '../../../../../../hooks/healthCheck';
import { Question } from '../../../../../../models/question';
import { AnswerCard } from '../../../AnswerCard';
import {
  QuestionnaireHeader,
  QuestionnaireIcon,
  QuestionnaireTitle,
  QuestionnaireWrapper,
  QuestionsWrapper
} from './styles';

interface CriticalRoleProps {
  questions: Question[];
}

export const CriticalRole = ({ questions }: CriticalRoleProps) => {
  const { bodyAnswers, answeredBodyQuestions } = useHealthCheck();

  const isQuestionnaireComplete = useCallback(
    (questionnaire: string) => {
      const isComplete = get(
        answeredBodyQuestions,
        camelCase(questionnaire),
        false
      );

      return isComplete;
    },
    [answeredBodyQuestions]
  );

  return (
    <QuestionnaireWrapper
      key={1}
      isComplete={isQuestionnaireComplete('Critical Role')}
    >
      <QuestionnaireHeader>
        <QuestionnaireIcon>
          <img
            src={
              // eslint-disable-next-line import/no-dynamic-require, global-require, @typescript-eslint/no-var-requires
              require(`../../../../../../assets/hearth-icon.png`).default
            }
            alt=""
          />
        </QuestionnaireIcon>

        <QuestionnaireTitle>Critical Role</QuestionnaireTitle>
      </QuestionnaireHeader>

      <QuestionsWrapper>
        {questions
          .sort((a, b) => (a.order > b.order ? 1 : -1))
          .map((question, questionIndex) => (
            <AnswerCard
              key={question.id}
              answers={bodyAnswers}
              questionIndex={questionIndex}
              question={question}
            />
          ))}
      </QuestionsWrapper>
    </QuestionnaireWrapper>
  );
};
