import * as Sentry from '@sentry/react';
import moment from 'moment-timezone';
import { HealthCheck } from '../models/user';
import { firestore } from '../services/firebase';

// Use moment.tz() to get a moment object in a specific timezone
const date = moment.tz('2021-10-01 12:00', 'America/New_York');
console.log(date.format());

const healthChecksCollection = firestore.collection('healthChecks');
const ongoingHealthChecksCollection = firestore.collection(
  'ongoingHealthChecks'
);

export const getOrganizationHealthChecks = async (
  propToFilter: 'lowerFirstName' | 'lowerLastName',
  searchString: string,
  organisationId: string
): Promise<HealthCheck[]> => {
  try {
    const lowerString = searchString.toLowerCase();
    const dbResult = await healthChecksCollection
      .where('user.organisation.id', '==', organisationId)
      .where(`user.${propToFilter}`, '>=', lowerString)
      .where(`user.${propToFilter}`, '<=', lowerString + '\uf8ff')
      .get();

    const healthChecks = dbResult.docs.map((item) => ({
      id: item.id,
      ...item.data()
    })) as HealthCheck[];

    return healthChecks;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error('Failed to get organisation health checks');
  }
};

export const getSpecificDayHealthChecks = async (
  organisationId: string,
  specificDate: string,
  checkStatus: 'completed' | 'ongoing' | 'all'
) => {
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formatString = 'YYYY-MM-DD-THH:mm';
  const timezone = currentTimezone;

  const firstDate = moment.tz(`${specificDate}T00:00`, formatString, timezone);
  const startOfDay = firstDate
    .clone()
    .tz('GMT')
    .format('YYYY-MM-DDT00:00:00.000Z');

  const lastDate = moment.tz(`${specificDate}T23:59`, formatString, timezone);
  const endOfDay = lastDate
    .clone()
    .tz('GMT')
    .format('YYYY-MM-DDT23:59:59.999Z');

  const getCompletedChecks = async () => {
    const dbResult = await healthChecksCollection
      .where('user.organisation.id', '==', organisationId)
      .where('createdAt', '>', startOfDay)
      .where('createdAt', '<', endOfDay)
      .get();
    return dbResult.docs;
  };

  const getOngoingChecks = async () => {
    const dbResult = await ongoingHealthChecksCollection
      .where('user.organisation.id', '==', organisationId)
      .where('createdAt', '>', startOfDay)
      .where('createdAt', '<', endOfDay)
      .where('submitted', '==', false)
      .get();
    return dbResult.docs.filter((snapshot) =>
      Boolean(snapshot.data().createdAt)
    );
  };

  try {
    let dbHealthChecks = [];

    if (checkStatus === 'completed') {
      dbHealthChecks = await getCompletedChecks();
    } else if (checkStatus === 'ongoing') {
      dbHealthChecks = await getOngoingChecks();
    } else {
      const completedChecks = await getCompletedChecks();
      const ongoingChecks = await getOngoingChecks();
      dbHealthChecks = [...completedChecks, ...ongoingChecks];
    }

    const healthChecks = dbHealthChecks.map((item) => ({
      id: item.id,
      ...item.data()
    })) as HealthCheck[];

    return healthChecks;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error('Failed to get specific day health checks');
  }
};
