import { isEmpty } from 'lodash';
import { useHealthCheck } from '../../../../hooks/healthCheck';
import { useQuestions } from '../../../../hooks/questions';
import { AnswerCard } from '../AnswerCard';

import { Container, QuestionsWrapper, QuestionWrapper } from './styles';

export function LifeQuestions() {
  const { lifeQuestionnaires } = useQuestions();
  const { lifeAnswers } = useHealthCheck();

  return (
    <Container>
      <QuestionsWrapper>
        {!isEmpty(lifeQuestionnaires) &&
          lifeQuestionnaires[0].questions
            .sort((a, b) => (a.order > b.order ? 1 : -1))
            .map((question, questionIndex) => (
              <QuestionWrapper key={question.id}>
                <AnswerCard
                  answers={lifeAnswers}
                  questionIndex={questionIndex}
                  question={question}
                />
              </QuestionWrapper>
            ))}
      </QuestionsWrapper>
    </Container>
  );
}
