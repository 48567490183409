import * as Sentry from '@sentry/react';
import { JobHealthHazardsHealthCheck } from '../../models/jobHealthHazards/JobHealthHazardsHealthCheck';
import { isProduction } from '../../services/firebase';
import { uploadFileExams, uploadImageExams } from './uploadExams';

export async function uploadHearingVisionStructuralHealthExams(
  healthCheckFolderPath: string,
  jobHealthHazardsHealthCheck: JobHealthHazardsHealthCheck
) {
  try {
    const hearingFiles = await uploadImageExams(
      `${healthCheckFolderPath}/hearing`,
      jobHealthHazardsHealthCheck.hearing?.exams
    );

    const visionFiles = await uploadImageExams(
      `${healthCheckFolderPath}/vision`,
      jobHealthHazardsHealthCheck.vision?.exams
    );

    const structuralHealthFiles = await uploadFileExams(
      `${healthCheckFolderPath}/structuralHealth`,
      jobHealthHazardsHealthCheck.structuralHealth?.exams
    );

    return {
      hearing: hearingFiles,
      vision: visionFiles,
      structuralHealth: structuralHealthFiles
    };
  } catch (error) {
    console.error('Fail to upload images: ', error);

    if (isProduction) {
      Sentry.captureException(error);
    }
    // Throw an exception here
    throw new Error('Fail to upload exams.');
  }
}
