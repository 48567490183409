import { upperFirst } from 'lodash';
import { useMemo } from 'react';
import { ScoreCard } from '../../..';
import { UserQuestionnaireAnswer } from '../../../../../../models/user';
import { aboutInfo } from '../../../../../../utils/aboutQuestionnaire';

interface VisionScoreProps {
  alertColor: 'green' | 'orange' | 'red' | '';
  answers?: UserQuestionnaireAnswer[] | undefined;
}

export const VisionScreen = ({ alertColor, answers }: VisionScoreProps) => {
  const questionnaire = 'Vision Screen';
  const about = useMemo(() => {
    const aboutArray = [] as string[];

    aboutInfo.visionScreen.map((info) => {
      if (info.type === 'text') {
        aboutArray.push(info.content);
      }
    });

    return aboutArray.join(' | ');
  }, []);

  return (
    <ScoreCard
      key={questionnaire}
      questionnaire={questionnaire}
      color={alertColor}
      icon="eyesight"
      score=""
      about={about}
      userAdvice={`visionScreen${upperFirst(alertColor)}`}
      clinicianAdvice={`visionScreen${upperFirst(alertColor)}Clinician`}
      // answerValue={getPhyscialValue(camelCaseQuestionnaire, section)}
      timeStamp=""
      answers={answers}
    />
  );
};
