import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import lodash from 'lodash';

import { PageTitleWrapper } from '../../../components/PageTitleWrapper';
import { OrganisationFilter } from '../../../components/OrganisationFilter';
import { PageTitle } from '../../../components/PageTitle';
import { CategoryStats } from '../components/CategoryStats';

import { useOrganisation } from '../../../hooks/organisations';
import { useQuestions } from '../../../hooks/questions';

import { Container } from '../styles';

export const Mind: React.FC = () => {
  const { currentOrganisation, chosenDivisionId } = useOrganisation();
  const { mindQuestionnaires } = useQuestions();

  const { mind } = useMemo(() => {
    const orgAverageScore = lodash.get(
      currentOrganisation,
      `scores.${chosenDivisionId}`
    );

    if (orgAverageScore) {
      return orgAverageScore;
    }

    return {
      mind: {}
    };
  }, [currentOrganisation, chosenDivisionId]);

  return (
    <Container>
      <OrganisationFilter hideUserSearch />

      <Helmet>
        <title>Mind summary</title>
      </Helmet>

      <PageTitleWrapper>
        <PageTitle heading="Mind summary" />
      </PageTitleWrapper>

      <CategoryStats
        categoryData={mind}
        categoryQuestionnaires={mindQuestionnaires}
      />
    </Container>
  );
};
