import React, { FormEvent, useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Autocomplete,
  TextField,
  Container,
  Grid,
  Card,
  CardContent,
  Box
} from '@mui/material';
import lodash from 'lodash';
import * as Sentry from '@sentry/react';

import { OrganisationFilter } from '../../../components/OrganisationFilter';
import { PageTitleWrapper } from '../../../components/PageTitleWrapper';
import { PageTitle } from '../../../components/PageTitle';
import { Spinner } from '../../../components/Spinner';

import {
  addUserNotification,
  addClinicianNotification
} from '../../../functions/users';

import { User } from '../../../models/user';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { useOrganisation } from '../../../hooks/organisations';
import { firebaseFunctions } from '../../../services/firebase';

import { Wrapper, SubmitButton } from './styles';
import { ClinicianNotification } from '../../../models/notification';

export const CreateNotification: React.FC = () => {
  const { user, updateUser } = useAuth();
  const { addToast } = useToast();
  const { currentOrganisation, users } = useOrganisation();

  const [chosenUser, setChosenUser] = useState({} as User);
  const [newNotification, setNewNotification] = useState({
    subject: '',
    text: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      if (!currentOrganisation) {
        addToast({
          title: 'Select an organization',
          type: 'info'
        });

        return;
      }

      if (!newNotification.subject || !newNotification.text || !chosenUser.id) {
        addToast({
          title: 'Please fill in all fields to continue',
          type: 'info'
        });

        return;
      }

      const formattedText = newNotification.text.replace(
        /(?:\r\n|\r|\n)/g,
        ' <br/> '
      );

      setIsLoading(true);

      const tokens: string[] = chosenUser.token ? [chosenUser.token] : [];

      try {
        await addUserNotification(chosenUser.id as string, {
          title: newNotification.subject,
          body: formattedText,
          clinicianId: user.id,
          clinicianName: `${user.firstName} ${user.lastName}`
        });

        const notificationObject = {
          title: newNotification.subject,
          body: formattedText,
          userId: chosenUser.id,
          organisationId: currentOrganisation.id,
          divisionId: chosenUser.division.id,
          createdAt: new Date(),
          userName: chosenUser.email
            ? `${chosenUser.firstName} ${chosenUser.lastName} - ${chosenUser.email}`
            : `${chosenUser.firstName} ${chosenUser.lastName} - ${chosenUser.phone}`
        } as ClinicianNotification;

        await addClinicianNotification(user.id, notificationObject);

        if (chosenUser.token && chosenUser.isReceivingPushNotifications) {
          const message = {
            notification: {
              title: newNotification.subject,
              body: newNotification.text
            },
            tokens
          };

          const sendNotification =
            firebaseFunctions.httpsCallable('sendNotification');

          await sendNotification(message);
        }

        const copyClinician = lodash.cloneDeep(user);
        updateUser(copyClinician);

        addToast({
          title: 'Message sent successfully',
          type: 'success'
        });

        setIsLoading(false);
      } catch (error) {
        console.error(error);
        addToast({
          title: 'Error sending message',
          description: 'Please, try again later',
          type: 'error'
        });
        setIsLoading(false);
        Sentry.captureException(error);
      }
    },
    [
      currentOrganisation,
      user,
      chosenUser,
      addToast,
      newNotification,
      updateUser
    ]
  );

  const handleChangeUser = useCallback(
    (userName: string) => {
      const selectedUser = users.find((_user) => {
        return _user.email
          ? userName === `${_user.firstName} ${_user.lastName} - ${_user.email}`
          : userName ===
              `${_user.firstName} ${_user.lastName} - ${_user.phone}`;
      });

      if (!selectedUser) return;

      setChosenUser(selectedUser);
    },
    [users]
  );

  const userNames = useMemo(() => {
    const namesList = users.map((_user) => {
      return _user.email
        ? `${_user.firstName} ${_user.lastName} - ${_user.email}`
        : `${_user.firstName} ${_user.lastName} - ${_user.phone}`;
    });

    return namesList;
  }, [users]);

  return (
    <Wrapper>
      <OrganisationFilter hideDivision />

      <Helmet>
        <title>Send new message</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle heading="Send new message" />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <Card>
                <CardContent>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ mt: 1, pl: 1, pr: 1 }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          disablePortal
                          options={userNames}
                          freeSolo={false}
                          onChange={(e) =>
                            handleChangeUser(e.currentTarget.innerHTML)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              fullWidth
                              label="Select user"
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="subject"
                          required
                          fullWidth
                          id="subject"
                          label="Subject"
                          value={newNotification.subject}
                          onChange={(e) =>
                            setNewNotification({
                              ...newNotification,
                              subject: e.target.value
                            })
                          }
                          autoFocus
                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <TextField
                          label="Message"
                          fullWidth
                          required
                          multiline
                          rows={4}
                          value={newNotification.text}
                          onChange={(e) =>
                            setNewNotification({
                              ...newNotification,
                              text: e.target.value
                            })
                          }
                        />
                      </Grid>
                    </Grid>

                    <SubmitButton type="submit">Send message</SubmitButton>
                  </Box>
                </CardContent>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </Container>

      {isLoading && <Spinner message="Sending message..." />}
    </Wrapper>
  );
};
