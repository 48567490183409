import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import * as Sentry from '@sentry/react';

import { PageTitle } from '../../../components/PageTitle';
import { OrganisationFilter } from '../../../components/OrganisationFilter';
import { Spinner } from '../../../components/Spinner';
import { NotificationCard } from './components/NotificationCard';

import { useAuth } from '../../../hooks/auth';
import { useOrganisation } from '../../../hooks/organisations';

import { getClinicianNotifications } from '../../../functions/users';
import { ClinicianNotification } from '../../../models/notification';
import { getUnixSeconds } from '../../../utils/formatDate';

import { Container, NotificationsWrapper } from './styles';

export const ListNotifications: React.FC = () => {
  const { user } = useAuth();
  const { users, currentOrganisation } = useOrganisation();

  const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);
  const [notifications, setNotifications] = useState<ClinicianNotification[]>(
    []
  );

  useEffect(() => {
    if (!user) return;

    const getNotificationsAsync = async () => {
      setIsLoadingNotifications(true);

      try {
        const response = await getClinicianNotifications(
          user.id as string,
          currentOrganisation.id
        );

        response.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));

        const currentUsersIds = users.map((_user) => _user.id);

        const filteredNotifications = response.filter((notification) => {
          return currentUsersIds.includes(notification.userId);
        });

        filteredNotifications.sort((notA, notB) =>
          getUnixSeconds(notA.createdAt) > getUnixSeconds(notB.createdAt)
            ? -1
            : 1
        );

        setNotifications(response);
        setIsLoadingNotifications(false);
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
      }
    };

    getNotificationsAsync();
  }, [users, user, currentOrganisation]);

  return (
    <Container>
      {isLoadingNotifications && <Spinner message="Fetching notifications" />}
      <OrganisationFilter hideDivision hideUserSearch />

      <Helmet>
        <title>Messages sent</title>
      </Helmet>

      <PageTitle heading="Messages sent" />
      {!notifications.length && <h2>No messages yet</h2>}

      <NotificationsWrapper>
        {notifications.map((notification) => (
          <NotificationCard
            notification={notification}
            key={String(notification.createdAt)}
          />
        ))}
      </NotificationsWrapper>
    </Container>
  );
};
