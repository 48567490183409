import * as Sentry from '@sentry/react';

import { Organisation } from '../models/organisation';
import { User } from '../models/user';
import { QuestionnaireHistory } from '../pages/Dashboard/components/HistoryChart';
import { firestore } from '../services/firebase';

const collectionOrganisations = firestore.collection('organisations');
const collectionOrganisationsScoresHistories = firestore.collection(
  'organisationsScoresHistories'
);
const collectionUsers = firestore.collection('users');

export const getOrganisations = async () => {
  const organisationsFormatted: Organisation[] = [];

  try {
    const organisations = await collectionOrganisations.get();

    organisations.docs.forEach((organisation) => {
      const organisationData = organisation.data() as Omit<Organisation, 'id'>;

      organisationsFormatted.push({
        id: organisation.id,
        ...organisationData
      });
    });

    return organisationsFormatted;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error('Fail to get organizations');
  }
};

export const checkIfOrganisationIsActive = async (organisationId: string) => {
  try {
    const organisationSnaptshot = await collectionOrganisations
      .doc(organisationId)
      .get();
    const organisation = organisationSnaptshot.data() as Organisation;

    return organisation.isActive;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
  }
};

export const getOrganisationUsers = async (orgId: string) => {
  const usersFormatted: User[] = [];

  try {
    const users = await collectionUsers
      .where('organisation.id', '==', orgId)
      .get();

    users.docs.forEach((user) => {
      const userData = user.data() as Omit<User, 'id'>;

      usersFormatted.push({
        id: user.id,
        ...userData
      });
    });

    return usersFormatted;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error('Fail to get organization users');
  }
};

export const getOrganisationQuestionnaireHistory = async (
  orgId: string,
  divisionId: string,
  questionnaire: string
) => {
  try {
    const historySnapshot = await collectionOrganisationsScoresHistories
      .doc(`${orgId}-${divisionId}-${questionnaire}`)
      .get();

    const history = historySnapshot.data() as QuestionnaireHistory | undefined;

    return history;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error('Fail to get organisation questionnaire history');
  }
};

export const getSearchedUsers = async (
  propToFilter: 'lowerFirstName' | 'lowerLastName',
  searchString: string,
  orgId: string
) => {
  const usersFormatted: User[] = [];

  const lowerString = searchString.toLowerCase();

  try {
    const users = await collectionUsers
      .where('organisation.id', '==', orgId)
      .where(propToFilter, '>=', lowerString)
      .where(propToFilter, '<=', lowerString + '\uf8ff')
      .get();

    users.docs.forEach((user) => {
      const userData = user.data() as Omit<User, 'id'>;

      usersFormatted.push({
        id: user.id,
        ...userData
      });
    });

    return usersFormatted;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error('Fail to get searched users');
  }
};
