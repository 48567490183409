import { ReactNode } from 'react';
import {
  DesignServicesTwoTone,
  ManageAccountsTwoTone,
  Spa,
  NotificationsNoneTwoTone,
  PsychologyOutlined,
  SelfImprovementOutlined,
  SettingsAccessibilityOutlined,
  WarningAmber,
  Category,
  Group,
  Flag
} from '@mui/icons-material';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  role?: string;
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Dashboard',
        icon: DesignServicesTwoTone,
        link: '/dashboard'
      },
      {
        name: 'Body',
        icon: SettingsAccessibilityOutlined,
        link: '/dashboard/body'
      },
      {
        name: 'Mind',
        icon: PsychologyOutlined,
        link: '/dashboard/mind'
      },
      {
        name: 'Life',
        icon: SelfImprovementOutlined,
        link: '/dashboard/life'
      },
      {
        name: 'Users',
        link: '/users',
        icon: Group
      },
      {
        name: 'Clients',
        link: '/clients',
        icon: ManageAccountsTwoTone
      },
      {
        name: 'Health Checks',
        link: '/all-health-checks',
        icon: Spa
      },
      {
        name: 'DAS Alerts',
        link: '/das-alerts',
        icon: Category
      },
      {
        name: 'Alerts',
        link: '/alerts',
        icon: WarningAmber
      },
      {
        name: 'Hearing Vision Alerts',
        link: '/hearing-vision-alerts',
        icon: Flag
      },
      {
        name: 'Messages',
        icon: NotificationsNoneTwoTone,
        items: [
          {
            name: 'List',
            link: 'messages'
          },
          {
            name: 'Send new',
            link: 'messages/create'
          }
        ]
      }
    ]
  }
];

export default menuItems;
