import styled from 'styled-components';
import { PureLightTheme } from '../../styles/schemes/PureLightTheme';

export const Container = styled.main`
  width: 100%;
  height: 100%;
`;

export const TableTitle = styled.h1`
  margin: 20px 0;
  text-align: center;
`;

export const TableContainer = styled.table`
  overflow-x: scroll;
  overflow-y: scroll;

  box-shadow: 2px 2px 5px #333;
  border-radius: 10px;
  padding: 5px;
  width: 100%;
  height: 100%;
  background-color: #eeeeee;

  thead {
    cursor: default;
  }

  tbody tr {
    background-color: #fff;
    transition: background-color 0.1s;
  }

  tbody tr:hover {
    background-color: ${PureLightTheme.colors.primary.light};
  }

  tr {
    height: 40px;
  }

  th {
    border-radius: 5px;
    padding: 5px;
  }

  td {
    max-height: 20px;
    text-align: center;
    border-radius: 5px;
    padding: 3px;
    overflow: hidden;

    img {
      height: 45px;
      width: 45px;
      border-radius: 5px;
    }

    ul {
      max-height: 80px;
      overflow-y: auto;
    }

    li {
      list-style: none;
      overflow: hidden;
    }

    span {
      position: relative;

      & > svg {
        position: absolute;
      }
    }
  }
`;
