import { FC, ReactNode } from 'react';
import { Container } from '@mui/material';

import { PageTitle } from './styles';

interface PageTitleWrapperProps {
  children?: ReactNode;
}

export const PageTitleWrapper: FC<PageTitleWrapperProps> = ({ children }) => {
  return (
    <>
      <PageTitle>
        <Container maxWidth="lg">{children}</Container>
      </PageTitle>
    </>
  );
};
