import styled from 'styled-components';

export const GalleryWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
`;

export const GalleryTitle = styled.strong`
  width: 100%;
  color: #333333cc;
  font-size: 14px;
`;

export const WrapsImages = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
  color: #333333cc;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 10px;
  width: 100%;
`;

export const WrapsImage = styled.img`
  cursor: pointer;
  width: 75px;
  height: 75px;
  object-fit: cover;
  object-position: 50% 15%;
  border-radius: 10px;
`;

interface ModalProps {
  width?: string;
}

// --- MODAL --- //
export const ModalWrapper = styled.main<ModalProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 10px;
  border: 2px solid #fff;
  border-radius: 10px;

  width: ${(props) => props.width || '80%'};

  background-color: #fff;
`;

export const CloseButton = styled.h1`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
  color: #000;
  font-weight: 700;
  text-align: right;
  font-size: 20px;
  text-shadow: 1px 0px #ffffff, -1px 0px #ffffff, 0px 1px #ffffff,
    0px -1px #ffffff;
`;

export const WrapsArrow = styled.div`
  cursor: pointer;
  position: absolute;

  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  top: inherit;
  color: #ffffff;
  width: 40px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
  border-radius: 5px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

export const WrapsPreviousButton = styled(WrapsArrow)`
  left: 20px;
`;

export const WrapsNextButton = styled(WrapsArrow)`
  right: 20px;
`;
