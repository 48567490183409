import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const ModalWrapper = styled.main`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 10px;
  border: 2px solid #fff;
  border-radius: 10px;

  max-height: 90vh;
  max-width: 650px;
  background-color: #fff;

  @media (max-width: 450px) {
    width: 300px;
  }

  @media (max-width: 350px) {
    width: 250px;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 24px;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const ModalInfo = styled.div`
  margin-left: 20px;

  p {
    text-align: justify;
    height: 120px;
    overflow-y: scroll;
    padding-right: 10px;
    font-size: 14px;
  }
`;

export const ModalHistory = styled.div``;

interface ScoreRangesProps {
  multipleColumns: boolean;
}

export const ScoreRanges = styled.div<ScoreRangesProps>`
  display: flex;
  justify-content: ${({ multipleColumns }) =>
    multipleColumns ? 'space-around' : 'space-between'};
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
`;

export const RangeCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
`;

interface RangeLineProps {
  red?: boolean;
  orange?: boolean;
  green?: boolean;
}

const getRangeColor = (red: boolean, orange: boolean, green: boolean) => {
  const redHex = '#fc6161';
  const orangeHex = '#ffa500';
  const greenHex = '#5dc45d';

  if (red) return redHex;
  if (orange) return orangeHex;
  if (green) return greenHex;

  return greenHex;
};

export const RangeLine = styled.div<RangeLineProps>`
  text-align: left;

  &::before {
    background-color: ${({ red, orange, green }) =>
      getRangeColor(red || false, orange || false, green || false)};

    content: '';
    margin-right: 5px;
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
  }
`;
