import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 40px;

  @media (max-width: 700px) {
    padding: 5px;
  }
`;

export const HeaderOptionsWrapper = styled.div`
  margin-top: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

interface DisableLayerProps {
  isDisabled: boolean;
}

export const DisableLayer = styled.div<DisableLayerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media (max-width: 700px) {
    flex-direction: column;
  }

  ${(props) =>
    props.isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.2;
    `}
`;

export const ToggleViewWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

interface ToggleButtonProps {
  isActive: boolean;
}

export const ToggleButton = styled.button<ToggleButtonProps>`
  background-color: ${(props) => (props.isActive ? '#3dd4cbaa' : '#fff')};
  color: ${(props) => (props.isActive ? '#fff' : '')};
  border-radius: 10px;
  border: none;
  font-weight: 500;
  padding: 5px;

  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;

export const DateFilterWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  div {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const DateFilter = styled.input`
  border-radius: 10px;
  border: none;
  font-weight: 400;
  padding: 5px;

  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
`;

export const HealthChecksWrapper = styled.main`
  margin-top: 10px;
  padding: 5px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.05);

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 5px;

  @media (max-width: 700px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
`;

export const HealthCheckCard = styled.div`
  border-radius: 10px;
  padding: 10px;
  background-color: #fff;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 700px) {
    flex-direction: column;
  }

  transition: transform 0.1s;

  &:hover {
    transform: scale(1.01);
  }
`;

export const UserDataWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  pointer-events: none;
`;

export const CheckUserName = styled.div`
  margin: 0;
  display: flex;
  justify-content: flex-start;
  color: #333333cc;
  flex-direction: column;

  div {
    display: flex;
    overflow: hidden;

    p {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;
      font-size: 22px;
    }

    p + p {
      margin: 0 3px;
    }
  }
`;

export const UserBirth = styled.div`
  display: flex;

  h4 {
    margin: 0 3px;
  }
`;

export const ConclusionDate = styled.div``;

export const ScoreWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;

  grid-template-areas:
    'a a a'
    'b c d';
`;

export const ScoreCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 10px;

  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 3px 3px 6px;

  img {
    height: 30px;
    width: 30px;
    opacity: 0.5;
  }

  &.average {
    img {
      height: 40px;
      width: 40px;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    p {
      margin: 0;
    }

    strong {
      color: #333333aa;
      font-size: 25px;
      font-weight: bold;
    }

    h6 {
      margin: 0;
    }
  }

  &.average {
    grid-area: a;
  }

  &.body {
    grid-area: b;
  }

  &.mind {
    grid-area: c;
  }

  &.life {
    grid-area: d;
  }
`;
