import { cloneDeep, isEmpty, set } from 'lodash';
import { JobHealthHazardsHealthCheck } from '../../models/jobHealthHazards/JobHealthHazardsHealthCheck';

export function getJobHealthHazardsToSave(
  jobHealthHazardsHealthCheck: JobHealthHazardsHealthCheck,
  uploadedFiles: {
    hearing: string[];
    vision: string[];
    structuralHealth: string[];
  }
): JobHealthHazardsHealthCheck {
  const jobHealthHazardsToSave = cloneDeep(jobHealthHazardsHealthCheck);
  delete jobHealthHazardsToSave?.hearing?.exams;
  delete jobHealthHazardsToSave?.vision?.exams;
  delete jobHealthHazardsToSave?.structuralHealth?.exams;

  if (jobHealthHazardsToSave.hearing && !isEmpty(uploadedFiles.hearing)) {
    set(jobHealthHazardsToSave.hearing, 'uploadedExams', uploadedFiles.hearing);
  }

  if (jobHealthHazardsToSave.vision && !isEmpty(uploadedFiles.vision)) {
    set(jobHealthHazardsToSave.vision, 'uploadedExams', uploadedFiles.vision);
  }

  if (
    jobHealthHazardsToSave.structuralHealth &&
    !isEmpty(uploadedFiles.structuralHealth)
  ) {
    set(
      jobHealthHazardsToSave.structuralHealth,
      'uploadedExams',
      uploadedFiles.structuralHealth
    );
  }

  return jobHealthHazardsToSave;
}
