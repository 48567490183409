export default {
  apiKey: 'AIzaSyALBBx8g7Yph0SbEzpo9TeFhq9DFYrWCt4',
  authDomain: 'kyndwellnesscloud.firebaseapp.com',
  projectId: 'kyndwellnesscloud',
  storageBucket: 'kyndwellnesscloud.appspot.com',
  examsStorageBucket: 'gs://kynd-exams-prod',
  messagingSenderId: '809458009455',
  appId: '1:809458009455:web:8bc58bc811722bc46a549a',
  measurementId: 'G-EC74KGJ8G5',
  databaseURL:
    'https://kyndwellnesscloud-default-rtdb.asia-southeast1.firebasedatabase.app'
};
