import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 40px;

  @media (max-width: 700px) {
    padding: 5px;
  }
`;

export const SubmitButton = styled.button`
  background-color: #3dd4cbaa;
  color: #fff;
  border-radius: 10px;
  border: none;
  font-weight: 500;
  padding: 5px;
  margin-top: 10px;

  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;
