import Loader from 'react-loader-spinner';

import { Container } from './styles';

interface SpinnerProps {
  message?: string;
}

export function Spinner({ message }: SpinnerProps) {
  return (
    <Container>
      {message && <h2>{message}</h2>}
      <Loader type="Circles" color="#fff" height={100} width={100} />
    </Container>
  );
}
