import { useCallback, useEffect, useState } from 'react';
import lodash, { camelCase } from 'lodash';

import { Timestamp } from '../../../../models/user';
import { QuestionnaireProps } from '../../../../hooks/questions';

import { DoughnutChart } from '../DoughnutChart';
import { TableView } from '../TableView';

import {
  Container,
  QuestionnaireCard,
  QuestionnaireName,
  QuestionnairesWrapper,
  SectionCard,
  SectionCardHeader,
  SectionsWrapper,
  ToggleViewButton
} from './styles';
import { QuestionnaireModal } from '../QuestionnaireModal';

export interface CategoryDataProps {
  calculatedAt: Timestamp | string;
  green: number;
  orange: number;
  red: number;
  totalUsers: number;
  question?: string;
  history: Array<{
    calculatedAt: Timestamp | string;
    green: number;
    orange: number;
    red: number;
    totalUsers: number;
  }>;
}

export interface SectionQuestionnaireData extends CategoryDataProps {
  questionnaire: string;
  icon: string;
}

export interface SectionProps {
  section: string;
  questionnairesData: SectionQuestionnaireData[];
}

interface CategoryStatsProps {
  categoryQuestionnaires: QuestionnaireProps[];
  categoryData: Record<string, CategoryDataProps>;
}

export const BodyStats: React.FC<CategoryStatsProps> = ({
  categoryQuestionnaires,
  categoryData
}) => {
  const [tableView, setTableView] = useState(false);
  const [sections, setSections] = useState<SectionProps[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({} as SectionQuestionnaireData);

  useEffect(() => {
    const validQuestionnaires = Object.keys(categoryData);
    const sectionsObject = {} as Record<string, SectionProps>;

    validQuestionnaires.map((questionnaire) => {
      let camelCaseQuestionnaire = questionnaire;

      if (questionnaire === 'systolic' || questionnaire === 'diastolic') {
        camelCaseQuestionnaire = 'systolicDiastolic';
      }

      const questionnaireData = categoryQuestionnaires.find(
        (data) => data.camelCaseQuestionnaire === camelCaseQuestionnaire
      );

      if (!questionnaireData) return;

      let questionnaireName = '';
      let questionnaireIcon = questionnaireData.icon;

      if (questionnaire === 'systolic') {
        questionnaireName = 'Systolic';
        questionnaireIcon = 'systolic';
      } else if (questionnaire === 'diastolic') {
        questionnaireName = 'Diastolic';
        questionnaireIcon = 'diastolic';
      } else {
        questionnaireName = questionnaireData?.questionnaire;
      }

      const { section } = questionnaireData;
      const camelCaseSection = camelCase(section);

      if (!sectionsObject[camelCaseSection]) {
        sectionsObject[camelCaseSection] = {} as SectionProps;
        sectionsObject[camelCaseSection].section = section;
        sectionsObject[camelCaseSection].questionnairesData = [];
      }

      const { green, orange, red, calculatedAt, history, totalUsers } =
        categoryData[questionnaire];

      const sectionQuestionnaire = {
        questionnaire: questionnaireName,
        icon: questionnaireIcon,
        green,
        orange,
        red,
        calculatedAt,
        history,
        totalUsers
      } as SectionQuestionnaireData;

      sectionsObject[camelCaseSection].questionnairesData.push(
        sectionQuestionnaire
      );
    });

    const physical = lodash.get(sectionsObject, 'physical');
    const smokingAndAlcohol = lodash.get(sectionsObject, 'smokingAndAlcohol');
    const lungFunction = lodash.get(sectionsObject, 'lungFunction');
    const hbA1C = lodash.get(sectionsObject, 'hbA1C');
    const restingHeartRate = lodash.get(sectionsObject, 'restingHeartRate');

    if (physical && smokingAndAlcohol) {
      physical.section += ' / Smoking and Alcohol';
      smokingAndAlcohol.questionnairesData.map((data) => {
        physical.questionnairesData.push({ ...data });
      });

      delete sectionsObject.smokingAndAlcohol;
    }

    if (lungFunction && hbA1C && restingHeartRate) {
      lungFunction.section += ' / HbA1C / Resting Heart Rate';
      hbA1C.questionnairesData.map((data) => {
        lungFunction.questionnairesData.push({ ...data });
      });
      restingHeartRate.questionnairesData.map((data) => {
        lungFunction.questionnairesData.push({ ...data });
      });

      delete sectionsObject.hbA1C;
      delete sectionsObject.restingHeartRate;
    }

    const sectionsValues = Object.values(sectionsObject);

    sectionsValues.sort((sectA, sectB) =>
      sectA.section > sectB.section ? -1 : 1
    );

    sectionsValues.forEach(({ questionnairesData }) => {
      questionnairesData.sort((a, b) =>
        a.questionnaire > b.questionnaire ? 1 : -1
      );
    });

    setSections(sectionsValues);
  }, [categoryQuestionnaires, categoryData]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleOpenModal = useCallback(
    (
      questionnaireData: SectionQuestionnaireData,
      questionnaireIndex: number
    ) => {
      const fullModalData = {
        ...questionnaireData,
        questionnaireIndex
      };

      setModalData(fullModalData);
      setIsModalOpen(true);
    },
    []
  );

  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ToggleViewButton onClick={() => setTableView(!tableView)}>
          {tableView ? 'Chart view' : 'Table view'}
        </ToggleViewButton>
      </div>

      {isModalOpen && (
        <QuestionnaireModal
          modalData={modalData}
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
        />
      )}

      {!tableView ? (
        <SectionsWrapper>
          {sections.map(({ section, questionnairesData }) => (
            <SectionCard key={section}>
              <SectionCardHeader>
                <h2>{section}</h2>
              </SectionCardHeader>

              <QuestionnairesWrapper>
                {questionnairesData.map((data, questionnaireIndex) => (
                  <QuestionnaireCard
                    key={data.questionnaire}
                    onClick={() => handleOpenModal(data, questionnaireIndex)}
                    title="Click to see more info"
                  >
                    <QuestionnaireName>
                      <img
                        src={
                          /* eslint-disable */
                          require(`../../../../assets/${
                            data.icon || 'life'
                          }.svg`).default
                          /* eslint-enable */
                        }
                        alt={data.icon}
                      />

                      <h3>{`${data.questionnaire} (${data.totalUsers})`}</h3>
                    </QuestionnaireName>

                    <DoughnutChart data={data} />
                  </QuestionnaireCard>
                ))}
              </QuestionnairesWrapper>
            </SectionCard>
          ))}
        </SectionsWrapper>
      ) : (
        <div style={{ margin: '24px 0px' }}>
          <TableView sections={sections} onRowClick={handleOpenModal} />
        </div>
      )}
    </Container>
  );
};
