import { useNavigate } from 'react-router-dom';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { Typography, Grid, Button } from '@mui/material';

interface PageTitleProps {
  heading?: string;
  subHeading?: string;
  textButton?: string;
  link?: string;
  onClick?: React.MouseEventHandler;
}

export const PageTitle: React.FC<PageTitleProps> = ({
  heading = '',
  subHeading = '',
  textButton = '',
  link = '',
  onClick,
  ...rest
}) => {
  const navigate = useNavigate();

  const handleLinkNavigation = () => navigate(link);

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      {...rest}
      style={{ marginTop: '10px' }}
    >
      <Grid item>
        <Typography
          variant="h3"
          component="h3"
          gutterBottom
          style={{
            color: '#333333aa'
          }}
        >
          {heading.toUpperCase()}
        </Typography>
        <Typography variant="subtitle2">{subHeading}</Typography>
      </Grid>
      {textButton && (
        <Grid item>
          <Button
            type="button"
            onClick={link ? handleLinkNavigation : onClick}
            sx={{ mt: { xs: 2, md: 0 } }}
            startIcon={<AddTwoToneIcon fontSize="small" />}
          >
            {textButton}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
