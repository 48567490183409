import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  text {
    font-weight: bold;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const ChartCard = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  width: 100%;
`;

export const ChartTitle = styled.h2`
  font-weight: bold;
`;
