import styled from 'styled-components';

export const Container = styled.footer`
  padding: 10px;
  color: #333333cc;

  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
`;

export const QuestionnairesHeader = styled.h2`
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid #737373;

  display: flex;
  align-items: center;
  gap: 20px;
  text-transform: uppercase;
`;

export const QuestionnairesWrapper = styled.h2`
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid #737373;
`;
