import styled, { css } from 'styled-components';

// ========== Images ==========

export const Container = styled.div``;

export const BoxSendImage = styled.div`
  margin: 10px;
  width: fit-content;
`;

export const ButtonSelectImage = styled.button`
  cursor: pointer;
  border-radius: 5px;
  background-color: transparent;

  border: solid 1px #3dd4cb;
  padding: 5px 10px;

  font-size: 15px;
  font-weight: 500;
  color: #333333;
  word-break: break-word;

  transition: background-color 0.2s;

  background-color: #f2f5f9;

  &:hover {
    background-color: #ffffff;
  }
`;

export const WrapsImages = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin: 10px;
`;

export const WrapsImage = styled.div`
  position: relative;

  span {
    cursor: pointer;
    position: absolute;
    top: -3px;
    right: 5px;
    color: #000;
    font-weight: 500;
    text-align: center;
    font-size: 15px;
    text-shadow: 1px 0px #ffffff, -1px 0px #ffffff, 0px 1px #ffffff,
      0px -1px #ffffff;
  }

  img {
    cursor: pointer;
    width: 75px;
    height: 75px;
    object-fit: cover;
    object-position: 50% 15%;
    border: 1px solid #3dd4cb;
  }
`;

export const CloseButton = styled.h1`
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 20px;
  color: #000;
  font-weight: 500;
  text-align: right;
  font-size: 20px;
  text-shadow: 1px 0px #ffffff, -1px 0px #ffffff, 0px 1px #ffffff,
    0px -1px #ffffff;
`;

interface ModalProps {
  width?: string;
}

// ========== Modal ==========
export const ModalWrapper = styled.main<ModalProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 10px;
  border: 2px solid #fff;
  border-radius: 10px;

  width: ${(props) => props.width || '80%'};

  background-color: #fff;
`;

export const WrapsArrow = styled.div`
  cursor: pointer;
  position: absolute;

  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  top: inherit;
  color: #ffffff;
  width: 40px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
  border-radius: 5px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

export const WrapsPreviousButton = styled(WrapsArrow)`
  left: 20px;
`;

export const WrapsNextButton = styled(WrapsArrow)`
  right: 20px;
`;

interface VideoScreenProps {
  isPictureTaken: boolean;
}

export const VideoScreen = styled.div<VideoScreenProps>`
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    @keyframes take-picture {
      from {
        filter: brightness(200%);
      }
      to {
        filter: brightness(100%);
      }
    }

    ${(props) =>
      props.isPictureTaken &&
      css`
        animation: take-picture 0.2s ease-out;
      `}
  }

  canvas {
    display: none;
  }
`;

export const CameraIconContainer = styled.div`
  svg {
    position: absolute;
    bottom: 5%;
    right: 43%;
    width: 8vw;
    height: 8vh;
    cursor: pointer;
    filter: invert(0);
    color: white;
    background-color: #33333399;
    border-radius: 10px;
  }
`;
