import { useEffect, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { differenceInYears } from 'date-fns';
import { v4 as uuid } from 'uuid';
import Loader from 'react-loader-spinner';
import { Card, CardContent, Collapse } from '@mui/material';
import {
  SmokingRoomsOutlined,
  LocalBarOutlined,
  InvertColorsOutlined,
  FavoriteBorderOutlined,
  ArrowBackOutlined,
  ArrowForward,
  KeyboardArrowUp,
  KeyboardArrowDown,
  Check,
  Close,
  EngineeringOutlined
} from '@mui/icons-material';
import { camelCase, cloneDeep, get, set, unset } from 'lodash';
import Markdown from 'markdown-to-jsx';
import moment from 'moment';

import {
  JOB_HEALTH_HAZARDS_ADDITIONAL_QUESTIONNAIRES,
  useQuestions
} from '../../hooks/questions';
import { useHealthCheck } from '../../hooks/healthCheck';
import { ScoreCard } from './ScoreCard';
import {
  AverageScore,
  GetQuestionnaireAnswersProps,
  HealthCheck,
  ScoreProps,
  User,
  UserQuestionnaireAnswer
} from '../../models/user';

import {
  getUserClinicalNotifications,
  getUserHealthChecks
} from '../../functions/users';

import { aboutInfo } from '../../utils/aboutQuestionnaire';
import { calculateCategoriesCompleteness } from '../../utils/calculateScore';
import {
  getHealthCheckMessages,
  MessageContent
} from '../../utils/getHealthCheckMessages';
import {
  formatDate,
  getDay,
  getMonthAsNumber,
  getUnixSeconds,
  getYear
} from '../../utils/formatDate';
import { populateScores } from '../../utils/populateScores';
import { getAverageScoreSummary } from '../../utils/getHealthCheckSummaryScores';

import {
  Container,
  CategoryTitle,
  DetailsGrid,
  CriticalData,
  CriticalDataLine,
  DataRow,
  ScoreBlock,
  ScoreData,
  ScoresWrapper,
  ScoreTitle,
  UserData,
  UserDataColumn,
  UserName,
  MessageWrapper,
  MessageHeader,
  MessagesWrapper,
  UserActivation
} from './styles';

import averageScoreIcon from '../../assets/logo-small.png';
import bodyIcon from '../../assets/body-icon.png';
import mindIcon from '../../assets/mind-icon.png';
import lifeIcon from '../../assets/life-icon.png';
import { JobHealthHazards } from './ScoreCard/components/JobHealthHazards';
import { useOrganisation } from '../../hooks/organisations';
import { JobHealthHazardsScore } from './ScoreCard/components/JobHealthHazards/Scores';
import { getQuestionnaireAnswers } from '../../utils/getQuestionnaireAnswers';
import { getJobHealthHazardsAlerts } from '../../functions/hearingVisionAlerts';
import { HazardAction } from '../../models/jobHealthHazards/hearingVisionAlert';
import { JobHealthHazardsHealthCheck } from '../../models/jobHealthHazards/JobHealthHazardsHealthCheck';

interface DetailsScreenProps {
  showDetails: boolean;
  userData?: User;
  answeredQuestionnaires?: Record<string, boolean>;
  hideResumeButton?: boolean;
  isUserDetailsPage?: boolean;
}

export const DetailsScreen: React.FC<DetailsScreenProps> = ({
  showDetails,
  userData,
  answeredQuestionnaires,
  hideResumeButton = false,
  isUserDetailsPage = false
}) => {
  const location: any = useLocation();
  const navigate = useNavigate();
  const {
    setUser,
    setUntouchedUser,
    disableMindQuestions,
    disableLifeQuestions,
    untouchedUser,
    setIsUserDetailsPage,
    setJobHealthHazardsHealthCheck
  } = useHealthCheck();

  const { organizationHasHearingVisionV2Access } = useOrganisation();

  const [isShowingUserMessages, setIsShowingUserMessages] = useState(false);
  const [isShowingAlertsActions, setIsShowingAlertsActions] = useState(false);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [isShowingClinicalMessages, setIsShowingClinicalMessages] =
    useState(false);

  const [clinicalObservations, setClinicalObservations] = useState<
    MessageContent[]
  >([]);
  const [userMessages, setUserMessages] = useState<MessageContent[]>([]);
  const [userAlertsActions, setUserAlertsActions] = useState<HazardAction[]>(
    []
  );

  const checkDate: string = get(location, 'state.checkDate', '');
  const showResumeCheckButton = get(
    location,
    'state.showResumeCheckButton',
    false
  );

  const user = useMemo(() => {
    let userDetails: User;

    if (showDetails) {
      userDetails = get(location, 'state.client', userData);
      setJobHealthHazardsHealthCheck(
        userDetails?.exams || ({} as JobHealthHazardsHealthCheck)
      );
      setUntouchedUser(userDetails);
    } else {
      userDetails = userData as User;
    }

    return userDetails;
  }, [
    location,
    setJobHealthHazardsHealthCheck,
    setUntouchedUser,
    showDetails,
    userData
  ]);

  const sortAndSetUserMessages = useCallback(
    (newMessages: MessageContent[]) => {
      setUserMessages((oldState) => {
        const oldStateCopy = cloneDeep(oldState);

        const groupedMessages = [...oldStateCopy, ...newMessages];

        groupedMessages.sort((a, b) => {
          const aCreatedAt = moment(
            a.submissionDate,
            'DD MMM YYYY, h:mm A'
          ).unix();
          const bCreatedAt = moment(
            b.submissionDate,
            'DD MMM YYYY, h:mm A'
          ).unix();

          return aCreatedAt > bCreatedAt ? -1 : 1;
        });

        return groupedMessages.filter(({ submissionDate }) => !!submissionDate);
      });
    },
    []
  );

  useEffect(() => {
    setIsUserDetailsPage(isUserDetailsPage);
  }, [setIsUserDetailsPage, isUserDetailsPage]);

  useEffect(() => {
    const getHazardsAlertsAsync = async () => {
      setIsLoadingMessages(true);
      const alerts = await getJobHealthHazardsAlerts(user.id);

      let actionsMerged: HazardAction[] = [];
      alerts.forEach(({ actions }) => {
        if (!actions) return;
        actionsMerged = actionsMerged.concat(actions);
      });

      setUserAlertsActions(actionsMerged);
      setIsLoadingMessages(false);
    };

    getHazardsAlertsAsync();
  }, [user.id]);

  const sortClinicalObservations = useCallback(
    (locationClinicalObservations: MessageContent[]) => {
      return locationClinicalObservations.sort((a, b) => {
        const unixA = getUnixSeconds(a.submissionDate);
        const unixB = getUnixSeconds(b.submissionDate);

        return unixA > unixB ? -1 : 1;
      });
    },
    []
  );

  useEffect(() => {
    setUserMessages([]);

    const locationClinicalObservations: MessageContent[] = get(
      location,
      'state.clinicalObservations',
      []
    );

    const locationUserMessages: MessageContent[] = get(
      location,
      'state.userMessages',
      []
    );

    if (locationClinicalObservations.length || locationUserMessages.length) {
      setClinicalObservations(
        sortClinicalObservations(locationClinicalObservations)
      );
      return;
    }

    const getHealthChecksAsync = async () => {
      setIsLoadingMessages(true);
      const userHealthChecks = await getUserHealthChecks(user.id);

      const allHealthChecks: HealthCheck[] = [];

      if (!userHealthChecks.length) {
        setIsLoadingMessages(false);
        return;
      }

      userHealthChecks.forEach((checkData) => {
        checkData.healthChecks.forEach((check) => {
          allHealthChecks.push(check);
        });
      });

      allHealthChecks.sort((checkA, checkB) =>
        getUnixSeconds(checkA.createdAt as any) >
        getUnixSeconds(checkB.createdAt as any)
          ? -1
          : 1
      );

      const checkMessages = getHealthCheckMessages(allHealthChecks);

      setClinicalObservations(
        sortClinicalObservations(checkMessages.clinicalObservations)
      );
      setIsLoadingMessages(false);
    };

    getHealthChecksAsync();
  }, [location, sortAndSetUserMessages, user.id, sortClinicalObservations]);

  useEffect(() => {
    const getUserMessagesAsync = async () => {
      const userClinicalNotifications = await getUserClinicalNotifications(
        user.id
      );

      const formattedMessages = userClinicalNotifications.map(
        ({ body, createdAt }) => {
          const formattedMessage = {
            message: body,
            submissionDate: formatDate({ date: createdAt })
          } as MessageContent;

          return formattedMessage;
        }
      );

      sortAndSetUserMessages(formattedMessages);
    };

    getUserMessagesAsync();
  }, [sortAndSetUserMessages, user.id]);

  const { bodyQuestionnaires, mindQuestionnaires, lifeQuestionnaires } =
    useQuestions();

  const averageScores = useMemo(() => {
    const currentUserAverageScore = get(
      user,
      'scores.averageScore',
      {} as AverageScore
    );
    const untouchedUserAverageScore = get(
      untouchedUser,
      'scores.averageScore',
      {} as AverageScore
    );
    const averageScore = getAverageScoreSummary(user, untouchedUser) || '-';
    const body = get(currentUserAverageScore, 'body', '-');
    const mind =
      currentUserAverageScore.mind || untouchedUserAverageScore.mind || '-';
    const life =
      currentUserAverageScore.life || untouchedUserAverageScore.life || '-';

    return {
      averageScore,
      body,
      mind,
      life
    };
  }, [user, untouchedUser]);

  const scores = useMemo(() => {
    const bodyScore = (user?.scores?.body || {}) as Record<string, ScoreProps>;
    const mindScore = get(
      user,
      'scores.mind',
      get(untouchedUser, 'scores.mind', {})
    ) as Record<string, ScoreProps>;
    const lifeScore = get(
      user,
      'scores.life',
      get(untouchedUser, 'scores.life', {})
    ) as Record<string, ScoreProps>;

    const body = populateScores(bodyScore, bodyQuestionnaires);
    const mind = populateScores(mindScore, mindQuestionnaires);
    const life = populateScores(lifeScore, lifeQuestionnaires);

    return { body, mind, life };
  }, [
    user,
    bodyQuestionnaires,
    mindQuestionnaires,
    lifeQuestionnaires,
    untouchedUser
  ]);

  const getPhyscialValue = useCallback(
    (questionnaire: string, section: string) => {
      const camelCaseSection = camelCase(section);

      const sectionAnswers = get(
        user,
        `answers.body.${camelCaseSection}`,
        {}
      ) as Record<string, UserQuestionnaireAnswer[]>;
      let propertyValue = '';

      if (!get(sectionAnswers, questionnaire)) {
        return propertyValue;
      }

      if (questionnaire !== 'ratioCholesterolHdl') {
        const questionData = sectionAnswers[questionnaire]['0'] || {};
        const { value } = questionData.answer;
        const unit = get(questionData, 'question.unit', '');

        propertyValue = `${value} ${unit}`;
      } else {
        const totalCholesterolData = sectionAnswers[questionnaire]['0'];
        const cholesterol = Number(
          get(totalCholesterolData, 'answer.value', 0)
        );
        const unit = Number(get(totalCholesterolData, 'question.unit', null));

        let ratio: number;

        const hdlData = sectionAnswers[questionnaire]['1'];
        if (hdlData) {
          const hdl = Number(get(hdlData, 'answer.value'));
          ratio = cholesterol / hdl;
        } else {
          ratio = cholesterol;
        }

        propertyValue = `${
          Number.isNaN(ratio) ? 0 : Math.round(ratio * 10) / 10
        } ${unit}`;
      }

      return propertyValue;
    },
    [user]
  );

  const specialScores = useMemo(() => {
    const dummyScore = {
      currentScore: '-',
      color: 'transparent',
      userAdvice: '',
      clinicianAdvice: '-',
      about: '',
      value: ''
    };

    const dasScore = (get(
      user,
      'scores.mind.depressionAnxietyAndStress',
      get(untouchedUser, 'scores.mind.depressionAnxietyAndStress')
    ) || {}) as ScoreProps;

    const depression = (dasScore && dasScore.depressionScore) || {
      ...dummyScore
    };
    const anxiety = (dasScore && dasScore.anxietyScore) || { ...dummyScore };
    const stress = (dasScore && dasScore.stressScore) || { ...dummyScore };

    const systolicDiastolicScore = get(
      user,
      'scores.body.systolicDiastolic',
      {}
    ) as ScoreProps;

    const hasSystolic =
      systolicDiastolicScore && systolicDiastolicScore.systolicScore;
    const systolic = hasSystolic
      ? systolicDiastolicScore.systolicScore
      : { ...dummyScore };

    const hasDiastolic =
      systolicDiastolicScore && systolicDiastolicScore.diastolicScore;
    const diastolic = hasDiastolic
      ? systolicDiastolicScore.diastolicScore
      : { ...dummyScore };

    set(depression, 'about', aboutInfo.depression[0].content);
    set(anxiety, 'about', aboutInfo.anxiety[0].content);
    set(stress, 'about', aboutInfo.stress[0].content);
    set(systolic, 'about', aboutInfo.systolic[0].content);
    set(diastolic, 'about', aboutInfo.diastolic[0].content);

    const systolicDiastolicAnswers = get(
      user,
      `answers.body.bloodPressure.systolicDiastolic`,
      {}
    );

    if (systolicDiastolicAnswers) {
      const firstQuestion = get(
        systolicDiastolicAnswers,
        `['0'].subQuestionnaire`,
        {}
      );

      const firstAnswer = get(systolicDiastolicAnswers, `['0'].answer`, {});
      const secondAnswer = get(systolicDiastolicAnswers, `['1'].answer`, {});

      const unit = get(systolicDiastolicAnswers, `['0'].question.unit`, {});

      let diastolicValue = '';
      let systolicValue = '';

      if (firstQuestion === 'diastolic') {
        diastolicValue = get(firstAnswer, 'value', '');
        systolicValue = get(secondAnswer, 'value', '');
      } else {
        diastolicValue = get(secondAnswer, 'value', '');
        systolicValue = get(firstAnswer, 'value', '');
      }

      set(diastolic, 'value', `${diastolicValue} ${unit}`);
      set(systolic, 'value', `${systolicValue} ${unit}`);
    }

    return {
      depression,
      anxiety,
      stress,
      systolic,
      diastolic
    };
  }, [user, untouchedUser]);

  const criticAnswers = useMemo(() => {
    const smokingAnswer = get(
      user,
      'answers.body.smokingAndAlcohol.smoking.0',
      {}
    ) as UserQuestionnaireAnswer;
    const alcoholAnswer = get(
      user,
      'answers.body.smokingAndAlcohol.alcohol.0',
      {}
    ) as UserQuestionnaireAnswer;
    const heartAnswer = get(
      user,
      'answers.body.restingHeartRate.restingHeartRate',
      {}
    ) as UserQuestionnaireAnswer[];
    const diabetesAnswer = get(
      user,
      'answers.body.hbA1C.hbA1C',
      {}
    ) as UserQuestionnaireAnswer[];
    const criticalRoleAnswer = get(
      user,
      'answers.body.jobHealthHazards.criticalRole',
      {}
    ) as UserQuestionnaireAnswer[];

    let smoker = '?';
    let drinksNumber = '?';
    let isHypertensive = '?';
    let hasDiabetes = '?';
    let isCriticalRole = '?';

    if (smokingAnswer) {
      smoker = get(smokingAnswer, 'answer.value', '') as string;
    }

    if (alcoholAnswer) {
      drinksNumber =
        get(alcoholAnswer, 'answer.value', '') + ' Drinks per week';
    }

    if (heartAnswer) {
      const secondQuestion = heartAnswer['1'];
      const thirdQuestion = heartAnswer['2'];

      const hypertension = get(secondQuestion, 'question.label', '').includes(
        'BETA'
      )
        ? thirdQuestion
        : secondQuestion;

      if (hypertension && hypertension.answer) {
        isHypertensive =
          get(hypertension, 'answer.value', '') === 'Yes'
            ? 'Hypertensive'
            : 'Non-Hypertensive';
      }
    }

    if (diabetesAnswer) {
      const firstQuestion = diabetesAnswer['0'];
      const secondQuestion = diabetesAnswer['1'];

      const diabetesQuestion =
        (get(firstQuestion, 'question.type', '') as string) === 'Number'
          ? secondQuestion
          : firstQuestion;

      hasDiabetes =
        get(diabetesQuestion, 'answer.value', '') === 'Yes'
          ? 'Diabetic'
          : 'Non-diabetic';
    }

    if (criticalRoleAnswer) {
      isCriticalRole =
        (get(criticalRoleAnswer, '[0].answer.value', '') as string) === 'Yes'
          ? 'Is in a Critical Role'
          : 'Is not in a Critical Role';
    }

    return {
      smoker,
      drinksNumber,
      isHypertensive,
      hasDiabetes,
      isCriticalRole
    };
  }, [user]);

  const userAgeAsString = useCallback((birthDate: any) => {
    const birthDay = Number(getDay(birthDate));
    const birthMonth = Number(getMonthAsNumber(birthDate));
    const birthYear = Number(getYear(birthDate));

    const userBirthDate = new Date(birthYear, birthMonth - 1, birthDay);

    const userAge = differenceInYears(new Date(), userBirthDate);

    return userAge;
  }, []);

  const isForbiddenQuestionnaire = useCallback((questionnaire: string) => {
    return (
      questionnaire === 'Weight' ||
      questionnaire === 'Height' ||
      questionnaire === 'Systolic/Diastolic' ||
      JOB_HEALTH_HAZARDS_ADDITIONAL_QUESTIONNAIRES.includes(
        camelCase(questionnaire)
      )
    );
  }, []);

  const categoriesCompleteness = useMemo(() => {
    const userScores = get(user, 'scores', {});
    const untouchedUserScores = get(untouchedUser, 'scores', {});
    const summaryScores = {
      ...untouchedUserScores,
      ...userScores
    };

    unset(summaryScores, 'averageScore');

    return calculateCategoriesCompleteness(
      summaryScores as Record<string, Record<string, ScoreProps>>
    );
  }, [user, untouchedUser]);

  const handleResumeCheck = useCallback(() => {
    const deepUserCopy = cloneDeep(user);
    deepUserCopy.answers = {};
    deepUserCopy.scores = { averageScore: {} };

    const userHealthCheck = user.ongoingHealthCheck;

    if (userHealthCheck && userHealthCheck.user) {
      setUser(userHealthCheck.user);
    } else {
      setUser(deepUserCopy);
    }

    const deepUntouchedCopy = cloneDeep(user);
    setUntouchedUser(deepUntouchedCopy);
    navigate('/new-health-check');
  }, [user, setUser, setUntouchedUser, navigate]);

  const getQuestionnaireTimestamp = useCallback(
    ({ category, section, questionnaire }: GetQuestionnaireAnswersProps) => {
      const camelCaseCategory = camelCase(category);
      const camelCaseSection = camelCase(section);
      const camelCaseQuestionnaire = camelCase(questionnaire);

      let timeStamp = get(
        scores,
        `${camelCaseCategory}.${camelCaseQuestionnaire}.calculatedAt`,
        ''
      );

      if (timeStamp) return timeStamp;

      const answers = get(
        user,
        `answers.${camelCaseCategory}.${camelCaseSection}.${camelCaseQuestionnaire}`
      );

      if (!answers) return '';

      timeStamp = get(answers, `0.answer.answeredAt`, '');
      const formattedTimestamp = formatDate({
        date: timeStamp,
        showHour: true
      });

      return formattedTimestamp;
    },
    [scores, user]
  );

  const isIncompleteQuestionnaire = (
    questionnaire: string,
    disabledQuestionnaire: boolean
  ) => {
    if (showDetails) return false;

    const isCompletedAnswered = get(
      answeredQuestionnaires,
      questionnaire,
      false
    );

    return disabledQuestionnaire ? false : isCompletedAnswered;
  };

  return (
    <Container
      style={{
        padding: showDetails ? '40px' : '0',
        marginTop: showDetails ? '0' : '10px'
      }}
    >
      <Helmet>{user.firstName}&apos;s details</Helmet>

      <Card>
        <CardContent style={{ padding: '16px 16px 6px 16px' }}>
          <UserName>
            {showDetails && (
              <button
                title="Go back"
                type="button"
                onClick={() => navigate(-1)}
                aria-label="Go back"
              >
                <ArrowBackOutlined />
              </button>
            )}

            {showDetails && (
              <div>
                <UserActivation
                  title={user.isActive ? 'Active user' : 'Inactive user'}
                >
                  {user.isActive ? (
                    <Check color="success" />
                  ) : (
                    <Close color="error" />
                  )}
                </UserActivation>
                <p>{user.firstName.toUpperCase()}</p>
                <p>{user.lastName.toUpperCase()}</p>
                <p>({userAgeAsString(user.birthDate)} yrs)</p>
              </div>
            )}

            {!hideResumeButton && showResumeCheckButton && (
              <button
                title="Resume health check"
                type="button"
                onClick={handleResumeCheck}
              >
                Resume check <ArrowForward />
              </button>
            )}
          </UserName>

          {showDetails && (
            <UserData>
              <UserDataColumn>
                <DataRow>
                  <p>Organisation</p>
                  <strong>{user.organisation?.name}</strong>
                </DataRow>

                <DataRow>
                  <p>Division</p>
                  <strong>{user.division?.name}</strong>
                </DataRow>

                <DataRow>
                  <p>Role</p>
                  <strong>{user.role}</strong>
                </DataRow>
              </UserDataColumn>

              <UserDataColumn>
                <DataRow>
                  <p>Contact</p>
                  <strong>{user.email || user.phone}</strong>
                </DataRow>

                <DataRow>
                  <p>Birth date</p>
                  <strong>
                    {formatDate({ date: user.birthDate, showHour: false })}
                  </strong>
                </DataRow>

                <DataRow>
                  <p>Sex</p>
                  <strong>{user.sex}</strong>
                </DataRow>
              </UserDataColumn>
            </UserData>
          )}

          <CriticalDataLine
            addExtraGridColumn={organizationHasHearingVisionV2Access}
          >
            <CriticalData>
              <SmokingRoomsOutlined />
              <strong>{criticAnswers.smoker}</strong>
            </CriticalData>

            <CriticalData>
              <LocalBarOutlined />
              <strong>{criticAnswers.drinksNumber}</strong>
            </CriticalData>

            <CriticalData>
              <InvertColorsOutlined />
              <strong>{criticAnswers.hasDiabetes}</strong>
            </CriticalData>

            <CriticalData>
              <FavoriteBorderOutlined />
              <strong>{criticAnswers.isHypertensive}</strong>
            </CriticalData>

            {organizationHasHearingVisionV2Access && (
              <CriticalData>
                <EngineeringOutlined />
                <strong>{criticAnswers.isCriticalRole}</strong>
              </CriticalData>
            )}
          </CriticalDataLine>

          <ScoreTitle>SCORES</ScoreTitle>

          <ScoresWrapper>
            <ScoreBlock className="area-1">
              <ScoreData className="average">
                <img src={averageScoreIcon} alt="average score icon" />
                <div>
                  <p>KYND Score</p>
                  <p>{categoriesCompleteness.total}%</p>
                  <strong>{averageScores.averageScore}</strong>
                </div>
              </ScoreData>
            </ScoreBlock>

            <ScoreBlock className="area-2">
              <ScoreData>
                <img src={bodyIcon} alt="body icon" />
                <div>
                  <p>Body | {categoriesCompleteness.body}%</p>
                  <strong>{averageScores.body}</strong>
                </div>
              </ScoreData>

              <ScoreData>
                <img src={mindIcon} alt="mind icon" />
                <div>
                  <p>Mind | {categoriesCompleteness.mind}%</p>
                  <strong>{averageScores.mind}</strong>
                </div>
              </ScoreData>

              <ScoreData>
                <img src={lifeIcon} alt="life icon" />
                <div>
                  <p>Life | {categoriesCompleteness.life}%</p>
                  <strong>{averageScores.life}</strong>
                </div>
              </ScoreData>
            </ScoreBlock>

            <ScoreBlock className="area-3">
              <ScoreCard
                questionnaire="Depression"
                color={specialScores.depression.color}
                icon="depression"
                score={specialScores.depression.currentScore}
                about={get(specialScores, 'depression.about', '')}
                userAdvice={specialScores.depression.userAdvice}
                clinicianAdvice={specialScores.depression.clinicianAdvice}
                answers={getQuestionnaireAnswers({
                  category: 'mind',
                  section: 'depressionAnxietyAndStress',
                  questionnaire: 'depressionAnxietyAndStress',
                  user
                })}
                timeStamp={getQuestionnaireTimestamp({
                  category: 'mind',
                  section: 'depressionAnxietyAndStress',
                  questionnaire: 'depressionAnxietyAndStress'
                })}
              />

              <ScoreCard
                questionnaire="Anxiety"
                color={specialScores.anxiety.color}
                icon="anxiety"
                score={specialScores.anxiety.currentScore}
                about={get(specialScores, 'anxiety.about', '')}
                userAdvice={specialScores.anxiety.userAdvice}
                clinicianAdvice={specialScores.anxiety.clinicianAdvice}
                answers={getQuestionnaireAnswers({
                  category: 'mind',
                  section: 'depressionAnxietyAndStress',
                  questionnaire: 'depressionAnxietyAndStress',
                  user
                })}
                timeStamp={getQuestionnaireTimestamp({
                  category: 'mind',
                  section: 'depressionAnxietyAndStress',
                  questionnaire: 'depressionAnxietyAndStress'
                })}
              />

              <ScoreCard
                questionnaire="Stress"
                color={specialScores.stress.color}
                icon="stress"
                score={specialScores.stress.currentScore}
                about={get(specialScores, 'stress.about', '')}
                userAdvice={specialScores.stress.userAdvice}
                clinicianAdvice={specialScores.stress.clinicianAdvice}
                answers={getQuestionnaireAnswers({
                  category: 'mind',
                  section: 'depressionAnxietyAndStress',
                  questionnaire: 'depressionAnxietyAndStress',
                  user
                })}
                timeStamp={getQuestionnaireTimestamp({
                  category: 'mind',
                  section: 'depressionAnxietyAndStress',
                  questionnaire: 'depressionAnxietyAndStress'
                })}
              />
            </ScoreBlock>
          </ScoresWrapper>

          <CategoryTitle>BODY</CategoryTitle>
          <DetailsGrid>
            <ScoreCard
              questionnaire="Weight"
              icon="weight"
              score={getPhyscialValue('weight', 'physical')}
              timeStamp={getQuestionnaireTimestamp({
                category: 'body',
                section: 'physical',
                questionnaire: 'weight'
              })}
            />

            <ScoreCard
              questionnaire="Height"
              icon="height"
              score={getPhyscialValue('height', 'physical')}
              timeStamp={getQuestionnaireTimestamp({
                category: 'body',
                section: 'physical',
                questionnaire: 'height'
              })}
            />

            <ScoreCard
              questionnaire="Systolic"
              color={specialScores.systolic.color}
              icon="systolic"
              score={specialScores.systolic.currentScore}
              about={get(specialScores, 'systolic.about', '')}
              userAdvice={specialScores.systolic.userAdvice}
              clinicianAdvice={specialScores.systolic.clinicianAdvice}
              answerValue={get(specialScores, 'systolic.value')}
              timeStamp={getQuestionnaireTimestamp({
                category: 'body',
                section: 'bloodPressure',
                questionnaire: 'systolicDiastolic'
              })}
            />

            <ScoreCard
              questionnaire="Diastolic"
              color={specialScores.diastolic.color}
              icon="diastolic"
              score={specialScores.diastolic.currentScore}
              about={get(specialScores, 'diastolic.about', '')}
              userAdvice={specialScores.diastolic.userAdvice}
              clinicianAdvice={specialScores.diastolic.clinicianAdvice}
              answerValue={get(specialScores, 'diastolic.value')}
              timeStamp={getQuestionnaireTimestamp({
                category: 'body',
                section: 'bloodPressure',
                questionnaire: 'systolicDiastolic'
              })}
            />

            {bodyQuestionnaires.map(
              ({
                questionnaire,
                camelCaseQuestionnaire,
                icon,
                about,
                section
              }) =>
                !isForbiddenQuestionnaire(questionnaire) && (
                  <ScoreCard
                    key={questionnaire}
                    questionnaire={questionnaire}
                    color={scores.body[camelCaseQuestionnaire].color}
                    icon={icon}
                    score={
                      scores.body[camelCaseQuestionnaire].score ||
                      scores.body[camelCaseQuestionnaire].currentScore
                    }
                    about={about}
                    userAdvice={scores.body[camelCaseQuestionnaire].userAdvice}
                    clinicianAdvice={
                      scores.body[camelCaseQuestionnaire].clinicianAdvice
                    }
                    answerValue={getPhyscialValue(
                      camelCaseQuestionnaire,
                      section
                    )}
                    timeStamp={getQuestionnaireTimestamp({
                      category: 'body',
                      section,
                      questionnaire
                    })}
                  />
                )
            )}
            <JobHealthHazardsScore />
          </DetailsGrid>

          <CategoryTitle>MIND</CategoryTitle>
          <DetailsGrid>
            {mindQuestionnaires.map(
              ({
                questionnaire,
                camelCaseQuestionnaire,
                icon,
                about,
                section
              }) =>
                camelCaseQuestionnaire !== 'depressionAnxietyAndStress' && (
                  <ScoreCard
                    key={questionnaire}
                    answers={getQuestionnaireAnswers({
                      category: 'mind',
                      section,
                      questionnaire,
                      user
                    })}
                    questionnaire={questionnaire}
                    color={scores.mind[camelCaseQuestionnaire].color}
                    icon={icon}
                    score={
                      scores.mind[camelCaseQuestionnaire].score ||
                      scores.mind[camelCaseQuestionnaire].currentScore
                    }
                    about={about}
                    userAdvice={scores.mind[camelCaseQuestionnaire].userAdvice}
                    clinicianAdvice={
                      scores.mind[camelCaseQuestionnaire].clinicianAdvice
                    }
                    isIncomplete={isIncompleteQuestionnaire(
                      camelCaseQuestionnaire,
                      disableMindQuestions
                    )}
                    timeStamp={getQuestionnaireTimestamp({
                      category: 'mind',
                      section,
                      questionnaire
                    })}
                  />
                )
            )}
          </DetailsGrid>

          <CategoryTitle>LIFE</CategoryTitle>
          <DetailsGrid>
            {lifeQuestionnaires.map(
              ({
                questionnaire,
                camelCaseQuestionnaire,
                icon,
                about,
                section
              }) => (
                <ScoreCard
                  key={questionnaire}
                  questionnaire={questionnaire}
                  color={scores.life[camelCaseQuestionnaire].color}
                  icon={icon}
                  answers={getQuestionnaireAnswers({
                    category: 'life',
                    section,
                    questionnaire,
                    user
                  })}
                  score={
                    scores.life[camelCaseQuestionnaire].score ||
                    scores.life[camelCaseQuestionnaire].currentScore
                  }
                  about={about}
                  userAdvice={scores.life[camelCaseQuestionnaire].userAdvice}
                  clinicianAdvice={
                    scores.life[camelCaseQuestionnaire].clinicianAdvice
                  }
                  isIncomplete={isIncompleteQuestionnaire(
                    camelCaseQuestionnaire,
                    disableLifeQuestions
                  )}
                  timeStamp={getQuestionnaireTimestamp({
                    category: 'life',
                    section,
                    questionnaire
                  })}
                />
              )
            )}
          </DetailsGrid>
        </CardContent>
      </Card>

      {organizationHasHearingVisionV2Access && <JobHealthHazards />}

      {!showResumeCheckButton && (
        <Card style={{ marginTop: '20px' }}>
          <MessagesWrapper>
            <MessageHeader
              title="Click to toggle job health hazards actions"
              onClick={() => setIsShowingAlertsActions(!isShowingAlertsActions)}
            >
              {isShowingAlertsActions ? (
                <KeyboardArrowDown />
              ) : (
                <KeyboardArrowUp />
              )}
              JOB HEALTH HAZARDS ACTIONS
              {isLoadingMessages ? (
                <Loader type="TailSpin" color="#333" height={15} width={15} />
              ) : (
                ` (${userAlertsActions.length})`
              )}
            </MessageHeader>

            <Collapse in={isShowingAlertsActions}>
              {userAlertsActions.map(
                ({ comment, status, timestamp, actionUser }) => (
                  <MessageWrapper key={formatDate({ date: timestamp })}>
                    <h3>
                      Action taken by:{' '}
                      {`${actionUser.firstName} ${actionUser.lastName}`}
                    </h3>
                    <h3>Status: {status}</h3>
                    <h3>{formatDate({ date: timestamp })}</h3>

                    <p>
                      <Markdown>{comment}</Markdown>
                    </p>
                  </MessageWrapper>
                )
              )}
            </Collapse>
          </MessagesWrapper>
        </Card>
      )}

      {!showResumeCheckButton && (
        <Card style={{ marginTop: '20px' }}>
          <MessagesWrapper>
            <MessageHeader
              title="Click to toggle user messages"
              onClick={() => setIsShowingUserMessages(!isShowingUserMessages)}
            >
              {isShowingUserMessages ? (
                <KeyboardArrowDown />
              ) : (
                <KeyboardArrowUp />
              )}
              MESSAGES SENT TO USER
              {isLoadingMessages ? (
                <Loader type="TailSpin" color="#333" height={15} width={15} />
              ) : (
                ` (${userMessages.length})`
              )}
            </MessageHeader>

            <Collapse in={isShowingUserMessages}>
              {userMessages.map(({ message, submissionDate }) => (
                <MessageWrapper
                  key={submissionDate + 'userMessage'}
                  isCurrentCheck={checkDate === submissionDate}
                >
                  <h3>{submissionDate}</h3>

                  <p>
                    <Markdown>{message}</Markdown>
                  </p>
                </MessageWrapper>
              ))}
            </Collapse>
          </MessagesWrapper>
        </Card>
      )}

      {!showResumeCheckButton && (
        <Card style={{ marginTop: '20px' }}>
          <MessagesWrapper>
            <MessageHeader
              title="Click to toggle clinical observations"
              onClick={() =>
                setIsShowingClinicalMessages(!isShowingClinicalMessages)
              }
            >
              {isShowingClinicalMessages ? (
                <KeyboardArrowDown />
              ) : (
                <KeyboardArrowUp />
              )}
              CLINICAL OBSERVATIONS
              {isLoadingMessages ? (
                <Loader type="TailSpin" color="#333" height={15} width={15} />
              ) : (
                ` (${clinicalObservations.length})`
              )}
            </MessageHeader>

            <Collapse in={isShowingClinicalMessages}>
              {clinicalObservations.map(({ message, submissionDate }) => (
                <MessageWrapper
                  key={uuid()}
                  isCurrentCheck={checkDate === submissionDate}
                >
                  <h3>{submissionDate}</h3>

                  <p>
                    <Markdown>{message}</Markdown>
                  </p>
                </MessageWrapper>
              ))}
            </Collapse>
          </MessagesWrapper>
        </Card>
      )}
    </Container>
  );
};
