import { differenceInYears } from 'date-fns';

import { getDay, getMonthAsNumber, getYear } from './formatDate';

export const getUserAge = (birthDate: any) => {
  if (!birthDate) return '';

  const birthDay = Number(getDay(birthDate));
  const birthMonth = Number(getMonthAsNumber(birthDate));
  const birthYear = Number(getYear(birthDate));

  const userBirthDate = new Date(birthYear, birthMonth - 1, birthDay);

  const userAge = differenceInYears(new Date(), userBirthDate);

  return userAge;
};
