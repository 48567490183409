import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import moment from 'moment';

import { useToast } from '../../hooks/toast';
import { useOrganisation } from '../../hooks/organisations';

import { OrganisationFilterCheckPage } from '../../components/OrganisationFilterCheckPage';
import { PageTitle } from '../../components/PageTitle';
import { HealthCheck, User } from '../../models/user';
import { Spinner } from '../../components/Spinner';

import { getUserAge } from '../../utils/getUserAge';
import { formatDate, getUnixSeconds } from '../../utils/formatDate';
import { calculateCategoriesCompleteness } from '../../utils/calculateScore';
import { getHealthCheckMessages } from '../../utils/getHealthCheckMessages';

import { getSpecificDayHealthChecks } from '../../functions/healthChecks';

import {
  CheckUserName,
  ConclusionDate,
  Container,
  HealthChecksWrapper,
  HealthCheckCard,
  ScoreCard,
  ScoreWrapper,
  UserBirth,
  UserDataWrapper
} from './styles';

import averageScoreIcon from '../../assets/logo-small.png';
import bodyIcon from '../../assets/body-icon.png';
import mindIcon from '../../assets/mind-icon.png';
import lifeIcon from '../../assets/life-icon.png';

export function ViewHealthChecks() {
  const navigate = useNavigate();

  const { currentOrganisation } = useOrganisation();
  const { addToast } = useToast();

  const [checkStatus, setCheckStatus] = useState('Completed health checks');
  const [checkDay, setCheckDay] = useState('Today');
  const [healthChecks, setHealthChecks] = useState([] as HealthCheck[]);
  const [isLoadingChecks, setIsLoadingChecks] = useState(false);
  const [specificDate, setSpecificDate] = useState(
    moment().format('YYYY-MM-DD')
  );

  useEffect(() => {
    const healthCheckStatusFilter = localStorage.getItem(
      '@KyndClinician:healthCheckStatusFilter'
    );
    const healthCheckDayFilter = localStorage.getItem(
      '@KyndClinician:healthCheckDayFilter'
    );
    const healthCheckSpecificDayFilter = localStorage.getItem(
      '@KyndClinician:healthCheckSpecificDayFilter'
    );

    setCheckStatus(healthCheckStatusFilter || 'Completed health checks');
    setCheckDay(healthCheckDayFilter || 'Today');
    setSpecificDate(
      healthCheckSpecificDayFilter || moment().format('YYYY-MM-DD')
    );
  }, []);

  useEffect(() => {
    const getChecksAsync = async () => {
      if (checkDay === 'Specific day') return;

      setIsLoadingChecks(true);
      const formattedCheckDay = getCheckDay(checkDay);

      const status = getSelectedStatus(checkStatus);

      const specificDayHealthChecks = await getSpecificDayHealthChecks(
        currentOrganisation.id,
        formattedCheckDay,
        status
      );
      setHealthChecks(
        specificDayHealthChecks.sort((a, b) =>
          a.user.firstName > b.user.firstName ? 1 : -1
        )
      );
      setIsLoadingChecks(false);
    };

    getChecksAsync();
  }, [checkDay, checkStatus, currentOrganisation.id]);

  const handleSeeSpecificDayChecks = useCallback(
    async (dateString: string) => {
      setHealthChecks([]);

      setIsLoadingChecks(true);

      const status = getSelectedStatus(checkStatus);

      try {
        const specificDayHealthChecks = await getSpecificDayHealthChecks(
          currentOrganisation.id,
          dateString,
          status
        );

        setHealthChecks(
          specificDayHealthChecks.sort((a, b) =>
            a.user.firstName > b.user.firstName ? 1 : -1
          )
        );
        setIsLoadingChecks(false);
      } catch (error) {
        console.error(error);
        setIsLoadingChecks(false);

        addToast({
          title: 'Error fetching health checks',
          description: 'Contact support',
          type: 'error'
        });
      }
    },
    [checkStatus, currentOrganisation.id, addToast]
  );

  useEffect(() => {
    if (checkDay !== 'Specific day') return;

    handleSeeSpecificDayChecks(specificDate);
  }, [checkDay, checkStatus, handleSeeSpecificDayChecks, specificDate]);

  const handleChangeSpecificDate = useCallback(async (dateString: string) => {
    const formattedDate = moment(dateString).format('YYYY-MM-DD');
    localStorage.setItem(
      '@KyndClinician:healthCheckSpecificDayFilter',
      formattedDate
    );
    setSpecificDate(formattedDate);
  }, []);

  const handleSeeDetails = useCallback(
    (user: User, createdAt: Date | string) => {
      const filteredUserHealthChecks = healthChecks.filter(
        (check) => check.user.id === user.id
      );

      const { clinicalObservations, userMessages } = getHealthCheckMessages(
        filteredUserHealthChecks
      );

      const checkDate = moment
        .utc(getUnixSeconds(createdAt))
        .local()
        .format('DD MMM YYYY, hh:mm A');

      navigate('details', {
        state: {
          client: user,
          clinicalObservations,
          userMessages,
          showResumeCheckButton: checkStatus === 'Ongoing health checks',
          checkDate
        }
      });
    },
    [healthChecks, navigate, checkStatus]
  );

  const scoresCompleteness = useCallback((cardUser: User) => {
    const completeness = {
      bodyCompleteness: '0 %',
      mindCompleteness: '0 %',
      lifeCompleteness: '0 %',
      totalCompleteness: '0 %'
    };

    const generalScores = get(cardUser, 'scores') as any;

    if (generalScores) {
      const categoriesCompleteness =
        calculateCategoriesCompleteness(generalScores);

      completeness.bodyCompleteness = categoriesCompleteness.body + ' %';
      completeness.mindCompleteness = categoriesCompleteness.mind + ' %';
      completeness.lifeCompleteness = categoriesCompleteness.life + ' %';
      completeness.totalCompleteness = categoriesCompleteness.total + ' %';
    }

    return completeness;
  }, []);

  const getCheckDay = (stringDay: string) => {
    let formattedDate = '';

    switch (stringDay) {
      case 'Today':
        formattedDate = moment().format('YYYY-MM-DD');
        break;
      case 'Yesterday':
        formattedDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
        break;
      case '2 days ago':
        formattedDate = moment().subtract(2, 'day').format('YYYY-MM-DD');
        break;
      case '5 days ago':
        formattedDate = moment().subtract(5, 'day').format('YYYY-MM-DD');
        break;
      case '15 days ago':
        formattedDate = moment().subtract(15, 'day').format('YYYY-MM-DD');
        break;
      default:
        formattedDate = stringDay;
        break;
    }

    return formattedDate;
  };

  const getSelectedStatus = (selectedStatus: string) => {
    let status: 'completed' | 'ongoing' | 'all';

    switch (selectedStatus) {
      case 'Completed health checks':
        status = 'completed';
        break;
      case 'Ongoing health checks':
        status = 'ongoing';
        break;
      default:
        status = 'all';
    }

    return status;
  };

  return (
    <Container>
      {isLoadingChecks && (
        <Spinner
          message={`Loading health checks from: ${moment(
            checkDay === 'Specific day' ? specificDate : getCheckDay(checkDay)
          ).format('D MMM YYYY')}`}
        />
      )}

      <OrganisationFilterCheckPage
        checkStatus={checkStatus}
        setCheckStatus={setCheckStatus}
        checkDay={checkDay}
        setCheckDay={setCheckDay}
        hideDivision
        hideUserSearch
        showDatePicker={checkDay === 'Specific day'}
        specificDate={specificDate}
        handleChangeSpecificDate={handleChangeSpecificDate}
      />

      <PageTitle heading={`Health Checks (${healthChecks.length})`} />

      <HealthChecksWrapper>
        {!healthChecks.length && <h2>No data to show</h2>}

        {healthChecks.map(({ user, createdAt, submitted }) => (
          <HealthCheckCard
            key={formatDate({
              date: createdAt,
              showHour: true
            })}
            onClick={() => handleSeeDetails(user, createdAt)}
            title="Click to open health check summary"
          >
            <UserDataWrapper>
              <CheckUserName>
                <div>
                  <p>{user.firstName.toUpperCase()}</p>
                  <p>{user.lastName.toUpperCase()}</p>
                </div>
                <h6>{user.email || user.phone}</h6>
              </CheckUserName>

              <UserBirth>
                <p>DOB:</p>
                <h4>{formatDate({ date: user.birthDate, showHour: false })}</h4>
                <h4>({getUserAge(user.birthDate)} yrs)</h4>
              </UserBirth>

              <ConclusionDate>
                <p>{submitted ? 'Submitted' : 'Last updated'}</p>
                <h4>
                  {moment
                    .utc(
                      getUnixSeconds(
                        user.scores.averageScore.averageCalculatedAt
                      )
                    )
                    .local()
                    .format('DD MMM YYYY, hh:mm A')}
                </h4>
              </ConclusionDate>
            </UserDataWrapper>

            <ScoreWrapper>
              <ScoreCard className="average">
                <img src={averageScoreIcon} alt="Average score" />
                <div>
                  <p>KYND Score</p>
                  <strong>{user.scores.averageScore.averageScore}</strong>
                  <h6>{scoresCompleteness(user).totalCompleteness}</h6>
                </div>
              </ScoreCard>

              <ScoreCard className="body">
                <img src={bodyIcon} alt="Body score" />
                <div>
                  <p>Body</p>
                  <strong>{user.scores.averageScore.body}</strong>
                  <h6>{scoresCompleteness(user).bodyCompleteness}</h6>
                </div>
              </ScoreCard>

              <ScoreCard className="mind">
                <img src={mindIcon} alt="Mind score" />
                <div>
                  <p>Mind</p>
                  <strong>{user.scores.averageScore.mind}</strong>
                  <h6>{scoresCompleteness(user).mindCompleteness}</h6>
                </div>
              </ScoreCard>

              <ScoreCard className="life">
                <img src={lifeIcon} alt="Life score" />
                <div>
                  <p>Life</p>
                  <strong>{user.scores.averageScore.life}</strong>
                  <h6>{scoresCompleteness(user).lifeCompleteness}</h6>
                </div>
              </ScoreCard>
            </ScoreWrapper>
          </HealthCheckCard>
        ))}
      </HealthChecksWrapper>
    </Container>
  );
}
