import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { TakePhotoField } from '../../../../../../../components/TakePhotoField';
import { getUploadedImagesUrl } from '../../../../../../../functions/jobHealthHazards/getUploadedExams';
import { useHealthCheck } from '../../../../../../../hooks/healthCheck';
import { JobHealthHazardsHealthCheck } from '../../../../../../../models/jobHealthHazards/JobHealthHazardsHealthCheck';
import { QuestionsWrapper, QuestionTitle } from '../styles';

export const VisionExams = () => {
  const { jobHealthHazardsHealthCheck, setJobHealthHazardsHealthCheck } =
    useHealthCheck();

  const [visionExams, setVisionExams] = useState([] as string[]);
  const [isDisabledUpload, setIsDisabledUpload] = useState(false);

  const [isFetchingExams, setIsFetchingExams] = useState(false);

  useEffect(() => {
    if (!isEmpty(jobHealthHazardsHealthCheck?.vision?.uploadedExams)) {
      setIsDisabledUpload(true);
      const uploadExamsPaths =
        jobHealthHazardsHealthCheck?.vision?.uploadedExams;
      if (!isFetchingExams) {
        setIsFetchingExams(true);
        setVisionExams([]);
        uploadExamsPaths?.forEach((path) => {
          getUploadedImagesUrl(path).then((result) => {
            setVisionExams((prev) => [...prev, result]);
          });
        });
      }
      return;
    }

    setVisionExams(jobHealthHazardsHealthCheck.vision?.exams || []);
  }, [isFetchingExams, jobHealthHazardsHealthCheck.vision]);

  return (
    <QuestionsWrapper>
      <QuestionTitle>Vision Exams</QuestionTitle>
      <TakePhotoField
        componentId="vision-exams"
        buttonTitle="Add Vision Exam"
        updateUploadedImages={(value) =>
          setJobHealthHazardsHealthCheck(
            (prev) =>
              ({
                ...prev,
                vision: {
                  ...prev.vision,
                  exams: value
                }
              } as JobHealthHazardsHealthCheck)
          )
        }
        uploadedImagesUrls={visionExams}
        isDisabledUpload={isDisabledUpload}
      />
    </QuestionsWrapper>
  );
};
