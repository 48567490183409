import React from 'react';
import { Container, Input, Textarea, Select, Radio } from './styles';

interface SelectProps {
  value: string;
  label: string;
}

interface FormFieldProps {
  label?: string;
  placeholder?: string;
  selected?: string | number;
  checked?: boolean;
  value: string | number;
  type: string;
  disabled?: boolean;
  readOnly?: boolean;
  name?: string;
  options?: SelectProps[];
  style?: React.CSSProperties;
  change?: (arg: string) => void;
  changeInput?: (arg: React.ChangeEvent<HTMLInputElement>) => void;
  greyBg?: boolean;
}

export const FormField: React.FC<FormFieldProps> = ({
  label,
  placeholder,
  change,
  changeInput,
  selected,
  checked,
  readOnly,
  name,
  options,
  value,
  type,
  style,
  disabled,
  greyBg,
  ...rest
}) => {
  if (type === 'textarea') {
    return (
      <Container style={{ ...style }}>
        <span>{label}</span>
        <Textarea
          value={value}
          placeholder={placeholder}
          onChange={(e) => {
            change && change(e.target.value);
          }}
          {...rest}
        />
      </Container>
    );
  }

  if (type === 'select') {
    return (
      <Container style={{ ...style }}>
        <span>{label}</span>
        <Select
          value={selected}
          onChange={(e) => {
            change && change(e.target.value);
          }}
          {...rest}
        >
          <option value="">{placeholder}</option>
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </Container>
    );
  }

  if (type === 'radio') {
    return (
      <>
        <Radio
          style={{ ...style }}
          value={value}
          type={type}
          name={name}
          checked={!!checked}
          disabled={disabled}
          readOnly={readOnly}
          onChange={changeInput}
          {...rest}
        />
        <span>{label}</span>
      </>
    );
  }

  return (
    <Container style={{ ...style }}>
      <span>{label}</span>
      <Input
        type={type}
        style={{ backgroundColor: greyBg ? '#f2f5f9' : '' }}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onWheel={(e) => {
          const event = e as any;
          event.target.blur();
        }}
        onChange={(e) => {
          change && change(e.target.value);
        }}
        min={0}
        max={
          type === 'date'
            ? `${String(new Date().getFullYear()).padStart(2, '0')}-${String(
                new Date().getMonth() + 1
              ).padStart(2, '0')}-${String(new Date().getDate()).padStart(
                2,
                '0'
              )}`
            : 999
        }
        {...rest}
      />
    </Container>
  );
};
