import { get, isEmpty } from 'lodash';
import { User } from '../models/user';
import { JobHealthHazardsHealthCheck } from '../models/jobHealthHazards/JobHealthHazardsHealthCheck';

interface CamelCaseQuestionnaireToExamsPath {
  [key: string]: string;
}

const camelCaseQuestionnaireToExamsPath: CamelCaseQuestionnaireToExamsPath = {
  hearingScreen: 'hearing',
  visionScreen: 'vision'
};

export const checkUserHasUploadedExams = (
  user: User,
  jobHealthHazardsHealthCheck: JobHealthHazardsHealthCheck,
  camelCaseQuestionnaire: string
): boolean => {
  const examsPath = camelCaseQuestionnaireToExamsPath[camelCaseQuestionnaire];

  if (!examsPath) {
    return true;
  }

  const pendingUploadExams = get(
    jobHealthHazardsHealthCheck,
    examsPath,
    {}
  ) as any;

  return (
    !isEmpty(pendingUploadExams.uploadedExams) ||
    !isEmpty(pendingUploadExams.exams)
  );
};
