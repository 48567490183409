import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { getUploadedImagesUrl } from '../../../../../../functions/jobHealthHazards/getUploadedExams';
import { useHealthCheck } from '../../../../../../hooks/healthCheck';
import { Gallery } from '../../../../../Gallery';
import { Title } from './styles';

export function Vision() {
  const { jobHealthHazardsHealthCheck, untouchedUser, isUserDetailsPage } =
    useHealthCheck();

  const [visionExams, setVisionExams] = useState([] as string[]);
  const [isFetchingExams, setIsFetchingExams] = useState(false);

  useEffect(() => {
    if (!isEmpty(visionExams) || isFetchingExams) return;

    const imageExams = jobHealthHazardsHealthCheck?.vision?.uploadedExams || [];

    // If we are in the User Details page, we must show the files stored inside the user.
    if (isUserDetailsPage || !isEmpty(imageExams)) {
      const uploadedExams = isUserDetailsPage
        ? untouchedUser.exams?.vision?.uploadedExams
        : imageExams;

      if (isEmpty(uploadedExams)) return;

      setIsFetchingExams(true);
      uploadedExams?.forEach((path) => {
        getUploadedImagesUrl(path).then((result) => {
          setVisionExams((prev) => [...prev, result]);
        });
      });
      return;
    }

    // If it is an ongoing health with new uploaded images, we must show the images that are being uploaded;
    if (jobHealthHazardsHealthCheck?.vision?.exams) {
      setVisionExams(jobHealthHazardsHealthCheck?.vision?.exams || []);
    }
  }, [
    isFetchingExams,
    isUserDetailsPage,
    jobHealthHazardsHealthCheck?.vision,
    untouchedUser.exams?.vision?.uploadedExams,
    visionExams
  ]);

  return (
    <>
      {!isEmpty(visionExams) && (
        <>
          <Title>Vision</Title>
          <Gallery title="Vision Exams" imageList={visionExams} />
        </>
      )}
    </>
  );
}
