import { JobHealthHazardsHealthCheck } from '../../models/jobHealthHazards/JobHealthHazardsHealthCheck';
import { getJobHealthHazardsToSave } from './getJobHealthHazardsToSave';
import { uploadHearingVisionStructuralHealthExams } from './uploadHearingVisionExams';

export async function uploadJobHealthHazardsExams(
  healthCheckFolderPath: string,
  jobHealthHazardsHealthCheck: JobHealthHazardsHealthCheck
) {
  const uploadedFiles = await uploadHearingVisionStructuralHealthExams(
    healthCheckFolderPath,
    jobHealthHazardsHealthCheck
  );

  const jobHealthHazardsToSave = getJobHealthHazardsToSave(
    jobHealthHazardsHealthCheck,
    uploadedFiles
  );

  return jobHealthHazardsToSave;
}
