import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px;

  @media (max-width: 700px) {
    padding: 5px;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  width: 30%;

  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const HealthChecksWrapper = styled.main`
  margin-top: 10px;
  padding: 5px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.05);

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 5px;

  @media (max-width: 550px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
`;

export const HealthCheckCard = styled.div`
  border-radius: 10px;
  padding: 10px;
  background-color: #fff;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: space-between;
  flex-direction: column;

  @media (max-width: 700px) {
    flex-direction: column;
  }

  transition: transform 0.1s;

  &:hover {
    transform: scale(1.01);
  }
`;

export const UserNameRow = styled.div`
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333333cc;

  div {
    display: flex;

    p {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;
      font-size: 22px;
    }

    p + p {
      margin: 0 3px;
    }
  }
`;

export const ScoreWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;

  grid-template-areas:
    'a b c d'
    'a b c d';

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'a'
      'b'
      'c'
      'd';
  }
`;

export const ScoreCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  width: 100%;

  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 3px 3px 6px;

  img {
    height: 30px;
    width: 30px;
    opacity: 0.5;
  }

  &.average {
    img {
      height: 40px;
      width: 40px;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    p {
      margin: 0;
    }

    strong {
      color: #333333aa;
      font-size: 25px;
      font-weight: bold;
    }

    h6 {
      margin: 0;
    }
  }

  &.average {
    grid-area: a;
  }

  &.body {
    grid-area: b;
  }

  &.mind {
    grid-area: c;
  }

  &.life {
    grid-area: d;
  }
`;

export const UpperCardInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    span {
      height: 20px;
      width: 20px;
      border-radius: 50%;

      &.red {
        background-color: #fc6161;
      }

      &.orange {
        background-color: #ffa500;
      }

      &.green {
        background-color: #5dc45d;
      }
    }
  }
`;

export const FooterData = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333333aa;
  margin: 0;
`;

export const UserCompany = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  p {
    font-size: 12px;
    font-weight: 500;
  }
`;

export const UpdatedAt = styled.p`
  font-size: 12px;
  font-weight: 500;
`;
