import * as Sentry from '@sentry/react';

import { firebaseSDK, isProduction, SETTINGS } from '../../services/firebase';

const { examsStorageBucket } = SETTINGS;

export async function uploadImageExams(
  healthCheckFolderPath: string,
  exams: string[] | undefined
): Promise<string[]> {
  if (!exams || exams.length <= 0) return [] as string[];

  const filesToUpload: string[] = [];

  try {
    let index = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const exam of exams) {
      const imageExtension = exam.substring(
        'data:image/'.length,
        exam.indexOf(';base64')
      );
      const imageName = `${index}.${imageExtension}`;
      const filePath = `${healthCheckFolderPath}/${imageName}`;
      filesToUpload.push(filePath);

      const base64Image = exam.replace('data:', '').replace(/^.+,/, '');

      const customBucket = firebaseSDK.app().storage(examsStorageBucket);
      const storageRef = customBucket.ref(filePath);

      // Upload file
      // eslint-disable-next-line no-await-in-loop
      await storageRef.putString(base64Image, 'base64');
      index += 1;
    }
  } catch (error) {
    console.error('Fail to upload file: rolling back uploaded files. ', error);
    if (isProduction) {
      Sentry.captureException(error, (scope) => {
        scope.clear();
        scope.setExtra('rollbackFiles', {
          uploadedFiles: filesToUpload,
          filesToUpload: exams.map((exam, index) => {
            const imageExtension = exam.substring(
              'data:image/'.length,
              exam.indexOf(';base64')
            );
            const imageName = `${index}.${imageExtension}`;
            return `${healthCheckFolderPath}/${imageName}`;
          })
        });
        return scope;
      });
    }
    // Add a rollback function here;
    await rollbackFiles(filesToUpload);
    throw new Error('Fail to upload image exams');
  }
  return filesToUpload;
}

export async function uploadFileExams(
  healthCheckFolderPath: string,
  exams: File[] | undefined
): Promise<string[]> {
  if (!exams || exams.length <= 0) return [] as string[];

  const filesToUpload: string[] = [];

  try {
    // eslint-disable-next-line no-restricted-syntax
    for (const exam of exams) {
      const filePath = `${healthCheckFolderPath}/${exam.name}`;
      filesToUpload.push(filePath);

      const customBucket = firebaseSDK.app().storage(examsStorageBucket);
      const storageRef = customBucket.ref(filePath);

      // Upload file
      // eslint-disable-next-line no-await-in-loop
      await storageRef.put(exam);
    }
  } catch (error) {
    console.error('Fail to upload file: rolling back uploaded files. ', error);
    if (isProduction) {
      Sentry.captureException(error, (scope) => {
        scope.clear();
        scope.setExtra('rollbackFiles', {
          uploadedFiles: filesToUpload,
          filesToUpload: exams.map(
            (exam) => `${healthCheckFolderPath}/${exam.name}`
          )
        });
        return scope;
      });
    }
    // Add a rollback function here;
    await rollbackFiles(filesToUpload);
    throw new Error('Fail to upload file exams');
  }
  return filesToUpload;
}

async function rollbackFiles(uploadedFiles: string[]) {
  const customBucket = firebaseSDK.app().storage(examsStorageBucket);

  // eslint-disable-next-line no-restricted-syntax
  for (const filePath of uploadedFiles) {
    try {
      const storageRef = customBucket.ref(filePath);

      // eslint-disable-next-line no-await-in-loop
      await storageRef.delete();
    } catch (error) {
      if (isProduction) {
        Sentry.captureException(error, (scope) => {
          scope.clear();
          scope.setExtra('rollbackFiles', {
            errorFile: filePath,
            filesToRollBack: uploadedFiles
          });
          return scope;
        });
      }
    }
  }
}
