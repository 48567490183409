import React, { ChangeEvent, useCallback } from 'react';
import { isNil } from 'lodash';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {
  BoxSendImage as BoxSendFile,
  ButtonSelectFile,
  WrapsImages,
  WrapsImage,
  Container,
  OpenFile,
  FileContainer,
  FileName
} from './styles';
import { useToast } from '../../hooks/toast';

interface ExamsFieldProps {
  buttonTitle: string;
  uploadedFilesUrls?: File[];
  updateUploadedFiles: React.Dispatch<React.SetStateAction<File[]>>;
  componentId: string;
  isDisabledUpload?: boolean;
}

export interface FileProps {
  image?: File;
  name?: string;
  url: string;
}

export const UploadFileField = ({
  buttonTitle,
  uploadedFilesUrls = [] as File[],
  updateUploadedFiles,
  componentId,
  isDisabledUpload = false
}: ExamsFieldProps) => {
  const { addToast } = useToast();

  // const [filesList, setFilesList] = useState();

  const deleteFile = useCallback(
    async (selectedFile: File) => {
      const confirmDeleteFile = window.confirm('Delete file?');

      if (!confirmDeleteFile) return;

      try {
        updateUploadedFiles(
          uploadedFilesUrls.filter((file) => file.name !== selectedFile.name)
        );
      } catch (error) {
        addToast({
          title: 'Error deleting picture',
          type: 'error'
        });
      }
    },
    [addToast, updateUploadedFiles, uploadedFilesUrls]
  );

  const handleFileUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) return;

      const file = event.target.files[0];

      if (isNil(file)) return;

      updateUploadedFiles([...uploadedFilesUrls, file]);
    },
    [updateUploadedFiles, uploadedFilesUrls]
  );

  return (
    <Container key={`${componentId}-exam-container`}>
      {!isDisabledUpload && (
        <BoxSendFile>
          <label htmlFor="exam-upload">
            <ButtonSelectFile>
              <UploadFileIcon />
              <span>{buttonTitle}</span>
            </ButtonSelectFile>
            <input
              type="file"
              id="exam-upload"
              accept=".png,.jpg,.jpeg,.pdf,.doc,.docx"
              onChange={handleFileUpload}
              style={{ display: 'none' }}
            />
          </label>
        </BoxSendFile>
      )}
      <WrapsImages>
        {uploadedFilesUrls &&
          uploadedFilesUrls.map((file, index) => {
            if (isNil(file)) return;

            return (
              <FileContainer key={`${componentId}-${index}`}>
                <WrapsImage>
                  <OpenFile
                    href={URL.createObjectURL(file)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <InsertDriveFileIcon />
                  </OpenFile>
                  {!isDisabledUpload && (
                    <span onClick={() => deleteFile(file)} aria-hidden="true">
                      X
                    </span>
                  )}
                </WrapsImage>
                <FileName>{file.name}</FileName>
              </FileContainer>
            );
          })}
      </WrapsImages>
    </Container>
  );
};
