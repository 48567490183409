import { useCallback, useMemo } from 'react';
import { camelCase, cloneDeep, get, isEmpty, unset } from 'lodash';
import { CheckOutlined, CancelOutlined } from '@mui/icons-material';

import { Section } from '../Section';
import { QuestionnaireProps, useQuestions } from '../../../../hooks/questions';

import {
  AnsweredQuestionnaires,
  Container,
  QuestionnaireTitle,
  QuestionnairesStatus
} from './styles';
import { JobHealthHazards } from './JobHealthHazards';
import { organizationHasHearingVisionV2Access } from '../../../../utils/updateUserAnswersAndScores';
import { useOrganisation } from '../../../../hooks/organisations';

import bodyQuestionnairesMap from './bodyQuestionnairesMap.json';
import { useHealthCheck } from '../../../../hooks/healthCheck';

export function BodyQuestions() {
  const { currentOrganisation } = useOrganisation();
  const { bodyQuestionnaires } = useQuestions();
  const { answeredBodyQuestions } = useHealthCheck();

  const groupedBySection = useMemo(() => {
    const groupedSectionsObject: Record<string, QuestionnaireProps[]> = {
      physical: [],
      smokingAndAlcohol: [],
      bloodPressure: [],
      restingHeartRate: [],
      lungFunction: [],
      cholesterol: [],
      hbA1C: []
    };

    bodyQuestionnaires
      .filter(({ section }) => section !== 'Job Health Hazards')
      .forEach((data) => {
        const section = camelCase(data.section);
        if (!isEmpty(data)) {
          groupedSectionsObject[section].push(data);
        }
      });

    const groupedSections = Object.values(groupedSectionsObject);

    return groupedSections;
  }, [bodyQuestionnaires]);

  const checkIfQuestionnaireIsComplete = useCallback(
    (questionnaire: string) => {
      const isComplete = get(
        answeredBodyQuestions,
        camelCase(questionnaire),
        false
      );

      return isComplete;
    },
    [answeredBodyQuestions]
  );

  const orgHasAccessToV2 =
    organizationHasHearingVisionV2Access(currentOrganisation);

  const allQuestionnaires = useMemo(() => {
    const mapCopy = cloneDeep(bodyQuestionnairesMap);

    if (!orgHasAccessToV2) {
      unset(mapCopy, 'hearingScreen');
      unset(mapCopy, 'visionScreen');
      unset(mapCopy, 'criticalRole');
    }

    return Object.values(mapCopy);
  }, [orgHasAccessToV2]);

  return (
    <>
      <QuestionnairesStatus>
        <h3>Questionnaires Status</h3>
        <AnsweredQuestionnaires>
          {allQuestionnaires.map((questionnaire) => (
            <QuestionnaireTitle
              key={questionnaire}
              isComplete={checkIfQuestionnaireIsComplete(questionnaire)}
            >
              {checkIfQuestionnaireIsComplete(questionnaire) ? (
                <CheckOutlined />
              ) : (
                <CancelOutlined />
              )}
              {questionnaire}
            </QuestionnaireTitle>
          ))}
        </AnsweredQuestionnaires>
      </QuestionnairesStatus>

      <Container>
        {groupedBySection.map(
          (section) =>
            !isEmpty(section) && (
              <Section
                key={get(section, '[0].section', '')}
                sectionData={section}
              />
            )
        )}
        {orgHasAccessToV2 && <JobHealthHazards />}
      </Container>
    </>
  );
}
