import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 3px 3px 6px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 8px;

  transition: transform 0.1s, background-color 0.1s;

  &:hover {
    transform: scale(1.03);
    background-color: rgba(0, 0, 0, 0.03);
  }
`;

interface CardHeaderPrps {
  disableHover: boolean;
}

export const CardHeader = styled.div<CardHeaderPrps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  line-height: 16px;
  white-space: nowrap;
  width: 100%;
  border-radius: 5px 5px 0 0;

  font-size: 12px;

  p + p {
    color: #33333366;
  }

  span {
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 50%;
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
      color: #d31717;
    }
  }

  ${(props) =>
    !props.disableHover &&
    css`
      &:hover {
        background-color: rgba(0, 0, 0, 0.06);
      }
    `}
`;

interface ColorLabelProps {
  backgroundColor: string;
}

export const ColorLabel = styled.span<ColorLabelProps>`
  background-color: ${(props) => props.backgroundColor};

  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  max-height: 20px;
  max-width: 20px;
  border-radius: 50%;

  cursor: default;
`;

export const ScoreLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  width: 100%;
`;

export const AdviceIcons = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: transparent;

  span {
    width: 30px;
  }
`;

interface ScoreDataProps {
  disableHover: boolean;
}

export const ScoreData = styled.span<ScoreDataProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  border-radius: 5px;

  ${(props) =>
    !props.disableHover &&
    css`
      &:hover {
        background-color: rgba(0, 0, 0, 0.06);
      }
    `}

  img {
    height: 40px;
    width: 40px;
    opacity: 0.5;
  }

  strong {
    color: #333333aa;
    font-size: 30px;
    font-weight: bold;
  }
`;

interface ValueRowProps {
  isTextAnswer: boolean;
}

export const ValueRow = styled.div<ValueRowProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h6 {
    margin: 0;
  }

  ${(props) =>
    props.isTextAnswer &&
    css`
      strong {
        font-size: 15px;
      }
    `}
`;

export const AboutAndColor = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: transparent;

  span {
    width: 30px;
  }
`;

export const AboutIcon = styled.aside`
  display: flex;
  justify-content: center;
  align-items: start;

  background: transparent;
`;

export const InfoIcon = styled.button`
  border: none;
  border-radius: 50%;
  background: transparent;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: transform 0.1s;

  &:hover {
    transform: scale(1.1);
  }

  svg {
    color: #3dd4cbaa;
    pointer-events: none;
  }
`;

export const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: rgba(0, 0, 0, 0.7);
  width: 500px;
  max-height: 80vh;
  padding: 30px;
  border: 2px solid #fff;
  border-radius: 10px;

  overflow-y: scroll;
  a {
    color: #14a79c;
  }

  @media (max-width: 450px) {
    width: 300px;
  }

  @media (max-width: 350px) {
    width: 250px;
  }
`;

export const ModalTitle = styled.div`
  position: sticky;
  width: 100%;
  border-radius: 10px;
  text-align: center;

  h2 {
    width: 90%;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;

  background: transparent;
  border: none;
  border-radius: 50%;

  height: 20px;
  width: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0px 0px 10px #fff;
  }

  svg {
    color: #fff;
    pointer-events: none;
  }
`;

export const WhiteLine = styled.div`
  background-color: #fff;
  width: 90%;
  height: 2px;
  margin: 10px auto;
  border-radius: 10px;
`;

export const ModalInfo = styled.div`
  color: #fff;
  text-align: justify;
  font-weight: 500;
  white-space: pre-line;

  li + li {
    margin-bottom: 10px;
  }
`;

export const TimeStamp = styled.p`
  font-size: 9px;
  margin-top: -8px;
  color: #333333cc;
  height: 10px;
`;

interface ScoreRangesProps {
  multipleColumns: boolean;
}

export const ScoreRanges = styled.div<ScoreRangesProps>`
  display: flex;
  justify-content: ${({ multipleColumns }) =>
    multipleColumns ? 'space-around' : 'space-between'};
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
`;

export const RangeCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;

  h3 {
    color: white;
  }
`;

interface RangeLineProps {
  red?: boolean;
  orange?: boolean;
  green?: boolean;
}

const getRangeColor = (red: boolean, orange: boolean, green: boolean) => {
  const redHex = '#fc6161';
  const orangeHex = '#ffa500';
  const greenHex = '#5dc45d';

  if (red) return redHex;
  if (orange) return orangeHex;
  if (green) return greenHex;

  return greenHex;
};

export const RangeLine = styled.div<RangeLineProps>`
  text-align: left;

  &::before {
    background-color: ${({ red, orange, green }) =>
      getRangeColor(red || false, orange || false, green || false)};

    content: '';
    margin-right: 5px;
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
  }
`;
