import React, { useEffect, useState } from 'react';
import {
  ErrorOutline,
  CheckCircleOutline,
  InfoOutlined
} from '@mui/icons-material';

import { ToastMessage, useToast } from '../../../hooks/toast';

import { Container } from './styles';

interface ToastProps {
  message: ToastMessage;
  style: Record<string, unknown>;
}

const icons = {
  info: <InfoOutlined sx={{ fontSize: 24 }} />,
  error: <ErrorOutline sx={{ fontSize: 24 }} />,
  success: <CheckCircleOutline sx={{ fontSize: 24 }} />
};

export const Toast: React.FC<ToastProps> = ({ message, style }) => {
  const { removeToast } = useToast();

  const [toastTimer, setToastTimer] = useState(true);

  useEffect(() => {
    setTimeout(() => setToastTimer(false), 50);

    const timer = setTimeout(() => {
      removeToast(message.id);
    }, message.duration);

    return () => {
      clearTimeout(timer);
    };
  }, [removeToast, message.id, message.duration]);

  return (
    <Container
      title="Click to close it"
      type={message.type}
      hasdescription={Number(!!message.description)}
      duration={message.duration!}
      style={style}
      onClick={() => removeToast(message.id)}
    >
      <span />

      {icons[message.type || 'info']}

      <div>
        <p>{message.title}</p>
        {message.description && <p>{message.description}</p>}
      </div>

      <span style={{ width: toastTimer ? '90%' : 0 }} />
    </Container>
  );
};
