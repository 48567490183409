import styled from 'styled-components';

export const Container = styled.main`
  margin-top: 10px;
  border-radius: 5px;
  padding: 5px;
  background-color: #f2f5f9;
  box-shadow: 2px 2px 10px;
`;

export const QuestionsWrapper = styled.div`
  padding: 5px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;

  @media (max-width: 350px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 3px;
  }
`;

export const QuestionWrapper = styled.div`
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.05);
`;
