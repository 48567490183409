import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const UserName = styled.div`
  margin: 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: #3dd4cb;
  border-radius: 10px;

  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media (max-width: 700px) {
    flex-direction: column;
    padding: 2px;

    span {
      width: 40px;
      height: 40px;
    }
  }

  p {
    margin: 0 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-weight: 500;
    font-size: 16px;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back-button {
    display: flex;
    justify-content: center;
    align-items: center;

    background: rgba(255, 255, 255, 0.4);
    border: none;
    border-radius: 10px 0 0 10px;
    height: 100%;
    width: 60px;
    height: 40px;

    transition: background-color 0.2s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    svg {
      color: #333333cc;
    }
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const SaveHealthCheckButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(255, 255, 255, 0.4);
  border: none;
  height: 100%;
  height: 40px;
  padding: 0 10px;
  font-weight: 500;

  transition: background-color 0.2s;

  @media (max-width: 700px) {
    border-radius: 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  svg {
    color: #333333cc;
  }
`;

export const SubmitHealthCheckButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(255, 255, 255, 0.4);
  border: none;
  border-radius: 0 10px 10px 0;
  height: 100%;
  height: 40px;
  padding: 0 10px;
  font-weight: 500;

  transition: background-color 0.2s;

  @media (max-width: 700px) {
    border-radius: 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  svg {
    color: #333333cc;
  }
`;

export const MainHeader = styled.header`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;

  @media (max-width: 700px) {
    gap: 3px;

    p {
      display: none;
    }
  }
`;

export const SubHeader = styled.header`
  margin-top: 10px;

  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;

  @media (max-width: 900px) {
    gap: 3px;

    p {
      display: none;
    }
  }
`;

interface HeaderButtonProps {
  activeTab: boolean;
}

export const HeaderButton = styled.button<HeaderButtonProps>`
  background: ${(props) => (props.activeTab ? '#3dd4cb' : 'transparent')};

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  height: 50px;
  width: 30%;
  white-space: nowrap;

  border-radius: 10px;
  border: 2px solid #3dd4cb;
  padding: 0 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;

  transition: background-color 0.2s, filter 0.2s;

  ${(props) =>
    props.activeTab &&
    css`
      color: #f2f5f9;
      cursor: not-allowed;
    `}

  ${(props) =>
    !props.activeTab &&
    css`
      &:hover {
        filter: brightness(1.1);
        background-color: #f2f5f9;
      }
    `}

  img {
    height: 35px;
    width: 35px;
    border-radius: 8px;
    padding: 2px;

    background-color: #f2f5f9;
  }
`;

export const SubHeaderButton = styled.button<HeaderButtonProps>`
  background: ${(props) => (props.activeTab ? '#3dd4cb' : 'transparent')};

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  height: 50px;
  width: 30%;
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  border-radius: 10px;
  border: 2px solid #3dd4cb;
  padding: 0 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;

  transition: background-color 0.2s, filter 0.2s;

  ${(props) =>
    props.activeTab &&
    css`
      color: #f2f5f9;
      cursor: not-allowed;
    `}

  ${(props) =>
    !props.activeTab &&
    css`
      &:hover {
        filter: brightness(1.1);
        background-color: #f2f5f9;
      }
    `}

  img {
    height: 35px;
    width: 35px;
    border-radius: 8px;
    padding: 2px;

    background-color: #f2f5f9;
  }

  @media (max-width: 1000px) {
    height: 40px;
    width: 20%;
    font-size: 12px;

    img {
      height: 27px;
      width: 27px;
    }
  }
`;
