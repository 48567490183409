import { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { floor, indexOf, size } from 'lodash';
import {
  GalleryWrapper,
  GalleryTitle,
  WrapsImages,
  WrapsImage,
  ModalWrapper,
  CloseButton,
  WrapsPreviousButton,
  WrapsNextButton
} from './styles';
import { ImageViewWithZoom } from '../ImageViewWithZoom';

interface ImageGalleryProps {
  title?: string;
  imageList: string[];
}

export function Gallery({ title, imageList }: ImageGalleryProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageBeingShownModal, setImageBeingShownModal] = useState('');
  const [nextImage, setNextImage] = useState('');
  const [previousImage, setPreviousImage] = useState('');

  const [imageSize, setImageSize] = useState({
    width: '80%'
  });

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const showImageModal = (image: string) => {
    setImageBeingShownModal(image);
    setIsModalOpen(true);

    const key = indexOf(imageList, image);

    if (key + 1 < size(imageList)) {
      setNextImage(imageList[key + 1]);
    } else {
      setNextImage('');
    }

    if (key - 1 >= 0) {
      setPreviousImage(imageList[key - 1]);
    } else {
      setPreviousImage('');
    }
  };

  const navigateNextImage = () => {
    showImageModal(nextImage);
  };

  const navigatePreviousImage = () => {
    showImageModal(previousImage);
  };

  useEffect(() => {
    if (!isModalOpen) return;

    setTimeout(() => {
      const imageComponent = document.getElementById(
        'openedImageGallery'
      ) as HTMLImageElement;
      const imageSizeObj = {
        width:
          floor((imageComponent?.naturalWidth || 0) * 0.9).toString() || '80%'
      };
      setImageSize(imageSizeObj);
    }, 500);
  }, [isModalOpen]);

  return (
    <>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <ModalWrapper width={`${imageSize.width}px`}>
          <CloseButton onClick={handleCloseModal} aria-hidden="true">
            X
          </CloseButton>

          <ImageViewWithZoom imageSrc={imageBeingShownModal} />

          {previousImage && (
            <WrapsPreviousButton onClick={navigatePreviousImage}>
              <NavigateBefore fontSize="large" />
            </WrapsPreviousButton>
          )}

          {nextImage && (
            <WrapsNextButton onClick={navigateNextImage}>
              <NavigateNext fontSize="large" />
            </WrapsNextButton>
          )}
        </ModalWrapper>
      </Modal>

      <GalleryWrapper>
        <WrapsImages>
          {title && <GalleryTitle>{title}</GalleryTitle>}
          {imageList.map((image, index) => {
            return (
              <WrapsImage
                key={index}
                src={image}
                alt={title}
                onClick={() => showImageModal(image)}
              />
            );
          })}
        </WrapsImages>
      </GalleryWrapper>
    </>
  );
}
