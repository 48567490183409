import { useMemo } from 'react';
import { camelCase } from 'lodash';
import { Container, QuestionnairesWrapper, SectionTitle } from './styles';
import {
  JOB_HEALTH_HAZARDS_ADDITIONAL_QUESTIONNAIRES,
  useQuestions
} from '../../../../../hooks/questions';
import { CriticalRole } from './CriticalRole';
import { Vision } from './Vision';
import { Hearing } from './Hearing';
import { StructuralHealth } from './StructuralHealth';

export const JobHealthHazards: React.FC = () => {
  const { bodyQuestionnaires } = useQuestions();

  const { hearingQuestions, visionQuestions, criticalRoleQuestions } =
    useMemo(() => {
      const filteredQuestionnaires = bodyQuestionnaires.filter(
        ({ questionnaire }) =>
          JOB_HEALTH_HAZARDS_ADDITIONAL_QUESTIONNAIRES.includes(
            camelCase(questionnaire)
          )
      );

      const hearingQuestionnaire = filteredQuestionnaires.find(
        ({ questionnaire }) => questionnaire === 'Hearing Screen'
      );
      const VisionQuestionnaire = filteredQuestionnaires.find(
        ({ questionnaire }) => questionnaire === 'Vision Screen'
      );
      const criticalRoleQuestionnaire = filteredQuestionnaires.find(
        ({ questionnaire }) => questionnaire === 'Critical Role'
      );

      return {
        hearingQuestions: hearingQuestionnaire!.questions,
        visionQuestions: VisionQuestionnaire!.questions,
        criticalRoleQuestions: criticalRoleQuestionnaire!.questions
      };
    }, [bodyQuestionnaires]);

  return (
    <Container className="jobHealthHazards">
      <SectionTitle>Job Health Hazards</SectionTitle>
      <QuestionnairesWrapper>
        <Hearing questions={hearingQuestions} />
        <Vision questions={visionQuestions} />
        <CriticalRole questions={criticalRoleQuestions} />
        <StructuralHealth />
      </QuestionnairesWrapper>
    </Container>
  );
};
