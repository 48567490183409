import styled from 'styled-components';

export const Container = styled.div`
  max-width: 750px;
`;

export const DateFilterWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  div {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const DateFilter = styled.input`
  border-radius: 10px;
  border: none;
  font-weight: 400;
  padding: 5px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.03);

  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
`;
