import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { TakePhotoField } from '../../../../../../../components/TakePhotoField';
import { getUploadedImagesUrl } from '../../../../../../../functions/jobHealthHazards/getUploadedExams';
import { useHealthCheck } from '../../../../../../../hooks/healthCheck';
import { JobHealthHazardsHealthCheck } from '../../../../../../../models/jobHealthHazards/JobHealthHazardsHealthCheck';
import { QuestionsWrapper, QuestionTitle } from '../styles';

export const AudioGramExams = () => {
  const { jobHealthHazardsHealthCheck, setJobHealthHazardsHealthCheck } =
    useHealthCheck();

  const [audiograms, setAudiograms] = useState([] as string[]);
  const [isDisabledUpload, setIsDisabledUpload] = useState(false);

  const [isFetchingExams, setIsFetchingExams] = useState(false);

  useEffect(() => {
    if (!isEmpty(jobHealthHazardsHealthCheck?.hearing?.uploadedExams)) {
      setIsDisabledUpload(true);
      const uploadExamsPaths =
        jobHealthHazardsHealthCheck?.hearing?.uploadedExams;
      if (!isFetchingExams) {
        setIsFetchingExams(true);
        setAudiograms([]);
        uploadExamsPaths?.forEach((path) => {
          getUploadedImagesUrl(path).then((result) => {
            setAudiograms((prev) => [...prev, result]);
          });
        });
      }
      return;
    }

    setAudiograms(jobHealthHazardsHealthCheck.hearing?.exams || []);
  }, [isFetchingExams, jobHealthHazardsHealthCheck.hearing]);

  return (
    <QuestionsWrapper>
      <QuestionTitle>Audiogram Exams</QuestionTitle>
      <TakePhotoField
        componentId="hearing-exams"
        buttonTitle="Add Audiogram"
        updateUploadedImages={(value) =>
          setJobHealthHazardsHealthCheck(
            (prev) =>
              ({
                ...prev,
                hearing: {
                  ...prev.hearing,
                  exams: value
                }
              } as JobHealthHazardsHealthCheck)
          )
        }
        uploadedImagesUrls={audiograms}
        isDisabledUpload={isDisabledUpload}
      />
    </QuestionsWrapper>
  );
};
