import styled from 'styled-components';

export const Container = styled.div`
  color: #333333aa;
  padding: 40px;

  @media (max-width: 700px) {
    padding: 5px;
  }
`;

export const MessagesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  height: 100%;

  margin-top: 10px;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const MessageCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 36vh;
  justify-content: flex-start;

  box-shadow: 2px 2px 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: #f2f5f9;

  h3 {
    background-color: rgba(0, 0, 0, 0.05);
    width: 100%;
    text-align: center;
    border-radius: 5px;
  }
`;

export const SubjectLine = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  width: 100%;
  margin-bottom: 7.5px;

  input {
    height: 40px;
    border-radius: 5px;
    border: none;
    padding: 5px;
    width: 100%;
  }
`;

export const MessageBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  width: 100%;

  textarea {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: none;
    padding: 5px;
    resize: none;

    @media (max-width: 1000px) {
      resize: vertical;
    }
  }
`;

export const ContainerRadio = styled.label`
  display: flex;
  gap: 5px;
  margin-top: 0.2rem;
  margin-bottom: 0.7rem;
`;
