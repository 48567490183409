import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
  useEffect
} from 'react';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { cloneDeep, isEmpty, unset } from 'lodash';
import {
  browserLocalPersistence,
  getAuth,
  setPersistence
} from 'firebase/auth';

import { useToast } from './toast';
import { auth, firebaseFunctions } from '../services/firebase';

import { User } from '../models/user';
import {
  checkIfUserIsActive,
  getClinicianClients,
  getUserData,
  resetUserPassword
} from '../functions/users';
import { checkIfOrganisationIsActive } from '../functions/organisations';

interface AuthState {
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  userIsLogged: boolean;
  loading: boolean;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): Promise<void>;
  updateUser(user: User): void;
  allowedOrganisationsIds: string[];
  isLoadingClients: boolean;
  fetchClinicianClients: () => Promise<void>;
  hasFetchedClients: boolean;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();

  const [allowedOrganisationsIds, setAllowedOrganisationsIds] = useState(
    [] as string[]
  );
  const [loading, setLoading] = useState(false);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [hasFetchedClients, setHasFetchedClients] = useState(false);
  const [data, setData] = useState({} as AuthState);

  const { addToast } = useToast();

  useEffect(() => {
    const authInstance = getAuth();
    const authUser = authInstance.currentUser;

    const storedUser = localStorage.getItem('@KyndClinician:user');

    if (!authUser && storedUser) {
      localStorage.removeItem('@KyndClinician:user');
      localStorage.removeItem('@KyndClinician:dasFilter');
      localStorage.removeItem('@KyndClinician:healthCheckStatusFilter');
      localStorage.removeItem('@KyndClinician:healthCheckDayFilter');
      localStorage.removeItem('@KyndClinician:healthCheckSpecificDayFilter');
      localStorage.removeItem('@KyndClinician:alertsLightFilter');

      setData({} as AuthState);

      navigate('/');
    }
  }, [navigate]);

  const fetchClinicianClients = async () => {
    const copyUserLogged = cloneDeep(data.user);

    if (!copyUserLogged) return;

    try {
      setIsLoadingClients(true);
      const clients = await getClinicianClients(copyUserLogged.id);

      copyUserLogged.clients = clients;

      setData({ user: copyUserLogged });
      setIsLoadingClients(false);
      setHasFetchedClients(true);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      setIsLoadingClients(false);
      throw new Error('Fail to fetch clinician clients');
    }
  };

  const signIn = async ({ email, password }: SignInCredentials) => {
    setLoading(true);

    const superAdminEmails = [
      'livi@kyndwellness.com',
      'tom@drtomonamission.com',
      'tania@kyndwellness.com',
      'dee@kyndwellness.com',
      'jo.davies@kyndwellness.com',
      'admin@fysight.ai'
    ];

    const trimmedEmail = email.trim();

    try {
      await setPersistence(auth, browserLocalPersistence);

      const { user } = await auth.signInWithEmailAndPassword(
        trimmedEmail,
        password
      );

      if (user) {
        const userData = await getUserData(user.uid);

        if (userData) {
          const changeUserCustomClaim = firebaseFunctions.httpsCallable(
            'changeUserCustomClaim'
          );

          const setRoleAuthClaim =
            firebaseFunctions.httpsCallable('setRoleAuthClaim');

          changeUserCustomClaim(user.uid);
          await setRoleAuthClaim(user.uid);

          const userLoggedIn = {
            ...userData,
            id: user.uid
          };

          const organisationIsActive = await checkIfOrganisationIsActive(
            userData.organisation.id
          );

          if (
            (userLoggedIn.role !== 'Clinician' &&
              !superAdminEmails.includes(trimmedEmail)) ||
            !userLoggedIn.isActive ||
            !organisationIsActive
          ) {
            addToast({
              type: 'info',
              title: "You don't have permission to get in"
            });
          } else {
            localStorage.setItem(
              '@KyndClinician:user',
              JSON.stringify(userLoggedIn)
            );

            userLoggedIn.clients = {};

            setData({
              user: userLoggedIn
            });
          }
        }

        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      const untypedError = error as any;
      if (untypedError.code === 'auth/wrong-password') {
        try {
          const checkIfUserIsMigrated = firebaseFunctions.httpsCallable(
            'checkIfUserIsMigrated'
          );

          const hasToSendRecoverPasswordEmail = await checkIfUserIsMigrated(
            trimmedEmail
          );

          if (hasToSendRecoverPasswordEmail.data) {
            await resetUserPassword(trimmedEmail);

            addToast({
              title: 'Welcome to the new version of KYND Wellness.',
              description:
                'We have sent you an e-mail to reset your password. It is required as an extra security layer.',
              type: 'info',
              duration: 30000
            });

            addToast({
              title:
                'If you have not received an e-mail, please go over in the Forgot Password section in the mobile app and try it there.',
              type: 'info',
              duration: 30000
            });
          } else {
            setLoading(false);
            addToast({
              title: 'Error signing in',
              description: 'Please, check your credentials',
              type: 'info'
            });
          }
        } catch (cloudFunctionError) {
          setLoading(false);
          console.error(cloudFunctionError);
          Sentry.captureException(cloudFunctionError);
          addToast({
            title: 'Error signing in',
            description: 'Please, check your credentials',
            type: 'info'
          });
        }
      } else {
        addToast({
          title: 'Error signing in',
          description: 'Please, check your credentials',
          type: 'info'
        });
      }

      setLoading(false);
    }
  };

  const signOut = useCallback(async () => {
    localStorage.removeItem('@KyndClinician:user');

    await auth.signOut();

    setAllowedOrganisationsIds([]);
    setData({} as AuthState);
    setLoading(false);
    navigate('/');
  }, [navigate]);

  useEffect(() => {
    async function checkUserActivity() {
      if (!data || !data.user || !data.user.id) return;

      const authInstance = getAuth();
      const authUser = authInstance?.currentUser;
      if (isEmpty(authUser)) return;

      const isUserActive = await checkIfUserIsActive(data.user.id);
      const isOrganisationActive = await checkIfOrganisationIsActive(
        data.user.organisation.id
      );

      if (
        typeof isUserActive === 'undefined' ||
        typeof isOrganisationActive === 'undefined'
      )
        return;

      if (!isUserActive || !isOrganisationActive) {
        addToast({
          title: 'Your subscription has ended',
          description: 'Your company no longer offers the app'
        });
        signOut();
      }
    }

    checkUserActivity();
  }, [data, addToast, signOut]);

  const updateUser = useCallback(
    (user: User) => {
      const copyUser = cloneDeep(user);

      unset(copyUser, 'clients');

      localStorage.setItem('@KyndClinician:user', JSON.stringify(copyUser));

      setData({
        user
      });
    },
    [setData]
  );

  const userIsLogged = useMemo(() => {
    return !!data.user;
  }, [data.user]);

  useEffect(() => {
    if (userIsLogged) return;

    setHasFetchedClients(false);
  }, [userIsLogged]);

  useEffect(() => {
    if (!userIsLogged) return;

    const allowedIds = data.user.allowedOrganisationsIds || [];

    let idsHaveChanged = false;

    allowedIds.map((orgId) => {
      if (!allowedOrganisationsIds.includes(orgId)) {
        idsHaveChanged = true;
      }
    });

    if (idsHaveChanged) {
      setAllowedOrganisationsIds(allowedIds);
    }
  }, [userIsLogged, data.user, allowedOrganisationsIds]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        userIsLogged,
        loading,
        signIn,
        signOut,
        updateUser,
        allowedOrganisationsIds,
        isLoadingClients,
        fetchClinicianClients,
        hasFetchedClients
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}
