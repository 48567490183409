import { Helmet } from 'react-helmet';
import { cloneDeep, isEmpty, orderBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Card, CardContent, Grid, TextField } from '@mui/material';

import { formatDate } from '../../utils/formatDate';
import { useOrganisation } from '../../hooks/organisations';
import { getHearingVisionAlerts } from '../../functions/hearingVisionAlerts';

import { Timestamp } from '../../models/user';

import { Spinner } from '../../components/Spinner';
import { PageTitle } from '../../components/PageTitle';
import { ActionsModal } from './components/ActionsModal';
import { UserSearchBox } from '../../components/OrganisationFilter/styles';
import { AlertTypeFilter } from './components/AlertTypeFilter';
import { AlertStatusFilter } from './components/AlertStatusFilter';
import { UpdateStatusModal } from './components/UpdateStatusModal';
import { OrganisationFilter } from '../../components/OrganisationFilter';

import {
  Container,
  UserCardsWrapper,
  DataRow,
  UserData,
  UserDataColumn,
  UserName,
  FooterButton,
  ButtonsRow,
  AlertCard,
  AlertWrapper,
  ColorLabel,
  TopLine
} from './styles';

import hearingIcon from '../../assets/hearing.svg';
import visionIcon from '../../assets/eyesight.svg';
import { HearingVisionAlert } from '../../models/jobHealthHazards/hearingVisionAlert';

export const HearingVisionAlerts: React.FC = () => {
  const { currentOrganisation } = useOrganisation();

  const [hearingVisionAlerts, setHearingVisionAlerts] = useState(
    [] as HearingVisionAlert[]
  );
  const [filteredAlerts, setFilteredAlerts] = useState(
    [] as HearingVisionAlert[]
  );
  const [alertStatusFilter, setAlertStatusFilter] = useState<
    'Open' | 'In Progress' | 'Resolved'
  >('Open');
  const [questionnaireFilter, setQuestionnaireFilter] = useState<
    'All' | 'Hearing Screen' | 'Vision Screen'
  >('All');
  const [currentEditingAlert, setCurrentEditingAlert] = useState(
    {} as HearingVisionAlert
  );
  const [currentAlertType, setCurrentAlertType] = useState<
    'Hearing Screen' | 'Vision Screen'
  >('Hearing Screen');
  const [userNameFilter, setUserNameFilter] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);
  const [alertToView, setAlertToView] = useState({} as HearingVisionAlert);
  const [alertTypeToView, setAlertTypeToView] = useState('');

  const fetchAlertsAsync = useCallback(async () => {
    setIsLoading(true);
    const dbFlags = await getHearingVisionAlerts(currentOrganisation.id);
    setHearingVisionAlerts(orderBy(dbFlags, ['createdAt'], 'desc'));
    setFilteredAlerts(dbFlags);
    setIsLoading(false);
  }, [currentOrganisation]);

  useEffect(() => {
    fetchAlertsAsync();
  }, [fetchAlertsAsync]);

  useEffect(() => {
    // Effect that is triggered by using filters
    if (isEmpty(hearingVisionAlerts)) return;

    let alertsCopy = cloneDeep(hearingVisionAlerts);

    if (questionnaireFilter === 'Hearing Screen') {
      alertsCopy = alertsCopy.filter(
        ({ questionnaireAlerts, hearingStatus }) =>
          questionnaireAlerts.hearing && hearingStatus === alertStatusFilter
      );
    }

    if (questionnaireFilter === 'Vision Screen') {
      alertsCopy = alertsCopy.filter(
        ({ questionnaireAlerts, visionStatus }) => {
          return (
            questionnaireAlerts.vision && visionStatus === alertStatusFilter
          );
        }
      );
    }

    if (questionnaireFilter === 'All') {
      alertsCopy = alertsCopy.filter(
        ({ hearingStatus, visionStatus }) =>
          hearingStatus === alertStatusFilter ||
          visionStatus === alertStatusFilter
      );
    }

    alertsCopy = isEmpty(userNameFilter)
      ? alertsCopy
      : alertsCopy.filter(({ user }) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          return fullName.toUpperCase().includes(userNameFilter.toUpperCase());
        });

    setFilteredAlerts(alertsCopy);
  }, [
    questionnaireFilter,
    alertStatusFilter,
    hearingVisionAlerts,
    userNameFilter
  ]);

  const getAdditionalFilters = () => {
    return [
      <Grid item xs={3} sm={3} key="SearchUserAlert">
        <UserSearchBox>
          <TextField
            fullWidth
            label="Search user"
            value={userNameFilter}
            onChange={(e) => setUserNameFilter(e.target.value)}
          />
        </UserSearchBox>
      </Grid>,
      <Grid item xs={3} sm={3} key="AlertStatusFilter">
        <AlertStatusFilter
          selectedValue={alertStatusFilter}
          handleChange={setAlertStatusFilter}
        />
      </Grid>,
      <Grid item xs={3} sm={3} key="AlertTypeFilter">
        <AlertTypeFilter
          selectedValue={questionnaireFilter}
          handleChange={setQuestionnaireFilter}
        />
      </Grid>
    ];
  };

  const handleOpenUpdateAlertModal = useCallback(
    (
      alertToUpdate: HearingVisionAlert,
      alertType: 'Hearing Screen' | 'Vision Screen'
    ) => {
      setCurrentEditingAlert(cloneDeep(alertToUpdate));
      setCurrentAlertType(alertType);
      setIsEditModalOpen(true);
    },
    []
  );

  const handleOpenActionsModal = useCallback(
    (
      alert: HearingVisionAlert,
      alertType: 'Hearing Screen' | 'Vision Screen'
    ) => {
      setIsActionsModalOpen(true);
      setAlertToView(alert);
      setAlertTypeToView(alertType);
    },
    []
  );

  const handleCloseUpdateAlertModal = useCallback(
    async (reloadAlerts?: boolean) => {
      setIsEditModalOpen(false);
      setCurrentEditingAlert({} as HearingVisionAlert);

      if (reloadAlerts) {
        await fetchAlertsAsync();
      }
    },
    [fetchAlertsAsync]
  );

  const handleCloseActionsModal = useCallback(() => {
    setIsActionsModalOpen(false);
    setAlertToView({} as HearingVisionAlert);
    setAlertTypeToView('');
  }, []);

  return (
    <Container>
      {isLoading && <Spinner />}

      <OrganisationFilter
        hideDivision
        hideUserSearch
        hideAllOptionOrgFilter
        additionalFilters={getAdditionalFilters()}
      />

      <Helmet>
        <title>Hearing and Vision Alerts</title>
      </Helmet>

      <PageTitle heading="Hearing and Vision Alerts" />

      {!hearingVisionAlerts.length && (
        <h2>No users with alert found. Type in the search bar to find users</h2>
      )}

      <UserCardsWrapper>
        {filteredAlerts.map((userAlert: HearingVisionAlert) => {
          return (
            <Card key={userAlert.id}>
              <CardContent>
                <UserName>
                  <p>{userAlert.user.firstName.toUpperCase()}</p>
                  <p>{userAlert.user.lastName.toUpperCase()}</p>
                </UserName>

                <UserData>
                  <UserDataColumn>
                    <DataRow>
                      <p>Organisation</p>
                      <strong>{userAlert.organisation?.name}</strong>
                    </DataRow>
                    <DataRow>
                      <p>Created at</p>
                      <strong>
                        {formatDate({
                          date: userAlert.createdAt as Timestamp
                        })}
                      </strong>
                    </DataRow>
                    <DataRow>
                      <p>Division</p>
                      <strong>{userAlert.division?.name}</strong>
                    </DataRow>
                    <DataRow>
                      <p>Email</p>
                      <strong>{userAlert.user.email}</strong>
                    </DataRow>
                  </UserDataColumn>

                  <UserDataColumn>
                    <AlertWrapper>
                      {userAlert.hearingStatus === alertStatusFilter && (
                        <AlertCard>
                          <TopLine>
                            <img src={hearingIcon} alt="hearing icon" />
                            <p>Hearing</p>
                            <ColorLabel title="Alert" backgroundColor="red" />
                          </TopLine>
                          {userAlert.hearingStatus === 'Resolved' && (
                            <TopLine>RESOLVED</TopLine>
                          )}
                          <ButtonsRow>
                            {userAlert.actions && (
                              <FooterButton
                                onClick={() =>
                                  handleOpenActionsModal(
                                    userAlert,
                                    'Hearing Screen'
                                  )
                                }
                              >
                                Actions taken
                              </FooterButton>
                            )}

                            {userAlert.hearingStatus !== 'Resolved' && (
                              <FooterButton
                                onClick={() =>
                                  handleOpenUpdateAlertModal(
                                    userAlert,
                                    'Hearing Screen'
                                  )
                                }
                              >
                                Update
                              </FooterButton>
                            )}
                          </ButtonsRow>
                        </AlertCard>
                      )}

                      {userAlert.visionStatus === alertStatusFilter && (
                        <AlertCard>
                          <TopLine>
                            <img src={visionIcon} alt="vision icon" />
                            <p>Vision Screen</p>
                            <ColorLabel title="Alert" backgroundColor="red" />
                          </TopLine>

                          {userAlert.visionStatus === 'Resolved' && (
                            <TopLine>RESOLVED</TopLine>
                          )}

                          <ButtonsRow>
                            {userAlert.actions && (
                              <FooterButton
                                onClick={() =>
                                  handleOpenActionsModal(
                                    userAlert,
                                    'Vision Screen'
                                  )
                                }
                              >
                                Actions taken
                              </FooterButton>
                            )}

                            {userAlert.visionStatus !== 'Resolved' && (
                              <FooterButton
                                onClick={() =>
                                  handleOpenUpdateAlertModal(
                                    userAlert,
                                    'Vision Screen'
                                  )
                                }
                              >
                                Update
                              </FooterButton>
                            )}
                          </ButtonsRow>
                        </AlertCard>
                      )}
                    </AlertWrapper>
                  </UserDataColumn>
                </UserData>
              </CardContent>
            </Card>
          );
        })}
      </UserCardsWrapper>

      <UpdateStatusModal
        isModalOpen={isEditModalOpen}
        handleCloseModal={handleCloseUpdateAlertModal}
        alert={currentEditingAlert}
        currentAlertType={currentAlertType}
      />

      <ActionsModal
        isModalOpen={isActionsModalOpen}
        handleCloseModal={handleCloseActionsModal}
        alert={alertToView}
        alertType={alertTypeToView}
      />
    </Container>
  );
};
