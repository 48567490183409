import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 10px;
  padding: 5px;
  background-color: #fff;

  overflow: hidden;
  cursor: pointer;

  transition: transform 0.2s;

  &:hover {
    transform: scale(1.01);
  }
`;

export const NotificationHeader = styled.header`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2px;
`;

export const HeaderTopic = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  padding: 3px;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px solid #3dd4cbaa;
`;

export const NotificationBody = styled.p`
  margin-top: 2px;
  border-radius: 8px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.02);

  text-align: justify;

  border: 1px solid #3dd4cbaa;
`;
