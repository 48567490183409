import moment from 'moment';
import { camelCase, get, isEmpty, unset, cloneDeep, set } from 'lodash';

import { QuestionnaireProps } from '../hooks/questions';
import { Organisation } from '../models/organisation';
import {
  AverageScore,
  HealthCheck,
  OngoingHealthCheck,
  ScoreHistory,
  ScoreProps,
  Timestamp,
  User
} from '../models/user';
import { getCategoryScore } from './calculateScore';

interface MixedScoreProps extends ScoreHistory {
  currentScore: number;
  color: string;
  clinicianAdvice: string;
  userAdvice: string;
  calculatedAt: string | Timestamp;
  averageScore: AverageScore;
  depressionScore: ScoreProps;
  anxietyScore: ScoreProps;
  stressScore: ScoreProps;
  systolicScore: ScoreProps;
  diastolicScore: ScoreProps;
  history?: [];
}

export const updatedUserAnswersAndScores = (
  user: User,
  clinicianUser: User,
  untouchedUser: User,
  keys: string[],
  values: Array<Array<string | number>>,
  questionnairesData: QuestionnaireProps[],
  answeredQuestions: Record<string, boolean>,
  clinicalObservation: string,
  userMessage: string,
  includeMindAnswers = false,
  includeLifeAnswers = false
) => {
  const calculatedAt = moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_MS);
  const answeredAt = moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_MS);

  const userCopy = cloneDeep(user);
  const copyUntouchedUser = cloneDeep(untouchedUser);

  const healthCheckAnswersObject = {} as HealthCheck;

  const categoriesAnswered = {
    body: false,
    mind: false,
    life: false
  } as Record<string, boolean>;

  keys.forEach((questionnaire, questionnaireIndex) => {
    if (!answeredQuestions[questionnaire]) return;

    const questionnaireData = questionnairesData.find(
      (data) => data.camelCaseQuestionnaire === questionnaire
    );

    if (!questionnaireData) {
      console.log({ questionnaireData, questionnairesData });
      throw new Error('Missing questionnaire data');
    }

    const category = camelCase(questionnaireData.questions[0].category);
    const section = camelCase(questionnaireData.section);

    const userAnswerObject = get(
      userCopy,
      `answers.${category}.${section}.${questionnaire}`,
      {}
    ) as any;

    let untouched = get(
      copyUntouchedUser,
      `answers.${category}.${section}.${questionnaire}`,
      {}
    ) as any;

    const isScorableQuestionnaire =
      questionnaire !== 'weight' &&
      questionnaire !== 'height' &&
      section !== 'jobHealthHazards';

    let scoreObject = {} as MixedScoreProps;
    if (isScorableQuestionnaire) {
      scoreObject = userAnswerObject.score;
      scoreObject.score = scoreObject.currentScore;
      unset(scoreObject, 'currentScore');
    }

    const healthCheckScoreObject = { score: {} } as any;

    if (isScorableQuestionnaire) {
      categoriesAnswered[category] = true;

      if (!untouched) {
        untouched = { score: {} };
      }

      const addScore = (
        calculatedScore: MixedScoreProps,
        scoreObjectData: Record<string, any>
      ) => {
        const scoreCopy = cloneDeep(scoreObjectData);

        scoreCopy.userAdvice = get(calculatedScore, 'userAdvice', '');
        scoreCopy.clinicianAdvice = get(calculatedScore, 'clinicianAdvice', '');
        scoreCopy.color = get(calculatedScore, 'color', '');
        scoreCopy.currentScore = get(calculatedScore, 'score', {});
        scoreCopy.calculatedAt = get(calculatedScore, 'calculatedAt', '');

        return scoreCopy;
      };

      untouched.score = addScore(scoreObject, untouched?.score || {});
      healthCheckScoreObject.score = addScore(
        scoreObject,
        healthCheckScoreObject.score
      );

      if (questionnaire === 'depressionAnxietyAndStress') {
        healthCheckScoreObject.score.depressionScore = {};
        healthCheckScoreObject.score.anxietyScore = {};
        healthCheckScoreObject.score.stressScore = {};

        if (!untouched.score.depressionScore) {
          untouched.score.depressionScore = {};
          untouched.score.anxietyScore = {};
          untouched.score.stressScore = {};
        }

        const depressionScore = scoreObject.depressionScore as MixedScoreProps;
        const anxietyScore = scoreObject.anxietyScore as MixedScoreProps;
        const stressScore = scoreObject.stressScore as MixedScoreProps;

        depressionScore.score = depressionScore.currentScore;
        anxietyScore.score = anxietyScore.currentScore;
        stressScore.score = stressScore.currentScore;

        unset(depressionScore, 'currentScore');
        unset(anxietyScore, 'currentScore');
        unset(stressScore, 'currentScore');

        untouched.score.depressionScore = addScore(
          depressionScore,
          untouched.score.depressionScore
        );
        untouched.score.anxietyScore = addScore(
          anxietyScore,
          untouched.score.anxietyScore
        );
        untouched.score.stressScore = addScore(
          stressScore,
          untouched.score.stressScore
        );

        healthCheckScoreObject.score.depressionScore = addScore(
          depressionScore,
          healthCheckScoreObject.score.depressionScore
        );
        healthCheckScoreObject.score.anxietyScore = addScore(
          anxietyScore,
          healthCheckScoreObject.score.anxietyScore
        );
        healthCheckScoreObject.score.stressScore = addScore(
          stressScore,
          healthCheckScoreObject.score.stressScore
        );
      }

      if (questionnaire === 'systolicDiastolic') {
        healthCheckScoreObject.score.systolicScore = {};
        healthCheckScoreObject.score.diastolicScore = {};

        if (!untouched.score.systolicScore) {
          untouched.score.systolicScore = {};
          untouched.score.diastolicScore = {};
        }

        const systolicScore = scoreObject.systolicScore as MixedScoreProps;
        const diastolicScore = scoreObject.diastolicScore as MixedScoreProps;

        systolicScore.score = systolicScore.currentScore;
        diastolicScore.score = diastolicScore.currentScore;

        unset(systolicScore, 'currentScore');
        unset(diastolicScore, 'currentScore');

        untouched.score.systolicScore = addScore(
          systolicScore,
          untouched.score.systolicScore
        );
        untouched.score.diastolicScore = addScore(
          diastolicScore,
          untouched.score.diastolicScore
        );

        healthCheckScoreObject.score.systolicScore = addScore(
          systolicScore,
          healthCheckScoreObject.score.systolicScore
        );
        healthCheckScoreObject.score.diastolicScore = addScore(
          diastolicScore,
          healthCheckScoreObject.score.diastolicScore
        );
      }

      // eslint-disable-next-line prettier/prettier
      set(copyUntouchedUser, `scores.${category}.${questionnaire}`, {
        ...untouched.score
      });
    }

    if (!untouched) {
      untouched = {};
    }

    // Section to set user answers
    const healthCheckAnswers = {} as any;
    values[questionnaireIndex].forEach((answer, answerIndex) => {
      if (isEmpty(get(userAnswerObject, answerIndex))) return;

      const questionObject = userAnswerObject[answerIndex].question;

      healthCheckAnswers[answerIndex] = {
        question: questionObject,
        answer: {
          value: answer,
          answeredAt
        }
      };

      if (untouched[answerIndex]) {
        untouched[answerIndex].answer = {
          value: answer,
          answeredAt
        };
      } else {
        untouched[answerIndex] = {
          question: questionObject,
          answer: {
            value: answer,
            answeredAt
          }
        };
      }

      set(
        healthCheckAnswersObject,
        // eslint-disable-next-line prettier/prettier
        `answers.${category}.${section}.${questionnaire}`,
        healthCheckAnswers
      );

      if (isScorableQuestionnaire) {
        set(
          healthCheckAnswersObject,
          // eslint-disable-next-line prettier/prettier
          `answers.${category}.${section}.${questionnaire}.score`,
          { ...healthCheckScoreObject.score }
        );
      }

      set(
        copyUntouchedUser,
        // eslint-disable-next-line prettier/prettier
        `answers.${category}.${section}.${questionnaire}`,
        untouched
      );
    });
  });

  const setUserAverageScore = () => {
    let untouchedAverageScore = cloneDeep(
      copyUntouchedUser.scores.averageScore
    ) as any;
    const untouchedScores = copyUntouchedUser.scores as any;

    if (!untouchedAverageScore) {
      untouchedAverageScore = {};
    }

    let hasBodyScore: boolean;
    let hasMindScore: boolean;
    let hasLifeScore: boolean;

    if (typeof untouchedAverageScore.averageScore === 'undefined') {
      hasBodyScore = categoriesAnswered.body;
      hasMindScore = categoriesAnswered.mind;
      hasLifeScore = categoriesAnswered.life;
    } else {
      hasBodyScore = untouchedScores.body;
      hasMindScore = untouchedScores.mind;
      hasLifeScore = untouchedScores.life;
    }

    const averageScoreObject = {} as any;

    if (hasBodyScore) {
      const bodyAverageScore = getCategoryScore({
        category: 'body',
        scores: copyUntouchedUser.scores
      });

      averageScoreObject.body = bodyAverageScore;
      untouchedAverageScore.bodyCalculatedAt = calculatedAt;
    }

    if (hasMindScore) {
      const mindAverageScore = getCategoryScore({
        category: 'mind',
        scores: copyUntouchedUser.scores
      });

      averageScoreObject.mind = mindAverageScore;
      untouchedAverageScore.mindCalculatedAt = calculatedAt;
    }

    if (hasLifeScore) {
      const lifeAverageScore = getCategoryScore({
        category: 'life',
        scores: copyUntouchedUser.scores
      });

      averageScoreObject.life = lifeAverageScore;
      untouchedAverageScore.lifeCalculatedAt = calculatedAt;
    }

    const { life, mind, body } = averageScoreObject;

    const lifeScore = life || 0;
    const mindScore = mind || 0;
    const bodyScore = body || 0;

    const averageScore = (lifeScore + bodyScore + mindScore) / 3;

    untouchedAverageScore.averageScore = Math.round(averageScore);
    untouchedAverageScore.life = lifeScore;
    untouchedAverageScore.mind = mindScore;
    untouchedAverageScore.body = bodyScore;
    untouchedAverageScore.averageCalculatedAt = calculatedAt;

    set(copyUntouchedUser, `scores.averageScore`, { ...untouchedAverageScore });
  };

  setUserAverageScore();

  if (includeMindAnswers) {
    const rawMindAnswers = get(untouchedUser, 'answers.mind', {});
    const rawMindScores = get(untouchedUser, 'scores.mind', {});

    if (!isEmpty(rawMindAnswers)) {
      set(userCopy, 'answers.mind', rawMindAnswers);
      set(userCopy, 'scores.mind', rawMindScores);

      const mindAverageScore = getCategoryScore({
        category: 'mind',
        scores: userCopy.scores
      });

      set(userCopy, 'scores.averageScore.mind', mindAverageScore);
      set(userCopy, 'scores.averageScore.mindCalculatedAt', calculatedAt);
    }
  }

  if (includeLifeAnswers) {
    const rawLifeAnswers = get(untouchedUser, 'answers.life', {});
    const rawLifeScores = get(untouchedUser, 'scores.life', {});

    if (!isEmpty(rawLifeAnswers)) {
      set(userCopy, 'answers.life', rawLifeAnswers);
      set(userCopy, 'scores.life', rawLifeScores);
    }

    const lifeAverageScore = getCategoryScore({
      category: 'life',
      scores: userCopy.scores
    });

    set(userCopy, 'scores.averageScore.life', lifeAverageScore);
    set(userCopy, 'scores.averageScore.lifeCalculatedAt', calculatedAt);
  }

  if (includeMindAnswers || includeLifeAnswers) {
    const userCopyAverageScore = get(
      userCopy,
      'scores.averageScore',
      {}
    ) as any;

    const { life, mind, body } = userCopyAverageScore;

    const lifeScore = life || 0;
    const mindScore = mind || 0;
    const bodyScore = body || 0;

    const averageScore = (lifeScore + bodyScore + mindScore) / 3;

    set(userCopy, 'scores.averageScore.averageScore', Math.round(averageScore));
    set(userCopy, 'scores.averageScore.averageCalculatedAt', calculatedAt);
  }

  set(healthCheckAnswersObject, `averageScore`, userCopy.scores.averageScore);
  set(healthCheckAnswersObject, `user`, userCopy);
  set(healthCheckAnswersObject, `clinicalObservation`, clinicalObservation);
  set(healthCheckAnswersObject, `userMessage`, userMessage);

  const clinicianCopy = cloneDeep(clinicianUser);

  const userHasHealthChecksWithThisClinician = get(
    copyUntouchedUser,
    `healthChecks.${clinicianCopy.id}`
  );

  let userHealthChecks = userHasHealthChecksWithThisClinician;

  if (!userHasHealthChecksWithThisClinician) {
    userHealthChecks = [];
  }

  userHealthChecks.push(healthCheckAnswersObject);

  set(copyUntouchedUser, `healthChecks.${clinicianCopy.id}`, userHealthChecks);

  const clinicianHasHealthChecksWithThisUser = get(
    clinicianCopy,
    `clients.${user.id}`
  );

  let clinicianHealthChecks = clinicianHasHealthChecksWithThisUser;

  if (!clinicianHasHealthChecksWithThisUser) {
    clinicianHealthChecks = [];
  }

  clinicianHealthChecks.push({
    ...healthCheckAnswersObject,
    user: userCopy,
    clinicalObservation,
    userMessage
  });

  set(clinicianCopy, `clients.${user.id}`, clinicianHealthChecks);

  copyUntouchedUser.ongoingHealthCheck = {} as OngoingHealthCheck;

  return {
    updatedUser: copyUntouchedUser,
    updatedClinician: clinicianCopy,
    clientHealthCheck: clinicianHealthChecks
  };
};

export const organizationHasHearingVisionV2Access = (
  organisation: Organisation
) => {
  return organisation?.customQuestionnaires?.some(
    (questionnaire) =>
      questionnaire === 'hearing-v2' || questionnaire === 'vision-v2'
  );
};
