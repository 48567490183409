import { useCallback, useMemo, useState } from 'react';
import { Slider } from '@mui/material';
import { camelCase, get } from 'lodash';
import * as Sentry from '@sentry/react';

import { FormField } from '../../../../components/FormField';
import { Question } from '../../../../models/question';
import { useHealthCheck } from '../../../../hooks/healthCheck';

import {
  ContainerRadio,
  OptionsAnswerContainer,
  QuestionTitle,
  QuestionWrapper
} from './styles';

interface AnswerCardProps {
  question: Question;
  questionIndex: number;
  answers: Record<string, (string | number)[]>;
}

export function AnswerCard({
  answers,
  questionIndex,
  question
}: AnswerCardProps) {
  const {
    handleChangeAnswers,
    answersSnapshot,
    disableMindQuestions,
    disableLifeQuestions
  } = useHealthCheck();

  const category = useMemo(() => {
    return camelCase(question.category);
  }, [question]);

  const questionnaire = useMemo(() => {
    return camelCase(question.questionnaire);
  }, [question]);

  const [currentRangeLevel, setCurrentRangeLevel] = useState(
    Number(get(answers, `${questionnaire}[${questionIndex}]`, 50))
  );

  const handleChangeCategoryAnswers = useCallback(
    (value: string | number) => {
      const copyAnswers = { ...answers };

      copyAnswers[questionnaire][questionIndex] = value;

      try {
        handleChangeAnswers(category, questionnaire, copyAnswers, question);
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
      }
    },
    [
      answers,
      handleChangeAnswers,
      questionnaire,
      category,
      questionIndex,
      question
    ]
  );

  const disableQuestion = useMemo(() => {
    if (category === 'mind' && disableMindQuestions) return true;
    if (category === 'life' && disableLifeQuestions) return true;
    if (get(answersSnapshot, questionnaire, false)) return true;

    return false;
  }, [
    answersSnapshot,
    category,
    disableLifeQuestions,
    disableMindQuestions,
    questionnaire
  ]);

  const howOftenAnswers = useMemo(() => {
    return questionnaire === 'depressionAnxietyAndStress'
      ? ['Never', 'Sometimes', 'Often', 'Almost always']
      : ['Never', 'Rarely', 'Sometimes', 'Often', 'Very often'];
  }, [questionnaire]);

  const agreeOrDisagreeAnswers = useMemo(() => {
    return questionnaire === 'life'
      ? [
          'Strongly disagree',
          'Disagree',
          'Not really',
          'Neither agree or disagree',
          'Agree',
          'Strongly agree'
        ]
      : ['Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree'];
  }, [questionnaire]);

  const marks = useMemo(() => {
    return [
      {
        value: 0,
        label: '0'
      },
      {
        value: currentRangeLevel,
        label: `${currentRangeLevel}`
      },
      {
        value: 100,
        label: '100'
      }
    ];
  }, [currentRangeLevel]);

  return (
    <QuestionWrapper>
      {question.type === 'Number' && (
        <>
          <FormField
            style={{ width: '100%' }}
            key={question.id}
            value={get(answers, `${questionnaire}[${questionIndex}]`, 0) as any}
            disabled={disableQuestion}
            type="number"
            label={`${question.label} ${question.unit && `(${question.unit})`}`}
            change={(value) => handleChangeCategoryAnswers(value)}
          />
        </>
      )}

      {question.type === 'Text answer' && (
        <>
          <FormField
            style={{ width: '100%' }}
            key={question.id}
            value={
              get(answers, `${questionnaire}[${questionIndex}]`, '') as any
            }
            disabled={disableQuestion}
            type="text"
            label={`${question.label} ${question.unit && `(${question.unit})`}`}
            change={(value) => handleChangeCategoryAnswers(value)}
          />
        </>
      )}

      {question.type === 'Custom options' && (
        <>
          <QuestionTitle>{question.label}</QuestionTitle>
          <OptionsAnswerContainer>
            {question.answers.map((answerOption) => {
              return (
                <ContainerRadio key={question.id + Math.random()}>
                  <FormField
                    label={answerOption}
                    type="radio"
                    name={question.id}
                    value={answerOption}
                    disabled={disableQuestion}
                    checked={
                      get(answers, `${questionnaire}[${questionIndex}]`, '') ===
                      answerOption
                    }
                    changeInput={() =>
                      handleChangeCategoryAnswers(answerOption)
                    }
                  />
                </ContainerRadio>
              );
            })}
          </OptionsAnswerContainer>
        </>
      )}

      {question.type === 'Agree or disagree' && (
        <>
          <QuestionTitle>{question.label}</QuestionTitle>
          <OptionsAnswerContainer>
            {agreeOrDisagreeAnswers.map((answerOption) => {
              return (
                <ContainerRadio key={question.id + Math.random()}>
                  <FormField
                    label={answerOption}
                    type="radio"
                    name={question.id}
                    value={answerOption}
                    disabled={disableQuestion}
                    checked={
                      get(answers, `${questionnaire}[${questionIndex}]`, '') ===
                      answerOption
                    }
                    changeInput={() =>
                      handleChangeCategoryAnswers(answerOption)
                    }
                  />
                </ContainerRadio>
              );
            })}
          </OptionsAnswerContainer>
        </>
      )}

      {question.type === 'How often' && (
        <>
          <QuestionTitle>
            {questionIndex <= 8 && 0}
            {questionIndex + 1} - {question.label}
          </QuestionTitle>
          <OptionsAnswerContainer>
            {howOftenAnswers.map((answerOption) => {
              return (
                <ContainerRadio key={question.id + Math.random()}>
                  <FormField
                    key={(question.id + answerOption).replaceAll(' ', '')}
                    label={answerOption}
                    type="radio"
                    name={question.id}
                    value={answerOption}
                    disabled={disableQuestion}
                    checked={
                      get(answers, `${questionnaire}[${questionIndex}]`, '') ===
                      answerOption
                    }
                    changeInput={() =>
                      handleChangeCategoryAnswers(answerOption)
                    }
                  />
                </ContainerRadio>
              );
            })}
          </OptionsAnswerContainer>
        </>
      )}

      {question.type === 'Percentage range' && (
        <>
          <QuestionTitle>
            {questionIndex <= 8 && 0}
            {questionIndex + 1} - {question.label}
          </QuestionTitle>
          <OptionsAnswerContainer>
            <ContainerRadio>
              <Slider
                aria-label="Percentage range"
                defaultValue={50}
                valueLabelDisplay="auto"
                style={{ width: '95%' }}
                disabled={disableQuestion}
                marks={marks}
                step={10}
                min={0}
                max={100}
                value={Number(
                  get(answers, `${questionnaire}[${questionIndex}]`, 50)
                )}
                onChange={(event) => {
                  const target = event.target as any;
                  setCurrentRangeLevel(target.value);
                  handleChangeCategoryAnswers(target.value);
                }}
                sx={{
                  width: '100%',
                  color: '#12a79e'
                }}
              />
            </ContainerRadio>
          </OptionsAnswerContainer>
        </>
      )}
    </QuestionWrapper>
  );
}
