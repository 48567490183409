import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { camelCase } from 'lodash';
import { endOfTomorrow, startOfYesterday } from 'date-fns';

import { Timestamp } from '../../../../models/user';
import {
  getDay,
  getFormattedDayMonthYear,
  getMonthAsNumber,
  getUnixSeconds,
  getYear
} from '../../../../utils/formatDate';

import { getOrganisationQuestionnaireHistory } from '../../../../functions/organisations';

import { useOrganisation } from '../../../../hooks/organisations';

import { Container, DateFilter, DateFilterWrapper } from './styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export interface QuestionnaireHistory {
  category: 'body' | 'mind' | 'life';
  divisionId: string;
  industry: string;
  organisationId: string;
  questionnaire: string;
  history: HistoryObjectProps[];
}

export interface HistoryObjectProps {
  calculatedAt: Timestamp | string;
  green: number;
  orange: number;
  red: number;
  totalUsers: number;
}

export interface HistoryDataProps {
  label: string;
  green: number;
  orange: number;
  red: number;
  calculatedAt: string | Timestamp;
}

interface HistoryChartProps {
  questionnaire: string;
}

export const HistoryChart: React.FC<HistoryChartProps> = ({
  questionnaire
}) => {
  const [currentData, setCurrentData] = useState([] as HistoryDataProps[]);
  const [history, setHistory] = useState([] as HistoryObjectProps[]);
  const [isLoadingHistory, setIsLoadingHistory] = useState(true);
  const [startDate, setStartDate] = useState('2000-01-01');
  const [endDate, setEndDate] = useState(() => {
    const currentYear = new Date().getFullYear();
    return `${currentYear + 5}-01-01`;
  });

  const { currentOrganisation, chosenDivisionId } = useOrganisation();

  useEffect(() => {
    const getHistoryAsync = async () => {
      const camelCaseQuestionnaire = camelCase(questionnaire);

      const orgId = currentOrganisation.id;
      const divisionId = chosenDivisionId;

      const historyObject = await getOrganisationQuestionnaireHistory(
        orgId,
        divisionId,
        camelCaseQuestionnaire
      );

      if (!historyObject) {
        setIsLoadingHistory(false);
        return [] as HistoryObjectProps[];
      }

      const historyArray = historyObject.history;
      historyArray.sort((a, b) => {
        const unixA = getUnixSeconds(a.calculatedAt);
        const unixB = getUnixSeconds(b.calculatedAt);

        return unixA > unixB ? 1 : -1;
      });

      setHistory(historyArray);
      setIsLoadingHistory(false);
    };

    getHistoryAsync();
  }, [currentOrganisation, chosenDivisionId, questionnaire]);

  const historyData = useMemo(() => {
    let addYearToLabel = false;

    if (history.length > 1) {
      const firstUnix = getUnixSeconds(history[0].calculatedAt);
      const lastUnix = getUnixSeconds(history[history.length - 1].calculatedAt);

      const firstYear = new Date(firstUnix).getFullYear();
      const lastYear = new Date(lastUnix).getFullYear();

      const currentYear = new Date().getFullYear();

      addYearToLabel = firstYear !== lastYear || lastYear !== currentYear;
    }

    const historyDataArray = [] as HistoryDataProps[];

    history.map(({ green, orange, red, calculatedAt }) => {
      const historyDataObject = {} as HistoryDataProps;

      historyDataObject.label = getFormattedDayMonthYear(
        calculatedAt,
        addYearToLabel
      );
      historyDataObject.green = green;
      historyDataObject.orange = orange;
      historyDataObject.red = red;
      historyDataObject.calculatedAt = calculatedAt;

      historyDataArray.push(historyDataObject);
    });

    return historyDataArray;
  }, [history]);

  useEffect(() => {
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();

    const filteredHistoryData = historyData.filter(({ calculatedAt }) => {
      const submittedAt = getUnixSeconds(calculatedAt);

      return submittedAt >= start && submittedAt <= end;
    });

    setCurrentData(filteredHistoryData);
  }, [startDate, endDate, historyData]);

  const { limitStartDate, limitEndDate } = useMemo(() => {
    let smallerDateUnix = startOfYesterday().getTime();
    let biggerDateUnix = endOfTomorrow().getTime();

    historyData.map(({ calculatedAt }) => {
      const checkDateUnix = getUnixSeconds(calculatedAt);

      if (checkDateUnix < smallerDateUnix) {
        smallerDateUnix = checkDateUnix;
      }

      if (checkDateUnix > biggerDateUnix) {
        biggerDateUnix = checkDateUnix;
      }
    });

    const smallerDate = new Date(smallerDateUnix);

    const day = getDay(smallerDate as any);
    const month = getMonthAsNumber(smallerDate as any);
    const year = getYear(smallerDate as any);

    const formattedMinimumDate = `${year}-${month}-${day}`;

    const lastDay = Number(getDay(biggerDateUnix as any));
    const lastMonth = getMonthAsNumber(biggerDateUnix as any);
    const lastYear = getYear(biggerDateUnix as any);

    const formattedMaximumDate = `${lastYear}-${lastMonth}-${String(
      lastDay - 1
    ).padStart(2, '0')}`;

    setStartDate(formattedMinimumDate);
    setEndDate(formattedMaximumDate);

    return {
      limitStartDate: formattedMinimumDate,
      limitEndDate: formattedMaximumDate
    };
  }, [historyData]);

  const chartData = useMemo(() => {
    const labels: string[] = [];
    const datasets = [
      {
        label: 'Green',
        data: [] as number[],
        borderColor: '#5dc45d',
        backgroundColor: '#5dc45d'
      },
      {
        label: 'Orange',
        data: [] as number[],
        borderColor: '#ffa500',
        backgroundColor: '#ffa500'
      },
      {
        label: 'Red',
        data: [] as number[],
        borderColor: '#fc6161',
        backgroundColor: '#fc6161'
      }
    ];

    currentData.map(({ label, green, orange, red }) => {
      labels.push(label);

      datasets[0].data.push(green);
      datasets[1].data.push(orange);
      datasets[2].data.push(red);
    });

    return {
      labels,
      datasets
    };
  }, [currentData]);

  const handleFilterByDate = useCallback(
    (dateString: string, filterType: 'start' | 'end') => {
      if (filterType === 'start') {
        setStartDate(dateString);
      } else {
        setEndDate(dateString);
      }
    },
    []
  );

  const chartOptions = useMemo(() => {
    return {
      responsive: true,
      plugins: {
        legend: {
          display: false
        }
      }
    };
  }, []);

  return (
    <Container>
      {isLoadingHistory && (
        <h2 style={{ textAlign: 'center' }}>Fetching history...</h2>
      )}

      <DateFilterWrapper>
        <div>
          <p>From</p>
          <DateFilter
            value={startDate}
            type="date"
            min={limitStartDate}
            max={endDate}
            onChange={(e) => handleFilterByDate(e.target.value, 'start')}
          />
        </div>

        <div>
          <p>To</p>
          <DateFilter
            type="date"
            value={endDate}
            onChange={(e) => handleFilterByDate(e.target.value, 'end')}
            min={startDate}
            max={limitEndDate}
          />
        </div>
      </DateFilterWrapper>

      <Line options={chartOptions} data={chartData} />
    </Container>
  );
};
