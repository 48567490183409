import React, { useCallback, useEffect, useState } from 'react';
import { clone, findIndex, floor, isEmpty, size } from 'lodash';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import CameraAltTwoToneIcon from '@mui/icons-material/CameraAltTwoTone';

import { Modal } from '@mui/material';
import {
  BoxSendImage,
  ButtonSelectImage,
  WrapsImages,
  WrapsImage,
  ModalWrapper,
  CloseButton,
  WrapsPreviousButton,
  WrapsNextButton,
  Container,
  VideoScreen,
  CameraIconContainer
} from './styles';
import { useToast } from '../../hooks/toast';
import { ImageViewWithZoom } from '../ImageViewWithZoom';

const CAMERA_SETTINGS = {
  video: {
    facingMode: 'user',
    width: { ideal: 1280 },
    height: { ideal: 720 }
  },
  audio: false
};

interface ExamsFieldProps {
  buttonTitle: string;
  uploadedImagesUrls?: string[];
  updateUploadedImages: React.Dispatch<React.SetStateAction<string[]>>;
  componentId: string;
  isDisabledUpload?: boolean;
}

export const TakePhotoField = ({
  buttonTitle,
  uploadedImagesUrls = [] as string[],
  updateUploadedImages,
  componentId,
  isDisabledUpload = false
}: ExamsFieldProps) => {
  const { addToast } = useToast();

  const [isModalGalleryOpen, setIsModalOpen] = useState(false);
  const [isPictureTaken, setIsPictureTaken] = useState(false);
  const [isModalTakePhotoOpen, setIsModalTakePhotoOpen] = useState(false);
  const [imageBeingShownModal, setImageBeingShownModal] = useState<string>('');

  const [nextImage, setNextImage] = useState({} as any);
  const [previousImage, setPreviousImage] = useState({} as any);

  const [imageSize, setImageSize] = useState({
    width: '80%'
  });

  useEffect(() => {
    const video = document.getElementById('fullResolutionVideo');
    if (!video) {
      const videoElement = document.createElement('video');
      videoElement.setAttribute('id', 'fullResolutionVideo');
      videoElement.setAttribute('autoplay', '' + true);
      videoElement.setAttribute('muted', '' + true);
      videoElement.setAttribute('playsInline', '' + true);
      videoElement.setAttribute('width', '100%');
      videoElement.setAttribute('height', '100%');
      videoElement.style.visibility = 'hidden';
      videoElement.style.position = 'absolute';
      videoElement.style.top = '-9999px';
      videoElement.style.left = '-9999px';
      document.body.appendChild(videoElement);
    }
  }, []);

  useEffect(() => {
    if (!isModalTakePhotoOpen) return;

    // This is made this way because we need to be sure that the video component is in the screen and the screen is
    // fully loaded to add the camera stream and enable browser to ask permission access to camera.
    setTimeout(() => {
      const modalVideo = document.getElementById(
        'modalVideo'
      ) as HTMLVideoElement;
      const fullResolutionVideo = document.getElementById(
        'fullResolutionVideo'
      ) as HTMLVideoElement;

      if (!modalVideo) {
        console.log('[Camera] There is no video element yet');
        return;
      }

      if (!modalVideo.srcObject) {
        navigator.mediaDevices
          .getUserMedia(CAMERA_SETTINGS)
          .then((stream) => {
            const track = stream.getVideoTracks()[0];
            const trackSettings = track.getSettings();
            const { width, height } = trackSettings;

            fullResolutionVideo.width =
              width || CAMERA_SETTINGS.video.width.ideal;
            fullResolutionVideo.height =
              height || CAMERA_SETTINGS.video.height.ideal;

            modalVideo.srcObject = stream;
            fullResolutionVideo.srcObject = stream;
            console.log('[Camera] Stream OK');
          })
          .catch((onError) => console.log('Error: ', onError));
      }
    }, 500);
  }, [isModalTakePhotoOpen]);

  const handleCloseModalGallery = () => {
    setIsModalOpen(false);
  };

  const handleCloseModalTakePhoto = () => {
    setIsModalTakePhotoOpen(false);
  };

  const showImageModal = useCallback(
    (image: string) => {
      setImageBeingShownModal(image);
      setIsModalOpen(true);

      const key = findIndex(uploadedImagesUrls, (o) => {
        return o === image;
      });

      if (key + 1 < size(uploadedImagesUrls)) {
        setNextImage(uploadedImagesUrls[key + 1]);
      } else {
        setNextImage(null);
      }

      if (key - 1 >= 0) {
        setPreviousImage(uploadedImagesUrls[key - 1]);
      } else {
        setPreviousImage(null);
      }
    },
    [uploadedImagesUrls]
  );

  const navigateNextImage = () => {
    showImageModal(nextImage);
  };

  const navigatePreviousImage = () => {
    showImageModal(previousImage);
  };

  const startTakePicture = () => {
    setIsModalTakePhotoOpen(true);
  };

  const deleteImage = useCallback(
    async (index: number) => {
      const confirmDeleteImage = window.confirm('Delete image?');

      if (!confirmDeleteImage) return;

      try {
        const images = clone(uploadedImagesUrls);
        images.splice(index, 1);
        updateUploadedImages([...images]);
      } catch (error) {
        addToast({
          title: 'Error deleting exam',
          type: 'error'
        });
      }
    },
    [addToast, updateUploadedImages, uploadedImagesUrls]
  );

  const takePicture = () => {
    setIsPictureTaken(true);
    setTimeout(() => {
      setIsPictureTaken(false);
    }, 200);
    const fullResolutionVideo = document.getElementById(
      'fullResolutionVideo'
    ) as HTMLVideoElement;

    const canvas = document.getElementById('photoCanvas') as HTMLCanvasElement;

    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    const { width, height } = fullResolutionVideo;

    if (width && height) {
      canvas.width = width;
      canvas.height = height;
      context.drawImage(fullResolutionVideo, 0, 0, width, height);

      const data = canvas.toDataURL('image/jpeg', 1);
      if (data) {
        updateUploadedImages([...uploadedImagesUrls, data]);
      }
    }
  };

  useEffect(() => {
    if (!isModalGalleryOpen) return;

    setTimeout(() => {
      const imageComponent = document.getElementById(
        'openedImageGallery'
      ) as HTMLImageElement;
      const imageSizeObj = {
        width:
          floor((imageComponent?.naturalWidth || 0) * 0.9).toString() || '80%'
      };
      setImageSize(imageSizeObj);
    }, 500);
  }, [isModalGalleryOpen]);

  return (
    <Container key={`${componentId}-exam-container`}>
      <Modal
        id="my-modal"
        open={isModalGalleryOpen}
        onClose={handleCloseModalGallery}
      >
        <ModalWrapper width={imageSize.width}>
          <CloseButton onClick={handleCloseModalGallery} aria-hidden="true">
            X
          </CloseButton>
          <ImageViewWithZoom imageSrc={imageBeingShownModal} />

          {previousImage && (
            <WrapsPreviousButton onClick={navigatePreviousImage}>
              <NavigateBefore fontSize="large" />
            </WrapsPreviousButton>
          )}

          {nextImage && (
            <WrapsNextButton onClick={navigateNextImage}>
              <NavigateNext fontSize="large" />
            </WrapsNextButton>
          )}
        </ModalWrapper>
      </Modal>

      <Modal open={isModalTakePhotoOpen} onClose={handleCloseModalTakePhoto}>
        <ModalWrapper>
          <CloseButton onClick={handleCloseModalTakePhoto} aria-hidden="true">
            X
          </CloseButton>
          <VideoScreen isPictureTaken={isPictureTaken}>
            <video id="modalVideo" autoPlay muted playsInline />
            <canvas id="photoCanvas" />
            <CameraIconContainer
              title="Click to take a picture"
              onClick={takePicture}
            >
              <CameraAltTwoToneIcon />
            </CameraIconContainer>
          </VideoScreen>
        </ModalWrapper>
      </Modal>
      {!isDisabledUpload && (
        <BoxSendImage>
          <ButtonSelectImage onClick={startTakePicture}>
            <span>{buttonTitle}</span>
          </ButtonSelectImage>
        </BoxSendImage>
      )}
      <WrapsImages>
        {uploadedImagesUrls &&
          uploadedImagesUrls.map((image, index) => {
            if (isEmpty(image)) return;

            return (
              <WrapsImage key={`${componentId}-${index}`}>
                <img
                  src={image}
                  alt="Camera Icon"
                  onClick={() => showImageModal(image)}
                  aria-hidden="true"
                />
                {!isDisabledUpload && (
                  <span onClick={() => deleteImage(index)} aria-hidden="true">
                    X
                  </span>
                )}
              </WrapsImage>
            );
          })}
      </WrapsImages>
    </Container>
  );
};
