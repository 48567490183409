import styled from 'styled-components';

export const Container = styled.label`
  width: 100%;
  margin: auto;
  display: block;
  margin-top: 5px;
  span {
    font-weight: 500;
  }
`;

export const Input = styled.input`
  background: #ffffff;
  border-radius: 4px;
  border: 0px;
  height: 40px;
  width: 100%;
  padding: 16px;
  margin-top: 7px;

  &:disabled {
    opacity: 30%;
  }

  p {
    color: var(--text-dark);
  }
`;

export const Textarea = styled.textarea`
  background: #ffffff;
  border-radius: 4px;
  border: 0;
  height: 180px;
  width: 100%;
  padding: 16px;
  margin-top: 7px;
  p {
    color: var(--text-dark);
  }
`;

export const Select = styled.select`
  background: #ffffff;
  border-radius: 4px;
  border: 0;
  height: 56px;
  width: 100%;
  padding: 16px;
  margin-top: 7px;
  appearance: none;

  &:disabled {
    opacity: 30%;
  }

  p {
    color: var(--text-dark);
  }
`;

interface RadioProps {
  checked?: boolean;
  itemRef?: string;
}

export const Radio = styled.input<RadioProps>`
  background: #ffffff;
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
  border: 2px solid #12a79e;
  padding: 0.5rem;
  appearance: none;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    opacity: 30%;
  }

  &::after {
    content: '';
    display: ${(props) => !props.checked && 'none'};
    border-radius: 50%;
    width: 0.7rem;
    position: absolute;
    height: 0.7rem;
    background: #12a79e;
    color: red;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }

  p {
    color: var(--text-dark);
  }
`;
