import 'dotenv/config';
import { useRoutes } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CssBaseline } from '@mui/material';

import GlobalStyle from './styles/global';
import { ThemeProviderWrapper } from './styles/theme/ThemeProvider';
import { AppProvider } from './hooks';
import { Routes } from './routes';

export const App: React.FC = () => {
  const content = useRoutes(Routes());

  return (
    <ThemeProviderWrapper>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AppProvider>
          <CssBaseline />
          {content}
        </AppProvider>

        <GlobalStyle />
      </LocalizationProvider>
    </ThemeProviderWrapper>
  );
};
