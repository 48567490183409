import { Autocomplete, TextField } from '@mui/material';

interface AlertTypeFilterParams {
  selectedValue: string;
  showLabel?: boolean;
  handleChange: (value: 'All' | 'Hearing Screen' | 'Vision Screen') => void;
}

const statusTypes = ['All', 'Hearing Screen', 'Vision Screen'];

export const AlertTypeFilter = ({
  selectedValue = 'All',
  showLabel = true,
  handleChange
}: AlertTypeFilterParams) => {
  return (
    <Autocomplete
      disablePortal
      id="hearing-vision-alert-question"
      options={statusTypes}
      value={selectedValue}
      freeSolo={false}
      disableClearable
      onChange={(event) =>
        handleChange(
          event.currentTarget.innerHTML as
            | 'All'
            | 'Hearing Screen'
            | 'Vision Screen'
        )
      }
      renderInput={(params) => {
        const label = showLabel ? 'Questionnaire' : '';
        return <TextField {...params} required fullWidth label={label} />;
      }}
    />
  );
};
