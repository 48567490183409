import { isEmpty, uniqueId } from 'lodash';
import {
  WrapsImages,
  WrapsImage,
  Container,
  OpenFile,
  FileContainer,
  FileName
} from './styles';

import pdfIcon from '../../assets/pdf-icon.png';

interface ExamsFieldProps {
  uploadedExams?: Record<string, string>[];
  componentId: string;
  isDisabledUpload?: boolean;
}

export interface FileProps {
  image?: File;
  name?: string;
  url: string;
}

export const FileExamsGallery = ({
  uploadedExams = [] as Record<string, string>[],
  componentId,
  isDisabledUpload = false
}: ExamsFieldProps) => {
  return (
    <Container key={`${componentId}-exam-container`}>
      {!isDisabledUpload && (
        <WrapsImages>
          {uploadedExams &&
            uploadedExams.map((file, index) => {
              if (isEmpty(file)) return;

              return (
                <FileContainer key={uniqueId()}>
                  <WrapsImage key={`${componentId}-${index}`}>
                    <OpenFile href={file.url} target="_blank" rel="noreferrer">
                      <img src={pdfIcon} alt="PDF Icon" />
                    </OpenFile>
                  </WrapsImage>
                  <FileName title={file.name}>
                    {file.name.substring(0, 20)}
                  </FileName>
                </FileContainer>
              );
            })}
        </WrapsImages>
      )}
    </Container>
  );
};
