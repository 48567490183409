import { useMemo } from 'react';
import { Slider } from '@mui/material';
import { camelCase } from 'lodash';

import { FormField } from '../../../../FormField';
import { Question } from '../../../../../models/question';

import {
  ContainerRadio,
  OptionsAnswerContainer,
  QuestionTitle,
  QuestionWrapper
} from './styles';
import { UserAnswer } from '../../../../../models/user';

interface AnswerCardProps {
  question: Question;
  questionIndex: number;
  answer: UserAnswer;
}

export function AnswerCard({
  answer,
  questionIndex,
  question
}: AnswerCardProps) {
  const { questionnaire, questionType } = useMemo(() => {
    const camelCaseQuestionnaire = camelCase(question.questionnaire);
    return {
      questionnaire: camelCaseQuestionnaire,
      questionType: question.type
    };
  }, [question]);

  const howOftenAnswers = useMemo(() => {
    return questionnaire === 'depressionAnxietyAndStress'
      ? ['Never', 'Sometimes', 'Often', 'Almost always']
      : ['Never', 'Rarely', 'Sometimes', 'Often', 'Very often'];
  }, [questionnaire]);

  const agreeOrDisagreeAnswers = useMemo(() => {
    return questionnaire === 'life'
      ? [
          'Strongly disagree',
          'Disagree',
          'Not really',
          'Neither agree or disagree',
          'Agree',
          'Strongly agree'
        ]
      : ['Strongly disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly agree'];
  }, [questionnaire]);

  const marks = useMemo(() => {
    const currentRangeLevel = Number(answer.value);

    return [
      {
        value: 0,
        label: '0'
      },
      {
        value: currentRangeLevel,
        label: `${currentRangeLevel}`
      },
      {
        value: 100,
        label: '100'
      }
    ];
  }, [answer]);

  return (
    <QuestionWrapper enableFlexView={questionType !== 'Percentage range'}>
      {questionType === 'Number' && (
        <>
          <FormField
            style={{ width: '100%' }}
            key={question.id}
            value={answer.value}
            disabled
            type="number"
            label={`${question.label} ${question.unit && `(${question.unit})`}`}
          />
        </>
      )}

      {questionType === 'Custom options' && (
        <>
          <QuestionTitle>{question.label}</QuestionTitle>
          <OptionsAnswerContainer>
            {question.answers.map((answerOption) => {
              return (
                <ContainerRadio key={question.id + Math.random()}>
                  <FormField
                    label={answerOption}
                    type="radio"
                    name={question.id}
                    value={answerOption}
                    disabled
                    checked={answer.value === answerOption}
                  />
                </ContainerRadio>
              );
            })}
          </OptionsAnswerContainer>
        </>
      )}

      {questionType === 'Agree or disagree' && (
        <>
          <QuestionTitle>{question.label}</QuestionTitle>
          <OptionsAnswerContainer>
            {agreeOrDisagreeAnswers.map((answerOption) => {
              return (
                <ContainerRadio key={question.id + Math.random()}>
                  <FormField
                    label={answerOption}
                    type="radio"
                    name={question.id}
                    value={answerOption}
                    disabled
                    checked={answer.value === answerOption}
                  />
                </ContainerRadio>
              );
            })}
          </OptionsAnswerContainer>
        </>
      )}

      {questionType === 'How often' && (
        <>
          <QuestionTitle>
            {questionIndex + 1} - {question.label}
          </QuestionTitle>
          <OptionsAnswerContainer>
            {howOftenAnswers.map((answerOption) => {
              return (
                <ContainerRadio key={question.id + Math.random()}>
                  <FormField
                    key={(question.id + answerOption).replaceAll(' ', '')}
                    label={answerOption}
                    type="radio"
                    name={question.id}
                    value={answerOption}
                    disabled
                    checked={answer.value === answerOption}
                  />
                </ContainerRadio>
              );
            })}
          </OptionsAnswerContainer>
        </>
      )}

      {questionType === 'Percentage range' && (
        <>
          <QuestionTitle>
            {questionIndex + 1} - {question.label}
          </QuestionTitle>
          <OptionsAnswerContainer>
            <ContainerRadio>
              <Slider
                aria-label="Percentage range"
                defaultValue={50}
                valueLabelDisplay="auto"
                style={{ width: '95%' }}
                disabled
                marks={marks}
                step={10}
                min={0}
                max={100}
                value={Number(answer.value)}
                sx={{
                  width: '100%',
                  color: '#12a79e'
                }}
              />
            </ContainerRadio>
          </OptionsAnswerContainer>
        </>
      )}
    </QuestionWrapper>
  );
}
