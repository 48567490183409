import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 40px;
  position: relative;

  @media (max-width: 700px) {
    padding: 5px;
  }
`;

export const ToggleScoreButton = styled.button`
  position: fixed;
  right: 100px;
  top: 130px;
  z-index: 2;

  border: none;
  background: transparent;
  border-radius: 50%;
  cursor: pointer;

  height: 50px;
  width: 50px;

  transition: background-color 0.2s;
  color: #333333aa;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  @media (max-width: 700px) {
    top: 100px;
  }

  @media (max-width: 1100px) {
    right: 20px;
  }

  @media (max-width: 500px) {
    right: 5px;
  }

  svg {
    pointer-events: none;
  }
`;

export const NoClientsWarning = styled.div`
  width: 80%;
  margin: 0 auto;
  text-align: center;
  margin-top: 30px;
`;

export const UserCardsWrapper = styled.main`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  gap: 16px;

  margin-top: 16px;

  &.new-check-button {
    color: black !important;
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 8px;
  }

  @media (max-width: 350px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 3px;
  }
`;

export const UserName = styled.h2`
  position: relative;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333cc;

  div {
    display: flex;

    p {
      margin: 0 3px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  p {
    margin: 0 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    left: 0;

    background: transparent;
    border: none;
    border-radius: 5px;
    height: 100%;
    width: 60px;

    transition: background-color 0.2s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    svg {
      pointer-events: none;
    }
  }
`;

export const UserData = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const UserDataColumn = styled.div`
  margin-bottom: 10px;

  @media (max-width: 600px) {
    margin-bottom: 0;
  }
`;

export const DataRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;

  color: #333333aa;
  max-width: 100%;
  border-radius: 5px;
  padding: 3px;
  margin: 2px 0 2px;
  background-color: rgba(0, 0, 0, 0.05);

  transition: background-color 0.1s;

  @media (max-width: 600px) {
    margin: 2px 0;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  strong {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #333333aa;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const CriticalDataLine = styled.div`
  width: 100%;
  display: grid;
  column-gap: 10px;
  row-gap: 2.5px;
  grid-template-columns: repeat(2, 1fr);

  margin-bottom: 8px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const CriticalData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  gap: 5px;

  padding: 3px;
  border-radius: 5px;

  background-color: rgba(0, 0, 0, 0.05);

  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  strong {
    color: #333333cc;
    font-size: 12px;
    font-weight: 500;
  }

  svg {
    pointer-events: none;
    color: #3dd4cb;
  }
`;

export const ScoreTitle = styled.h4`
  margin: 0 0 3px;
  color: #333333cc;
`;

export const ScoresWrapper = styled.footer`
  margin-bottom: 10px;

  display: grid;
  gap: 10px;
  grid-template-areas:
    'a b b b'
    'a c c c';

  @media (max-width: 700px) {
    grid-template-areas:
      'a'
      'b'
      'c';
  }
`;

export const ScoreBlock = styled.div`
  display: flex;
  gap: 10px;
  flex: 1;

  @media (max-width: 700px) {
    flex-direction: column;
  }

  &.area-1 {
    grid-area: a;
  }

  &.area-2 {
    grid-area: b;
  }

  &.area-3 {
    grid-area: c;
  }
`;

export const ScoreData = styled.div`
  position: relative;
  overflow: hidden;

  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 3px 3px 6px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 10px;

  transition: transform 0.1s, background-color 0.1s;

  &.average {
    flex-direction: column;
    justify-content: center;
  }

  &:hover {
    transform: scale(1.03);
    background-color: rgba(0, 0, 0, 0.03);
  }

  img {
    height: 40px;
    width: 40px;
    opacity: 0.5;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    p {
      margin: 0;
    }

    strong {
      color: #333333aa;
      font-size: 30px;
      font-weight: bold;
    }

    h6 {
      margin: 0;
    }
  }
`;

interface ColorLabelProps {
  backgroundColor: string;
}

export const ColorLabel = styled.span<ColorLabelProps>`
  position: absolute;
  right: 5px;
  bottom: 5px;

  background-color: ${(props) => props.backgroundColor};

  height: 15px;
  width: 15px;
  min-height: 15px;
  min-width: 15px;
  max-height: 15px;
  max-width: 15px;
  border-radius: 50%;
`;

interface FooterButtonProps {
  hasOngoingHealthCheck?: boolean;
}

export const ButtonsRow = styled.footer`
  display: flex;
  gap: 10px;

  @media (max-width: 600px) {
    margin-top: 10px;
    flex-direction: column;
  }
`;

export const FooterButton = styled.button<FooterButtonProps>`
  cursor: pointer;
  border-radius: 5px;
  background-color: transparent;
  color: #3dd4cb;
  border: solid 1px #3dd4cb;
  height: 30px;
  padding: 0 10px;

  font-size: 16px;
  font-weight: 500;

  transition: background-color 0.2s;

  ${(props) =>
    props.hasOngoingHealthCheck &&
    css`
      color: #ff8137;
      border-color: #ff8137;
    `}

  &:hover {
    background-color: ${(props) =>
      props.hasOngoingHealthCheck ? '#ff813722' : '#3dd4cb22'};
  }
`;

export const DatesRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  font-size: 10px;
  color: #333;
  margin-top: 5px;
`;

export const UserActivation = styled.div`
  margin: 0;
  padding: 0;
  cursor: help;

  & svg {
    height: 1.25rem;
    width: 1.25rem;
  }

  transition: transform 0.2s;

  &:hover {
    transform: scale(1.15);
  }
`;
