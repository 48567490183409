/* eslint-disable */

export const advices = {
  ACL150: `
  <ul>
    <li>Refer to online resources for ideas to improve social wellness.</li>
    <li>This may be part of a bigger picture with MIND scores also low.</li>
    <li>Recommend professional help if so and if health and wellbeing are obviously being affected.</li>
  </ul>
  `,
  ACL140: `
  <ul>
    <li>Refer to online resources for ideas to improve social wellness.</li>
    <li>This may be part of a bigger picture with MIND scores also low.</li>
    <li>Recommend professional help if so and if health and wellbeing are obviously being affected.</li>
  </ul>
  `,
  ACL130: `
  <ul>
    <li>Refer to online resources for ideas to improve social wellness.</li>
    <li>This may be part of a bigger picture with MIND scores also low.</li>
    <li>Recommend professional help if so and if health and wellbeing are obviously being affected.</li>
  </ul>
  `,
  ACL120: `
  <ul>
    <li>Refer to online resources for ideas to improve social wellness.</li>
    <li>This may be part of a bigger picture with MIND scores also low.</li>
    <li>Recommend professional help if so and if health and wellbeing are obviously being affected.</li>
  </ul>
  `,
  ACL110: `
  No action required.
  `,
  APL150: `
  <strong>What does your score mean?</strong>\n \n
  Your score indicates you could be struggling with some aspects of your social health. You may be experiencing symptoms of depression or stress as well. This may be affecting your health and wellbeing.\n \n
  <strong>Why is this important?</strong>\n \n
  There are lots of different components to LIFE, such as sense of purpose, community connections and financial security. Having lower scores in some of these areas could contribute to stress and anxiety in your life. It’s important to see where you answered the lowest in your LIFE questionnaire, so you can have a target on what aspect you would like to improve. You can do this by tapping into the “Entries” tab, and reviewing your answers. Finding the areas you may want to improve on is a great starting place, to come up with strategies on how to improve. Having a higher LIFE score can contribute to our mental and physical health also.\n \n
  <strong>What can I do about it?</strong>\n \n
  Identify the areas you scored lowest, and then create a plan. It is likely important to consider some professional help. Making contact with your GP or a psychologist will get you the help you need. They will be able to see where you may be struggling the most and assist you with a plan to improve.\n \n
  <a target="_blank" href="https://www.nih.gov/health-information/social-wellness-toolkit">www.nih.gov/health-information\n \n</a>
  <a target="_blank" href="https://www.betteryou.ai/how-to-improve-social-wellness/">www.betteryou.ai\n \n</a>
  <a target="_blank" href="https://www.optimumperformanceinstitute.com/life-coaching/7-ways-to-successfully-cultivate-social-wellness-for-life/">www.optimumperformanceinstitute.com</a>
  `,
  APL140: `
  <strong>What does your score mean?</strong>\n \n
  Your score indicates you could be struggling with some aspects of your social health. You may be experiencing symptoms of depression or stress as well. This may be affecting your health and wellbeing.\n \n
  <strong>Why is this important?</strong>\n \n
  There are lots of different components to LIFE, such as sense of purpose, community connections and financial security. Having lower scores in some of these areas could contribute to stress and anxiety in your life. It’s important to see where you answered the lowest in your LIFE questionnaire, so you can have a target on what aspect you would like to improve. You can do this by tapping into the “Entries” tab, and reviewing your answers. Finding the areas you may want to improve on is a great starting place, to come up with strategies on how to improve. Having a higher LIFE score can contribute to our mental and physical health also.\n \n
  <strong>What can I do about it?</strong>\n \n
  Identify the areas you scored lowest, and then create a plan. It is likely important to consider some professional help. Making contact with your GP or a psychologist will get you the help you need. They will be able to see where you may be struggling the most and assist you with a plan to improve.\n \n
  <a target="_blank" href="https://www.nih.gov/health-information/social-wellness-toolkit">www.nih.gov/health-information\n \n</a>
  <a target="_blank" href="https://www.betteryou.ai/how-to-improve-social-wellness/">www.betteryou.ai\n \n</a>
  <a target="_blank" href="https://www.optimumperformanceinstitute.com/life-coaching/7-ways-to-successfully-cultivate-social-wellness-for-life/">www.optimumperformanceinstitute.com</a>
  `,
  APL130: `
  <strong>What does your score mean?</strong>\n \n
  You may be surviving but not really thriving with this score. In some aspects of your social wellness you are doing well, but in certain areas of your life, you would probably agree, you could be doing better.\n \n
  <strong>Why is this important?</strong> \n \n
  There are lots of different components to LIFE, such as sense of purpose, community connections and financial security. Having lower scores in some of these areas could contribute to stress and anxiety in your life. It’s important to see where you answered poorly in your LIFE questionnaire, so you can have a target on what aspect you would like to improve.Having a higher LIFE score can contribute to our mental and physical health also.\n \n
  <strong>What can I do about it?</strong>\n \n
  Identifying which sections you scored lowest is the best place to start. You can do this by tapping into the “Entries” tab, and reviewing your answers. Finding the areas you may want to improve on is a great starting place, to come up with strategies on how to improve. You may want to try and get creative with these solutions yourself, such as if you have a low sense of community connections, are there some volunteering programs in your community you could look into? Speaking with family and friends is also a good way to get ideas on how to improve. Finally, it could be useful to seek some professional help from your GP or a psychologist to find some solutions.\n \n
  <a target="_blank" href="https://www.nih.gov/health-information/social-wellness-toolkit">www.nih.gov/health-information\n \n</a>
  <a target="_blank" href="https://www.betteryou.ai/how-to-improve-social-wellness/">www.betteryou.ai\n \n</a>
  <a target="_blank" href="https://www.optimumperformanceinstitute.com/life-coaching/7-ways-to-successfully-cultivate-social-wellness-for-life/">www.optimumperformanceinstitute.com</a>
  `,
  APL120: `
  <strong>What does your score mean?</strong>\n \n
  You may be surviving but not really thriving with this score. In some aspects of your social wellness you are doing well, but in certain areas of your life, you would probably agree, you could be doing better.\n \n
  <strong>Why is this important?</strong> \n \n
  There are lots of different components to LIFE, such as sense of purpose, community connections and financial security. Having lower scores in some of these areas could contribute to stress and anxiety in your life. It’s important to see where you answered poorly in your LIFE questionnaire, so you can have a target on what aspect you would like to improve.Having a higher LIFE score can contribute to our mental and physical health also.\n \n
  <strong>What can I do about it?</strong>\n \n
  Identifying which sections you scored lowest is the best place to start. You can do this by tapping into the “Entries” tab, and reviewing your answers. Finding the areas you may want to improve on is a great starting place, to come up with strategies on how to improve. You may want to try and get creative with these solutions yourself, such as if you have a low sense of community connections, are there some volunteering programs in your community you could look into? Speaking with family and friends is also a good way to get ideas on how to improve. Finally, it could be useful to seek some professional help from your GP or a psychologist to find some solutions.\n \n
  <a target="_blank" href="https://www.nih.gov/health-information/social-wellness-toolkit">www.nih.gov/health-information\n \n</a>
  <a target="_blank" href="https://www.betteryou.ai/how-to-improve-social-wellness/">www.betteryou.ai\n \n</a>
  <a target="_blank" href="https://www.optimumperformanceinstitute.com/life-coaching/7-ways-to-successfully-cultivate-social-wellness-for-life/">www.optimumperformanceinstitute.com</a>
  `,
  APL110: `
  <strong>What does your score mean?</strong>\n \n
  Great! Your score suggests you have strong, healthy relationships and great social connections in your life. This will be of benefit to your health and wellbeing in many ways.\n \n
  <strong>Why is this important?</strong>\n \n
  Healthy relationships and strong social connections are vital to our health and wellbeing. Taha whānau is about who makes you feel like you belong, who you care about and who you share your life with. Spending time with whānau, doing things for them and getting involved gives you a feeling of purpose, connection and wellbeing. As a core source of strength, support, security and identity, whānau plays a central role in your wellbeing.\n \n
  <strong>What can I do about it?</strong>\n \n
  Continue to value and prioritise your family, friends and relationships. Some of the questions may have made you reflect and consider areas you want to improve on.\n \n
  You may want to see what scores you colleagues, friends and family have. You may be able to give them a few tips on how to improve their score.
  `,
  ACM630: `
  <ul>
    <li>Suggest a conscious effort in this area will be beneficial to many areas of life. Websites, books to refer to.</li>
    <li>Can help address issues in other areas of life. Mindfulness is an important element in the treatment of a number of problems, including: depression, substance abuse, eating disorders, couples’ conflicts, anxiety disorders, and obsessive-compulsive disorder.</li>
    <li>For the non-believers, explain there are many ways to be mindful. It is not just sitting cross legged with eyes closed!</li>
  </ul>
  `,
  ACM620: `
  <ul>
    <li>Suggest a conscious effort in this area will be beneficial to many areas of life. Websites, books to refer to.</li>
    <li>Can help address issues in other areas of life. Mindfulness is an important element in the treatment of a number of problems, including: depression, substance abuse, eating disorders, couples’ conflicts, anxiety disorders, and obsessive-compulsive disorder.</li>
    <li>For the non-believers, explain there are many ways to be mindful. It is not just sitting cross legged with eyes closed! Concentrate on being present in the current moment whatever that is, washing the dishes, eating dinner, talking to loved ones.</li>
  </ul>
  `,
  ACM610: `
  <ul>
    <li>No action required.</li>
    <li>Reinforce the benefits of being mindful.</li>
  </ul>
  `,
  APM630: `
  <strong>What does your score mean?</strong>\n \n
  You have the opportunity to make improvements to your health and improve your wellbeing by becoming more mindful.\n \n
  <strong>Why is this important?</strong>\n \n
  Have you noticed yourself experiencing nervousness, not being attentive, a lack of focus, patience or self-compassion? These are all signs of a lack of mindfulness. Nowadays we can be distracted by so much stimuli that our ability to focus and keep still is reduced. The need to constantly entertain ourselves and keep busy does not do us any favours.\n \n
  Mindfulness can help relieve stress, treat heart disease, lower blood pressure, reduce chronic pain, improve sleep, and alleviate gastrointestinal difficulties.\n \n
  Improving your mindfulness helps you acknowledge your thoughts and feelings in a healthy way and enables you to avoid habits or behaviours that might be destructive or unhelpful.\n \n
  <strong>What can I do about it?</strong>\n \n
  Invest in yourself. Learn more about the practice of mindfulness and how it can benefit your overall wellbeing. There are many ways to practice mindfulness and there is something for everyone.\n \n
  Modern mindfulness practices can focus on meditation, relaxation and attention even while undertaking your normal day to day activities. The goal is to observe passing thoughts and be present in that particular moment.\n \n
  Support is freely available on websites and through books. Try your library or buy online.\n \n
  If you want to know more about mindfulness and its benefits, refer to:\n \n
  <a target="_blank" href="//mindful.org">www.mindful.org\n \n</a>
  If you have lots of excuses about not being mindful have a look at...\n \n
  <a target="_blank" href="https://www.thecornerofexcellence.com/en/lack-of-mindfulness-excuses-overcome/">www.thecornerofexcellence.com</a>
  `,
  APM620: `
  <strong>What does your score mean?</strong>\n \n
  You have the opportunity to make improvements to your health and improve your wellbeing by becoming more mindful.\n \n
  <strong>Why is this important?</strong>\n \n
  Have you noticed yourself experiencing nervousness, not being attentive, a lack of focus, patience or self-compassion? These are all signs of a lack of mindfulness. Nowadays we can be distracted by so much stimuli that our ability to focus and keep still is reduced. The need to constantly entertain ourselves and keep busy does not do us any favours.\n \n
  Mindfulness can help relieve stress, lower blood pressure, reduce chronic pain and improve sleep.\n \n
  Improving your mindfulness helps you acknowledge your thoughts and feelings in a healthy way and enables you to avoid habits or behaviours that might be destructive or unhelpful.\n \n
  <strong>What can I do about it?</strong>\n \n
  Invest in yourself. Learn more about the practice of mindfulness and how it can benefit your overall wellbeing. There are many ways to practice mindfulness and there is something for everyone.\n \n
  Modern mindfulness practices can focus on meditation, relaxation and attention even while undertaking your normal day to day activities. The goal is to observe passing thoughts and be present in that particular moment.\n \n
  Support is freely available on websites and through books. Try your library or buy online.\n \n
  If you want to know more about mindfulness and its benefits, refer to:\n \n
  <a target="_blank" href="//mindful.org">www.mindful.org\n \n</a>
  If you have lots of excuses about not being mindful have a look at...\n \n
  <a target="_blank" href="https://www.thecornerofexcellence.com/en/lack-of-mindfulness-excuses-overcome/">www.thecornerofexcellence.com</a>
  `,
  APM610: `
  <strong>What does your score mean?</strong>\n \n
  Well done, by being mindful you benefit your health and overall wellbeing.\n \n
  <strong>Why is this important?</strong>\n \n
  Practicing mindfulness is thought to have many positive effects on many aspects of our well-being.  Improvements in mood, increased positive emotions, reduced anxiety and emotional reactivity are all possible effects from introducing mindfulness into our day to day lives.\n \n
  <strong>What can I do about it?</strong>\n \n
  Maybe mindfulness comes naturally to you or you have been making a conscious effort in this department. Either way you will be benefiting from its place in your life.\n \n
  If you want to know more about mindfulness and its benefits or you want to share the ideas with a friend refer to...\n \n
  <a target="_blank" href="//mindful.org">www.mindful.org\n \n</a>
  If you have friends and family who have lots of excuses about not being mindful send them to...\n \n
  <a target="_blank" href="https://www.thecornerofexcellence.com/en/lack-of-mindfulness-excuses-overcome/">www.thecornerofexcellence.com</a>
  `,
  ACM730: `
  <ul>
    <li>Identify any obvious reasons for poor sleep, sleep hygiene, stress, other worries, shift work?</li>
    <li>Assess sleep hygiene, direct to resources detailing this.</li>
    <li>Refer individual to their GP for assessment.</li>
    <li>Advise them that holding a driver’s license requires them to be medically fit to drive and only drive when they are safe to do so, this means not driving when too tired. </li>
  </ul>
  `,
  ACM720: `
  <ul>
    <li>Identify any obvious reasons for the poor sleep, sleep hygiene, stress, other worries, shift work?</li>
    <li>Assess sleep hygiene, direct to resources detailing this.</li>
    <li>Refer individuals to their GP for assessment if it is impacting on quality of life, symptoms are chronic or there are other concerns.</li>
    <li>Advise them that holding a driver’s license requires them to be medically fit to drive and only drive when they are safe to do so, this means not driving when too tired.</li>
  </ul>
  `,
  ACM710: `
  <ul>
    <li>No action required</li>
    <li>Provide a reminder of good sleep hygiene practices if relevant</li>
  </ul>
  `,
  APM730: `
  <strong>What does your score mean?</strong>\n \n
  Your score indicates it's possible your health, wellbeing and safety are all being affected by a lack of quality sleep.\n \n
  <strong>Why is this important?</strong>\n \n
  A common myth is that people can learn to get by on little sleep with no negative effects. However, research shows that getting enough quality sleep at the right times is vital for mental health, physical health and quality of life.\n \n
  Achieving good quality sleep maintains your health and therefore the safety of you and others in the home and workplace. if you drive a car or operate machinery while sleep deprived you could be putting yourself and others at risk. A lack of sleep can harm your driving ability as much as being drunk.\n \n
  As a result, sleep deficiency is not only harmful on a personal level, but it also can cause large-scale damage and tragic accidents.\n \n
  <strong>What can I do about it?</strong>\n \n
  It is important to make an appointment with your GP to discuss your sleep.\n \n
  Take steps to improve your sleep habits also known as "sleep hygiene". There is plenty of information available online regarding this.\n \n
  <a target="_blank" href="https://www.sleepfoundation.org/sleep-hygiene">www.sleepfoundation.org/sleep-hygiene\n \n</a>
  <a target="_blank" href="https://www.healthinfo.org.nz/patientinfo/46895.pdf">www.healthinfo.org.nz\n \n</a>
  Think about your priorities around factors affecting your sleep eg. shift work, early morning workouts, late nights out.\n \n
  Address any obvious reasons that may be affecting your sleep eg. financial difficulties, stress.
  `,
  APM720: `
  <strong>What does your score mean?</strong>\n \n
  Your score indicates you are not getting adequate sleep. You need to look at ways to improve the quality and quantity of your sleep so your health, wellbeing and safety can improve.\n \n
  <strong>Why is this important?</strong>\n \n
  A common myth is that people can learn to get by on little sleep with no negative effects. However, research shows that getting enough quality sleep at the right times is vital for mental health, physical health and quality of life.\n \n
  Achieving good quality sleep maintains your health and therefore the safety of you and others in the home and workplace. If you drive a car or operate machinery while sleep deprived you could be putting yourself and others at risk. A lack of sleep can harm your driving ability as much as being drunk.\n \n
  As a result, sleep deficiency is not only harmful on a personal level, but it also can cause large-scale damage and tragic accidents.\n \n
  <strong>What can I do about it?</strong>\n \n
  Make an appointment with your GP to discuss your sleep if it is impacting on your quality of life, the symptoms are chronic or there are other concerns.\n \n
  Take steps to improve your sleep habits also known as "sleep hygiene". There is plenty of information available online regarding this.\n \n
  <a target="_blank" href="https://www.sleepfoundation.org/sleep-hygiene">www.sleepfoundation.org/sleep-hygiene\n \n</a>
  <a target="_blank" href="https://www.healthinfo.org.nz/patientinfo/46895.pdf">www.healthinfo.org.nz\n \n</a>
  Think about your priorities around factors affecting your sleep eg. shift work, early mornings workouts, late nights out. \n \n
  Address any obvious reasons that may be affecting sleep e.g. financial difficulties, stress
  `,
  APM710: `
  <strong>What does your score mean?</strong>\n \n
  Great news! Your score indicates you are getting plenty of quality sleep to ensure a healthy and productive life.\n \n
  <strong>Why is this important?</strong>\n \n
  A common myth is that people can learn to get by on little sleep with no negative effects. However, research shows that getting enough quality sleep at the right times is vital for mental health, physical health, quality of life and safety.\n \n
  Your current sleep quality and habits will be helping to protect your health and safety.\n \n
  <strong>What can I do about it?</strong>\n \n
  Enjoy all the great sleep you are getting!\n \n
  An occasional night of poor, broken or even non-existent sleep may still happen and you may well feel tired and irritable. But it won’t harm your health in the long term.\n \n
  If broken or troubled sleep starts to happen regularly it's important to address.
  `,
  ACM830: `
  <ul>
    <li>Refer individuals to their GP or FIt for Duty for an assessment.</li>
    <li>Employer MUST be informed there is a safety risk if employee is in a safety critical role eg machinery</li>
    <li>The public system is accessed via the individual’s GP.</li>
    <li>The private system is accessed via Fit for Duty or another sleep specialist preferred by the individual.</li>
    <li>Depending on the symptoms and the urgency for treatment either a public or private health system could be accessed.</li>
    <li>Refer to pros and costs list of each on iPad</li>
  </ul>
  `,
  ACM820: `
  Refer individuals to their GP for an assessment.\n \n
  Reminder of lifestyle choices that keep the risk of OSA low\n \n
  <ul>
    <li>healthy weight</li>
    <li>smoke free</li>
    <li>abstain or low alcohol intake</li>
    <li>regular exercise</li>
    <li>position of sleeping - on side</li>
  </ul>
  `,
  ACM810: `
  Reminder of lifestyle choices that keep the risk of OSA low\n \n
  <ul>
    <li>healthy weight</li>
    <li>smoke free</li>
    <li>abstain or low alcohol intake</li>
    <li>regular exercise</li>
    <li>position of sleeping - on side</li>
  </ul>
  `,
  APM830: `
  <strong>What does your score mean?</strong>\n \n
  Your score indicates you have symptoms that put you at a high risk of having OSA.\n \n
  <strong>Why is this important?</strong>\n \n
  Sleep plays a vital role to our overall well being. A lack of quality sleep can raise the risks for some chronic health problems like heart disease, diabetes, stroke and being overweight. Getting good sleep also helps your immunity, resilience to stress and your mental health.\n \n
  Achieving good quality sleep maintains your health and therefore the safety of you and others in the home and workplace.\n \n
  if you drive a car or operate machinery you could be putting yourself and others at risk. A lack of sleep can harm your driving ability as much as being drunk.\n \n
  As a result, sleep deficiency is not only harmful on a personal level, but can cause large-scale damage and tragic accidents.\n \n
  <strong>What can I do about it?</strong>\n \n
  It is important that you are assessed further by a health professional.\n \n
  This can be arranged through your GP or a sleep specialist.\n \n
  Fit for Duty specialises in the diagnosis of OSA and can arrange for a home based sleep test to be sent to you for you to be assessed in the comfort of your home and when it suits you.  If a positive result for OSA results they will coordinate a treatment plan with you.\n \n
  <a target="_blank" href="https://www.fitforduty.co.nz/about">www.fitforduty.co.nz/about</a>
  `,
  APM820: `
  <strong>What does your score mean?</strong>\n \n
  Your score shows you have some symptoms of daytime sleepiness and possible risk factors for obstructive sleep apnea (OSA).\n \n
  <strong>Why is this important?</strong>\n \n
  Sleep plays a vital role in keeping our immunity high, particularly important for helping our defences against infections. Lack of quality sleep can raise your risk for some chronic health problems like heart disease, diabetes, stroke and being overweight. Getting good sleep also helps with your resilience to stress and your mental health.\n \n
  Achieving good quality sleep maintains your health and therefore the safety of you and others in the home and workplace.\n \n
  <strong>What can I do about it?</strong>\n \n
  With symptoms of sleep disruption and the potential risks to health and safety, we recommend that you speak to your GP to review your sleepiness and the symptoms suggestive of sleep apnea.
  `,
  APM810: `
  <strong>What does your score mean?</strong>\n \n
  Your score means you have a low risk for obstructive sleep apnea (OSA) and are unlikely to be suffering from this.\n \n
  <strong>Why is this important?</strong>\n \n
  Sleep plays a vital role in keeping our immunity high, particularly important for helping our defences against infections. Lack of quality sleep can raise your risk for some chronic health problems like heart disease, diabetes, stroke and being overweight. Getting good sleep also helps with your resilience to stress and your mental health.\n \n
  Achieving good quality sleep maintains your health and therefore the safety of you and others in the home and workplace.\n \n
  <strong>What can I do about it?</strong>\n \n
  Stay with lifestyle choices that keep the risk of OSA low, ie maintaining a healthy weight and exercising regularly.\n \n
  Drink alcohol moderately, if at all. Quit smoking or remain smoke free.
  `,
  ACM530: `
  <ul>
    <li>If someone is having a really tough time, telling them to be more grateful could be invalidating. Keep this in mind when making your recommendations based off their other lights</li>
    <li>May be worthwhile to check the DASS21 or Life score if concerns regarding significant mental illness.</li>
    <li>Recommend professional help if other mental health issues and areas of concern.</li>
    <li>Reinforce the benefits of a grateful attitude on health and wellbeing.</li>
    <li>If support required suggest websites, books etc</li>
  </ul>
  `,
  ACM520: `
  <ul>
    <li>Reinforce the benefits of a grateful attitude on health and wellbeing.</li>
    <li>If support required suggest websites, books etc</li>
    <li><a target="_blank" href="//mindful.org">www.mindful.org</a></li>
    <li><a target="_blank" href="//healthnavigator.org.nz/healthy-living/g/gratitude/">www.healthnavigator.org.nz</a></li>
  </ul>
  `,
  ACM510: `
  <ul>
    <li>Reinforce the benefits of a grateful attitude on health and wellbeing.</li>
    <li>If support required suggest websites, books etc</li>
    <li><a target="_blank" href="//mindful.org">www.mindful.org</a></li>
    <li><a target="_blank" href="//healthnavigator.org.nz/healthy-living/g/gratitude/">www.healthnavigator.org.nz</a></li>
  </ul>
  `,
  APM530: `
  <strong>What does your score mean?</strong>\n \n
  You may benefit from practising, having or focussing on grateful thoughts. Some people are naturally more grateful than others. But the good news is we can all become more grateful through practice.\n \n
  <strong>Why is this important?</strong>\n \n
  Gratitude has been said to be the best predictor of well being out of any character strength.\n \n
  Grateful people are more likely to have improved self-control, which may help with healthy eating and quitting smoking. Having grateful thoughts on a regular basis may contribute positively to many areas of your life. Gratitude helps people feel more positive emotions, relish good experiences, deal with adversity, and build strong relationships.\n \n
  <strong>What can I do about it?</strong>\n \n
  There are many websites available that describe the benefits of gratitude and how to practice it.\n \n
  <a target="_blank" href="//mindful.org">www.mindful.org\n \n</a>
  <a target="_blank" href="//healthnavigator.org.nz/healthy-living/g/gratitude/">www.healthnavigator.org.nz\n \n</a>
  Books are available through the library, bookshops and online\n \n
  The best way to reap the benefits of gratitude is to notice new things you’re grateful for every day. Gratitude journaling is one way ,but try out creative ways like a gratitude jar. Any time you experience a moment of gratitude, write it on a piece of paper and put it in a jar. On New Year’s Eve, empty the jar and review everything you wrote. 
  `,
  APM520: `
  <strong>What does your score mean?</strong>\n \n
  You may benefit from practising, having or focussing on grateful thoughts. Some people are naturally more grateful than others. But the good news is we can all become more grateful through practice.\n \n
  <strong>Why is this important?</strong>\n \n
  Appreciating the positive aspects of life around you guarantees you a healthier, more rewarding life. Many studies have found that people who count their blessings tend to be happier and have better mental health.\n \n
  Grateful people are more likely to have improved self-control, which may help with healthy eating and quitting smoking. Having grateful thoughts on a regular basis may contribute positively to many areas of your life. Gratitude helps people feel more positive emotions, relish good experiences, deal with adversity, and build strong relationships.\n \n
  <strong>What can I do about it?</strong>\n \n
  There are many websites available that describe the benefits of gratitude and how to practice it.\n \n
  <a target="_blank" href="//mindful.org">www.mindful.org\n \n</a>
  <a target="_blank" href="//healthnavigator.org.nz/healthy-living/g/gratitude/">www.healthnavigator.org.nz\n \n</a>
  Books are available through the library, bookshops and online.\n \n
  The best way to reap the benefits of gratitude is to notice new things you’re grateful for every day. Gratitude journaling is one way, but try out creative ways like a gratitude jar. Any time you experience a moment of gratitude, write it on a piece of paper and put it in a jar. On New Year’s Eve, empty the jar and review everything you wrote. The ways to experience gratitude are endless.
  `,
  APM510: `
  <strong>What does your score mean?</strong>\n \n
  Well done, by regularly feeling grateful you are looking after your wellbeing.\n \n
  <strong>Why is this important?</strong>\n \n
  The benefits of practicing gratitude are nearly endless. People who regularly practice gratitude experience more positive emotions, feel more alive, sleep better, and express more compassion and kindness.\n \n
  <strong>What can I do about it?</strong>\n \n
  Keep up the good work. People who consciously count their blessings tend to be happier and have better mental health.
  `,
  ACM150: `
  Recommend seeking professional help. Doctor/Psychologist\n \n
  Provide resource card\n \n
  Provide details of Healthy Thinking Learning Programme <a target="_blank" href="https://drtom.thinkific.com/">www.drtom.thinkific.com\n \n</a>
  <strong>Overview of Healthy Thinking® programme</strong>\n \n
  <ul>
    <li>Helps you analyse a situation to decide if your emotions are healthy or unhealthy</li>
    <li>Develop and use a Cognitive switch to override unhealthy thoughts and emotions</li>
    <li>Replace an unhealthy attitude with a healthy one</li>
    <li>Reduce unhealthy emotions like stress, anxiety, frustration and anger</li>
    <li>Identify unhealthy attitudes in yourself and others.</li>
	  >li>Improves productivity</li>
  </ul>
  `,
  ACM140: `
  Recommend seeking professional help. Doctor/Psychologist\n \n
  Provide resource card\n \n
  Provide details of Healthy Thinking Learning Programme <a target="_blank" href="https://drtom.thinkific.com/">www.drtom.thinkific.com\n \n</a>
  <strong>Overview of Healthy Thinking® programme</strong>\n \n
  <ul>
    <li>Helps you analyse a situation to decide if your emotions are healthy or unhealthy</li>
    <li>Develop and use a Cognitive switch to override unhealthy thoughts and emotions</li>
    <li>Replace an unhealthy attitude with a healthy one</li>
    <li>Reduce unhealthy emotions like stress, anxiety, frustration and anger</li>
    <li>Identify unhealthy attitudes in yourself and others.</li>
	  <li>Improves productivity</li>
  </ul>
  `,
  ACM130: `
  <ul>
    <li>Provide resource card</li>
    <li>Provide details of the Healthy Thinking Learning Programme. <a target="_blank" href="https://drtom.thinkific.com/">www.drtom.thinkific.com</a></li>
    <li>monitor results using the KYND app every 2 weeks.</li>
    <li>seek professional help if results worsen.</li>
    <li>Provide details of Healthy Thinking Learning Programme and books</li>
  </ul>
  <strong>Overview of Healthy Thinking® programme</strong>\n \n
  Helps you analyse a situation to decide if your emotions are healthy or unhealthy\n \n
  Develop and use a Cognitive switch to override unhealthy thoughts and emotions\n \n
  Replace an unhealthy attitude with a healthy one\n \n
  Reduce unhealthy emotions like stress, anxiety, frustration and anger\n \n
  Identify unhealthy attitudes in yourself and others\n \n
  Improves productivity\n \n
  <a target="_blank" href="https://drtom.thinkific.com/">www.drtom.thinkific.com</a>
  `,
  ACM120: `
  <ul>
    <li>Provide resource card</li>
    <li>Provide details of the Healthy Thinking Learning Programme. <a target="_blank" href="https://drtom.thinkific.com/">www.drtom.thinkific.com</a></li>
    <li>monitor results using the KYND app every 2 weeks.</li>
    <li>seek professional help if results worsen.</li>
    <li>Provide details of Healthy Thinking Learning Programme and books</li>
  </ul>
  <strong>Overview of Healthy Thinking® programme</strong>\n \n
  Helps you analyse a situation to decide if your emotions are healthy or unhealthy\n \n
  Develop and use a Cognitive switch to override unhealthy thoughts and emotions\n \n
  Replace an unhealthy attitude with a healthy one\n \n
  Reduce unhealthy emotions like stress, anxiety, frustration and anger\n \n
  Identify unhealthy attitudes in yourself and others\n \n
  Improves productivity\n \n
  <a target="_blank" href="https://drtom.thinkific.com/">www.drtom.thinkific.com</a>
  `,
  ACM110: `
  <ul>
    <li>Congratulate on their score</li>
    <li>Reinforce the need to regularly update their KYND score monthly to monitor this part of their health</li>
  </ul>
  `,
  APM150: `
  What does your score mean\n \n
  It is likely you spend a large amount of your time in a negative emotional state. You could be having thoughts and emotions that are not helpful to your physical and mental health.\n \n
  <strong>Why is this important?</strong>\n \n
  Strong emotions such as anger, sadness or frustration, arising from stress and unhealthy thoughts can affect the way the body functions in a way that is not helpful. This can affect your heart rate and blood pressure, increase blood sugars level, increase muscle tension and cause hormones like adrenaline and cortisol to spike.\n \n
  <strong>What can I do about it?</strong>\n \n
  We encourage you to seek professional help. You could contact your own doctor, a psychologist or use services provided by your workplace EAP programme.\n \n
  Emotional Index® is a set of tools developed by KYND's founder that can help you work with unhealthy thoughts and enable you to manage your emotions and behaviours more positively. These changes lead to improved emotional health and wellbeing.\n \n
  If you would like to view the tools Dr Tom has created, visit <a target="_blank" href="https://drtom.thinkific.com/">www.drtom.thinkific.com</a>
  `,
  APM140: `
  What does your score mean\n \n
  It is likely you spend a large amount of your time in a negative emotional state. You could be having thoughts and emotions that are not helpful to your physical and mental health.\n \n
  <strong>Why is this important?</strong>\n \n
  Strong emotions such as anger, sadness or frustration, arising from stress and unhealthy thoughts can affect the way the body functions in a way that is not helpful. This can affect your heart rate and blood pressure, increase blood sugars level, increase muscle tension and cause hormones like adrenaline and cortisol to spike.\n \n
  <strong>What can I do about it?</strong>\n \n
  We encourage you to seek professional help. You could contact your own doctor, a psychologist or use services provided by your workplace EAP programme.\n \n
  Emotional Index® is a set of tools developed by KYND's founder that can help you work with unhealthy thoughts and enable you to manage your emotions and behaviours more positively. These changes lead to improved emotional health and wellbeing.\n \n
  If you would like to view the tools Dr Tom has created, visit <a target="_blank" href="https://drtom.thinkific.com/">www.drtom.thinkific.com</a>
  `,
  APM130: `
  <strong>What does your score mean?</strong>\n \n
  This score indicates for a good part of your day you're aware of your thoughts and emotions. You can deal with them whether they are positive or negative and can manage them in a helpful way. However an orange light indicates perhaps some of the day you might struggle to deal with your emotions. This is not unusual at all! And there are a lot of things we can do to improve this.\n \n
  <strong>Why is this important?</strong>\n \n
  Some people can spend about 30 percent of their day in negative emotional states, this places unnecessary stress on the body.\n \n
  Strong emotions such as anger, sadness or frustration, arising from stress and unhealthy thoughts can affect the way the body functions in a way that is not helpful. This can affect your heart rate and blood pressure, increase blood sugars level, increase muscle tension and cause hormones like adrenaline and cortisol to spike.\n \n
  <strong>What can I do about it?</strong>\n \n
  It is possible to change your thoughts to being more consistently helpful to you. It requires more awareness around your thoughts and emotions.\n \n
  Emotional Index® is a set of tools developed by KYND's founder that can help you work with unhealthy thoughts and enable you to manage your emotions and behaviours more positively. These changes lead to improved emotional health and wellbeing.\n \n
  If you would like to view the tools Dr Tom has created, visit <a target="_blank" href="https://drtom.thinkific.com/">www.drtom.thinkific.com</a>
  `,
  APM120: `
  <strong>What does your score mean?</strong>\n \n
  This score indicates for a good part of your day you're aware of your thoughts and emotions. You can deal with them whether they are positive or negative and can manage them in a helpful way. However an orange light indicates perhaps some of the day you might struggle to deal with your emotions. This is not unusual at all! And there are a lot of things we can do to improve this.\n \n
  <strong>Why is this important?</strong>\n \n
  Some people can spend about 30 percent of their day in negative emotional states, this places unnecessary stress on the body.\n \n
  Strong emotions such as anger, sadness or frustration, arising from stress and unhealthy thoughts can affect the way the body functions in a way that is not helpful. This can affect your heart rate and blood pressure, increase blood sugars level, increase muscle tension and cause hormones like adrenaline and cortisol to spike.\n \n
  <strong>What can I do about it?</strong>\n \n
  It is possible to change your thoughts to being more consistently helpful to you. It requires more awareness around your thoughts and emotions.\n \n
  Emotional Index® is a set of tools developed by KYND's founder that can help you work with unhealthy thoughts and enable you to manage your emotions and behaviours more positively. These changes lead to improved emotional health and wellbeing.\n \n
  If you would like to view the tools Dr Tom has created, visit <a target="_blank" href="https://drtom.thinkific.com/">www.drtom.thinkific.com</a>
  `,
  APM110: `
  <strong>What does your score mean?</strong>\n \n
  Nice work! Your score suggests you are a Healthy Thinker.\n \n
  <strong>Why is this important?</strong>\n \n
  Emotional health is an important part of overall health. People who practice healthy thinking are more frequently in control of their thoughts, feelings, and behaviours. They’re likely more able to cope with life’s challenges.\n \n
  Being emotionally healthy doesn’t mean you’re happy all the time. It means you’re aware of your emotions. You can deal with them, whether they’re positive or negative. Emotionally healthy people still feel stress, anger, and sadness. But they know how to manage their negative feelings better.\n \n
  <strong>What can I do about it?</strong>\n \n
  Keep up the Healthy Thoughts!\n \n
  If you would like to view the tools Dr Tom has created, visit <a target="_blank" href="https://drtom.thinkific.com/">www.drtom.thinkific.com</a> to better understand this process and help yourself to help others.\n \n
  `,
  ACM210: `
  <ul>
    <li>No action required</li>
    <li>Monitor mental health via KYND app repeat monthly</li>
  </ul>
  `,
  ACM220: `
  <ul>
    <li>Monitor mental health via KYND app repeat every 1 -2 weeks</li>
    <li>Provide Resource Card</li>
    <li>Suggest sharing how they are feeling with supportive friends and family</li>
    <li>Reinforce need for professional help if symptoms persist or worsen</li>
    <li>Direct to helplines below</li>
    <li>Mental Health Organisation Free call or text 1737 any time for support from a trained counsellor</li>
    <li>Lifeline - 0800 543 354 (0800 LIFELINE) or free text 4357 (HELP)</li>
    <li>Depression Helpline - 0800 111 757 or free text 4202 (to talk to a trained counsellor about how you are feeling or to ask any questions)</li>
    <li><a target="_blank" href="https://depression.org.nz">www.depression.org.nz</a></li>
    <li>Suicide Crisis Helpline – 0508 828 865 (0508 TAUTOKO)</li>
  </ul>
  `,
  ACM230: `
  <ul>
    <li>Monitor mental health via KYND app repeat every 1 -2 weeks</li>
    <li>Provide Resource Card</li>
    <li>Suggest sharing how they are feeling with supportive friends and family</li>
    <li>Reinforce need for professional help if symptoms persist or worsen</li>
    <li>Direct to helplines below</li>
    <li>Mental Health Organisation Free call or text 1737 any time for support from a trained counsellor</li>
    <li>Lifeline - 0800 543 354 (0800 LIFELINE) or free text 4357 (HELP)</li>
    <li>Depression Helpline - 0800 111 757 or free text 4202 (to talk to a trained counsellor about how you are feeling or to ask any questions)</li>
    <li><a target="_blank" href="https://depression.org.nz">www.depression.org.nz</a></li>
    <li>Suicide Crisis Helpline – 0508 828 865 (0508 TAUTOKO)</li>
  </ul>
  `,
  ACM240: `
  <ul>
    <li>Monitor mental health via KYND app repeat every 1 -2 weeks</li>
    <li>Provide Resource Card</li>
    <li>Suggest sharing how they are feeling with supportive friends and family</li>
    <li>Reinforce need for professional help if symptoms persist or worsen</li>
    <li>Direct to helplines below</li>
    <li>Mental Health Organisation Free call or text 1737 any time for support from a trained counsellor</li>
    <li>Lifeline - 0800 543 354 (0800 LIFELINE) or free text 4357 (HELP)</li>
    <li>Depression Helpline - 0800 111 757 or free text 4202 (to talk to a trained counsellor about how you are feeling or to ask any questions)</li>
    <li><a target="_blank" href="https://depression.org.nz">www.depression.org.nz</a></li>
    <li>Suicide Crisis Helpline – 0508 828 865 (0508 TAUTOKO)</li>
  </ul>
  `,
  ACM250: `
  <ul>
    <li>Monitor mental health via KYND app repeat every 1 -2 weeks</li>
    <li>Provide Resource Card</li>
    <li>Suggest sharing how they are feeling with supportive friends and family</li>
    <li>Reinforce need for professional help if symptoms persist or worsen</li>
    <li>Direct to helplines below</li>
    <li>Mental Health Organisation Free call or text 1737 any time for support from a trained counsellor</li>
    <li>Lifeline - 0800 543 354 (0800 LIFELINE) or free text 4357 (HELP)</li>
    <li>Depression Helpline - 0800 111 757 or free text 4202 (to talk to a trained counsellor about how you are feeling or to ask any questions)</li>
    <li><a target="_blank" href="https://depression.org.nz">www.depression.org.nz</a></li>
    <li>Suicide Crisis Helpline – 0508 828 865 (0508 TAUTOKO)</li>
  </ul>
  `,
  APM210: `
  <strong>What does your score mean?</strong>\n \n
  While it's normal to have good days and bad, ups and downs, your score means you aren't showing signs or symptoms of suffering from depression.\n \n
  <strong>Why is this important?</strong>\n \n
  Depression can seriously affect people's lives. Symptoms can be severe enough to cause noticeable problems in day-to-day activities, such as work, school, social activities or relationships with others.\n \n
  While you may not be suffering from depression, chances are you will know someone who is.\n \n
  <strong>What can I do about it?</strong>\n \n
  It is important to appreciate your good mental health, enjoy it and continue to nurture it.\n \n
  Be knowledgeable about depression and continue to monitor your mental health using the KYND app. Keep an eye on friends and family for symptoms of depression and be ready to lend support if necessary.\n \n
  <a target="_blank" href="https://depression.org.nz">www.depression.org.nz\n \n</a>
  It’s important to bear in mind that our mental health is fluid, and can change over the course of time. That’s why regularly updating your KYND score is so important.
  `,
  APM220: `
  <strong>What does your score mean?</strong>\n \n
  While it's normal to have good days and bad, ups and downs, your score suggests you are showing some signs or symptoms of poor mental health and/or depression. The good news about depression is that it is highly treatable with the right support.\n \n
  Some symptoms of depression include a persistent low mood, a loss of interest in things you once enjoyed, feelings of worthlessness, poor concentration and thoughts of harming yourself or taking your life.\n \n
  Fatigue and lack of energy, trouble sleeping or sleeping too much, changes in appetite and weight are also common.\n \n
  <strong>Why is this important?</strong>\n \n
  Depression can seriously affect people's lives. Symptoms can be severe enough to cause noticeable problems in day-to-day activities, such as work, school, social activities or relationships with others.\n \n
  It is important to monitor your mood for persistent or severe symptoms of depression.\n \n
  It is important to address these signs and symptoms if they persist or worsen and seek professional help.\n \n
  <strong>What can I do about it?</strong>\n \n
  Update your KYND MIND score weekly and if your symptoms persist or get worse, consult your doctor or a psychologist to get some professional help.\n \n
  Things you can do in the meantime to support your mental health include getting enough exercise, reaching out to your friends and family and making your quality of sleep a priority.\n \n
  Resources you can use for support include:\n \n
  Mental Health Organisation Free call or text 1737 any time for support from a trained counsellor\n \n
  Lifeline - 0800 543 354 (0800 LIFELINE) or free text 4357 (HELP)\n \n
  Depression Helpline - 0800 111 757 or free text 4202 (to talk to a trained counsellor about how you are feeling or to ask any questions)\n \n
  <a target="_blank" href="https://depression.org.nz">www.depression.org.nz\n \n</a>
  Suicide Crisis Helpline – 0508 828 865 (0508 TAUTOKO)
  `,
  APM230: `
  <strong>What does your score mean?</strong>\n \n
  While it's normal to have good days and bad, ups and downs, your score suggests you are showing some signs or symptoms of poor mental health and/or depression. The good news about depression is that it is highly treatable with the right support.\n \n
  Some symptoms of depression include a persistent low mood, a loss of interest in things you once enjoyed, feelings of worthlessness, poor concentration and thoughts of harming yourself or taking your life.\n \n
  Fatigue and lack of energy, trouble sleeping or sleeping too much, changes in appetite and weight are also common.\n \n
  <strong>Why is this important?</strong>\n \n
  Depression can seriously affect people's lives. Symptoms can be severe enough to cause noticeable problems in day-to-day activities, such as work, school, social activities or relationships with others.\n \n
  It is important to monitor your mood for persistent or severe symptoms of depression.\n \n
  It is important to address these signs and symptoms if they persist or worsen and seek professional help.\n \n
  <strong>What can I do about it?</strong>\n \n
  Update your KYND MIND score weekly and if your symptoms persist or get worse, consult your doctor or a psychologist to get some professional help.\n \n
  Things you can do in the meantime to support your mental health include getting enough exercise, reaching out to your friends and family and making your quality of sleep a priority.\n \n
  Resources you can use for support include:\n \n
  Mental Health Organisation Free call or text 1737 any time for support from a trained counsellor\n \n
  Lifeline - 0800 543 354 (0800 LIFELINE) or free text 4357 (HELP)\n \n
  Depression Helpline - 0800 111 757 or free text 4202 (to talk to a trained counsellor about how you are feeling or to ask any questions)\n \n
  <a target="_blank" href="https://depression.org.nz">www.depression.org.nz\n \n</a>
  Suicide Crisis Helpline – 0508 828 865 (0508 TAUTOKO)
  `,
  APM240: `
  <strong>What does your score mean?</strong>\n \n
  Your score suggests you are showing signs and symptoms of poor mental health.\n \n
  It is likely you could be suffering from depression.\n \n
  Some symptoms of depression include: a persistent low mood, a loss of interest in things you once enjoyed, feelings of worthlessness, poor concentration and thoughts of harming yourself or taking your life.\n \n
  Fatigue and lack of energy, trouble sleeping or sleeping too much, changes in appetite and weight are also common. The good news about depression is that it is highly treatable with the right support.\n \n
  <strong>Why is this important?</strong>\n \n
  Depression can seriously affect people's lives. Symptoms can be severe enough to cause noticeable problems in day-to-day activities, such as work, school, social activities or relationships with others.\n \n
  It is important to monitor your mood for persistent or severe symptoms of depression.\n \n
  It is important to address these signs and symptoms and seek professional help.\n \n
  <strong>What can I do about it?</strong>\n \n
  It is important to consult your doctor or a psychologist to get some professional help with your symptoms. Visiting your GP is a great way to begin this process if you are not familiar with the resources available to you for mental health.\n \n
  Things you can do in the meantime include increasing your exercise, reaching out to friends and family and making your quality of sleep a priority.\n \n
  Resources for support include:\n \n
  Mental Health Organisation Free call or text 1737 any time for support from a trained counsellor\n \n
  Lifeline - 0800 543 354 (0800 LIFELINE) or free text 4357 (HELP)\n \n
  Depression Helpline - 0800 111 757 or free text 4202 (to talk to a trained counsellor about how you are feeling or to ask any questions)\n \n
  <a target="_blank" href="https://depression.org.nz">www.depression.org.nz\n \n</a>
  Suicide Crisis Helpline – 0508 828 865 (0508 TAUTOKO)
  `,
  APM250: `
  <strong>What does your score mean?</strong>\n \n
  Your score suggests you are showing signs and symptoms of poor mental health.\n \n
  It is likely you could be suffering from depression.\n \n
  Some symptoms of depression include: a persistent low mood, a loss of interest in things you once enjoyed, feelings of worthlessness, poor concentration and thoughts of harming yourself or taking your life.\n \n
  Fatigue and lack of energy, trouble sleeping or sleeping too much, changes in appetite and weight are also common. The good news about depression is that it is highly treatable with the right support.\n \n
  <strong>Why is this important?</strong>\n \n
  Depression can seriously affect people's lives. Symptoms can be severe enough to cause noticeable problems in day-to-day activities, such as work, school, social activities or relationships with others.\n \n
  It is important to monitor your mood for persistent or severe symptoms of depression.\n \n
  It is important to address these signs and symptoms and seek professional help.\n \n
  <strong>What can I do about it?</strong>\n \n
  It is important to consult your doctor or a psychologist to get some professional help with your symptoms. Visiting your GP is a great way to begin this process if you are not familiar with the resources available to you for mental health.\n \n
  Things you can do in the meantime include increasing your exercise, reaching out to friends and family and making your quality of sleep a priority.\n \n
  Resources for support include:\n \n
  Mental Health Organisation Free call or text 1737 any time for support from a trained counsellor\n \n
  Lifeline - 0800 543 354 (0800 LIFELINE) or free text 4357 (HELP)\n \n
  Depression Helpline - 0800 111 757 or free text 4202 (to talk to a trained counsellor about how you are feeling or to ask any questions)\n \n
  <a target="_blank" href="https://depression.org.nz">www.depression.org.nz\n \n</a>
  Suicide Crisis Helpline – 0508 828 865 (0508 TAUTOKO)
  `,
  ACM310: `
  Reinforce monitoring of mental health via the KYND app every month.
  `,
  ACM320: `
  <ul>
    <li>Reinforce the need for professional help if their symptoms are interfering with day to day life.</li>
    <li>Check for other red lights in mental health to get an idea of the magnitude of the problem</li>
    <li>Provide resource card.</li>
    <li>Refer to lifelines/blog material</li>
    <li>Reinforce monitoring of mental health via KYND app every 1- 2 weeks.\n</li>
  </ul>
  `,
  ACM330: `
  <ul>
    <li>Reinforce the need for professional help if their symptoms are interfering with day to day life.</li>
    <li>Check for other red lights in mental health to get an idea of the magnitude of the problem</li>
    <li>Provide resource card.</li>
    <li>Refer to lifelines/blog material</li>
    <li>Reinforce monitoring of mental health via KYND app every 1- 2 weeks.\n</li>
  </ul>
  `,
  ACM340: `
  <ul>
    <li>Reinforce need for professional help if interfering with day to day life</li>
    <li>Check for other red lights in mental health to get magnitude of problem</li>
    <li>Provide Resource card</li>
    <li>Refer to websites/blog material</li>
    <li>Reinforce monitoring of mental health via the KYND app every 1-2 weeks</li>
  </ul>
  `,
  ACM350: `
  <ul>
    <li>Reinforce need for professional help if interfering with day to day life</li>
    <li>Check for other red lights in mental health to get magnitude of problem</li>
    <li>Provide Resource card</li>
    <li>Refer to websites/blog material</li>
    <li>Reinforce monitoring of mental health via the KYND app every 1-2 weeks</li>
  </ul>
  `,
  APM310: `
  <strong>What does your score mean?</strong>\n \n
  Great news! Your score suggests you aren't experiencing anxiety that is interfering with your day-to-day life at this point in time.\n \n
  <strong>Why is this important?</strong>\n \n
  Any anxiety that you experience is most likely short lived and appropriate to the situation. It likely isn't impacting on your relationships with others, your self-confidence or your ability to complete daily tasks at work or school.\n \n
  <strong>What can I do about it?</strong>\n \n
  Appreciate your current mental health, but it is important to keep monitoring it using the KYND app. Remember that mental health is fluid, and can fluctuate at any time.
  `,
  APM320: `
  <strong>What does your score mean?</strong>\n \n
  Your score suggests anxiety could be having an impact on your wellbeing and day to day life.\n \n
  <strong>Why is this important?</strong>\n \n
  Your relationships with others, your self-confidence or your ability to complete daily tasks may be affected if you are experiencing levels of anxiety above those which are considered helpful.\n \n
  <strong>What can I do about it?</strong>\n \n
  It would be helpful to develop a better understanding of anxiety and discuss this and how you are feeling with others. This should include your doctor if necessary.\n \n
  Learn to accept and tolerate normal anxiety and know when yours isn't.\n \n
  Consider trying techniques to help you cope with anxiety, like breathing exercises. A great tool you can begin to practice immediately is box breathing. This is when you essentially draw a box in your mind with your breath. You breathe in for four seconds (one line) hold for four seconds (another line) breathe out for four seconds (third line) and hold for four seconds (final line to draw your mental box!). This helps us slow our breathing and heart rate, and can be a great tool for dealing with everyday anxiety that we can do in any situation without anyone even noticing!\n \n
  <a target="_blank" href="//www.healthline.com/health/box-breathing">www.healthline.com\n \n</a>
  If anxiety is interfering with your day to day life it is important to get professional support from your doctor or a psychologist.\n \n
  <a target="_blank" href="//www.anxiety.org.nz">www.anxiety.org.nz\n \n</a>
  <a target="_blank" href="//www.depression.org.nz/is-it-depression-anxiety/anxiety/">www.depression.org.nz</a>
  `,
  APM330: `
  <strong>What does your score mean?</strong>\n \n
  Your score suggests anxiety could be having an impact on your wellbeing and day to day life.\n \n
  <strong>Why is this important?</strong>\n \n
  Your relationships with others, your self-confidence or your ability to complete daily tasks may be affected if you are experiencing levels of anxiety above those which are considered helpful.\n \n
  <strong>What can I do about it?</strong>\n \n
  It would be helpful to develop a better understanding of anxiety and discuss this and how you are feeling with others. This should include your doctor if necessary.\n \n
  Learn to accept and tolerate normal anxiety and know when yours isn't.\n \n
  Consider trying techniques to help you cope with anxiety, like breathing exercises. A great tool you can begin to practice immediately is box breathing. This is when you essentially draw a box in your mind with your breath. You breathe in for four seconds (one line) hold for four seconds (another line) breathe out for four seconds (third line) and hold for four seconds (final line to draw your mental box!). This helps us slow our breathing and heart rate, and can be a great tool for dealing with everyday anxiety that we can do in any situation without anyone even noticing!\n \n
  <a target="_blank" href="//www.healthline.com/health/box-breathing">www.healthline.com\n \n</a>
  If anxiety is interfering with your day to day life it is important to get professional support from your doctor or a psychologist.\n \n
  <a target="_blank" href="//www.anxiety.org.nz">www.anxiety.org.nz\n \n</a>
  <a target="_blank" href="//www.depression.org.nz/is-it-depression-anxiety/anxiety/">www.depression.org.nz</a>
  `,
  APM340: `
  <strong>What does your score mean?</strong>\n \n
  Your score suggests anxiety could be having a significant impact on your wellbeing and day to day life.\n \n
  <strong>Why is this important?</strong>\n \n
  Anxiety could be causing a significant impact on your day to day life including your relationships with others, your self-confidence or your ability to complete daily tasks.\n \n
  <strong>What can I do about it?</strong>\n \n
  If anxiety is interfering with your day to day life it is important to get professional support from your doctor or a psychologist.\n \n
  It may be helpful to develop a better understanding of anxiety and discuss this and how you are feeling with others.\n \n
  Refer to helpful websites below\n \n
  <a target="_blank" href="//www.anxiety.org.nz">www.anxiety.org.nz\n \n</a>
  <a target="_blank" href="//www.depression.org.nz/is-it-depression-anxiety/anxiety/">www.depression.org.nz\n \n</a>
  Learn to accept and tolerate normal anxiety and know when yours isn't.\n \n
  Consider trying techniques to help you cope with anxiety, like breathing exercises. See the link on this below.\n \n
  <a target="_blank" href="//www.healthline.com/health/box-breathing">www.healthline.com</a>
  `,
  APM350: `
  <strong>What does your score mean?</strong>\n \n
  Your score suggests anxiety could be having a significant impact on your wellbeing and day to day life.\n \n
  <strong>Why is this important?</strong>\n \n
  Anxiety could be causing a significant impact on your day to day life including your relationships with others, your self-confidence or your ability to complete daily tasks.\n \n
  <strong>What can I do about it?</strong>\n \n
  If anxiety is interfering with your day to day life it is important to get professional support from your doctor or a psychologist.\n \n
  It may be helpful to develop a better understanding of anxiety and discuss this and how you are feeling with others.\n \n
  Refer to helpful websites below\n \n
  <a target="_blank" href="//www.anxiety.org.nz">www.anxiety.org.nz\n \n</a>
  <a target="_blank" href="//www.depression.org.nz/is-it-depression-anxiety/anxiety/">www.depression.org.nz\n \n</a>
  Learn to accept and tolerate normal anxiety and know when yours isn't.\n \n
  Consider trying techniques to help you cope with anxiety, like breathing exercises. See the link on this below.\n \n
  <a target="_blank" href="//www.healthline.com/health/box-breathing">www.healthline.com</a>
  `,
  ACM410: `
  <ul>
    <li>Continue to use the KYND app to monitor stress levels every 4 weeks.</li>
  </ul>
  `,
  ACM420: `
  <ul>
    <li>Current stress levels may be acute and about a particular reason - provide support to address this</li>
    <li>Suggest websites/blogs for dealing with stress</li>
    <li>Lifestyle changes</li>
    <li>Addressing cause of stressors</li>
    <li>Learning new tools to cope with stress</li>
    <li>Advise when to seek professional help</li>
  </ul>
  `,
  ACM430: `
  <ul>
    <li>Current stress levels may be acute and about a particular reason - provide support to address this</li>
    <li>Suggest websites/blogs for dealing with stress</li>
    <li>Lifestyle changes</li>
    <li>Addressing cause of stressors</li>
    <li>Learning new tools to cope with stress</li>
    <li>Advise when to seek professional help</li>
  </ul>
  `,
  ACM440: `
  <ul>
    <li>Check - current stress levels may be acute and about a particular reason - provide support to address this</li>
    <li>Suggest websites/blogs for dealing with stress</li>
    <li>Lifestyle changes</li>
    <li>Addressing cause of stressors</li>
    <li>Learning new tools to cope with stress</li>
    <li>Advise when to seek professional help</li>
  </ul>
  `,
  ACM450: `
  <ul>
    <li>Check - current stress levels may be acute and about a particular reason - provide support to address this</li>
    <li>Suggest websites/blogs for dealing with stress</li>
    <li>Lifestyle changes</li>
    <li>Addressing cause of stressors</li>
    <li>Learning new tools to cope with stress</li>
    <li>Advise when to seek professional help</li>
  </ul>
  `,
  APM410: `
  <strong>What does your score mean?</strong>\n \n
  This is a great result and suggests you are not under any long term stress.\n \n
  <strong>Why is this important?</strong>\n \n
  Not all stress is bad. Some amounts of stress are good to push you to high levels of alertness and performance, but long-term stress can lead to health problems.\n \n
  The fact you are preventing and managing long-term stress lowers your risk for conditions like heart disease, obesity, high blood pressure, and depression.\n \n
  <strong>What can I do about it?</strong>\n \n
  Continue to enjoy and appreciate your life by staying on top of stress.\n \n
  It's a good idea to monitor your stress levels using the KYND app at least every 4 weeks to ensure you are keeping levels of stress acceptable and at a manageable level.
  `,
  APM420: `
  <strong>What does your score mean?</strong>\n \n
  This score is a warning light that you may be experiencing levels of stress that could impact on your health and wellbeing.\n \n
  <strong>Why is this important?</strong>\n \n
  Not all stress is bad. Some amounts of stress are good to push you to high levels of alertness and performance.  If we never got a little stressed about things such as deadlines, it’s likely we’d never get anything done! It's long-term or chronic stress that can lead to health problems affecting your body, your thoughts and feelings and your behaviour. Being able to recognise common stress symptoms can help you manage them.\n \n
  Stress that's left unchecked can contribute to many health problems, such as high blood pressure, heart disease, obesity and diabetes.\n \n
  <strong>What can I do about it?</strong>\n \n
  Sometimes we can feel stress is unavoidable when we are facing challenging situations in home or work life, however there are tools we can use to manage our stress so it is productive rather than harmful.\n \n
  Many lifestyle changes can improve the way we cope with stress.\n \n
  <a target="_blank" href="https://www.healthline.com/nutrition/16-ways-relieve-stress-anxiety">www.healthline.com/nutrition\n \n</a>
  <a target="_blank" href="https://www.healthnavigator.org.nz/health-a-z/s/stress/">www.healthnavigator.org.nz\n \n</a>
  If you're not sure if stress is the cause or if you've taken steps to control your stress but your symptoms continue, you should see your doctor. Your healthcare provider may want to check for other potential causes.\n \n
  You consider seeing a professional counsellor or therapist, who can help you identify sources of your stress and learn new coping tools.
  `,
  APM430: `
  <strong>What does your score mean?</strong>\n \n
  This score is a warning light that you may be experiencing levels of stress that could impact on your health and wellbeing.\n \n
  <strong>Why is this important?</strong>\n \n
  Not all stress is bad. Some amounts of stress are good to push you to high levels of alertness and performance.  If we never got a little stressed about things such as deadlines, it’s likely we’d never get anything done! It's long-term or chronic stress that can lead to health problems affecting your body, your thoughts and feelings and your behaviour. Being able to recognise common stress symptoms can help you manage them.\n \n
  Stress that's left unchecked can contribute to many health problems, such as high blood pressure, heart disease, obesity and diabetes.\n \n
  <strong>What can I do about it?</strong>\n \n
  Sometimes we can feel stress is unavoidable when we are facing challenging situations in home or work life, however there are tools we can use to manage our stress so it is productive rather than harmful.\n \n
  Many lifestyle changes can improve the way we cope with stress.\n \n
  <a target="_blank" href="https://www.healthline.com/nutrition/16-ways-relieve-stress-anxiety">www.healthline.com/nutrition\n \n</a>
  <a target="_blank" href="https://www.healthnavigator.org.nz/health-a-z/s/stress/">www.healthnavigator.org.nz\n \n</a>
  If you're not sure if stress is the cause or if you've taken steps to control your stress but your symptoms continue, you should see your doctor. Your healthcare provider may want to check for other potential causes.\n \n
  You consider seeing a professional counsellor or therapist, who can help you identify sources of your stress and learn new coping tools.
  `,
  APM440: `
  <strong>What does your score mean?</strong>\n \n
  You are likely experiencing high levels of stress that will be impacting on your health and wellbeing.\n \n
  <strong>Why is this important?</strong>\n \n
  Not all stress is bad. Some amounts of stress are good to push you to high levels of alertness and performance.\n \n
  It's long-term or chronic stress that can lead to health problems affecting your body, your thoughts and feelings and your behaviour. Being able to recognize common stress symptoms can help you manage them.\n \n
  Stress that's left unchecked can contribute to many health problems, such as high blood pressure, heart disease, obesity and diabetes.\n \n
  <strong>What can I do about it?</strong>\n \n
  Sometimes we can feel stress is unavoidable when we are facing challenging situations in home or work life, however there are tools we can use to manage our stress so it is productive rather than harmful.\n \n
  Many lifestyle changes can improve the way we cope with stress.\n \n
  <a target="_blank" href="https://www.healthline.com/nutrition/16-ways-relieve-stress-anxiety">www.healthline.com/nutrition\n \n</a>
  <a target="_blank" href="https://www.healthnavigator.org.nz/health-a-z/s/stress/">www.healthnavigator.org.nz\n \n</a>
  If you're not sure if stress is the cause or if you've taken steps to control your stress but your symptoms continue, you should see your doctor. Your healthcare provider may want to check for other potential causes.\n \n
  You consider seeing a professional counsellor or therapist, who can help you identify sources of your stress and learn new coping tools.
  `,
  APM450: `
  <strong>What does your score mean?</strong>\n \n
  You are likely experiencing high levels of stress that will be impacting on your health and wellbeing.\n \n
  <strong>Why is this important?</strong>\n \n
  Not all stress is bad. Some amounts of stress are good to push you to high levels of alertness and performance.\n \n
  It's long-term or chronic stress that can lead to health problems affecting your body, your thoughts and feelings and your behaviour. Being able to recognize common stress symptoms can help you manage them.\n \n
  Stress that's left unchecked can contribute to many health problems, such as high blood pressure, heart disease, obesity and diabetes.\n \n
  <strong>What can I do about it?</strong>\n \n
  Sometimes we can feel stress is unavoidable when we are facing challenging situations in home or work life, however there are tools we can use to manage our stress so it is productive rather than harmful.\n \n
  Many lifestyle changes can improve the way we cope with stress.\n \n
  <a target="_blank" href="https://www.healthline.com/nutrition/16-ways-relieve-stress-anxiety">www.healthline.com/nutrition\n \n</a>
  <a target="_blank" href="https://www.healthnavigator.org.nz/health-a-z/s/stress/">www.healthnavigator.org.nz\n \n</a>
  If you're not sure if stress is the cause or if you've taken steps to control your stress but your symptoms continue, you should see your doctor. Your healthcare provider may want to check for other potential causes.\n \n
  You consider seeing a professional counsellor or therapist, who can help you identify sources of your stress and learn new coping tools.
  `,
  ACB210: `
  <ul>
    <li>Encourage an attempt at quitting again. Multiple attempts are part of the process.</li>
    <li>QUITLINE <a target="_blank" href="//www.quit.org.nz">www.quit.org.nz</a> 0800 778 778 This has amazing resources and support NRT products available. Using the support of QUITLINE significantly increases the chance of success.</li>
    <li>All pharmacies are able to prescribe subsidised products to help quit smoking ie patches, gum, lozenges. $5/product/3months supply. </li>
    <li>GP's can prescribe a range of medications that help people to quit smoking.</li>
    <li>MOH current position on vaping Not risk free but can help people quit smoking, less harmful than smoking.</li>
    <li>Vaping is not harmless – don’t start if you don't smoke</li>
    <li>Vaping is less harmful than smoking - only vape to quit</li>
    <li>The difference between smoking and vaping is that smoking delivers nicotine by burning tobacco, which can cause smoking-related illnesses, and vaping can deliver nicotine by heating a liquid in a much less harmful way.</li>
  </ul>
  `,
  ACB220: `
  <ul>
    <li>Note that cravings have been recorded for up to two years after quitting so abstinence does require constant vigilance. QUITLINE can be used for support in this phase.</li>
    <li>QUITLINE <a target="_blank" href="//www.quit.org.nz">www.quit.org.nz</a> 0800 778 778 All pharmacies are able to prescribe subsidised products to help quit smoking ie patches, gum, lozenges. $5/3 months supply</li>
  </ul>
  `,
  ACB230: `
  Congratulate if a previous smoker and now > 1 year smoke free
  `,
  ACB240: `
  Congratulate if a previous smoker and now > 1 year smoke free
  `,
  APB210: `
  <strong>What does your score mean?</strong>\n \n
  Smoking costs people their lives, their health, and time with their families. It also costs people socially and financially. The health risks associated with vaping are still unknown.  By attempting to quit smoking or vaping you have the chance to improve and benefit your life and the lives of those closest to you every day.\n \n
  <strong>Why is this important?</strong>\n \n
  Smoking and vaping can harm your body in many ways. The quantity and quality of your life is known to be seriously impacted by smoking. The reality being less precious time to spend with your family, your children, grandchildren and extended whanau. Every cigarette you don't smoke will save you money, time and give you more memories with loved ones.  If you vape, although health risks are assumed to be less, many of the risks are still not fully known.  If you are pregnant, it is vital for your baby’s health that you stop smoking or vaping.\n \n
  <strong>What can I do about it?</strong>\n \n
  Smoking and vaping are incredibly addictive. It is no easy task to quit. Go easy on yourself if you have tried to quit in the past and relapsed. It has been proven this is part of the process. 
  Contact QUITLINE via its website and phone number below. Nicotine patches, gum and lozenges can be a great way to try and reduce smoking cravings.\n \n
  You can access these via Quitline or your local pharmacist can prescribe fully subsidised products. Your GP is able to prescribe you a number of medicines that help increase the chance of quitting.\n \n
  The Ministry of Health's current position on vaping is, it is not harmless but it is less harmful than smoking. Vaping can help some people quit smoking when other methods have been unsuccessful. For more information see <a target="_blank" href="//www.vapingfacts.health.nz">www.vapingfacts.health.nz</a>.\n \n
  On average, people quit 12 times before they are successful so even if you have tried before, please try again! Call Quitline, your pharmacist or GP for help.\n \n
  Quitline\n \n
  0800 778 778\n \n
  <a target="_blank" href="//www.quit.org.nz">www.quit.org.nz</a>
  `,
  APB220: `
  <strong>What does your score mean?</strong>\n \n
  Congratulations! Whether you have been smoke free for 1 day or almost 1 year, quitting smoking or vaping is the single best thing you could have done for your health and that of your whanau.\n \n
  <strong>Why is this important?</strong>\n \n
  Smoking is an incredibly addictive habit that can be very hard to kick. However, it is important to quit as smoking may increase your risk of heart attacks, strokes, cataracts, as well as many types of cancer.  With vaping, although health risks are assumed to be less, many of the risks are still not fully known.\n \n
  Every day you choose to not smoke or vape, improves and benefits your life and the lives of those closest to you.\n \n
  <strong>What can I do about it?</strong>\n \n
  Get support and be mindful to remain smoke and vape free.\n \n
  Quitting smoking or vaping is a process that takes time and determination. Eventually you won’t crave it at all. You may even find it hard to imagine you ever liked smoking or vaping. Before you reach that point you may face challenges that test your resolve to quit. QUITLINE can provide you with support and resources to help with these cravings and keep you on track to remaining smoke and vape free.\n \n
  Quitline\n \n
  0800 778 778\n \n
  <a target="_blank" href="//www.quit.org.nz">www.quit.org.nz</a>
  `,
  APB230: `
  <strong>What does your score mean?</strong>\n \n
  Great decision! The choice to neither smoke or vape, makes a positive difference to the physical wellbeing of you, your family and whanau.\n \n
  <strong>Why is this important?</strong>\n \n
  Smoking costs people their health and wellbeing and often their lives.  With vaping many of the risks are still unknown. Smoking costs people socially and financially. By not smoking or vaping you improve and benefit your life and the lives of those closest to you every day.\n \n
  <strong>What can I do about it?</strong>\n \n
  Continue to stay smoke and vape free to protect the health and wellbeing of you and your family and whanau.
  `,
  APB240: `
  <strong>What does your score mean?</strong>\n \n
  Great decision! The choice to neither smoke or vape, makes a positive difference to the physical wellbeing of you, your family and whanau.\n \n
  <strong>Why is this important?</strong>\n \n
  Smoking costs people their health and wellbeing and often their lives.  With vaping many of the risks are still unknown. Smoking costs people socially and financially. By not smoking or vaping you improve and benefit your life and the lives of those closest to you every day.\n \n
  <strong>What can I do about it?</strong>\n \n
  Continue to stay smoke and vape free to protect the health and wellbeing of you and your family and whanau.
  `,
  ACB310: `
  <ul>
    <li>Promote an informed choice - discuss risks</li>
    <li>If pregnant or planning it - abstain - provide support if needed</li>
    <li>Ask if they think they have a drinking problem? Website available that has a questionnaire addressing this.</li>
    <li>Do they want support regarding alcohol intake?</li>
    <li>This could include:</li>
    <li>Their doctor</li>
    <li>Alcohol Drug Helpline on 0800 787 797</li>
    <li>Māori line – 0800 787 798</li>
    <li>Pasifika line – 0800 787 999</li>
  </ul>
  `,
  ACB320: `
  <ul>
    <li>Promote an informed choice - discuss risks</li>
    <li>If pregnant or planning it - abstain - provide support if needed</li>
    <li>Reinforce importance of avoiding binge drinking and having 2 alcohol free days per week</li>
    <li>To reduce long term health effects: max 1- 2drinks/day woman, men 2-3/day. Some overseas guidelines have recommended lower levels:  max 1 drink/day for both female/male.</li>
    <li>To reduce risk of alcohol related injury 4 drinks/occasion female, 5 drinks/occasion male</li>
  </ul>
  `,
  ACB330: `
  <ul>
    <li>Reinforce the good choice.</li>
    <li>If previous alcoholic or drinking problems recommend support to remain alcohol free</li>
    <li>It is not recommended anyone start drinking for possible health benefits</li>
  </ul>
  `,
  APB310: `
  <strong>What does your score mean?</strong>\n \n
  It is generally agreed health risks increase with any alcohol intake. However, this intake is considered to be above recommended guidelines and may be associated with higher health risks.\n \n
  <strong>Why is this important?</strong>\n \n
  Drinking alcohol is associated with at least 7 types of cancer, heart and liver disease, diabetes, sleep apnea, stomach ulcers and poor mental health.\n \n
  Alcohol also plays a part in a variety of social and mental health problems. Drinking larger amounts of alcohol increases the risk of difficulties in many areas of life. These can include legal problems, social issues and work related problems. If drinking is causing problems in your life, then you have a drinking problem.\n \n
  <strong>What can I do about it?</strong>\n \n
  Be aware of how to reduce risks when drinking by remaining within guidelines to protect your long term health and to protect you from injury.  Be aware there is no risk free level of drinking alcohol. Make an informed decision around your alcohol intake.\n \n
  Guidelines can help you reduce your risks. <a target="_blank" href="https://www.alcohol.org.nz/help-advice/advice-on-alcohol/low-risk-alcohol-drinking-advice">www.alcohol.org.nz/help-advice</a>.\n \n
  If you are worried about your drinking or you are not sure if it is a problem, it will help to talk to someone outside of your situation.\n \n
  This could include:\n \n
  <ul>
    <li>Your doctor</li>
    <li>Alcohol Drug Helpline on 0800 787 797</li>
    <li>Māori line – 0800 787 798</li>
    <li>Pasifika line – 0800 787 999</li>
    <li><a target="_blank" href="https://aa.org.nz">www.aa.org.nz</a></li>
    <li><a target="_blank" href="https://www.alcohol.org.nz/help-advice">www.alcohol.org.nz/help-advice</a></li>
    <li><a target="_blank" href="https://livingsober.org.nz">www.livingsober.org.nz</a></li>
    <li><a target="_blank" href="https://mentalhealth.org.nz/conditions/condition/alcohol">www.mentalhealth.org.nz</a></li>
    <li><a target="_blank" href="https://www.health.govt.nz/your-health/services-and-support/health-care-services/help-alcohol-and-drug-problems">www.health.govt.nz/your-health</a></li>
  </ul>
  `,
  APB320: `
  <strong>What does your score mean?</strong>\n \n
  Alcohol in any form, beer, wine or spirits is a toxin and is known to be a cancer causing substance that we tolerate as a society but it is known to be linked to many health related diseases. This light reflects that drinking alcohol does come with some risks.\n \n
  <strong>Why is this important?</strong>\n \n
  This might not be the answer you want to hear, but there is no safe level for drinking alcohol. The evidence shows that the ideal situation for health is to not drink at all.\n \n
  Drinking large amounts of alcohol can also increase difficulties in many areas of life. Not only health, but also social and sometimes professional.\n \n
  Drinking alcohol is associated with at least 7 types of cancer, heart and liver disease, diabetes, sleep apnea, stomach ulcers and poor mental health. Hormones and fertility can also be affected resulting in problems controlling blood sugar, loss of sex drive and reduced fertility.\n \n
  <strong>What can I do about it?</strong>\n \n
  Make sure you are aware of the health risks associated with drinking even low or moderate amounts of alcohol. Know how to reduce risks when drinking by remaining within guidelines to protect your long term health and to protect you from injury.  Be aware there is no risk free level of drinking alcohol. Make an informed decision around your alcohol intake.\n \n
  <a target="_blank" href="https://www.alcohol.org.nz/help-advice/advice-on-alcohol/low-risk-alcohol-drinking-advice">www.alcohol.org.nz/help-advice</a>
  `,
  APB330: `
  <strong>What does your score mean?</strong>\n \n
  This is a healthy choice to make for you and your whanau, great work!\n \n
  <strong>Why is this important?</strong>\n \n
  Choosing to not drink alcohol has numerous benefits to your health and finances, it can reduce your risk of accidents or harm and can improve your relationship with friends and family.\n \n
  <strong>What can I do about it?</strong>\n \n
  Your reason for not drinking is personal to you. Common reasons include religion, health, medical, philosophical, social, past alcoholism, or sometimes it is simply a matter of taste or preference.\n \n
  Some people may find peer pressure and the drinking culture in New Zealand makes this choice challenging.\n \n
  It is important to reflect on and consider the importance of your lifestyle choice. This is the healthiest and safest option with regards to your health. 
  `,
  ACB410: `
  <ul>
    <li>WC over 100cm dramatically increases the risk for heart disease and stroke.</li>
    <li>Increased WC is strongly linked to high BP, unhealthy HbA1c and lipid levels, and sleep apnoea.</li>
    <li>Exercising for 30 minutes each day will help to burn visceral fat.</li>
    <li>Advise, maintain or introduce healthy lifestyle factors to improve this measurement.</li>
    <li>Refer to nutritionist if appropriate.</li>
  </ul>
  `,
  ACB420: `
  Advise, maintain or introduce healthy lifestyle factors to improve on this measurement to keep health risks lower.\n \n
  Waist Circumference is a vital sign of health and linked to blood pressure, lipids, HbA1c, and sleep apnoea and is a strong predictor of death risk unlike BMI.
  `,
  ACB430: `
  Advise, maintain or introduce healthy lifestyle factors to retain this good measurement.
  `,
  ACB440: `
  <ul>
    <li>WC over 86cm dramatically increases the risk for heart disease and stroke. Heart disease is the most common cause of death for NZ women.</li>
    <li>Hormonal shifts during and after menopause can cause fat deopsits around the middle, increasing risk.</li>
    <li>Increased WC is strongly linked to high BP, increased HbA1c and lipids, and sleep apnoea.</li>
    <li>Exercising for 30 minutes each day will help to burn visceral fat.</li>
    <li>Advise a balanced healthy diet avoiding or cutting down on sugar, alcohol, fatty foods low in nutrients, and processed foods high in fat and sugar.</li>
    <li>Refer to nutritionist if appropriate</li>
  </ul>
  `,
  ACB450: `
  <ul>
    <li>Advise on lifestyle advice around diet and exercise.</li>
    <li>Hormonal shifts during perimenopause/ menopause can cause weight gain around the waist for women</li>
    <li>Their GP may be able to advise on hormone assessment.</li>
    <li>Refer to Nutritionist for more detailed and individual diet help.</li>
  </ul>
  `,
  ACB460: `
  <ul>
    <li>Advise to maintain or introduce healthy lifestyle factors to maintain this good measurement.</li>
    <li>Women tend to store body fat on the hips and thighs as opposed to the waist where men store fat. This has lower health risks.</li>
    <li>Reassure women that if they are carrying extra weight on their hips and thighs they are still healthy if their WC is green. </li>
  </ul>
  `,
  APB410: `
  <strong>What does your score mean?</strong>\n \n
  Your score indicates that the body fat stores around your waist are higher than recommended by health professionals.\n \n
  <strong>Why is this important?</strong>\n \n
  Fat stored around the middle is inflammatory and can be linked to some serious health risks. 
  A red light for waist circumference dramatically increases your risk of developing conditions like type 2 diabetes, cancer, and heart disease, particularly for men.\n \n
  <strong>What can I do about it?</strong>\n \n
  It’s important to take some action and lifestyle changes can have a huge impact. Here are some ideas: Start exercising a minimum of 3 times a week for 10 minutes, and then build up over time to 30 minutes. Exercise not only burns fat stores and calories but it also reduces stress as it burns up our stress hormones.\n \n
  Reducing stress is helpful as stress can make us crave unhealthy food and alcohol. Eat more vegetables. It sounds simple but it works. They are full of nutrients and fill us up so there is less room for other less nutritious foods. 
  Address alcohol consumption. Excess alcohol increases the fat that is laid down around the tummy and can lower our resolve to make healthy food choices.\n \n
  Consider using a nutritionist to help put a plan into place that suits your needs. Losing weight can seem daunting, but it’s such a great way to reduce risk for other health conditions and by introducing more exercise this can really positively impact our mental health too.  You’ve got this!
  `,
  APB420: `
  <strong>What does your score mean?</strong>\n \n
  Your score indicates the body fat stores around your waist are higher than recommended by health professionals.\n \n
  <strong>Why is this important?</strong>\n \n
  This means you could have an increased risk of developing conditions like type 2 diabetes and heart disease.\n \n
  <strong>What can I do about it?</strong>\n \n
  It’s easier to reverse this measurement before health conditions have taken hold, so we recommend introducing some smaller, healthy habits to improve  now. While fat around the waist is more dangerous than fat stored on other parts of the body, it’s also the easiest to move, which is great news!\n \n
  The lifestyle changes needed to do this involve regular exercise. Avoid or cut down on alcohol. Dietary changes include reducing junk food high in sugar and unhealthy fats. Increase foods that are nutrient dense such as vegetables and good quality fats and protein. Aim for smaller serves of complex carbohydrate found in wholegrains and add dairy foods to ensure nutrient requirements are met.
  `,
  APB430: `
  <strong>What does your score mean?</strong>\n \n
  Congratulations! You have a healthy waist circumference.\n \n
  <strong>Why is this important?</strong>\n \n
  You are keeping any future health risks as low as possible with your current waist circumference.\n \n
  <strong>What can I do about it?</strong>\n \n
  Keep up the good work with your healthy lifestyle. Check your lights are also green for lipids and HbA1c to complete the picture for a low risk body composition.\n \n
  `,
  APB440: `
  <strong>What does your score mean?</strong>\n \n
  Your score indicates that the body fat stores around your waist are higher than recommended by health professionals.\n \n
  <strong>Why is this important?</strong>
  If not addressed this can increase your risk of heart disease, type 2 diabetes and other illnesses. Hormonal changes that come with age also cause increases in stomach fat and if combined with higher than normal lipids, HbA1c or blood pressure the risk is increased further. This is why addressing the lights in waist circumference are important for reducing our overall health risks.\n \n
  <strong>What can I do about it?</strong>\n \n
  It’s much easier to reverse this measurement before health conditions have developed, so we encourage you to make small changes now. It is important to consider why this has happened. Is it hormonal? Is it a decrease in activity? Emotional eating? Tiredness? A lack of time and energy to put into wellness? Poor food choices? a lack of knowledge? Or too much alcohol? There are a lot of reasons we gain weight, often we don’t even notice it creeping up on us. Getting to the bottom of what’s increased your waist circumference may help with getting you back to a healthier risk profile.\n \n
  Knowledge is power, numbers can be changed and making improvements here will have a positive impact in  a lot of other areas of your health.  Eating a whole food diet and exercising regularly will normalise, not only waist circumference, but hormonal balance too. Consider using a nutritionist to help put an individualised plan into place that suits your needs.\n \n
  Losing weight can seem daunting, but it’s such a great way to reduce risk for other health conditions and by introducing more exercise this can really positively impact our mental health too.  You’ve got this!
  `,
  APB450: `
  <strong>What does your score mean?</strong>\n \n
  Your score indicates you may be  starting to deposit fat around your middle at levels higher than recommended by health professionals. \n \n
  <strong>Why is this important?</strong>\n \n
  Having fat around our waist that is more than recommended can sometimes lead to the increased risk of health problems, such a heart disease and other illnesses.Hormonal changes that come with age can cause these increases in midsection body fat.  If combined with higher than normal lipids, HbA1c, or blood pressure the risk is increased further. This is why paying attention to our light in waist circumference is important.\n \n
  <strong>What can I do about it?</strong>\n \n
  An orange light can easily be changed to green by addressing lifestyle factors such as alcohol, food choices and exercise.\n \n
  Many women are juggling responsibilities and unintentionally neglecting their health due to time and other life pressures. It’s really common. It's important to prioritise time for regular exercise that is enjoyable and commit to eating nutritious food. Protein at every meal has shown to reduce cravings and stabilises blood sugar. Healthy fats help the brain function well and stabilise hormones. Create good sleeping habits and delegate some of your jobs wherever possible. It’s important to put yourself first sometimes and prioritise your wellness!
  `,
  APB460: `
  <strong>What does your score mean?</strong>\n \n
  Congratulations. You have a healthy waist circumference.\n \n
  <strong>Why is this important?</strong>\n \n
  You are helping  keeping future health risks as low as possible with this waist circumference result.\n \n
  <strong>What can I do about it?</strong>\n \n
  Keep up the good work with your healthy lifestyle. Check your lights are also green for lipids and HbA1c to complete the picture for a low risk body profile.
  `,
  ACB510: `
  Check\n \n
- if they have been diagnosed with high blood pressure.\n \n
- do they take medicine for it?\n \n
- have they remembered to take it as usual?\n \n
- are there any other signs or symptoms of concern, confusion, blurred vision, chest pain, severe headache etc.\n \n
If the individual has forgotten their medicine that day, they should take their medicine and have their BP checked an hour later, either by KYND, their doctor or at a pharmacy.  
If they have run out of their medication, pharmacists can sell them an emergency supply of 3 days until a prescription is organised.\n \n
- Readings 140- 180\n
Refer to their GP, the higher the reading the sooner they should be seen, ideally within the week.  Blood pressure readings could be repeated at a pharmacy or at home with a monitor.\n \n
- Recordings > 180 systolic or > 105 diastolic\n
Refer to their regular health practitioner within 24 hours.\n \n
- Any BP recording > 230 systolic or >120 diastolic\n
Refer to their regular health practitioner that day\n \n
Any BP recording > 180 systolic or >105 diastolic with associated:\n
- Confusion\n
- Blurred vision\n
- Chest pain\n
- Severe headache\n
- Other new concerns\n
- Refer to medical attention immediately, ambulance if necessary\n \n
If a client is not used to accessing their GP and is hesitant, or does not have a GP and the clinician is worried then the clinician can ring the GP surgery and try and expedite an appointment. Alternatives are to locate a local After Hours or A&E clinic for them, providing address and telephone number, or the emergency department of the hospital if they do not have a GP. 
  `,
  ACB520: `
  At the lower end of this range not too much concern\n \n
  <ul>
    <li>Reinforce that healthy lifestyle choices can prevent or delay the onset of high blood pressure or other health problems.</li>
    <li>Reduce salt.</li>
    <li>Exercise regularly.</li>
    <li>Maintain a healthy weight.</li>
    <li>Keep stress at acceptable levels.</li>
    <li>Reminder BP should continue to be monitored regularly.</li>
    <li>Continue to monitor blood pressure at regular intervals based on age, ethnicity and - comorbidities.</li>
  </ul>

  At the higher end of this range\n \n
  <ul>
    <li>Rule out any obvious causes of it being high eg medicine forgotten or stress</li>
    <li>Be aware GP may be happier with a slightly higher BP if on medication and a low BP a risk with falls etc</li>
    <li>Recommend rechecking if possible with a nurse or at pharmacy, if still high or unable to recheck, Dr's appt should be made to discuss.</li>
    <li>Reinforce lifestyle changes as above</li>
    <li>Having high blood pressure is a risk factor for sleep apnoea.</li>
    <li>Blood pressure can be checked by a nurse or at most pharmacies.</li>
    <li>Suggest the purchase of a monitor for home or some businesses have them for their employees. Cost $100-$200.</li>
  </ul>
  `,
  ACB530: `
  <ul>
    <li>Congratulate individuals, especially if the result is down to healthy lifestyle choices.</li>
    <li>Reinforcing healthy lifestyle choices can prevent or delay the onset of high blood pressure or other health problems.</li>
    <li>Stop smoking.</li>
    <li>Reduce salt.</li>
    <li>Exercise regularly.</li>
    <li>Maintain a healthy weight.</li>
    <li>Keep stress at acceptable levels.</li>
    <li>Continue to monitor blood pressure at regular intervals based on age, ethnicity and co-morbidities.</li>
    <li>Blood pressure can be checked by a nurse or at most pharmacies.</li>
    <li>Suggest the purchase of a monitor for home or some businesses have them for their employees. Cost $100-$200</li>
  </ul>
  `,
  ACB540: `
  Check\n \n
  <ul>
    <li>if they have been diagnosed with high blood pressure?\n</li>
    <li>do they take medicine for it?\n</li>
    <li>have they remembered to take it as usual?\n</li>
    <li>are there any other signs or symptoms of concern, confusion, blurred vision, chest pain, severe headache etc</li>
  </ul>
  If the individual has forgotten their medicine that day, they should take their medicine and have their BP checked an hour later, either by KYND, their doctor or at a pharmacy.\n \n
  If they have run out of their medication, pharmacists can sell them an emergency supply of 3 days until a prescription is organised.\n \n
  Readings continually over 90 should be referred to their GP, the higher the reading the sooner they should be seen, ideally within the week.\n \n
  Any elevation in diastolic blood pressure (even if it’s small) can increase risk of cardiovascular problems. \n \n
  Most likely systolic pressure will be elevated as well but sometimes not.\n \n
  Check systolic blood pressure and what other risk factors are present to get an idea of overall risk.\n \n
  Maintain or adopt a healthy lifestyle:\n \n
  <ul>
    <li>Stop smoking.\n</li>
    <li>Reduce salt.\n</li>
    <li>Exercise regularly.\n</li>
    <li>Maintain a healthy weight.\n</li>
    <li>Keep stress at acceptable levels</li>
  </ul>
  Continue to monitor blood pressure at regular intervals based on age, ethnicity and comorbidities.\n \n
  Between 18 and 40 screening every three to five years. If other health conditions screen annually.\n \n
  Over 40 years screen annually.
  `,
  ACB550: `
  Check\n \n
  <ul>
    <li>if they have been diagnosed with high blood pressure?\n</li>
    <li>do they take medicine for it?\n</li>
    <li>have they remembered to take it as usual?\n</li>
    <li>are there any other signs or symptoms of concern, confusion, blurred vision, chest pain, severe headache etc</li>
  </ul>
  If the individual has forgotten their medicine that day, they should take their medicine and have their BP checked an hour later, either by KYND, their doctor or at a pharmacy.\n \n
  If they have run out of their medication, pharmacists can sell them an emergency supply of 3 days until a prescription is organised.\n \n
  Readings continually over 90 should be referred to their GP, the higher the reading the sooner they should be seen, ideally within the week.\n \n
  Any elevation in diastolic blood pressure (even if it’s small) can increase risk of cardiovascular problems. \n \n
  Most likely systolic pressure will be elevated as well but sometimes not.\n \n
  Check systolic blood pressure and what other risk factors are present to get an idea of overall risk.\n \n
  Maintain or adopt a healthy lifestyle:\n \n
  <ul>
    <li>Stop smoking.\n</li>
    <li>Reduce salt.\n</li>
    <li>Exercise regularly.\n</li>
    <li>Maintain a healthy weight.\n</li>
    <li>Keep stress at acceptable levels</li>
  </ul>
  Continue to monitor blood pressure at regular intervals based on age, ethnicity and comorbidities.\n \n
  Between 18 and 40 screening every three to five years. If other health conditions screen annually.\n \n
  Over 40 years screen annually.
  `,
  ACB560: `
  Maintain or adopt a healthy lifestyle to continue to prevent or delay the onset of high blood pressure.\n \n
  <ul>
    <li>Stop smoking.\n</li>
    <li>Reduce salt.\n</li>
    <li>Exercise regularly.\n</li>
    <li>Maintain a healthy weight.\n</li>
    <li>Keep stress to acceptable levels\n</li>
    <li>Continue to monitor blood pressure at regular intervals based on age, ethnicity and comorbidities.\n</li>
    <li>Between 18 and 40 screen every three to five years. If other health conditions screen annually.\n</li>
    <li>Over 40 years screen annually.\n</li>
  </ul>
  `,
  APB510: `
  <strong>What does your score mean?</strong>\n \n
  Your blood pressure is higher than the range recommended by health professionals.\n \n
  <strong>Why is this important?</strong>\n \n
  Having blood pressure consistently above the recommended range increases some risks to your health. Over time, the force and friction of high blood pressure can cause damage to the inside of blood vessels. This can then lead to narrowing of the blood vessels and may increase your risk of heart disease, heart attacks, stroke, kidney disease among other conditions.\n \n
  As high blood pressure often has no symptoms it's important that you keep an eye on your blood pressure and monitor it. Otherwise there is no way of knowing your risks.\n \n 
  <strong>What can I do about it?</strong>\n \n
  A single blood pressure measurement higher than normal does not necessarily mean you have high blood pressure, however, it is important to get your blood pressure at rest checked a few times over the course of the next week. If you continually record readings over 140 at rest it is recommended you make an appointment to discuss this with your GP.\n \n
  There are plenty of ways that you can help reduce your blood pressure by changing your lifestyle. If you smoke, stop. Lower the amount of salt in your diet. Cut back on alcohol if you drink. Get to or stay at a healthy weight and exercise regularly. Chronic stress can increase blood pressure, so looking at ways of reducing this.
  `,
  APB520: `
  <strong>What does your score mean?</strong>\n \n
  Your blood pressure may be a little higher than recommended. Keep in mind your blood pressure goes up and down normally over the course of the day, due to a variety of factors.\n \n
  If you have been diagnosed with high blood pressure and are taking medications for it this may be an acceptable measurement for you. Your doctor can advise you on this.\n \n
  <strong>Why is this important?</strong>\n \n
  Having blood pressure consistently above the recommended range increases some risks to your health. Over time, the force and friction of high blood pressure can cause damage to the inside of blood vessels. This can then lead to narrowing of the blood vessels and may increase your risk of heart disease, heart attacks, stroke, kidney disease among other conditions.\n \n
  As high blood pressure often has no symptoms it's very important that you keep an eye on your blood pressure and monitor it. Otherwise there is no way of knowing your risk.\n \n
  <strong>What can I do about it?</strong>\n \n
  A single blood pressure measurement higher than normal does not necessarily mean you have high blood pressure. However, it is important to check this again over the next week. If your systolic  blood pressure is  consistently elevated at rest over 140 mmHg. You should make an appointment with your GP.\n \n
  There are plenty of ways to help reduce your blood pressure by improving your lifestyle.  If you smoke, stop. Lower the amount of salt in your diet. Cut back on alcohol if you drink. Get to, or stay at, a healthy weight and exercise regularly. Chronic stress can increase blood pressure, so look at ways of reducing this. Improving your quantity and quality of sleep also helps reduce blood pressure. 
  `,
  APB530: `
  <strong>What does your score mean?</strong>\n \n
  This is a great blood pressure measurement and is within the normal acceptable range.\n \n
  <strong>Why is this important?</strong>\n \n
  Having a green light here means your blood vessels, heart, kidneys, eyes and other organs are likely to stay a lot healthier. Your risk of a heart attack and stroke is a lot lower than someone with blood pressure outside of the recommended range.\n \n
  <strong>What can I do about it?</strong>\n \n
  If your blood pressure is normal, maintaining or adopting a healthy lifestyle can prevent or delay the onset of high blood pressure or other health problems.\n \n
  It's important to know your blood pressure can change over time, so it’s important to get it checked regularly.  
  `,
  APB540: `
  <strong>What does your score mean?</strong>\n \n
  Your blood pressure is higher than recommended. Keep in mind your blood pressure goes up and down depending on a lot of factors over the course of the day. Factors that increase your blood pressure include exercise, coffee, smoking, talking, stress, your emotions, even having your blood pressure measured!\n \n
  <strong>Why is this important?</strong>\n \n
  Having blood pressure consistently above the recommended range increases the risk to your health. Over time, the force and friction of high blood pressure can damage the inside of the blood vessels. This can then lead to narrowing of the blood vessels and may increase the risk of heart disease, heart attacks, stroke, kidney disease among other conditions.\n \n
  As high blood pressure often has no symptoms it's important that you keep an eye on your blood pressure and monitor it. Otherwise there is no way of knowing your risks. \n \n
  <strong>What can I do about it?</strong>\n \n
  A single blood pressure measurement higher than normal does not necessarily mean you have high blood pressure, so it is important to recheck it a few times over the next week. If you continually record over 90 at rest, you should make an appointment with your GP.\n \n
  In the meantime, often changes to your lifestyle can reduce blood pressure. If you smoke, stop. Lower the amount of salt in your diet. Cut back on alcohol if you drink. Get to or stay at a healthy weight and exercise regularly. Chronic stress can also increase blood pressure, so look at ways of reducing it to have a positive effect on your blood pressure.
  `,
  APB550: `
  <strong>What does your score mean?</strong>\n \n
  Your blood pressure is higher than recommended. Keep in mind your blood pressure goes up and down depending on a lot of factors over the course of the day. Factors that increase your blood pressure include exercise, coffee, smoking, talking, stress, your emotions, even having your blood pressure measured!\n \n
  <strong>Why is this important?</strong>\n \n
  Having blood pressure consistently above the recommended range increases the risk to your health. Over time, the force and friction of high blood pressure can damage the inside of the blood vessels. This can then lead to narrowing of the blood vessels and may increase the risk of heart disease, heart attacks, stroke, kidney disease among other conditions.\n \n
  As high blood pressure often has no symptoms it's important that you keep an eye on your blood pressure and monitor it. Otherwise there is no way of knowing your risks. \n \n
  <strong>What can I do about it?</strong>\n \n
  A single blood pressure measurement higher than normal does not necessarily mean you have high blood pressure, so it is important to recheck it a few times over the next week. If you continually record over 90 at rest, you should make an appointment with your GP.\n \n
  In the meantime, often changes to your lifestyle can reduce blood pressure. If you smoke, stop. Lower the amount of salt in your diet. Cut back on alcohol if you drink. Get to or stay at a healthy weight and exercise regularly. Chronic stress can also increase blood pressure, so look at ways of reducing it to have a positive effect on your blood pressure.
  `,
  APB560: `
  <strong>What does your score mean?</strong>\n \n
  As long as your systolic blood pressure is at an acceptable level, having a green light here means your heart and blood vessels aren't under any extra strain. Any risks to your health associated with high blood pressure are at an acceptable level. However, even with a green light here, if your systolic reading is high and you have an orange or red light for systolic,  you will still have work to do to reduce the overall reading.\n \n
  <strong>Why is this important?</strong>\n \n
  While high systolic pressure has a greater influence on heart health and complications, high diastolic readings also affect a person's risk, regardless of the systolic reading. It's important to take into account both the blood pressure measurements when looking at your health risks.\n \n
  <strong>What can I do about it?</strong>\n \n
  If your blood pressure is normal, maintaining or adopting a healthy lifestyle can prevent or delay the onset of high blood pressure or other health problems. It's important to know your blood pressure can change over time, so it’s important to get it checked regularly.
  `,
  ACB610: `
  <ul>
    <li>CVD risk assessment - lipid management should be viewed as one aspect of reducing CVD risk rather than in isolation - CVD risk assessment</li>
    <li>Monitor non-fasting lipids every 6-12 months until the desired target is reached. Once achieved, annual monitoring is appropriate.</li>
    <li>High cholesterol is linked to hypothyroidism (have bloods been done by GP before?)</li>
    <li>High total cholesterol is linked to anorexia (check waist, weight, MIND scores) and refer if necessary</li>
  </ul>
  `,
  ACB620: `
  Check Ratio.\n \n
  If the ratio is green, risk is reduced. Their HDL is “mopping up” the excess cholesterol.\n \n
  If the ratio is orange or a red light, this orange light for total cholesterol is more significant in terms of risk due to low HDL.\n \n
  CVD risk assessment - refer to GP if other conditions are orange/red\n \n
  Monitoring - All men over 45 years, women over 55 years check lipids every 10 years.\n \n
  People of Māori, Pacific or Indo-Asian ethnicity should start having their lipids checked 10 years earlier than this.\n \n
  Some people will have their lipids checked more frequently if using lipid lowering medications, or have diabetes or heart disease.\n \n
  Monitor non-fasting lipids every 6-12 months after treatment until the desired target is reached. Once achieved, annual monitoring is appropriate.
  `,
  ACB630: `
  <ul>
    <li>Lipid management should be viewed as one aspect of reducing CVD risk rather than in isolation</li>
    <li>All men aged over 45 years and all women aged over 55 years should have their lipids checked at least once every 10 years.</li>
    <li>People of Māori, Pacific or Indo-Asian ethnicity should start having their lipids checked 10 years earlier than this.</li>
    <li>Some people will have their lipids checked more frequently if using lipid lowering medicine, or have diabetes or heart disease.</li>
    <li>Check the total cholesterol is not too low. Less than or equal to 3mmol/L.</li>
    <li>Check LDL levels are not low (less than 1.3mmol/L)</li>
    <li>Low cholesterol can be linked to a range of health conditions and low Vit D.</li>
    <li>Check the KYND Mind score, plus check for signs of depression. Low cholesterol is a risk factor for suicide or violent behaviour.</li>
    <li>If pregnant, low cholesterol increases the risk of premature birth or a baby born with low birth weight.</li>
    <li>Discuss with the GP at the next appointment.</li>
  </ul>
  `,
  APB610: `
  <strong>What does your score mean?</strong>\n \n
  This result indicates your cholesterol is very high and means you may be at an increased risk of serious health complications involving your heart.\n \n
  <strong>Why is this important?</strong>\n \n
  Having a cholesterol level this high increases your chance of having a heart attack or stroke. Because high cholesterol doesn't often have any symptoms you may not have known it was high until this blood test.\n \n
  Sometimes high cholesterol can be genetic so it’s a good idea to know your family history.\n \n
  <strong>What can I do about it?</strong>\n \n
  It is important to make an appointment with your doctor to discuss this result. Your ideal cholesterol level depends on your overall risk of heart attack and stroke. To help know this your GP may do a "cardiovascular risk assessment" and advise a combination of medication and diet/lifestyle advice.\n \n
  Even if there’s a genetic reason for your high cholesterol we know diet and lifestyle are still important. Watch high saturated fat foods like butter, cheese, fatty or deep fried takeaways, fatty or processed meat, commercial cakes and biscuits, and any snack food that is high in saturated fat. Increase vegetables as the fibre helps lower cholesterol and the nutrients help protect the heart and blood vessels.
  `,
  APB620: `
  <strong>What does your score mean?</strong>\n \n
  This result is higher than recommended. Unless you have high levels of HDL cholesterol to compensate, (which acts like a vacuum cleaner mopping up excess cholesterol), this slightly elevated cholesterol level increases the risk of a heart attack or stroke.\n \n
  <strong>Why is this important?</strong>\n \n
  If there is too much cholesterol in the blood plaques or cholesterol deposits can form inside the blood vessels. These plaques may eventually cause blockages in the blood vessels and the blockage leads to a heart attack or stroke. \n \n
  <strong>What can I do about it?</strong>\n \n
  Your orange light for cholesterol needs to be considered alongside other health numbers to assess the risk of heart attack and stroke. If you are showing orange or red light for other BODY measurements such as blood pressure, waist circumference, HbA1c or your other lipids, it is important you make an appointment with your GP. Your GP will consider all these factors to assess the risk to your heart.\n \n
  In the meantime, you can help your lipid levels by keeping weight at a healthy level, limiting saturated fat in the diet that can be found in fatty and processed meats and certain full fat dairy products, and keeping highly processed junk foods to a minimum. Commit to regular exercise and include foods that are known to lower cholesterol such as heart healthy fats and soluble fibre foods.
  `,
  APB630: `
  <strong>What does your score mean?</strong>\n \n
  This is a great result, your total cholesterol is in the healthy range recommended for heart health.\n \n
  <strong>Why is this important?</strong>\n \n
  The risk of a heart attack or stroke is reduced as your blood vessels are not blocked with excess cholesterol. The blood can flow easily and get the nutrients and oxygen to where it’s needed.\n \n
  <strong>What can I do about it?</strong>\n \n
  Stay active and keep eating a diet low in saturated fats and consume adequate amounts of heart healthy fats, and lots of vegetables. It is important that your other lipid measurements such as HDL and LDL are also within healthy ranges. If you have diabetes, heart disease or had a stroke your health practitioner may discuss a lower target with you.
  `,
  ACB710: `
  <ul>
    <li>CVD risk assessment - refer to GP if other Body measurements are orange/red</li>
    <li>A diet and exercise plan will help – nutritionist</li>
    <li>Check family history.</li>
    <li>Low HDL levels - linked to insulin resistance - check HbA1c</li>
    <li>Smokers - low HDL levels are linked, another reason to quit.</li>
  </ul>
  `,
  ACB720: `
  CVD risk assessment - refer to GP if other Body measurements (including other lipids) are orange/red\n \n
  A diet and exercise plan will help address this.\n \n
  Check family history.\n \n
  All men aged over 45 years and all women aged over 55 years should have their lipids checked at least once every 10 years.\n \n
  People of Māori, Pacific or Indo-Asian ethnicity should start having their lipids checked 10 years earlier than this.\n \n
  Some people will have their lipids checked more frequently due to tx with lipid lowering medicine, have diabetes or have other reasons
  `,
  ACB730: `
  <ul>
    <li>HDL can be too high</li>
    <li>Over 2.3mmol/L, HDL may behave more like LDL and raise the risk of disease </li>
    <li>Discuss with GP at next appointment</li>
    <li>Maintain or introduce healthy lifestyle factors</li>
    <li>All men aged over 45 years and all women aged over 55 years should have their lipids checked at least once every 10 years.</li>
    <li>People of Māori, Pacific or Indo-Asian ethnicity should start having their lipids checked 10 years earlier than this.</li>
    <li>Some people will have their lipids checked more frequently due to tx with lipid lowering medicine, have diabetes or have other reasons</li>
  </ul>
  `,
  APB710: `
  <strong>What does your score mean?</strong>\n \n
  Your HDL level is very low and could be putting you at risk for heart disease. Without enough HDL, excess cholesterol is not being adequately removed from the blood vessels and can build up. Your low HDL can be from a number of factors. Sometimes it’s genetic make up, other times it’s from an unhealthy diet and lack of exercise. Whatever the reason, it’s important to get on top of it to reduce your risk.\n \n
  <strong>Why is this important?</strong>\n \n
  Healthy HDL levels protect the heart by keeping total cholesterol levels at a recommended level. HDL removes harmful cholesterol from where it doesn’t belong, if you have low levels of HDL, this indicates there’s not enough of it to keep excess cholesterol in check and this puts you at greater risk of a heart attack or stroke.\n \n
  <strong>What can I do about it?</strong>\n \n
  Your lifestyle plays a part in determining your HDL levels. Any unhealthy lifestyle habits, such as smoking or a diet high in processed foods may be increasing your risk for other diseases too.\n \n
  If you smoke, you need to quit. Commit to regular aerobic exercise at least 3 times a week. Eat five plus servings of vegetables a day and increase legumes (such as chickpeas, peanuts, black beans etc), their fibre helps lower cholesterol. Aim to reduce eating highly saturated fatty food and replace it with heart healthy fats.\n \n
  It would be beneficial to work with a nutritionist who can help with this and can provide an individual dietary plan.
  `,
  APB720: `
  <strong>What does your score mean?</strong>\n \n
  This HDL level could be higher to improve your heart health and increase protection from heart disease.\n \n
  <strong>Why is this important?</strong>\n \n
  Healthy HDL levels protect the heart by keeping total cholesterol levels to a recommended level. HDL removes harmful cholesterol from where it doesn’t belong, if you have low levels of HDL there’s not enough of it to keep excess cholesterol in check. \n \n
  <strong>What can I do about it?</strong>\n \n
  Your lifestyle plays a part in determining your HDL levels. Any unhealthy lifestyle habits may be increasing your risk for other diseases too. If you smoke, quit. Smoking lowers HDL. Keep a healthy weight and take part in regular exercise. Aerobic exercise improves HDL levels. Swap saturated fats for heart healthy monounsaturated fats such as olive oil and avocado, Saturated fats raise cholesterol and monounsaturated fats lower total cholesterol and raise protective HDL levels.
  `,
  APB730: `
  <strong>What does your score mean?</strong>\n \n
  Congratulations, you have healthy levels of HDL cholesterol. HDL is great at doing its job and carrying cholesterol efficiently through the blood and getting rid of any excess.\n \n
  <strong>Why is this important?</strong>\n \n
  Having healthy levels of HDL cholesterol helps prevent the blockages in the blood vessels caused by high cholesterol.\n \n
  <strong>What can I do about it?</strong>\n \n
  Maintain or introduce healthy lifestyle factors to maintain this good HDL level. Regular exercise, certain high fibre foods and heart healthy monounsaturated fats boost HDL levels. Keep up the good work!
  `,
  ACB810: `
  <ul>
    <li>CVD assessment - risk is compounded if more than one lipid value is red and/or orange.</li>
    <li>Referral to a GP to assess if high overall risk if haven't previously</li>
    <li>Monitor non-fasting lipids every 6-12 months until the desired target is reached. Once achieved, annual monitoring is appropriate.</li>
    <li>Lifestyle changes involve decreasing saturated fat, increasing monounsaturated fat</li>
    <li>Increase vegetables and other soluble fibre foods</li>
    <li>Get weight to a healthy level</li>
    <li>Become or remain smoke free</li>
  </ul>
  `,
  ACB820: `
  <ul>
    <li>If other lipids are all green - overall risk is much lower and often change to diet and exercise not required</li>
    <li>CVD assessment if other health conditions orange or red</li>
    <li>Referral to a GP to assess if high overall risk</li>
    <li>All men aged over 45 years and all women aged over 55 years should have their lipids checked at least once every 10 years.</li>
    <li>People of Māori, Pacific or Indo-Asian ethnicity should start having their lipids checked 10 years earlier than this.</li>
    <li>Some people will have their lipids checked more frequently due to tx with lipid lowering medicine, have diabetes or have other reasons</li>
    <li>Monitor non-fasting lipids every 6-12 months until the desired target is reached. Once achieved, annual monitoring is appropriate.</li>
    <li>Lifestyle changes involve decreasing saturated fat, increasing monounsaturated fat (eg olive oil, olives, avocado, peanuts)</li>
    <li>Increase vegetables and other soluble fibre foods</li>
    <li>Get weight to a healthy level</li>
    <li>Become or remain smoke free</li>
  </ul>
  `,
  ACB830: `
  <ul>
    <li>Maintain or introduce healthy lifestyle factors to maintain this LDL level.</li>
    <li>All men aged over 45 years and all women aged over 55 years should have their lipids checked at least once every 10 years.</li>
    <li>People of Māori, Pacific or Indo-Asian ethnicity should start having their lipids checked 10 years earlier than this.</li>
    <li>Some people will have their lipids checked more frequently if they are on lipid lowering medications, have type two diabetes or a risk factor for heart disease.</li>
  </ul>
  `,
  APB810: `
  <strong>What does your score mean?</strong>\n \n
  This level is high and can mean too much cholesterol is being transported around in the  blood.\n \n
  <strong>Why is this important?</strong>\n \n
  A high level of LDL cholesterol puts you at risk of a heart attack or stroke. This is because high levels of LDL in the blood can form "plaques" in the walls of your arteries. This build-up of plaque is called hardening of the arteries (or atherosclerosis) and LDL or the "bad cholesterol" is the raw material of the cholesterol plaques. The cholesterol plaques are the cause of heart attack and strokes.\n \n
  <strong>What can I do about it?</strong>\n \n
  If you are showing orange or red for any other BODY measurements such as blood pressure, waist circumference, HbA1c or your other lipids it is important you make an appointment with your GP. Your GP will consider all these factors to assess the risk to your heart.\n \n
  High LDL levels can be lowered with a healthy diet and regular exercise. Introduce healthy choices with food, in particular reduce saturated fat foods such as fatty meats and high fat takeaways and increase the number of servings of vegetables eaten each day (3-5 serves a day if possible!).  A nutritionist can help with a personalized plan.\n \n
  It’s important to be smoke free and monitor weight and blood pressure.\n \n
  Sometimes genetics can be a risk factor here, so it’s always good to check your family medical history.
  `,
  APB820: `
  <strong>What does your score mean?</strong>\n \n
  Your LDL cholesterol levels are a little higher than recommended.\n \n
  <strong>Why is this important?</strong>\n \n
  Excess cholesterol of this type can build up in the arteries and can potentially cause blockages which increases your risk for a heart attack or stroke. The risk becomes even greater if you are showing orange or red light for any of the other BODY measurements such as blood pressure, waist circumference, HbA1c and your other lipids. If this is the case it is important you make an appointment with your GP. Your GP will consider all these factors to assess the overall risk to your heart.\n \n
  <strong>What can I do about it?</strong>\n \n
  It is important to look at all risk factors including your other lipid measurements. If you are showing orange or red for other BODY measurements like blood pressure, waist circumference, HbA1c and your other lipids it is important you make an appointment with your GP. Your GP will consider all these factors to assess the risk to your heart.\n \n
  In the meantime, there are lifestyle factors that can help lower LDL to the recommended level. Maintain a healthy nutritious healthy diet, commit to regular exercise, be smoke free and monitor weight and blood pressure. 
  `,
  APB830: `
  <strong>What does your score mean?</strong>\n \n
  Congratulations, you have healthy levels of LDL cholesterol. It is important to have some cholesterol in our blood to make hormones, build cells and help with vitamin D synthesis. This score indicates you have the working.\n \n
  <strong>Why is this important?</strong>\n \n
  Having just the right amount here means you get the health benefits that cholesterol offers without the risk of heart disease from having too much. You have the right amount.\n \n
  <strong>What can I do about it?</strong>\n \n
  It is important to look at all risk factors including your other lipid measurements. If you are showing orange or red for other BODY measurements like blood pressure, waist circumference, HbA1c and your other lipids it is important you make an appointment with your GP. Your GP will consider all these factors to assess the risk to your heart.\n \n
  In the meantime, there are lifestyle factors that can help lower LDL to the recommended level. Maintain a healthy nutritious healthy diet, commit to regular exercise, be smoke free and monitor weight and blood pressure. 
  `,
  ACB910: `
  <ul>
    <li>Consider with other lipid results for full picture</li>
    <li>Raised triglycerides can be the result of food eaten prior to the blood test.</li>
    <li>It’s a good opportunity to inquire about sugar and alcohol consumption as both are known to raise triglycerides.</li>
  </ul>
  `,
  ACB920: `
  <ul>
    <li>Consider with other lipid results for full picture</li>
    <li>Raised triglycerides can be the result of food eaten prior to the blood test</li>
    <li>This is a good opportunity to inquire about sugar and alcohol consumption as both are known to raise triglycerides.</li>
    <li>All men aged over 45 years and all women aged over 55 years should have their lipids checked at least once every 10 years.</li>
    <li>People of Māori, Pacific or Indo-Asian ethnicity should start having their lipids checked 10 years earlier than this.</li>
    <li>Some people will have their lipids checked more frequently due to tx with lipid lowering medicine, have diabetes or have other reasons.</li>
  </ul>
  `,
  ACB930: `
  <ul>
    <li>Reinforce maintaining/introducing a healthy lifestyle to maintain this result.</li>
    <li>All men aged over 45 years and all women aged over 55 years should have their lipids checked at least once every 10 years.</li>
    <li>People of Māori, Pacific or Indo-Asian ethnicity should start having their lipids checked 10 years earlier than this.</li>
    <li>Some people will have their lipids checked more frequently due to treatment with lipid lowering medicine, have diabetes or have other reasons</li>
  </ul>
  `,
  APB910: `
  <strong>What does your score mean?</strong>\n \n
  Your triglyceride levels are high, however if you have just eaten prior to the blood test this may have affected your result. Excess alcohol can also raise triglycerides.\n \n
  <strong>Why is this important?</strong>\n \n
  Increased blood triglyceride levels can lead to more fat being deposited on the body and around the organs. This can put you at higher risk of diseases such as high blood pressure, heart disease and certain cancers.\n \n
  <strong>What can I do about it?</strong>\n \n
  It’s important to get out and get active. Lose weight if needed, which can help reduce blood fats and the excess fat stored on the body from high blood triglyceride levels. Reduce highly refined carbohydrate foods like sugar and white flour and foods made from these. Cut out sugary fizzy drinks and added sugar to tea and coffee. Reduce/eliminate alcohol and increase omega-3 essential fatty acids which are found in oily fish or fish oil supplements, and walnuts, chia seeds and linseeds.
  `,
  APB920: `
  <strong>What does your score mean?</strong>\n \n
  Your triglyceride levels are slightly higher than recommended however if you have just eaten prior to the blood test this may increase your result.\n \n
  <strong>Why is this important?</strong>\n \n
  Increased blood triglyceride levels can lead to more fat being deposited on the body and around the organs. This can put you at higher risk of diseases such as high blood pressure, heart disease and certain cancers.\n \n
  <strong>What can I do about it?</strong>\n \n
  If your moderately high triglycerides are an indication of unhealthy food and alcohol choices, changes need to be made. Reduce highly refined carbohydrate foods like sugar and white flour and foods made from these. Reduce or eliminate alcohol and increase omega 3 essential fatty acids which are found in oily fish or fish oil supplements, and walnuts, chia seeds and linseeds.\n \n
  If you have just eaten prior to this blood test and the rest of your lipid results are green, your result may not require a change of lifestyle.
  `,
  APB930: `
  <strong>What does your score mean?</strong>\n \n
  Congratulations. You have healthy blood triglyceride levels.\n \n
  <strong>Why is this important?</strong>\n \n
  This means you are eating and processing fat, carbohydrate, and alcohol appropriately and keeping your risk for heart disease low.\n \n
  <strong>What can I do about it?</strong>\n \n
  Keep up the healthy lifestyle habits. These are keeping your blood triglyceride levels at a healthy level.
  `,
  ACB1010: `
  <ul>
    <li>Low HDL levels are often linked to insulin resistance so it is a good idea to check HbA1c and encourage a diet that reduces highly refined carbohydrate as well addressing the types of fat in the diet.</li>
    <li>Check smoking status. If a smoker, point out that low HDL levels are linked and another reason to quit!</li>
  </ul>
  `,
  ACB1020: `
  <ul>
    <li>Check other lipid measurements.</li>
    <li>Check other KYND scores blood Pressure, HbA1c, and waist circumference.</li>
    <li>Low HDL can often be linked with lifestyle choices that lower the KYND score for other Body measurements.</li>
    <li>A diet and exercise plan can address all of these issues.</li>
    <li>Check family history.</li>
  </ul>
  `,
  ACB1030: `
  <ul>
    <li>If either total cholesterol or HDL is orange and the other is green but the overall ratio is still green it is worth pointing out the lifestyle factors that help achieve green for both total cholesterol and HDL</li>
  </ul>
  `,
  APB1010: `
  <strong>What does your score mean?</strong>\n \n
  This score means either your HDL level is very low or your cholesterol is high, or both. This may increase your risk of heart disease. With high cholesterol and/or low HDL levels, cholesterol is not being removed adequately and can accumulate in the arteries.  This can build up and form a plaque that eventually could block blood vessels.\n \n
  <strong>Why is this important?</strong>\n \n
  A red light for your ratio means you are at risk. With high cholesterol and/or low HDL levels, cholesterol is not being removed adequately and can accumulate in the arteries.  This can build up and form a plaque that eventually could block blood vessels, this may increase your risk of a heart attack or stroke.\n \n
  Very high cholesterol and low HDL is often linked to other health numbers that may need addressing with lifestyle change too. Improving this result with healthy food and regular exercise can improve all KYND Body measurements and reduce overall risk of disease.\n \n
  <strong>What can I do about it?</strong>\n \n
  <ul>
    <li>If smoking, quit.</li>
    <li>Commit to regular aerobic exercise</li>
    <li>Eat 5 plus servings of vegetables a day</li>
    <li>Increase legume consumption (peas beans and lentils and chickpeas), the fibre helps lower cholesterol.</li>
    <li>Decrease highly saturated fatty food and replace it with heart healthy monounsaturated fats.</li>
    <li>Work with a healthcare professional to plan and instigate diet changes.</li>
  </ul>
  `,
  APB1020: `
  <strong>What does your score mean?</strong>\n \n
  This cholesterol to HDL ratio may be a little high to give you optimal heart health and protection from heart disease. Either your HDL level is low or your cholesterol is high or a combination of the two.\n \n
  <strong>Why is this important?</strong>\n \n
  A healthy ratio means your HDL is available in a large enough quantity to clear any excess cholesterol. Healthy HDL levels protect the heart by keeping total cholesterol levels at recommended levels. HDL basically removes harmful cholesterol from where it doesn’t belong, if you have low levels of HDL there’s not enough of it to keep excess cholesterol in check. If you have too much cholesterol you may not have enough HDL to help clear it.\n \n
  <strong>What can I do about it?</strong>\n \n
  If you smoke, it’s really important to attempt to quit. Smoking lowers HDL and raises total cholesterol. Keep a healthy weight and introduce regular exercise. Aerobic exercise can help improves HDL levels. Swap saturated fats for heart healthy fats such as olive oil and avocado. Saturated fats raise cholesterol. Monounsaturated fats lower total cholesterol and raise protective HDL levels.
  `,
  APB1030: `
  <strong>What does your score mean?</strong>\n \n
  Well done. Your ratio is healthy indicating either your total cholesterol is good or your HDL is good or both are good!\n \n
  <strong>Why is this important?</strong>\n \n
  A healthy ratio lowers the risk for heart disease and stroke.\n \n
  <strong>What can I do about it?</strong>\n \n
  Keep up the good work and keep choosing a lifestyle that promotes a healthy heart, eating a fresh whole food diet and exercising regularly.\n \n
  If either total cholesterol or HDL is orange and the other is green but the overall ratio is still green it is worth pointing out the lifestyle factors that help achieve green for both total cholesterol and HDL
  `,
  ACB1110: `
  <ul>
    <li>Reinforce importance of being seen by GP</li>
    <li>Explain health risks with no treatment</li>
    <li>Regular exercise and diet changes will help but may not be enough. It is still hugely beneficial to encourage reducing or cutting out refined carbohydrates, especially sugar. Discuss possible common sources such as fizzy sugary drinks, fruit juice, energy drinks, sugar added to tea and coffee, sugar in cakes and biscuits and snack foods, sweets, chocolate, ice cream and other common sweet foods. Addressing this will still make a difference.</li>
    <li>Nutritionist may be required in addition to GP</li>
  </ul>
  `,
  ACB1120: `
  <ul>
    <li>Reinforce importance of being seen by GP</li>
    <li>Explain health risks with no treatment</li>
    <li>Regular exercise and diet changes will help but may not be enough. It is still hugely beneficial to encourage reducing or cutting out refined carbohydrates, especially sugar. Discuss possible common sources such as fizzy sugary drinks, fruit juice, energy drinks, sugar added to tea and coffee, sugar in cakes and biscuits and snack foods, sweets, chocolate, ice cream and other common sweet foods. Addressing this will still make a difference.</li>
    <li>Nutritionist may be required in addition to GP</li>
  </ul>
  `,
  ACB1130: `
  <ul>
    <li>Reinforce importance of being seen by GP this level needs to be addressed and monitored</li>
    <li>Explain health risks with no treatment</li>
    <li>Regular exercise and diet changes will help but may not be enough</li>
    <li>Nutritionist may be required in addition to GP</li>
  </ul>
  `,
  ACB1140: `
  <ul>
    <li>Congratulate the individual, especially if the result is down to healthy lifestyle choices.</li>
    <li>Reinforce maintaining healthy lifestyle choices around diet and exercise can prevent an increased risk of diabetes</li>
  </ul>
  `,
  APB1110: `
  <strong>What does your score mean?</strong>\n \n
  This result indicates possible diabetes and will need the care  of your healthcare provider.\n \n
  <strong>Why is this important?</strong>\n \n
  This result suggests it is highly likely you have the health condition diabetes. This means you consistently have high levels of sugar in the blood. This can damage the blood vessels and other organs if left untreated.\n \n
  <strong>What can I do about it?</strong>\n \n
  It is very important you see your GP to discuss this result within the week. Your GP will want to investigate this result further. Medication may be required to get your blood sugar levels down to an acceptable level.\n \n
  You can also help yourself by increasing moderate exercise, keeping waist circumference at a healthy level, and making sure a healthy eating plan is followed.\n \n
  You may have noticed other symptoms of diabetes including excessive thirst, fatigue, sores that won’t heal and frequent trips to the bathroom. These are all signs of having high blood sugar. The good news is that research has shown healthy food choices can make a huge impact on reversing type two diabetes, so the sooner you get on to this the better!
  `,
  APB1120: `
  <strong>What does your score mean?</strong>\n \n
  This result indicates possible diabetes and will need the care  of your healthcare provider.\n \n
  <strong>Why is this important?</strong>\n \n
  This result suggests it is highly likely you have the health condition diabetes. This means you consistently have high levels of sugar in the blood. This can damage the blood vessels and other organs if left untreated.\n \n
  <strong>What can I do about it?</strong>\n \n
  It is very important you see your GP to discuss this result within the week. Your GP will want to investigate this result further. Medication may be required to get your blood sugar levels down to an acceptable level.\n \n
  You can also help yourself by increasing moderate exercise, keeping waist circumference at a healthy level, and making sure a healthy eating plan is followed.\n \n
  You may have noticed other symptoms of diabetes including excessive thirst, fatigue, sores that won’t heal and frequent trips to the bathroom. These are all signs of having high blood sugar. The good news is that research has shown healthy food choices can make a huge impact on reversing type two diabetes, so the sooner you get on to this the better!
  `,
  APB1130: `
  <strong>What does your score mean?</strong>\n \n
  This means the warning light for diabetes is on. It means your blood sugars have been higher than recommended for the last 2-3 months.\n \n
  <strong>Why is this important?</strong>\n \n
  This result could mean you are prediabetic. Pre means before; so this stage is just before diabetes. If it is not monitored and action taken, it is possible for this to develop into diabetes.  Type two  diabetes is responsible for a lot of health conditions like nerve damage, kidney disease and heart and blood vessel disease. Now is the time to make some changes to your lifestyle and reduce this number and the risks associated with it.\n \n
  <strong>What can I do about it?</strong>\n \n
  This measurement needs to be checked on and monitored by your GP now, so it is important you make an appointment soon.  In the meantime, lifestyle factors can make a positive impact on HbA1c measurements. This can be achieved by increasing moderate exercise, keeping waist circumference at a healthy level, and making sure a healthy eating plan is followed. Introduce lean servings of protein at each meal, a small serving of healthy fats, lots of green and other brightly coloured vegetables and low sugar dairy foods like plain yogurt and wholegrains.\n \n
  Avoiding sugar is recommended and a nutritionist can help you learn where to find hidden sugars in foods and help you with an individualised plan. It’s also advisable to cut down on, or avoid alcohol altogether.
  `,
  APB1140: `
  <strong>What does your score mean?</strong>\n \n
  Well done! This is a healthy HbA1c level. It shows normal blood sugar levels.\n \n
  <strong>Why is this important?</strong>\n \n
  This indicates good blood sugar levels and that the body is processing and utilising carbohydrate or sugars as it should.\n \n
  <strong>What can I do about it?</strong>\n \n
  Keep up the good work, stay active, keep weight at a healthy level, and continue to eat a wide range of healthy foods.
  `,
  ACB1210: `
  <ul>
    <li>Check for knowledge of previous HbA1c</li>
    <li>Refer to GP diabetes uncontrolled, are they actually taking prescribed medication? diet appropriate?</li>
    <li>Explain health risks with no treatment</li>
    <li>People with diabetes are advised to have this test every 3 to 6 months, or more frequently if it is not under control.</li>
    <li>Nutritionist may be required in addition to GP</li>
  </ul>
  `,
  ACB1220: `
  <ul>
    <li>Check for knowledge of previous HbA1c</li>
    <li>Refer to GP diabetes uncontrolled, are they actually taking prescribed medication? diet appropriate?</li>
    <li>Explain health risks with no treatment</li>
    <li>People with diabetes are advised to have this test every 3 to 6 months, or more frequently if it is not under control.</li>
    <li>Nutritionist may be required in addition to GP</li>
  </ul>
  `,
  ACB1230: `
  <ul>
    <li>Check for knowledge of previous HbA1c - is this better or worse than previously?</li>
    <li>Refer to GP if diabetes is not fully controlled, are they actually taking prescribed medication? Following diet and exercise advice? Congratulate individual if HbA1c is coming down.</li>
    <li>Explain the health risks associated with no treatment</li>
    <li>People with diabetes are advised to have this test every 3 to 6 months, or more frequently if it is not under control.</li>
    <li>Nutritionist may be required in addition to GP</li>
  </ul>
  `,
  ACB1240: `
  <ul>
    <li>Congratulate the individual. They are adhering to a healthy diet and/or taking medications as prescribed.</li>
    <li>People with diabetes are advised to have this test every 3 to 6 months, or more frequently if it is not under control.</li>
  </ul>
  `,
  APB1210: `
  <strong>What does your score mean?</strong>\n \n
  This result indicates that your blood sugar levels have been high over the last 2-3 months.\n \n
  <strong>Why is this important?</strong>\n \n
  This result suggests your diabetes is not being controlled adequately. If left untreated the inflammation caused by high sugar levels can cause blood vessel damage in your body. This puts you at risk for some of the long term health consequences of diabetes such as nerve damage, eye problems, kidney disease, heart disease, and circulation issues.\n \n
  <strong>What can I do about it?</strong>\n \n
  This result needs to be discussed with your GP within the week. Further lifestyle changes and/or medication may be required to lower your blood glucose to recommended levels.\n \n
  Focus on a healthy waist circumference. Stay away from food high in refined sugar and white flour. Reduce or eliminate alcohol and sugar based fizzy drinks or fruit juices. Focus on regular exercise and maintain a diet filled with fresh vegetables and lean protein sources with smaller serves of healthy fats and complex carbohydrates.
  It may help to work with a nutritionist to develop a personalised plan.
  `,
  APB1220: `
  <strong>What does your score mean?</strong>\n \n
  This result indicates that your blood sugar levels have been high over the last 2-3 months.\n \n
  <strong>Why is this important?</strong>\n \n
  This result suggests your diabetes is not being controlled adequately. If left untreated the inflammation caused by high sugar levels can cause blood vessel damage in your body. This puts you at risk for some of the long term health consequences of diabetes such as nerve damage, eye problems, kidney disease, heart disease, and circulation issues.\n \n
  <strong>What can I do about it?</strong>\n \n
  This result needs to be discussed with your GP within the week. Further lifestyle changes and/or medication may be required to lower your blood glucose to recommended levels.\n \n
  Focus on a healthy waist circumference. Stay away from food high in refined sugar and white flour. Reduce or eliminate alcohol and sugar based fizzy drinks or fruit juices. Focus on regular exercise and maintain a diet filled with fresh vegetables and lean protein sources with smaller serves of healthy fats and complex carbohydrates.
  It may help to work with a nutritionist to develop a personalised plan.
  `,
  APB1230: `
  <strong>What does your score mean?</strong>\n \n
  Your blood sugar levels over the last 2-3 months have regularly been outside the recommended range needed to protect your health.\n \n
  <strong>Why is this important?</strong>\n \n
  If you have achieved an orange light after a red light, well done and keep going! However, these sugar levels are still higher than they should be. There is still the potential for damage being done to the body and the risk of other health conditions as a result.\n \n
  <strong>What can I do about it?</strong>\n \n
  It is important that you make an appointment with your GP to discuss this result. Further lifestyle changes and/or medication changes may be required to lower your blood sugar to recommended levels.\n \n
  Keep focussing on a healthy waist circumference. Stay away from food high in refined sugars and white flour. Reduce or eliminate alcohol and sugar based fizzy drinks, energy drinks or fruit juices. Focus on regular exercise and maintain a healthy diet. It may help to work with a nutritionist to develop a personalised plan. 
  `,
  APB1240: `
  <strong>What does your score mean?</strong>\n \n
  This result indicates your diabetes is really well controlled. Congratulations, well done and keep up the good work.\n \n
  <strong>Why is this important?</strong>\n \n
  Keeping your blood sugars levels within a recommended range means your blood vessels and other organs are not having unnecessary damage done to them.\n \n
  <strong>What can I do about it?</strong>\n \n
  Continue to eat a healthy diet high in nutrients, low in refined carbohydrate and stay active. Making lifestyle choices that support your blood sugar levels to remain within the recommended range means better control and less medication may be required.
  `,
  ACB1310: `
  <ul>
    <li>Recommended individuals continue to monitor heart rate and record in the KYND app.\n \n  </li>
    <li>Explain how to measure heart rate and maximum heart rate calculation.220-age=max HR.</li>
    <li>Can be done manually as below, oximeter (from pharmacy) wearable or blood pressure monitor.</li>
    <li>Recommend increasing high intensity exercise to strengthen heart muscle and to keep health risks as low as possible.\n \n </li>
    <li>Introduce exercise slowly if not used to it, consult with GP if other health conditions first.</li>
  </ul>
  `,
  ACB1320: `
  <ul>
    <li>Recommend individuals continue to monitor heart rate and record in the KYND app.\n \n </li>
    <li>Increase high intensity exercise to strengthen heart muscle and to help keep health risks as low as possible. Get Dr’s ok if new to exercise,  and/or health conditions present, and/or mature age.</li>
    <li>Introduce exercise slowly if not used to it.</li>
    <li>Explain how to measure heart rate and maximum heart rate calculation 220-age=max HR.</li>
    <li>Can be done manually as below, oximeter (from pharmacy) wearable or blood pressure monitor.</li>
  </ul>
  `,
  ACB1330: `
  <ul>
    <li>Recommend individuals continue to monitor heart rate and record in the KYND app.\n \n </li>
    <li>Increase high intensity exercise to strengthen heart muscle and to help keep health risks as low as possible. Get Dr’s ok if new to exercise,  and/or health conditions present, and/or mature age.</li>
    <li>Introduce exercise slowly if not used to it.</li>
    <li>Explain how to measure heart rate and maximum heart rate calculation 220-age=max HR.</li>
    <li>Can be done manually as below, oximeter (from pharmacy) wearable or blood pressure monitor.</li>
  </ul>
  `,
  ACB1340: `
  <ul>
    <li>If a person is not fit and used to exercise there may be a medical reason for a very low heart rate e.g. health condition or they are taking beta blocker medication.</li>
    <li>Recommend - monitoring heart rate, manually, oximeter (from pharmacy) wearable or blood pressure monitor.</li>
    <li>See GP if heart rate consistently low <strong 60 if symptoms of dizziness and fatigue, lack of energy, low stamina, weakness, chest pains, confusion/memory problems, heart palpitations or flutters.</li>
  </ul>
  `,
  APB1310: `
  <strong>What does your score mean?</strong>\n \n
  This resting heart rate measurement is high and is outside of an acceptable range.\n \n
  <strong>Why is this important?</strong>\n \n
  A high resting heart rate means your heart is having to work a lot harder than it should to circulate blood around the body. A resting heart rate that is lower means a reduced rate of health issues like heart attacks.\n \n
  <strong>What can I do about it?</strong>\n \n
  Make an appointment to chat with your doctor if your resting heart rate is regularly giving a red light (above 100).\n \n
  There are ways to lower it and keep it within an acceptable range. The best way to lower your resting heart rate is to exercise. Even small amounts of high intensity exercise can make a change.\n \n
  Here is how to check heart rate easily: <a target="_blank" href="https://www.heartfoundation.org.nz/wellbeing/managing-risk/how-to-check-your-pulse-heart-rate">www.heartfoundation.org.nz</a>.\n \n
  However, it’s important not to exceed your maximum recommended heart rate during exercise, especially if you are new to it. You can work this out by taking 220 and minusing your age. For example, if you are 54 years old, your maximum recommended heart rate would be 220 -54 = 166. 
  `,
  APB1320: `
  <strong>What does your score mean?</strong>\n \n
  This resting heart rate measurement is within an acceptable range, but there may be room for improvement.\n \n
  <strong>Why is this important?</strong>\n \n
  A lower heart rate is a good sign your heart is working efficiently and isn't having to work too hard to circulate blood. Your resting heart rate, together with blood pressure and cholesterol measurements help identify potential health problems and give health professionals an idea of the health of your heart.\n \n
  A lower resting heart rate usually means a person is physically fit, this can mean health conditions, like a heart attack or stroke is less likely.\n \n
  <strong>What can I do about it?</strong>\n \n
  The most reliable way to lower your resting heart rate is to exercise. Even small amounts of high intensity exercise can make a change.\n \n
  Here is how to check heart rate easily: <a target="_blank" href="https://www.heartfoundation.org.nz/wellbeing/managing-risk/how-to-check-your-pulse-heart-rate">www.heartfoundation.org.nz</a>.\n \n
  However, it’s important not to exceed your maximum recommended heart rate during exercise, especially if you are new to it. You can work this out by taking 220 and minusing your age. For example, if you are 54 years old, your maximum recommended heart rate would be 220 -54 = 166.
  `,
  APB1330: `
  <strong>What does your score mean?</strong>\n \n
  This resting heart rate measurement is within an acceptable range, but there may be room for improvement.\n \n
  <strong>Why is this important?</strong>\n \n
  A lower heart rate is a good sign your heart is working efficiently and isn't having to work too hard to circulate blood. Your resting heart rate, together with blood pressure and cholesterol measurements help identify potential health problems and give health professionals an idea of the health of your heart.\n \n
  A lower resting heart rate usually means a person is physically fit, this can mean health conditions, like a heart attack or stroke is less likely.\n \n
  <strong>What can I do about it?</strong>\n \n
  The most reliable way to lower your resting heart rate is to exercise. Even small amounts of high intensity exercise can make a change.\n \n
  Here is how to check heart rate easily: <a target="_blank" href="https://www.heartfoundation.org.nz/wellbeing/managing-risk/how-to-check-your-pulse-heart-rate">www.heartfoundation.org.nz</a>.\n \n
  However, it’s important not to exceed your maximum recommended heart rate during exercise, especially if you are new to it. You can work this out by taking 220 and minusing your age. For example, if you are 54 years old, your maximum recommended heart rate would be 220 -54 = 166. 
  `,
  APB1340: `
  <strong>What does your score mean?</strong>\n \n
  Great work, you have a healthy resting heart rate. In general, the more physically fit you are, the lower your heart rate.\n \n
  <strong>Why is this important?</strong>\n \n
  Having a green light for heart rate is a good sign your heart is functioning well and isn't working too hard to circulate blood.\n \n
  Your resting heart rate, together with blood pressure and cholesterol measurements help identify potential health problems and give health professionals an idea of the health of your heart.\n \n
  <strong>What can I do about it?</strong>\n \n
  Keep up what you are doing by remaining physically active to keep your heart fit and strong.\n \n
  However, if your heart rate is very low (less than 50) and isn’t due to a very high level of fitness, it may be good to monitor your heart rate. Low heart rates can be linked to some medical conditions and medications. If your rate is consistently below 50 and you experience dizziness or fatigue it is important to discuss this with your doctor.\n \n
  Here is how to check heart rate easily:\n \n
  <a target="_blank" href="https://www.heartfoundation.org.nz/wellbeing/managing-risk/how-to-check-your-pulse-heart-rate">www.heartfoundation.org.nz</a>
  `,
  ACB1410: `
  <ul>
    <li>Consider whether the technique and effort in the test was satisfactory. Technicians should indicate potential issues.</li>
    <li>Explain there may be a lung condition present.</li>
    <li>Provide smoking cessation support if appropriate.</li>
    <li>They should organise an appointment to discuss these results with their GP.</li>
  </ul>
  `,
  ACB1420: `
  <ul>
    <li>Consider whether the technique and effort in the test was satisfactory. Technicians should indicate potential issues.</li>
    <li>Remind individuals there may be a lung condition present but other signs and symptoms have not developed yet.</li>
    <li>Provide smoking cessation support if appropriate.</li>
    <li>They should discuss results with their GP at their next appointment.</li>
  </ul>
  `,
  ACB1430: `
  <ul>
    <li>Congratulate non-smokers.</li>
    <li>Explain to those known as  "healthy smokers" that a small % of smokers have normal spirometry. The damage is still happening and health related effects like cancer are still a risk.</li>
    <li>Provide smoking cessation support if appropriate.</li>
  </ul>
  `,
  APB1410: `
  <strong>What does your score mean?</strong>\n \n
  This measurement suggests significant airflow obstruction. This can be caused by conditions such as asthma, old infections or smoking.\n \n
  <strong>Why is this important?</strong>\n \n
  Even though you may have no signs and symptoms of problems with your lungs, this measurement suggests you could be suffering from an obstructive lung condition like asthma or chronic obstructive pulmonary (lung) disease (COPD).\n \n
  <strong>What can I do about it?</strong>\n \n
  It would be a good idea to make an appointment with your GP to discuss these results, especially if you are experiencing signs and symptoms of shortness of breath, wheezing, tightness of chest or other breathing issues.\n \n
  If you are smoke free, fantastic! Continue with this lifestyle choice.\n \n
  If you smoke, quitting smoking is one of the best things you can do for your body.
  `,
  APB1420: `
  <strong>What does your score mean?</strong>\n \n
  This measurement suggests you may have mild to moderate airflow obstruction.\n \n
  <strong>Why is this important?</strong>\n \n
  Even though you may have no signs and symptoms of problems with your lungs, this measurement suggests you may be suffering from an obstructive lung condition like asthma or chronic obstructive pulmonary (lung) disease (COPD).\n \n
  <strong>What can I do about it?</strong>\n \n
  It would be a good idea to discuss the results of this test with your GP at your next appointment, especially if you are experiencing signs and symptoms of shortness of breath, wheezing, tightness of chest or other breathing issues.\n \n 
  If you are smoke free, fantastic! Continue this lifestyle.\n \n
  If you are a smoker, quitting smoking is one of the best things you can do for your body.
  `,
  APB1430: `
  <strong>What does your score mean?</strong>\n \n
  Your measurement suggests you aren’t experiencing any airway obstruction.\n \n
  <strong>Why is this important?</strong>\n \n
  This suggests you are unlikely to be suffering from lung conditions that cause breathing difficulties.\n \n
  <strong>What can I do about it?</strong>\n \n
  If you are smoke free, fantastic! Continue this lifestyle.\n \n
  If you are a smoker this result should not reassure you. A large amount of small airways disease must accumulate first before this measurement, which reflects large airways disease, becomes affected. If you smoke, there is no doubt you are damaging your lungs, it will just take time before damage is measurable in this manner.\n \n
  Quitting smoking is one of the best things you can do for your body.
  `,
  hearingSurvey: `Too much noise can permanently damage hearing.\n
  So, the foreman, or whoever is in charge, should be regularly checking how much noise workers are exposed to. This includes when something changes, like a new machine coming on to the worksite.\n
  Reducing noise at source, for example by repairing damaged mufflers or choosing quieter equipment, is the best way to protect people's hearing. Earmuffs are secondary protection.\n
  If someone's been exposed to a lot of noise, a hearing test should be done to see if they have hearing loss.
  `,
  noiseLevelMonitor: `A persong controlling a worksite must ensure workers (and peoople visitin the worksite) are not exposed to noise levels:\n \n
  <ul>
    <li>Equivalent to 85 decibels averaged over 8 hours, or</li>
    <li>A peak noise level above 140 decibels.</li>
  </ul>
  These are NZ standards and always apply, regardless of whether hearing protection is worn.\n \n
  The Sound Meter app is a tool that measures noise levels.\n \n
  For Android use the below link:
  <a target="_blank" href="https://play.google.com/store/apps/details?id=com.gamebasic.decibel&hl=pt_BR&gl=US">Sound Meter</a>\n \n
  For iOS use the below link:
  <a target="_blank" href="https://apps.apple.com/us/app/niosh-sound-level-meter/id1096545820">NIOSH Sound Level Meter</a>\n \n
  After installation, open the App.\n\n
  The current noise level (in decibels) is displayed.
  The graph below shows the level of noise over time and the Minimum, Average and Maximum Levels for the monitoring period.\n\n
  Take note of the Average and Total time (sec).\n\n
  Go back to the KYND app.
  Inside Noise Level Monitor select ADD NEW then Get Started.\n\n
  Add your Work Task.
  Add the Average and Maximum Noise Level (dB) value.
  Add the monitoring time period (sec).`,
  eyesightSurvey: `Eye problems can make things hard for us work.\n
  Far away things like road or warning sings can be hard to read. Or close-up things like maps and book work can look out of focus.\n
  About one in 12 men (and a small number of women) also have trouble telling the difference between colours - particularly greens and red, and occasionally blues.`,
  hearingCheck: `Noise at work can be harmfull to hearing, but hearing loss is preventable if you adopt noise control measures and create an environment that safe.\n\n
  Click on the following WorkSafe website link to learn more about noise exposure, noise induced hearing loss how to manage noise risks.\n\n
  <a target="_blank" href="http://www.worksafe.govt.nz/topic-and-industry/noise/">www.worksafe.govt.nz/topic-and-industry/noise/</a>`,
  visionCheck: `Vision impairment or vision loss is a decreased ability to see to a degree that causes problems not fixable by usual means, such as glasses.\n
  For some occupations are subject to regular vision testing because there may be reliance on visual identification of risk (overhead hazards, driving, machine operation, man/machine interaction and so on).`,
  hearingQuestionnaireGreen: `
  <strong>What does your score mean?</strong>\n \n
  Great work, there is nothing to suggest there are any concerns with your hearing at present.\n \n
  <strong>Why is this important?</strong>\n \n
  Hearing well is vital to our overall wellbeing and health. It is one of our most important senses for connecting us to people and interacting with the world. Adequate hearing improves your communication in the workplace, your safety, your social connections and your quality of life. Impaired hearing is strongly associated with an increased risk of a reduction in a person’s physical and mental health.\n \n
  <strong>What can I do about it?</strong>\n \n
  Although the screening questions have not identified any concerns, it is important to have a hearing screen periodically.  As hearing loss can occur gradually and go unnoticed,  a quick hearing screen by an audiologist or as part of a KYND health check is very important.\n \n
  If you are exposed to excessive loud noise in the workplace or home you should be screened annually.   All ages can be screened for hearing loss, however, a general recommendation is you should have a baseline test at least by age 60 and earlier if any symptoms develop.
  `,
  hearingQuestionnaireGreenClinician: `
  Even if green light,  all ages can be screened for hearing loss, recommend hearing screen at least by age 60, annually if exposed to excessive noise at work or socially.  Hearing screens are often free at audiologists.\n \n
  Check with the employee for any obvious causes to indicate if earlier screening may be necessary:\n \n
  <ul>
    <li>Excessive noise exposure</li>
    <li>Injury</li>
    <li>Viral infections (such as measles or mumps)</li>
    <li>Wax buildup</li>
    <li>Ototoxic drugs (medications that damage hearing) chemotherapy, gentamicin, furosemide, NSAIDS.</li>
    <li>Genetics</li>
  </ul>
  `,
  hearingQuestionnaireOrange: `
  <strong>What does your score mean?</strong>\n \n
  Your result is showing a warning light. You are experiencing signs and symptoms that indicate your hearing needs further investigation.\n \n
  <strong>Why is this important?</strong>\n \n
  Being able to hear adequately is vital for us to be able to connect and communicate with others, it gives us information about what is happening around us and alerts us to danger. It provides us with enjoyment through listening to music or the sounds of nature. Because hearing loss often occurs gradually, it is often difficult to notice how it affects all aspects of our health and overall quality of life.\n \n
  <strong>What can I do about it?</strong>\n \n
  It is recommended that your hearing needs to have more investigation. If you have noticed difficulties with your hearing, get this checked out right away by an audiologist. Hearing tests are an extremely easy, quick, and painless way to determine if you have hearing loss.\n \n
  If you have developed any signs and symptoms like ringing in your ears, discharge or pain these are best checked out with your doctor.
  `,
  hearingQuestionnaireOrangeClinician: `
  <ul>
    <li>Employee needs to have a hearing screen or visit Dr depending on whether hearing difficulties noted (audiologist) or clinical symptoms present (Dr).</li>
  </ul>
  `,
  visionQuestionnaireGreen: `
  <strong>What does your score mean?</strong>\n \n
  Your result means you are on top of monitoring the health of your eyes and aren’t experiencing any concerns with your vision.\n \n
  <strong>Why is this important?</strong>\n \n
  Regular checks on the health of your eyes reduces the risk of vision loss occurring.  This helps to maintain your overall well-being and reduce your risk of accidents. It also means any health conditions that could be developing without your awareness are picked up as soon as possible.\n \n
  <strong>What can I do about it?</strong>\n \n
  Continue to reduce your health risks by having an eye exam with an optometrist every two years. Organise an exam earlier if you develop any eye problems or if advised to by your doctor or optometrist. If you are over 65 years, it is recommended that you have an eye test every year.
  `,
  visionQuestionnaireGreenClinician: `
  <ul>
    <li>It is recommended that people undergo an eye exam every 2 years or earlier if symptoms develop</li>
    <li>People over 65 years should be screened annually.</li>
  </ul>
  `,
  visionQuestionnaireOrange: `
  <strong>What does your score mean?</strong>\n \n
  Your result is showing a warning light.  This might be because you have not had a vision exam in the last 2 years, or you are experiencing signs and symptoms that indicate you need to have a vision exam now.\n \n
  <strong>Why is this important?</strong>\n \n
  Vision loss can be prevented or treated, this is why regular eye examinations every 2 years are recommended.  It’s important that any changes in your vision are detected as soon as possible. Any eye conditions, or other health conditions, that can develop slowly without your awareness. Symptoms like blurred vision usually means you need glasses — but you should have your eyes checked no matter what. Not only can blurred vision signal a medical problem with the eye itself, it can also be a sign of a more serious illness like diabetes.\n \n
  <strong>What can I do about it?</strong>\n \n
  Organise to have an eye examination with an optometrist if it has been longer than 2 years since your last eye check or you are experiencing changes or difficulties with your vision.\n \n
  Any eye symptoms like pain, double vision or redness especially if other symptoms are present, need to be assessed by your doctor as soon as possible.
  `,
  visionQuestionnaireOrangeClinician: `
  <ul>
    <li>Employee needs to have a eye examination or visit Dr depending on whether vision difficulties noted (optometrist) or clinical symptoms present (Dr)</li>
  </ul>
  `,
  hearingScreenGreen: `
  <strong>What does your score mean?</strong>\n \n
  Great work, no problems have been identified in your hearing questionnaire or hearing screen, to suggest there are any concerns with your hearing at present.\n \n
  <strong>Why is this important?</strong>\n \n
  Hearing well is vital to our overall wellbeing and health. It is one of our most important senses for connecting us to people and interacting with the world.  Adequate hearing improves your communication in the workplace, your safety, your social connections and your quality of life. Impaired hearing is strongly associated with an increased risk of a reduction in a person’s physical and mental health.\n \n
  <strong>What can I do about it?</strong>\n \n
  Repeat a hearing screen if any signs or symptoms affecting your hearing develop. If you are exposed to excessive loud noise in the workplace or home you should be screened annually. All ages can be screened for hearing loss, however, a general recommendation is you should have a baseline test at least by age 60 and earlier if any symptoms develop.\n \n
  Continue to protect your hearing by:\n \n
  <ul>
    <li>Turning the volume down.</li>
    <li>Walking away from the loud noise.</li>
    <li>Taking breaks from loud noise.</li>
    <li>Avoiding loud, noisy activities and places.</li>
    <li>Always use hearing protection when exposed to loud noise.</li>
  </ul>
  `,
  hearingScreenGreenClinician: `
  Even with a  green light,  all ages can be screened for hearing loss, recommend hearing screen at least by age 60, annually if exposed to excessive noise at work or socially. Repeat hearing screens are often free at audiologists.\n \n
  Advise to continue to protect hearing by:\n \n
  <ul>
    <li>Turning the volume down.</li>
    <li>Walking away from the loud noise.</li>
    <li>Taking breaks from loud noise.</li>
    <li>Avoiding loud, noisy activities and places.</li>
    <li>Always use hearing protection when exposed to loud noise.</li>
  </ul>
  `,
  hearingScreenOrange: `
  <strong>What does your score mean?</strong>\n \n
  Your result is showing a warning light. Either you are experiencing signs and symptoms that indicate your hearing needs further investigation or your audiogram results indicate that your hearing is reduced.\n \n
  <strong>Why is this important?</strong>\n \n
  Being able to hear adequately is vital for us to be able to connect and communicate with others, it gives us information about what is happening around us and alerts us to danger. It provides us with enjoyment through listening to music or the sounds of nature. Because hearing loss often occurs gradually, it is often difficult to notice how it affects all aspects of our health and overall quality of life.\n \n
  <strong>What can I do about it?</strong>\n \n
  It is recommended that your hearing needs to have more investigation. If the KYND clinician has noted an issue on your audiogram you should make an appointment with an audiologist for further investigation.\n \n
  If your audiogram showed no concerns but you are experiencing signs and symptoms like ringing in your ears, discharge or pain these are best checked out with your doctor.
  `,
  hearingScreenOrangeClinician: `
  <ul>
    <li>Employee needs to have a hearing screen or visit Dr depending on whether hearing difficulties noted (audiologist) or clinical symptoms present (Dr)</li>
  </ul>
  `,
  hearingScreenRed: `
  <strong>What does your score mean?</strong>\n \n
  Your result is showing a warning light. You are experiencing signs and symptoms that indicate your hearing needs further investigation. This is especially so if you are working in a safety critical role where inadequate hearing could affect the safety of yourself, or others.\n \n
  <strong>Why is this important?</strong>\n \n
  Being able to hear adequately is vital for us to be able to connect and communicate with others, it gives us information about what is happening around us and alerts us to danger. It provides us with enjoyment through listening to music or the sounds of nature. Because hearing loss often occurs gradually, it is often difficult to notice how it affects all aspects of our health and overall quality of life.\n \n
  <strong>What can I do about it?</strong>\n \n
  It is recommended that your hearing should have more investigation carried out by an audiologist. Hearing tests are an extremely easy, quick, and painless way to determine if you have hearing loss. As safety in the workplace could be compromised, your employer will be notified of your result. They will work together with you, to ensure you and your workmates are kept safe on the job.
  `,
  hearingScreenRedClinician: `
  <ul>
    <li>The employee needs to be followed up by an audiologist. Confidentiality broken and this result shows up on a named employee basis on the organisations dashboard.</li>
  </ul>
  `,
  visionScreenGreen: `
  <strong>What does your score mean?</strong>\n \n
  Your result means you are on top of monitoring the health of your eyes and aren’t experiencing any concerns with your vision.\n \n
  <strong>Why is this important?</strong>\n \n
  Regular checks on the health of your eyes reduces the risk of vision loss occurring. This helps to maintain your overall well-being and reduce your risk of accidents. It also means any health conditions that could be developing without your awareness are picked up as soon as possible.\n \n
  <strong>What can I do about it?</strong>\n \n
  While no problems have been identified with your ability to see, it is important to continue to reduce your health risks by having an eye exam with an optometrist <strong>every two years</strong>. The vision screen you have undertaken is not a substitute for an eye examination by an optometrist.\n \n
  Make sure you organise an exam earlier if you develop any eye problems or if advised to by your doctor or optometrist. If you are over 65 years, it is recommended that you have an eye test every year.
  `,
  visionScreenGreenClinician: `
  <ul>
    <li>The vision screen does not replace a full eye examination with an optometrist.</li>
    <li>It is recommended that people undergo an eye exam every 2 years or earlier if symptoms develop</li>
    <li>People over 65 years should be screened annually.</li>
  </ul>
  `,
  visionScreenOrange: `
  <strong>What does your score mean?</strong>\n \n
  Your result is showing a warning light.  This will be because you have not had a full vision exam in the last 2 years, and/or you are experiencing signs and symptoms affecting your vision, and/or concerns regarding your vision have been picked up in this vision screen.\n \n
  <strong>Why is this important?</strong>\n \n
  Vision loss can be prevented or treated, this is why regular eye examinations every 2 years are recommended. It’s important that any changes in your vision are detected as soon as possible.  any eye conditions, or other health conditions, that can develop slowly without your awareness. Symptoms like blurred vision usually means you need glasses — but you should have your eyes checked no matter what. Not only can blurred vision signal a medical problem with the eye itself, it can also be a sign of a more serious illness like diabetes.\n \n
  <strong>What can I do about it?</strong>\n \n
  If problems with your vision have been identified in this vision screen you should make an appointment with an optometrist for a full eye examination.\n \n
  Even if no problems were identified with your vision, it is important to continue to reduce your health risks by having an eye exam with an optometrist <strong>every two years</strong>. The vision screen you have undertaken is not a substitute for an eye examination by an optometrist.\n \n
  Any eye symptoms like pain, double vision or redness especially if other symptoms are present, need to be assessed by your doctor as soon as possible.
  `,
  visionScreenOrangeClinician: `
  <ul>
    <li>Employee needs to have a eye examination or visit Dr depending on whether vision difficulties noted (optometrist) or clinical symptoms present (Dr)</li>
  </ul>
  `,
  visionScreenRed: `
  <strong>What does your score mean?</strong>\n \n
  Your result is showing a warning light. You are experiencing signs and symptoms that indicate your vision needs further investigation. This is especially so if you are working in a safety critical role where inadequate vision could affect the safety of yourself, or others.\n \n
  <strong>Why is this important?</strong>\n \n
  Having adequate vision is important so that you can undertake your work efficiently and safely. Vision loss can also be prevented or treated so it’s important that any changes in your vision are detected as soon as possible. It’s possible that eye conditions, or other health conditions can develop slowly without your awareness, so a full eye examination at an optometrist is vital. Symptoms like blurred vision may just mean you need glasses — but you should have your eyes checked no matter what. Not only can blurred vision signal a medical problem with the eye itself, it can also be a sign of a more serious illness like diabetes.\n \n
  <strong>What can I do about it?</strong>\n \n
  If problems with your vision have been identified in this vision screen you should make an appointment with an optometrist for a full eye examination.  As safety in the workplace could be compromised, your employer will be notified of your result. They will work together with you, to ensure you and your workmates are kept safe on the job.
  `,
  visionScreenRedClinician: `
  <ul>
    <li>The employee needs to be followed up by an audiologist. Confidentiality broken and this result shows up on a named employee basis on the organisations dashboard.</li>
  </ul>
  `
};
