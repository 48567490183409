import { isEmpty, last } from 'lodash';
import { useEffect, useState } from 'react';
import { getUploadedImagesUrl } from '../../../../../../functions/jobHealthHazards/getUploadedExams';
import { useHealthCheck } from '../../../../../../hooks/healthCheck';
import { FileExamsGallery } from '../../../../../FileExamsGallery';
import { Title } from './styles';

export function StructuralHealth() {
  const {
    jobHealthHazardsHealthCheck,
    untouchedUser,
    user,
    isOngoingHealthCheck,
    isUserDetailsPage
  } = useHealthCheck();

  const [uploadedExams, setUploadedExams] = useState(
    [] as Record<string, string>[]
  );
  const [isFetchingExams, setIsFetchingExams] = useState(false);

  useEffect(() => {
    if (!isEmpty(uploadedExams) || isFetchingExams) return;

    const ongoingUploadedExams =
      jobHealthHazardsHealthCheck?.structuralHealth?.uploadedExams;
    // If we are in the User Details page, we must show the files stored inside the user.
    if (isUserDetailsPage || !isEmpty(ongoingUploadedExams)) {
      const structuralHealthExams = isUserDetailsPage
        ? untouchedUser.exams?.structuralHealth?.uploadedExams
        : ongoingUploadedExams;

      if (isEmpty(structuralHealthExams)) return;

      setIsFetchingExams(true);

      structuralHealthExams?.forEach((path) => {
        getUploadedImagesUrl(path).then((result) => {
          const fileName = last(path.split('/')) || '';
          setUploadedExams((prev) => [
            ...prev,
            { name: fileName, url: result }
          ]);
        });
      });
      return;
    }

    // If it is an ongoing health with new uploaded files, we must show the files that are being uploaded;
    const fileExams = jobHealthHazardsHealthCheck?.structuralHealth?.exams;
    if (!isEmpty(fileExams) && fileExams) {
      const parsedFileExams = fileExams.map((file) => ({
        url: URL.createObjectURL(file),
        name: file.name
      }));
      setUploadedExams(parsedFileExams);
    }
  }, [
    isOngoingHealthCheck,
    untouchedUser,
    user,
    isFetchingExams,
    uploadedExams,
    isUserDetailsPage,
    jobHealthHazardsHealthCheck?.structuralHealth
  ]);

  return (
    <>
      {!isEmpty(uploadedExams) && (
        <>
          <Title>Structural Health</Title>
          <FileExamsGallery
            componentId="structural-health-score"
            uploadedExams={uploadedExams}
          />
        </>
      )}
    </>
  );
}
