import { useCallback } from 'react';
import { camelCase, get } from 'lodash';

import visionIcon from '../../../../../../assets/eyesight.svg';
import { useHealthCheck } from '../../../../../../hooks/healthCheck';
import { Question } from '../../../../../../models/question';
import { AnswerCard } from '../../../AnswerCard';
import { VisionExams } from './fields/VisionExams';
import {
  QuestionnaireHeader,
  QuestionnaireIcon,
  QuestionnaireTitle,
  QuestionnaireWrapper,
  QuestionsWrapper
} from './styles';

interface VisionProps {
  questions: Question[];
}

export const Vision = ({ questions }: VisionProps) => {
  const { bodyAnswers, answeredBodyQuestions } = useHealthCheck();

  const isQuestionnaireComplete = useCallback(
    (questionnaire: string) => {
      const isComplete = get(
        answeredBodyQuestions,
        camelCase(questionnaire),
        false
      );

      return isComplete;
    },
    [answeredBodyQuestions]
  );

  return (
    <QuestionnaireWrapper isComplete={isQuestionnaireComplete('Vision Screen')}>
      <QuestionnaireHeader>
        <QuestionnaireIcon>
          <img src={visionIcon} alt="vision icon" />
        </QuestionnaireIcon>

        <QuestionnaireTitle>Vision Screen</QuestionnaireTitle>
      </QuestionnaireHeader>

      <QuestionsWrapper>
        {questions
          .sort((a, b) => (a.order > b.order ? 1 : -1))
          .map((question, questionIndex) => (
            <AnswerCard
              key={question.id}
              answers={bodyAnswers}
              questionIndex={questionIndex}
              question={question}
            />
          ))}
        <VisionExams />
      </QuestionsWrapper>
    </QuestionnaireWrapper>
  );
};
