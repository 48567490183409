import { useMemo } from 'react';
import { camelCase } from 'lodash';

import { useHealthCheck } from '../../../../hooks/healthCheck';
import { QuestionnaireProps, useQuestions } from '../../../../hooks/questions';
import { AnswerCard } from '../AnswerCard';

import { Container, QuestionsWrapper, QuestionWrapper } from './styles';

export function MindQuestions() {
  const { mindQuestionnaires } = useQuestions();
  const { currentMindTab, mindAnswers } = useHealthCheck();

  const { questions } = useMemo(() => {
    const questionnaireData = mindQuestionnaires.find(
      (data) => currentMindTab === camelCase(data.questionnaire)
    );

    if (questionnaireData) {
      return questionnaireData;
    }

    return {} as QuestionnaireProps;
  }, [mindQuestionnaires, currentMindTab]);

  return (
    <Container>
      <QuestionsWrapper>
        {questions
          .sort((a, b) => (a.order > b.order ? 1 : -1))
          .map((question, questionIndex) => (
            <QuestionWrapper key={question.id}>
              <AnswerCard
                answers={mindAnswers}
                questionIndex={questionIndex}
                question={question}
              />
            </QuestionWrapper>
          ))}
      </QuestionsWrapper>
    </Container>
  );
}
