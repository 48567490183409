import { FirstPage, LastPage, Search } from '@mui/icons-material';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useCallback } from 'react';
import { useOrganisation } from '../../hooks/organisations';

import {
  Container,
  PropFilterBox,
  PropFilterWrapper,
  SearchUsersButton,
  UserSearchBox
} from './styles';

interface OrganisationFilterProps {
  hideDivision?: boolean;
  hideUserSearch?: boolean;
  hideOrganisation?: boolean;
  checkStatus: string;
  setCheckStatus: React.Dispatch<React.SetStateAction<string>>;
  checkDay: string;
  setCheckDay: React.Dispatch<React.SetStateAction<string>>;
  showDatePicker: boolean;
  specificDate: string;
  handleChangeSpecificDate: (dateString: string) => Promise<void>;
}

export function OrganisationFilterCheckPage({
  hideDivision = false,
  hideUserSearch = false,
  hideOrganisation = false,
  checkStatus,
  setCheckStatus,
  checkDay,
  setCheckDay,
  showDatePicker,
  specificDate,
  handleChangeSpecificDate
}: OrganisationFilterProps) {
  const {
    industriesNames,
    organisationsNames,
    divisionsNames,
    chosenIndustry,
    chosenOrganisation,
    chosenDivision,
    handleChangeIndustry,
    handleChangeOrganisation,
    handleChangeDivision,
    userSearch,
    setUserSearch,
    fetchSearchedUsers,
    setPropToFilter,
    propToFilter
  } = useOrganisation();

  const acceptBlankSearch = true;

  const handleChangeCheckStatus = useCallback(
    (status: string) => {
      localStorage.setItem('@KyndClinician:healthCheckStatusFilter', status);
      setCheckStatus(status);
    },
    [setCheckStatus]
  );

  const handleChangeCheckDay = useCallback(
    (day: string) => {
      localStorage.setItem('@KyndClinician:healthCheckDayFilter', day);
      setCheckDay(day);
    },
    [setCheckDay]
  );

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Autocomplete
            disablePortal
            id="industries-combo-box"
            options={industriesNames}
            value={chosenIndustry}
            freeSolo={false}
            disableClearable
            onChange={(e) => handleChangeIndustry(e.currentTarget.innerHTML)}
            renderInput={(params) => (
              <TextField {...params} required fullWidth label="Industry" />
            )}
          />
        </Grid>

        {!hideOrganisation && (
          <Grid item xs={12} sm={3}>
            <Autocomplete
              disablePortal
              id="organisations-combo-box"
              options={organisationsNames}
              value={chosenOrganisation}
              freeSolo={false}
              disableClearable
              onChange={(e) => {
                handleChangeOrganisation(e.currentTarget.innerHTML);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  label="Organisation"
                />
              )}
            />
          </Grid>
        )}

        {!hideDivision && (
          <Grid item xs={12} sm={3}>
            <Autocomplete
              disablePortal
              id="divisions-combo-box"
              options={divisionsNames}
              value={chosenDivision}
              freeSolo={false}
              disableClearable
              onChange={(e) => handleChangeDivision(e.currentTarget.innerHTML)}
              renderInput={(params) => (
                <TextField {...params} required fullWidth label="Division" />
              )}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={3}>
          <Autocomplete
            disablePortal
            id="checks-status-combo-box"
            options={[
              'Completed health checks',
              'Ongoing health checks',
              'All health checks'
            ]}
            value={checkStatus}
            defaultValue={checkStatus}
            freeSolo={false}
            disableClearable
            onChange={(e) => handleChangeCheckStatus(e.currentTarget.innerHTML)}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                fullWidth
                label="Health check status"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Autocomplete
            disablePortal
            id="checks-day-combo-box"
            options={[
              'Today',
              'Yesterday',
              '2 days ago',
              '5 days ago',
              '15 days ago',
              'Specific day'
            ]}
            value={checkDay}
            defaultValue={checkDay}
            freeSolo={false}
            disableClearable
            onChange={(e) => handleChangeCheckDay(e.currentTarget.innerHTML)}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                fullWidth
                label="Health check day"
              />
            )}
          />
        </Grid>

        {showDatePicker && (
          <Grid item xs={12} sm={3}>
            <DesktopDatePicker
              label="Health check date"
              inputFormat="dd/MM/yyyy"
              value={specificDate}
              onChange={(date) => date && handleChangeSpecificDate(date)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
        )}

        {!hideUserSearch && (
          <Grid item xs={12} sm={3}>
            <UserSearchBox>
              <TextField
                fullWidth
                type="date"
                label="Search user"
                value={userSearch}
                onChange={(e) => setUserSearch(e.target.value)}
              />

              <PropFilterWrapper>
                <PropFilterBox
                  type="button"
                  onClick={() => setPropToFilter('lowerFirstName')}
                  isActive={propToFilter === 'lowerFirstName'}
                  title="Filter by first name"
                >
                  <FirstPage />
                </PropFilterBox>

                <PropFilterBox
                  type="button"
                  onClick={() => setPropToFilter('lowerLastName')}
                  isActive={propToFilter === 'lowerLastName'}
                  title="Filter by last name"
                >
                  <LastPage />
                </PropFilterBox>
              </PropFilterWrapper>

              <SearchUsersButton
                type="button"
                onClick={() => fetchSearchedUsers(acceptBlankSearch)}
                title="Search users - blank search to fetch all users at once (not recommended)"
              >
                <Search />
              </SearchUsersButton>
            </UserSearchBox>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
