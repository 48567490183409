import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 10px;
  box-shadow: 2px 2px 10px;
  padding: 10px;
  margin-top: 10px;

  &.jobHealthHazards {
    grid-area: h;
  }
`;

export const QuestionnairesWrapper = styled.main`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
  margin: 10px 0 0;
`;

export const SectionTitle = styled.strong`
  display: flex;
  flex: 1;

  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 3px;

  font-size: 18px;
  font-weight: 500;
`;
