// eslint-disable-next-line import/no-unresolved
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { OpenedGalleryImage, ZoomButtonsContainer } from './styles';

interface ImageViewWithZoomProps {
  imageSrc: string;
}

export const ImageViewWithZoom = ({ imageSrc }: ImageViewWithZoomProps) => {
  return (
    <>
      <TransformWrapper
        initialScale={1}
        initialPositionX={0}
        initialPositionY={0}
      >
        {({ zoomIn, zoomOut, resetTransform }) => (
          <>
            <ZoomButtonsContainer>
              <button onClick={() => zoomIn()} type="button">
                +
              </button>
              <button onClick={() => zoomOut()} type="button">
                -
              </button>
              <button onClick={() => resetTransform()} type="button">
                x
              </button>
            </ZoomButtonsContainer>
            <TransformComponent>
              <OpenedGalleryImage
                src={imageSrc}
                alt="Exam"
                id="openedImageGallery"
              />
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    </>
  );
};
