import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import * as Sentry from '@sentry/react';

import { isEmpty, isNil, trim } from 'lodash';
import { useAuth } from './auth';
import { useToast } from './toast';

import { getOrganisations, getSearchedUsers } from '../functions/organisations';

import { Spinner } from '../components/Spinner';
import { OngoingHealthCheck, User } from '../models/user';
import { Organisation } from '../models/organisation';
import { calculateAllOrgsSummary } from '../utils/allOrgsSummary';
import { getOrgOngoingHealthChecks } from '../functions/users';

export interface AdditionalSearchProps {
  propToFilter: 'lowerFirstName' | 'lowerLastName';
  searchString: string;
  organisationId: string;
}

interface AdditionalSearchFunction {
  call: (props: AdditionalSearchProps) => Promise<void>;
}

interface OrganisationsContextProps {
  currentOrganisation: Organisation;
  currentClients: User[];
  users: User[];
  setUsers: React.Dispatch<React.SetStateAction<User[]>>;
  isLoadingUsers: boolean;
  industriesNames: string[];
  organisationsNames: string[];
  divisionsNames: string[];
  chosenIndustry: string;
  chosenOrganisation: string;
  chosenDivision: string;
  chosenDivisionId: string;
  userSearch: string;
  organisations: Organisation[];
  propToFilter: 'lowerFirstName' | 'lowerLastName';
  handleChangeIndustry: (industryName: string) => void;
  handleChangeOrganisation: (organisationName: string) => void;
  handleChangeDivision: (divisionName: string) => void;
  setUserSearch: (search: string) => void;
  setOrganisations: (organisations: Organisation[]) => void;
  setCurrentOrganisation: (organisation: Organisation) => void;
  fetchSearchedUsers: (acceptBlankSearch?: boolean) => void;
  setPropToFilter: React.Dispatch<
    React.SetStateAction<'lowerFirstName' | 'lowerLastName'>
  >;
  setAdditionalSearch?: React.Dispatch<
    React.SetStateAction<AdditionalSearchFunction>
  >;
  orgOngoingHealthChecks: OngoingHealthCheck[];
  setOrgOngoingHealthChecks: React.Dispatch<
    React.SetStateAction<OngoingHealthCheck[]>
  >;
  getSelectedOrganisationId: () => string;
  organizationHasHearingVisionV2Access: boolean;
}

const OrganisationsContext = createContext<OrganisationsContextProps>(
  {} as OrganisationsContextProps
);

export const OrganisationProvider: React.FC = ({ children }) => {
  const [currentOrganisations, setCurrentOrganisations] = useState(
    [] as Organisation[]
  );
  const [currentOrganisation, setCurrentOrganisation] = useState(
    {} as Organisation
  );
  const [currentClients, setCurrentClients] = useState([] as User[]);
  const [organisations, setOrganisations] = useState([] as Organisation[]);
  const [industriesNames, setIndustriesNames] = useState([] as string[]);
  const [organisationsNames, setOrganisationsNames] = useState([] as string[]);
  const [divisionsNames, setDivisionsNames] = useState(['All']);
  const [chosenIndustry, setChosenIndustry] = useState('Accounting');
  const [chosenOrganisation, setChosenOrganisation] = useState('');
  const [chosenDivision, setChosenDivision] = useState('All');
  const [chosenDivisionId, setChosenDivisionId] = useState('all');
  const [userSearch, setUserSearch] = useState('');
  const [additionalSearch, setAdditionalSearch] = useState<
    undefined | AdditionalSearchFunction
  >();
  const [propToFilter, setPropToFilter] = useState<
    'lowerFirstName' | 'lowerLastName'
  >('lowerFirstName');
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [users, setUsers] = useState([] as User[]);
  const [orgOngoingHealthChecks, setOrgOngoingHealthChecks] = useState<
    OngoingHealthCheck[]
  >([]);
  const [triggerFetchOrgOngoingChecks, setTriggerFetchOrgOngoingChecks] =
    useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const { user, userIsLogged, allowedOrganisationsIds } = useAuth();
  const { addToast } = useToast();

  useEffect(() => {
    const getAllOrganisations = async () => {
      setIsLoading(true);

      if (!userIsLogged) {
        setIndustriesNames(['N/A']);
        setOrganisationsNames(['N/A']);
        setDivisionsNames(['N/A']);
        setChosenIndustry('N/A');
        setChosenOrganisation('N/A');
        setChosenDivision('N/A');

        setIsLoading(false);
        return;
      }

      if (!allowedOrganisationsIds || !allowedOrganisationsIds.length) {
        setIndustriesNames(['N/A']);
        setOrganisationsNames(['N/A']);
        setDivisionsNames(['N/A']);
        setChosenIndustry('N/A');
        setChosenOrganisation('N/A');
        setChosenDivision('N/A');

        setIsLoading(false);
        return;
      }

      const allOrganisations = await getOrganisations();

      const filteredOrganisations = allOrganisations.filter((org) =>
        allowedOrganisationsIds.includes(org.id)
      );

      setOrganisations(filteredOrganisations);

      if (filteredOrganisations.length) {
        const firstOrganisation = filteredOrganisations[0];

        const filteredCurrentOrganisations = filteredOrganisations.filter(
          (org) => org.industry === firstOrganisation.industry
        );

        setCurrentOrganisations(filteredCurrentOrganisations);
        setCurrentOrganisation(firstOrganisation);
        setChosenIndustry(firstOrganisation.industry);
        setChosenOrganisation(firstOrganisation.name);
        setChosenDivision('All');

        const divisions = ['All'] as string[];

        firstOrganisation.divisions.forEach((division) => {
          divisions.push(division.name);
        });

        setDivisionsNames(divisions);

        const organisationsNamesArray = [] as string[];
        const industries = [] as string[];

        filteredOrganisations.forEach((organisation) => {
          if (organisation.industry === firstOrganisation.industry) {
            organisationsNamesArray.push(organisation.name);
          }

          if (!industries.includes(organisation.industry)) {
            industries.push(organisation.industry);
          }
        });

        setUsers([]);
        setUserSearch('');

        industries.sort((a, b) => (a.trim() > b.trim() ? 1 : -1));

        setIndustriesNames(industries);
        setOrganisationsNames(['All', ...organisationsNamesArray]);
      }

      setIsLoading(false);
    };

    getAllOrganisations();
  }, [allowedOrganisationsIds, userIsLogged]);

  const handleChangeIndustry = useCallback(
    async (industryName: string) => {
      setIsLoading(true);
      setChosenIndustry(industryName);

      const firstOrganisation = organisations.find(
        ({ industry }) => trim(industry) === trim(industryName)
      );

      if (!firstOrganisation) return;

      setCurrentOrganisation(firstOrganisation);
      setChosenIndustry(industryName);
      setChosenOrganisation(firstOrganisation.name);

      const divisions = ['All'] as string[];

      firstOrganisation.divisions.forEach((division) => {
        divisions.push(division.name);
      });

      setDivisionsNames(divisions);
      setChosenDivision('All');
      setChosenDivisionId('all');

      const organisationsNamesArray = [] as string[];
      const currentOrganisationsArray = [] as Organisation[];

      organisations.forEach((org) => {
        if (trim(org.industry) === trim(industryName)) {
          organisationsNamesArray.push(org.name);
          currentOrganisationsArray.push(org);
        }
      });

      setCurrentOrganisations(currentOrganisationsArray);
      setOrganisationsNames(['All', ...organisationsNamesArray]);
      setIsLoading(false);
    },
    [organisations]
  );

  const handleChangeOrganisation = useCallback(
    async (organisationName: string) => {
      setIsLoading(true);

      setUsers([]);
      setUserSearch('');

      if (organisationName === 'All') {
        const generalOrganisation = calculateAllOrgsSummary(
          currentOrganisations,
          chosenIndustry
        );

        setCurrentOrganisation(generalOrganisation);
        setDivisionsNames(['All']);
        setChosenDivision('All');
        setChosenDivisionId('all');
      } else {
        const organisationSelected = organisations.find(
          ({ name }) => trim(name) === trim(organisationName)
        );

        if (!organisationSelected) return;

        setCurrentOrganisation(organisationSelected);
        const divisions = ['All'];

        organisationSelected.divisions.forEach((division) => {
          divisions.push(division.name);
        });

        setDivisionsNames(divisions);
        setChosenDivision('All');
        setChosenDivisionId('all');
      }

      setChosenOrganisation(organisationName);
      setIsLoading(false);
    },
    [organisations, chosenIndustry, currentOrganisations]
  );

  const handleChangeDivision = useCallback(
    (divisionName: string) => {
      const fixedDivisionName = divisionName.replace('&amp;', '&');

      setChosenDivision(fixedDivisionName);

      if (divisionName === 'All') {
        setChosenDivisionId('all');
      } else {
        const selectedDivision = currentOrganisation.divisions.find(
          ({ name }) => trim(name) === trim(fixedDivisionName)
        );

        if (!selectedDivision) return;

        setChosenDivisionId(selectedDivision.id);
      }
    },
    [currentOrganisation]
  );

  const organizationHasHearingVisionV2Access = useMemo(() => {
    const orgCustomQuestionnaires =
      currentOrganisation.customQuestionnaires || [];

    return orgCustomQuestionnaires.some(
      (questionnaire) =>
        questionnaire === 'hearing-v2' || questionnaire === 'vision-v2'
    );
  }, [currentOrganisation]);

  useEffect(() => {
    if (!userIsLogged) return;

    const clientsIdsArray = Object.keys(user.clients || {});

    const clients = users.filter((_user) => clientsIdsArray.includes(_user.id));

    setCurrentClients(clients);
  }, [users, userIsLogged, user]);

  const runAdditionalSearch = useCallback(
    async (props: AdditionalSearchProps) => {
      if (isNil(additionalSearch?.call)) {
        return;
      }

      await additionalSearch?.call(props);
    },
    [additionalSearch]
  );

  const fetchSearchedUsers = useCallback(
    async (acceptBlankSearch = false) => {
      if (!acceptBlankSearch && !userSearch) return;

      if (!currentOrganisation.id) {
        window.alert('Select an organisation to find users');
        return;
      }

      setTriggerFetchOrgOngoingChecks((oldState) => !oldState);

      if (
        window.location.pathname === '/all-health-checks' &&
        isEmpty(userSearch)
      ) {
        alert(
          'You must type a user name in order to make a search on this page'
        );
        return;
      }

      setIsLoadingUsers(true);

      try {
        const organisationUsers = await getSearchedUsers(
          propToFilter,
          userSearch,
          currentOrganisation.id
        );

        const additionalSearchProps = {
          propToFilter,
          searchString: userSearch,
          organisationId: currentOrganisation.id
        } as AdditionalSearchProps;

        await runAdditionalSearch(additionalSearchProps);

        if (!organisationUsers) return;

        organisationUsers.sort((userA, userB) => {
          const nameA = `${userA.firstName} ${userA.lastName}`;
          const nameB = `${userB.firstName} ${userB.lastName}`;

          return nameA > nameB ? 1 : -1;
        });

        setUsers(organisationUsers);
        setIsLoadingUsers(false);
      } catch (error) {
        setIsLoadingUsers(false);
        Sentry.captureException(error);
        addToast({
          title: 'Error fetching users',
          description: 'Please, try again later',
          type: 'error'
        });
      }
    },
    [
      userSearch,
      currentOrganisation.id,
      propToFilter,
      runAdditionalSearch,
      addToast
    ]
  );

  const getSelectedOrganisationId = useCallback((): string => {
    const organizationFound = organisations.find(
      ({ name }) => trim(name) === trim(chosenOrganisation)
    );
    return organizationFound?.id || '';
  }, [organisations, chosenOrganisation]);

  useEffect(() => {
    if (!currentOrganisation.id) return;

    const getHealthChecksAsync = async () => {
      const orgChecks = await getOrgOngoingHealthChecks(currentOrganisation.id);
      setOrgOngoingHealthChecks(orgChecks);
    };

    getHealthChecksAsync();
  }, [currentOrganisation, triggerFetchOrgOngoingChecks]);

  return (
    <OrganisationsContext.Provider
      value={{
        currentOrganisation,
        currentClients,
        industriesNames,
        organisationsNames,
        divisionsNames,
        chosenIndustry,
        chosenOrganisation,
        chosenDivision,
        chosenDivisionId,
        userSearch,
        organisations,
        handleChangeIndustry,
        handleChangeOrganisation,
        handleChangeDivision,
        setUserSearch,
        setOrganisations,
        setCurrentOrganisation,
        users,
        setUsers,
        isLoadingUsers,
        fetchSearchedUsers,
        propToFilter,
        setPropToFilter,
        setAdditionalSearch,
        orgOngoingHealthChecks,
        setOrgOngoingHealthChecks,
        getSelectedOrganisationId,
        organizationHasHearingVisionV2Access
      }}
    >
      {isLoading && <Spinner />}
      {isLoadingUsers && <Spinner message="Loading users" />}
      {children}
    </OrganisationsContext.Provider>
  );
};

export function useOrganisation(): OrganisationsContextProps {
  const context = useContext(OrganisationsContext);

  return context;
}
