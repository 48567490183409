export default {
  apiKey: 'AIzaSyAfv0UHfNyH13chjmjBhVK4UaFqFg2B-VY',
  authDomain: 'kynd-development.firebaseapp.com',
  projectId: 'kynd-development',
  storageBucket: 'kynd-development.appspot.com',
  examsStorageBucket: 'gs://kynd-exams',
  messagingSenderId: '875450003032',
  appId: '1:875450003032:web:6b2d9810e094f0962de42d',
  measurementId: 'G-T6RPL2DCER',
  databaseURL:
    'https://kynd-development-default-rtdb.asia-southeast1.firebasedatabase.app'
};
