import styled from 'styled-components';

export const MeasurementButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  img {
    max-width: 20px;
    max-height: 20px;
    padding: 2px;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }
`;

interface ColorCardProps {
  color: string;
}

export const ColorCard = styled.div<ColorCardProps>`
  background-color: ${(props) => props.color};
  border-radius: 5px;
  width: 100%;
  font-weight: 500;
  color: #333333aa;
`;
