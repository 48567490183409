import styled, { css } from 'styled-components';

interface QuestionWrapperProps {
  enableFlexView: boolean;
}

export const QuestionWrapper = styled.div<QuestionWrapperProps>`
  background-color: rgba(242, 242, 242, 0.9);
  border-radius: 5px;

  ${(props) =>
    props.enableFlexView &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: start;
      flex-direction: column;
    `}
`;

export const QuestionTitle = styled.div`
  margin: 5px 5px 0;
  font-weight: 500;
  text-align: justify;
`;

export const OptionsAnswerContainer = styled.div`
  padding-top: 10px;
  padding-left: 10px;
`;

export const ContainerRadio = styled.label`
  display: flex;
  gap: 5px;
  margin-bottom: 0.7rem;
`;
