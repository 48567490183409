import { useLocation, matchPath } from 'react-router-dom';
import { ListSubheader } from '@mui/material';

import { MenuWrapper, SubMenuWrapper } from './styles';

import menuItems, { MenuItem } from './items';
import SidebarMenuItem from './item';

interface ChildRoutesProps {
  ev: JSX.Element[];
  path: string;
  item: MenuItem;
}

interface SideBarMenuItems {
  items: MenuItem[];
  path: string;
}

const reduceChildRoutes = ({
  ev,
  path,
  item
}: ChildRoutesProps): Array<JSX.Element> => {
  const key = item.name;

  const Path = (link: string, end: boolean) => {
    return !!matchPath(
      {
        path: link,
        end
      },
      path
    );
  };

  const exactMatch = item.link ? Path(item.link, true) : false;

  if (item.items) {
    const partialMatch = item.link ? Path(item.link, false) : false;

    ev.push(
      <SidebarMenuItem
        key={key}
        active={partialMatch ? 'true' : 'false'}
        open={partialMatch}
        name={item.name}
        icon={item.icon}
        link={item.link}
        badge={item.badge}
        role={item.role}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={key}
        active={exactMatch ? 'true' : 'false'}
        name={item.name}
        link={item.link}
        badge={item.badge}
        icon={item.icon}
        role={item.role}
      />
    );
  }

  return ev;
};

const renderSidebarMenuItems = ({
  items,
  path
}: SideBarMenuItems): JSX.Element => {
  return (
    <SubMenuWrapper>
      {items.reduce(
        (ev, item) => reduceChildRoutes({ ev, item, path }),
        [] as JSX.Element[]
      )}
    </SubMenuWrapper>
  );
};

function SidebarMenu() {
  const location = useLocation();

  return (
    <>
      {menuItems.map((section) => (
        <MenuWrapper
          key={section.heading}
          subheader={
            <ListSubheader component="div" disableSticky>
              {section.heading}
            </ListSubheader>
          }
        >
          {renderSidebarMenuItems({
            items: section.items,
            path: location.pathname
          })}
        </MenuWrapper>
      ))}
    </>
  );
}

export default SidebarMenu;
