/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import { useCallback, useMemo, useState } from 'react';
import MarkDown from 'markdown-to-jsx';
import {
  InfoOutlined,
  HealthAndSafetyOutlined,
  AdminPanelSettingsOutlined,
  CancelOutlined,
  ErrorOutline
} from '@mui/icons-material';
import { Modal } from '@mui/material';
import { camelCase, get } from 'lodash';

import { advices } from '../../../utils/advices';
import { AnswersModal } from './components/AnswersModal';
import { UserQuestionnaireAnswer } from '../../../models/user';
import { RangeDataProps, scoreRangesText } from '../../../utils/scoreRanges';

import {
  Container,
  CardHeader,
  ScoreLine,
  ColorLabel,
  AdviceIcons,
  InfoIcon,
  ScoreData,
  AboutIcon,
  ModalContent,
  ModalTitle,
  ModalInfo,
  CloseButton,
  WhiteLine,
  AboutAndColor,
  ValueRow,
  TimeStamp,
  ScoreRanges,
  RangeCard,
  RangeLine
} from './styles';

interface ScoreCardProps {
  answers?: UserQuestionnaireAnswer[];
  questionnaire: string;
  icon: string;
  score: number | string;
  color?: string;
  about?: string;
  userAdvice?: string;
  clinicianAdvice?: string;
  answerValue?: string;
  isIncomplete?: boolean;
  timeStamp: string;
}

export function ScoreCard({
  answers,
  questionnaire,
  icon,
  score,
  color,
  about,
  userAdvice,
  clinicianAdvice,
  answerValue,
  isIncomplete,
  timeStamp
}: ScoreCardProps) {
  const background = useMemo(() => {
    switch (color) {
      case 'green':
        return '#12a721';
      case 'orange':
        return '#ff8137';
      case 'red':
        return '#d31717';
      default:
        return 'transparent';
    }
  }, [color]);

  const [isAnswersModalOpen, setIsAnswersModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [infoModalTitle, setInfoModalTitle] = useState('');
  const [infoModalData, setInfoModalData] = useState('');
  const [isTextAnswer, setIsTextAnswer] = useState(false);

  const cursor = answers ? 'pointer' : 'default';
  const enableAnswersModal = !!answers;
  const title = enableAnswersModal
    ? `Click to open '${questionnaire}' answers`
    : '';

  const handleOpenModal = useCallback(
    (dataType: 'about' | 'userAdvice' | 'clinicianAdvice') => {
      if (dataType === 'about') {
        setInfoModalTitle(`About ${questionnaire}`);
        setInfoModalData(about || 'Nothing to declare...');
      }

      const usersAdvices = advices as Record<string, string>;
      const backupMessage = 'There is no advice for it yet...';

      if (dataType === 'userAdvice') {
        setInfoModalTitle(`User advice for ${questionnaire}`);
        setInfoModalData(usersAdvices[userAdvice!] || backupMessage);
      }

      if (dataType === 'clinicianAdvice') {
        setInfoModalTitle(`Clinician advice for ${questionnaire}`);
        setInfoModalData(usersAdvices[clinicianAdvice!] || backupMessage);
      }

      setIsInfoModalOpen(true);
    },
    [about, userAdvice, clinicianAdvice, questionnaire]
  );

  const centerScore = useMemo(() => {
    return (
      !clinicianAdvice &&
      !userAdvice &&
      questionnaire !== 'Weight' &&
      questionnaire !== 'Height'
    );
  }, [clinicianAdvice, userAdvice, questionnaire]);

  const formattedAnswerValue = useMemo(() => {
    if (!answerValue) return ['', ''];

    const splitStrings = answerValue.split(' ');

    const firstValue = splitStrings.shift();

    if (Number.isNaN(Number(firstValue))) {
      setIsTextAnswer(true);
    }

    return [firstValue || '', ` ${splitStrings.join(' ')}`];
  }, [answerValue]);

  const scoreRanges = useMemo(() => {
    const camelCaseQuestionnaire = camelCase(questionnaire);

    const ranges = get(
      scoreRangesText,
      camelCaseQuestionnaire,
      [] as Array<RangeDataProps>
    );

    return ranges;
  }, [questionnaire]);

  return (
    <Container>
      <CardHeader
        onClick={() => enableAnswersModal && setIsAnswersModalOpen(true)}
        title={enableAnswersModal ? title : ''}
        style={{ cursor }}
        disableHover={!enableAnswersModal}
      >
        <p>{questionnaire}</p>
        {answerValue && <p>Score: {score}</p>}

        {isIncomplete && (
          <span title="Incomplete answers">
            <ErrorOutline />
          </span>
        )}
      </CardHeader>

      <ScoreLine>
        <AdviceIcons>
          {clinicianAdvice && clinicianAdvice !== '-' && (
            <InfoIcon
              type="button"
              title="Clinician advice"
              onClick={() => handleOpenModal('clinicianAdvice')}
            >
              <HealthAndSafetyOutlined />
            </InfoIcon>
          )}

          {userAdvice && (
            <InfoIcon
              type="button"
              title="User advice"
              onClick={() => handleOpenModal('userAdvice')}
            >
              <AdminPanelSettingsOutlined />
            </InfoIcon>
          )}

          {centerScore && <span />}
        </AdviceIcons>

        <ScoreData
          onClick={() => enableAnswersModal && setIsAnswersModalOpen(true)}
          title={enableAnswersModal ? title : ''}
          style={{ cursor }}
          disableHover={!enableAnswersModal}
        >
          <img
            src={require(`../../../assets/${icon || 'life'}.svg`).default}
            alt={questionnaire}
          />

          {answerValue && (
            <ValueRow isTextAnswer={isTextAnswer}>
              <strong>{formattedAnswerValue[0]}</strong>
              <h6>{formattedAnswerValue[1]}</h6>
            </ValueRow>
          )}

          {!answerValue && <strong>{answerValue || score}</strong>}
        </ScoreData>

        <AboutAndColor>
          <AboutIcon>
            {about && (
              <InfoIcon
                type="button"
                title={`Info about ${questionnaire}`}
                onClick={() => handleOpenModal('about')}
              >
                <InfoOutlined />
              </InfoIcon>
            )}
          </AboutIcon>

          <ColorLabel title="Score color" backgroundColor={background} />
        </AboutAndColor>
      </ScoreLine>

      <Modal open={isInfoModalOpen} onClose={() => setIsInfoModalOpen(false)}>
        <ModalContent>
          <ModalTitle>
            <h2>{infoModalTitle}</h2>

            <CloseButton
              title="Close info modal"
              type="button"
              onClick={() => setIsInfoModalOpen(false)}
            >
              <CancelOutlined />
            </CloseButton>
          </ModalTitle>

          <WhiteLine />

          <ModalInfo>
            <ScoreRanges multipleColumns={scoreRanges.length > 1}>
              {scoreRanges.map(({ title: scoreTitle, red, orange, green }) => (
                <RangeCard id={scoreTitle}>
                  <h3>{scoreTitle}</h3>

                  <RangeLine red>{red}</RangeLine>
                  <RangeLine orange>{orange}</RangeLine>
                  <RangeLine green>{green}</RangeLine>
                </RangeCard>
              ))}
            </ScoreRanges>

            <MarkDown>{infoModalData}</MarkDown>
          </ModalInfo>
        </ModalContent>
      </Modal>

      {answers && (
        <AnswersModal
          questionnaire={questionnaire}
          answers={answers}
          isAnswersModalOpen={isAnswersModalOpen}
          setIsAnswersModalOpen={setIsAnswersModalOpen}
        />
      )}

      <TimeStamp>{timeStamp}</TimeStamp>
    </Container>
  );
}
