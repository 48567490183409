import { get } from 'lodash';
import { AverageScore, ScoreProps } from '../models/user';

/* eslint-disable */
export interface ReturnedScore {
  score: number;
  color: string;
  clinicianAdvice: string;
  userAdvice: string;
}

export const calculateScore = (
  questionnaire: string,
  answers: Array<number | string>,
  invertedAnswers: boolean[],
  sex?: string | undefined
): ReturnedScore | undefined => {
  let score = 0;
  let color = '';
  let clinicianAdvice = '';
  let userAdvice = '';

  if (questionnaire === 'life') {
    answers.map(value => {
      score += getLifeScoreAgreeDisagree(String(value));
    });

    if (score >= 0 && score < 20) {
      color = 'red';
      clinicianAdvice = 'ACL150';
      userAdvice = 'APL150';
    }

    if (score >= 20 && score  < 40) {
      color = 'red';
      clinicianAdvice = 'ACL140';
      userAdvice = 'APL140';
    }

    if (score >= 40 && score  < 60) {
      color = 'orange';
      clinicianAdvice = 'ACL130';
      userAdvice = 'APL130';
    }

    if (score >= 60 && score  < 80) {
      color = 'orange';
      clinicianAdvice = 'ACL120';
      userAdvice = 'APL120';
    }

    if (score >= 80) {
      color = 'green';
      clinicianAdvice = 'ACL110';
      userAdvice = 'APL110';
    }

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  if (questionnaire === 'mindfulness') {
    answers.map((value, index) => {
      score += getScoreHowOften(String(value), invertedAnswers[index]);
    });

    if (score >= 0 && score < 30) {
      color = 'red';
      clinicianAdvice = 'ACM630';
      userAdvice = 'APM630';
    }

    if (score >= 30 && score < 55) {
      color = 'orange';
      clinicianAdvice = 'ACM620';
      userAdvice = 'APM620';
    }

    if (score >= 55) {
      color = 'green';
      clinicianAdvice = 'ACM610';
      userAdvice = 'APM610';
    }

    score = score / 8;
    score = Math.floor(score);

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  if (questionnaire === 'sleep') {
    answers.map((value, index) => {
      score += getSleepScore(String(value), invertedAnswers[index]);
    });

    score = score;

    if (score >= 0 && score <= 6) {
      color = 'red';
      clinicianAdvice = 'ACM730';
      userAdvice = 'APM730';
    }

    if (score > 6 && score <= 11) {
      color = 'orange';
      clinicianAdvice = 'ACM720';
      userAdvice = 'APM720';
    }

    if (score > 11) {
      color = 'green';
      clinicianAdvice = 'ACM710';
      userAdvice = 'APM710';
    }

    score = (score / 16) * 10;
    score = Math.floor(score);

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  if (questionnaire === 'obstructiveSleepApnea') {
    answers.map((value) => {
      score += getObstructiveSleepApneaScore(String(value));
    });

    if (score >= 0 && score <= 14) {
      color = 'green';
      clinicianAdvice = 'ACM810';
      userAdvice = 'APM810';
    }

    if (score > 14 && score <= 29) {
      color = 'orange';
      clinicianAdvice = 'ACM820';
      userAdvice = 'APM820';
    }

    if (score > 29) {
      color = 'red';
      clinicianAdvice = 'ACM830';
      userAdvice = 'APM830';
    }

    score = (score / 48) * 10;
    score = Math.floor(score);
    score = 10 - score;

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  if (questionnaire === 'gratitude') {
    answers.map((value) => {
      score += getScoreAgreeDisagree(String(value));
    });

    score = score/2;
    score = Math.floor(score);

    if (score >= 0 && score < 4) {
      color = 'red';
      clinicianAdvice = 'ACM530';
      userAdvice = 'APM530';
    }

    if (score >= 4 && score <= 7) {
      color = 'orange';
      clinicianAdvice = 'ACM520';
      userAdvice = 'APM520';
    }

    if (score > 7) {
      color = 'green';
      clinicianAdvice = 'ACM510';
      userAdvice = 'APM510';
    }

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  if (questionnaire === 'emotionalIndex') {
    answers.map((value) => {
      score += getPercentageRangeScore(Number(value));
    });

    if (score >= 0 && score < 20) {
      color = 'red';
      clinicianAdvice = 'ACM150';
      userAdvice = 'APM150';
    }

    if (score >= 20 && score < 40) {
      color = 'red';
      clinicianAdvice = 'ACM140';
      userAdvice = 'APM140';
    }

    if (score >= 40 && score < 60) {
      color = 'orange';
      clinicianAdvice = 'ACM130';
      userAdvice = 'APM130';
    }

    if (score >= 60 && score < 80) {
      color = 'orange';
      clinicianAdvice = 'ACM120';
      userAdvice = 'APM120';
    }

    if (score >= 80) {
      color = 'green';
      clinicianAdvice = 'ACM110';
      userAdvice = 'APM110';
    }

    score = (score / 100) * 30;
    score = Math.floor(score);

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  if (questionnaire === 'depressionAnxietyAndStress') {
    const depressionScore = answers[0] as number;
    const anxietyScore = answers[1] as number;
    const stressScore = answers[2] as number;

    score = depressionScore + anxietyScore + stressScore;

    const dep = (10 - depressionScore) / 10 * 42;
    const anx = (10 - anxietyScore) / 10 * 42;
    const str = (10 - stressScore) / 10 * 42;

    if (
      dep >= 0 && dep <= 9 &&
      anx >= 0 && anx <= 7 &&
      str >= 0 && str <= 14
    ) {
      color = 'green';
      clinicianAdvice = '';
      userAdvice = '';
    }

    if (
      dep > 9 && dep <= 13 &&
      anx > 7 && anx <= 9 &&
      str > 14 && str <= 18
    ) {
      color = 'orange';
      clinicianAdvice = '';
      userAdvice = '';
    }

    if (
      dep > 13 && dep <= 20 &&
      anx > 9 && anx <= 14 &&
      str > 18 && str <= 25
    ) {
      color = 'orange';
      clinicianAdvice = '';
      userAdvice = '';
    }

    if (
      dep > 20 && dep <= 27 &&
      anx > 14 && anx <= 19 &&
      str > 25 && str <= 33
    ) {
      color = 'red';
      clinicianAdvice = '';
      userAdvice = '';
    }

    if (
      dep > 27 &&
      anx > 19 &&
      str > 23
    ) {
      color = 'red';
      clinicianAdvice = '';
      userAdvice = '';
    }

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  if (questionnaire === 'depression') {
    answers.map((value) => {
      score += getScoreDASHowOften(String(value));
    });

    score = score * 2;

    if (score >= 0 && score <= 9) {
      color = 'green';
      clinicianAdvice = 'ACM210';
      userAdvice = 'APM210';
    }

    if (score > 9 && score <= 13) {
      color = 'orange';
      clinicianAdvice = 'ACM220';
      userAdvice = 'APM220';
    }
    
    if (score > 13 && score <= 20) {
      color = 'orange';
      clinicianAdvice = 'ACM230';
      userAdvice = 'APM230';
    }

    if (score > 20 && score <= 27) {
      color = 'red';
      clinicianAdvice = 'ACM240';
      userAdvice = 'APM240';
    }

    if (score > 27) {
      color = 'red';
      clinicianAdvice = 'ACM250';
      userAdvice = 'APM250';
    }

    score = score / 42 * 10;
    score = 10 - Math.floor(score);

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  if (questionnaire === 'anxiety') {
    answers.map((value) => {
      score += getScoreDASHowOften(String(value));
    });

    score = score * 2;

    if (score >= 0 && score <= 7) {
      color = 'green';
      clinicianAdvice = 'ACM310';
      userAdvice = 'APM310';
    }

    if (score > 7 && score <= 9) {
      color = 'orange';
      clinicianAdvice = 'ACM320';
      userAdvice = 'APM320';
    }
    
    if (score > 9 && score <= 14) {
      color = 'orange';
      clinicianAdvice = 'ACM330';
      userAdvice = 'APM330';
    }

    if (score > 14 && score <= 19) {
      color = 'red';
      clinicianAdvice = 'ACM340';
      userAdvice = 'APM340';
    }

    if (score > 19) {
      color = 'red';
      clinicianAdvice = 'ACM350';
      userAdvice = 'APM350';
    }

    score = score / 42 * 10;
    score = 10 - Math.floor(score);

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  if (questionnaire === 'stress') {
    answers.map((value) => {
      score += getScoreDASHowOften(String(value));
    });

    score = score * 2;

    if (score >= 0 && score <= 14) {
      color = 'green';
      clinicianAdvice = 'ACM410';
      userAdvice = 'APM410';
    }

    if (score > 14 && score <= 18) {
      color = 'orange';
      clinicianAdvice = 'ACM420';
      userAdvice = 'APM420';
    }
    
    if (score > 18 && score <= 25) {
      color = 'orange';
      clinicianAdvice = 'ACM430';
      userAdvice = 'APM430';
    }

    if (score > 25 && score <= 33) {
      color = 'red';
      clinicianAdvice = 'ACM440';
      userAdvice = 'APM440';
    }

    if (score > 33) {
      color = 'red';
      clinicianAdvice = 'ACM450';
      userAdvice = 'APM450';
    }

    score = score / 42 * 10;
    score = 10 - Math.floor(score);

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  if (questionnaire === 'smoking') {
    const value = answers[0];
    score = getSmokingScore(String(value));

    if (score === 0) {
      color = 'red';
      clinicianAdvice = 'ACB210';
      userAdvice = 'APB210';
    }

    if (score === 5) {
      color = 'orange';
      clinicianAdvice = 'ACB220';
      userAdvice = 'APB220';
    }

    if (score === 15 && String(value) !== 'Never smoked') {
      color = 'green';
      clinicianAdvice = 'ACB230';
      userAdvice = 'APB230';
    }

    if (score === 15 && String(value) === 'Never smoked') {
      color = 'green';
      clinicianAdvice = 'ACB240';
      userAdvice = 'APB240';
    }

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  if (questionnaire === 'alcohol') {
    const value = answers[0];
    const userSex = sex ? sex : 'male';
    score = getAlcoholScore(Number(value), userSex);

    if (score === 0) {
      color = 'red';
      clinicianAdvice = 'ACB310';
      userAdvice = 'APB310';
    }

    if (score === 5) {
      color = 'orange';
      clinicianAdvice = 'ACB320';
      userAdvice = 'APB320';
    }

    if (score === 15) {
      color = 'green';
      clinicianAdvice = 'ACB330';
      userAdvice = 'APB330';
    }

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  if (questionnaire === 'hbA1C') {
    const value = answers[0];
    const hasDiabetes = answers[1] === 'Yes' ? true : false;

    score = getDiabetesScore(Number(value), hasDiabetes);

    if (hasDiabetes) {
      if (score === 0) {
        color = 'red';
        clinicianAdvice = 'ACB1210';
        userAdvice = 'APB1210';
      }
      if (score === 3) {
        color = 'red';
        clinicianAdvice = 'ACB1220';
        userAdvice = 'APB1220';
      }
      if (score === 8) {
        color = 'orange';
        clinicianAdvice = 'ACB1230';
        userAdvice = 'APB1230';
      }
      if (score === 15) {
        color = 'green';
        clinicianAdvice = 'ACB1240';
        userAdvice = 'APB1240';
      }
    } else {
      if (score === 0) {
        color = 'red';
        clinicianAdvice = 'ACB1110';
        userAdvice = 'APB1110';
      }
      if (score === 3) {
        color = 'red';
        clinicianAdvice = 'ACB1120';
        userAdvice = 'APB1120';
      }
      if (score === 8) {
        color = 'orange';
        clinicianAdvice = 'ACB1130';
        userAdvice = 'APB1130';
      }
      if (score === 15) {
        color = 'green';
        clinicianAdvice = 'ACB1140';
        userAdvice = 'APB1140';
      }
    }

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  if (questionnaire === 'waistCircumference') {
    const value = answers[0];
    const userSex = sex ? sex : 'male';
    score = getWaistCircumferenceScore(Number(value), userSex);

    if (userSex === 'male') {
      if (score === 0) {
        color = 'red';
        clinicianAdvice = 'ACB410';
        userAdvice = 'APB410';
      }
  
      if (score === 8) {
        color = 'orange';
        clinicianAdvice = 'ACB420';
        userAdvice = 'APB420';
      }
  
      if (score === 15) {
        color = 'green';
        clinicianAdvice = 'ACB430';
        userAdvice = 'APB430';
      }
    } else {
      if (score === 0) {
        color = 'red';
        clinicianAdvice = 'ACB440';
        userAdvice = 'APB440';
      }
  
      if (score === 8) {
        color = 'orange';
        clinicianAdvice = 'ACB450';
        userAdvice = 'APB450';
      }
  
      if (score === 15) {
        color = 'green';
        clinicianAdvice = 'ACB460';
        userAdvice = 'APB460';
      }
    }

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  if (questionnaire === 'systolicDiastolic') {
    const systolic = answers[0] as number;
    const diastolic = answers[1] as number;
    score = systolic + diastolic;

    if (score >= 0 && score < 10) {
      color = 'red';
    }

    if (score >= 10 && score < 15) {
      color = 'orange';
    }

    if (score >= 15) {
      color = 'green';
    }

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  if (questionnaire === 'systolic') {
    const value = answers[0] as number;
    score = getSystolicScore(value);

    if (score === 0) {
      color = 'red';
      clinicianAdvice = 'ACB510';
      userAdvice = 'APB510';
    }

    if (score === 5) {
      color = 'orange';
      clinicianAdvice = 'ACB520';
      userAdvice = 'APB520';
    }

    if (score === 7.5) {
      color = 'green';
      clinicianAdvice = 'ACB530';
      userAdvice = 'APB530';
    }

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  if (questionnaire === 'diastolic') {
    const value = answers[0] as number;
    score = getDiastolicScore(value);

    if (score === 0) {
      color = 'red';
      clinicianAdvice = 'ACB540';
      userAdvice = 'APB540';
    }

    if (score === 5) {
      color = 'red';
      clinicianAdvice = 'ACB550';
      userAdvice = 'APB550';
    }

    if (score === 7.5) {
      color = 'green';
      clinicianAdvice = 'ACB560';
      userAdvice = 'APB560';
    }

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  if (questionnaire === 'restingHeartRate') {
    const value = answers[0] as number;
    score = getRestingHeartRateScore(value);

    if (score === 0) {
      color = 'red';
      clinicianAdvice = 'ACB1310';
      userAdvice = 'APB1310';
    }

    if (score === 2) {
      color = 'orange';
      clinicianAdvice = 'ACB1320';
      userAdvice = 'APB1320';
    }

    if (score === 3) {
      color = 'orange';
      clinicianAdvice = 'ACB1330';
      userAdvice = 'APB1330';
    }

    if (score === 5) {
      color = 'green';
      clinicianAdvice = 'ACB1340';
      userAdvice = 'APB1340';
    }

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  if (questionnaire === 'lungFunction') {
    const value = answers[0] as number;
    score = getLungFunctionScore(value);

    if (score === 0) {
      color = 'red';
      clinicianAdvice = 'ACB1410';
      userAdvice = 'APB1410';
    }

    if (score === 3) {
      color = 'orange';
      clinicianAdvice = 'ACB1420';
      userAdvice = 'APB1420';
    }

    if (score === 5) {
      color = 'green';
      clinicianAdvice = 'ACB1430';
      userAdvice = 'APB1430';
    }

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  if (questionnaire === 'ratioCholesterolHdl') {
    const cholesterol = answers[0] as number;
    const hdl = answers[1] as number;

    const value = cholesterol/hdl;

    score = getRatioCholesterolHdlScore(value);

    if (score === 0) {
      color = 'red';
      clinicianAdvice = 'ACB1010';
      userAdvice = 'APB1010';
    }

    if (score === 2) {
      color = 'orange';
      clinicianAdvice = 'ACB1020';
      userAdvice = 'APB1020';
    }

    if (score === 3) {
      color = 'green';
      clinicianAdvice = 'ACB1030';
      userAdvice = 'APB1030';
    }

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  if (questionnaire === 'hdl') {
    const value = answers[0] as number;

    score = getHdlScore(value);

    if (score === 0) {
      color = 'red';
      clinicianAdvice = 'ACB710';
      userAdvice = 'APB710';
    }

    if (score === 2) {
      color = 'orange';
      clinicianAdvice = 'ACB720';
      userAdvice = 'APB720';
    }

    if (score === 3) {
      color = 'green';
      clinicianAdvice = 'ACB730';
      userAdvice = 'APB730';
    }

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  if (questionnaire === 'ldl') {
    const value = answers[0] as number;

    score = getLdlScore(value);

    if (score === 0) {
      color = 'red';
      clinicianAdvice = 'ACB810';
      userAdvice = 'APB810';
    }

    if (score === 2) {
      color = 'orange';
      clinicianAdvice = 'ACB820';
      userAdvice = 'APB820';
    }

    if (score === 3) {
      color = 'green';
      clinicianAdvice = 'ACB830';
      userAdvice = 'APB830';
    }

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  if (questionnaire === 'triglycerides') {
    const value = answers[0] as number;

    score = getTriglyceridesScore(value);

    if (score === 0) {
      color = 'red';
      clinicianAdvice = 'ACB910';
      userAdvice = 'APB910';
    }

    if (score === 2) {
      color = 'orange';
      clinicianAdvice = 'ACB920';
      userAdvice = 'APB920';
    }

    if (score === 3) {
      color = 'green';
      clinicianAdvice = 'ACB930';
      userAdvice = 'APB930';
    }

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  if (questionnaire === 'totalCholesterol') {
    const value = answers[0] as number;

    score = getTotalCholesterolScore(value);

    if (score === 0) {
      color = 'red';
      clinicianAdvice = 'ACB610';
      userAdvice = 'APB610';
    }

    if (score === 2) {
      color = 'orange';
      clinicianAdvice = 'ACB620';
      userAdvice = 'APB620';
    }

    if (score === 3) {
      color = 'green';
      clinicianAdvice = 'ACB630';
      userAdvice = 'APB630';
    }

    return {
      score,
      color,
      clinicianAdvice,
      userAdvice
    };
  }

  return undefined;
}

interface CategoryScoreProps {
  category: string;
  scores: Record<string | 'averageScore', Record<string, ScoreProps> | AverageScore>;
}

export const getCategoryScore = ({category, scores}: CategoryScoreProps) => {
  const categoryScores = get(scores, `${category}`, {}) as Record<string, ScoreProps>;
  const questionnaires = Object.keys(categoryScores);

  let overallScore = 0;

  questionnaires.forEach((questionnaire) => {
    const scoreObj = categoryScores[questionnaire];
    const userHasScore = Boolean(scoreObj.currentScore || scoreObj.score);

    if (userHasScore) {
      overallScore += scoreObj.currentScore || scoreObj.score || 0;
    }
  })

  if (overallScore > 100) overallScore = 100;

  return Math.floor(overallScore);
};

export const calculateCategoriesCompleteness = (scores: Record<string, Record<string, ScoreProps>>) => {
  const numberOfQuestionnaires = {
    life: 1,
    mind: 6,
    body: 12
  } as Record<string, number>;

  const categories = ['life', 'mind', 'body'];

  const categoriesCompleteness = {
    life: 0,
    mind: 0,
    body: 0,
    total: 0
  } as Record<string, number>;

  categories.map((category) => {
    if (scores && !scores[category]) return;
    const categoryScores = scores[category];
    const questionnaires = Object.keys(categoryScores);

    let numberOfCompleteQuestionnaires = 0;

    questionnaires.map((questionnaire) => {
      if (categoryScores[questionnaire].currentScore >= 0 || categoryScores[questionnaire].score >= 0) {
        numberOfCompleteQuestionnaires += 1;
      }
    });

    categoriesCompleteness[category] = Math.floor(numberOfCompleteQuestionnaires/numberOfQuestionnaires[category]*100);
  });

  const { life, mind, body } = categoriesCompleteness;

  categoriesCompleteness.total = Math.floor((life + mind + body)/3);

  return categoriesCompleteness;
}

const getLifeScoreAgreeDisagree = (optionValue: string) => {
  switch (optionValue) {
    case 'Strongly disagree':
      return 0
    case 'Disagree':
      return 1
    case 'Not really':
      return 2
    case 'Neither agree or disagree':
      return 3
    case 'Agree':
      return 4
    case 'Strongly agree':
      return 5
    default:
      return 0;
  }
}

const getScoreAgreeDisagree = (optionValue: string) => {
  switch (optionValue) {
    case 'Strongly disagree':
      return 0
    case 'Disagree':
      return 1
    case 'Neutral':
      return 2
    case 'Agree':
      return 3
    case 'Strongly agree':
      return 4
    default:
      return 0;
  }
}

const getScoreDASHowOften = (optionValue: string) => {
  switch (optionValue) {
    case 'Never':
      return 0;
    case 'Sometimes':
      return 1;
    case 'Often':
      return 2;
    case 'Almost always':
      return 3;
    default:
      return 3;
  }
}

const getScoreHowOften = (optionValue: string, invertedValues?: boolean) => {
  switch (optionValue) {
    case 'Never':
      return invertedValues ? 4 : 0;
    case 'Rarely':
      return invertedValues ? 3 : 1;
    case 'Sometimes':
      return 2;
    case 'Often':
      return invertedValues ? 1 : 3;
    case 'Very often':
      return invertedValues ? 0 : 4;
    default:
      return 0;
  }
}

const getPercentageRangeScore = (value: number) => {
  return 10 - value/10;
}

const getSleepScore = (optionValue: string, invertedAnswer: boolean) => {
  switch (optionValue) {
    case '< 6 hours':
      return 0;
    case '6-8 hours':
      return 1;
    case '> 8 hours':
      return 2;
    case '0-1 times':
      return 2;
    case '2-3 times':
      return 1;
    case 'More than 3 times':
      return 0;
    case 'Never':
      return invertedAnswer ? 2 : 0;
    case '0-2 per week and < 30 min':
      return 1;
    case '0-2 per week and > 30 min':
      return 0;
    case 'Sometimes':
      return 1;
    case 'Often':
      return invertedAnswer ? 0 : 2;
    case 'Yes':
      return 0;
    case 'Great':
      return 2;
    case 'Fair':
      return 1;
    case 'Poor':
      return 0;
    default:
      return 0;
  }
}

const getObstructiveSleepApneaScore = (optionValue: string) => {
  switch (optionValue) {
    case 'Yes':
      return 2;
    case 'No':
      return 0;
    case 'Never':
      return 0;
    case 'Slight chance':
      return 1;
    case 'Moderate chance':
      return 2;
    case 'High chance':
      return 3;
    default:
      return 3;
  }
}

const getSmokingScore = (optionValue: string) => {
  switch (optionValue) {
    case 'Never smoked':
      return 15;
    case 'Current smoker':
      return 0;
    case 'Ex-Smoker (last smoke < 1 year)':
      return 5;
    case 'Ex-Smoker (last smoke > 1 year)':
      return 15;
    default:
      return 0;
  }
}

const getAlcoholScore = (drinks: number, userSex: string) => {
  if (userSex === 'male') {
    if (drinks === 0) {
      return 15
    }
    if (drinks > 0 && drinks <= 15) {
      return 5
    }
    if (drinks > 15) {
      return 0
    }
  } else {
    if (drinks === 0) {
      return 15
    }
    if (drinks > 0 && drinks <= 10) {
      return 5
    }
    if (drinks > 10) {
      return 0
    }
  }

  return 0;
}

const getDiabetesScore = (hbA1C: number, hasDiabetes: boolean) => {
  if (hasDiabetes) {
    if (hbA1C < 45) {
      return 15;
    }
    if (hbA1C >= 45 && hbA1C < 55) {
      return 8;
    }
    if (hbA1C >= 55 && hbA1C <= 60) {
      return 3;
    }
    if (hbA1C > 60) {
      return 0;
    }
  } else {
    if (hbA1C < 40) {
      return 15;
    }
    if (hbA1C >= 40 && hbA1C < 50) {
      return 8;
    }
    if (hbA1C >= 50 && hbA1C < 56) {
      return 3;
    }
    if (hbA1C >= 56) {
      return 0;
    }
  }

  return 0;
}

const getWaistCircumferenceScore = (circumference: number, userSex: string) => {
  if (userSex === 'male') {
    if (circumference >= 0 && circumference < 94) {
      return 15;
    }
    if (circumference >= 94 && circumference <= 100) {
      return 8;
    }
    if (circumference > 100) {
      return 0;
    }
  } else {
    if (circumference >= 0 && circumference < 80) {
      return 15;
    }
    if (circumference >= 80 && circumference <= 86) {
      return 8;
    }
    if (circumference > 86) {
      return 0;
    }
  }

  return 0;
}

const getSystolicScore = (systolic: number) => {
  if (systolic >= 140) {
    return 0;
  }

  if (systolic > 120 && systolic < 140) {
    return 5;
  }

  if (systolic <= 120) {
    return 7.5;
  }

  return 0;
}

const getDiastolicScore = (diastolic: number) => {
  if (diastolic >= 100) {
    return 0;
  }

  if (diastolic > 91 && diastolic < 100) {
    return 5;
  }

  if (diastolic <= 91) {
    return 7.5;
  }

  return 0;
}

const getRestingHeartRateScore = (heartRate: number) => {
  if (heartRate >= 0 && heartRate < 65) {
    return 5;
  }

  if (heartRate >= 65 && heartRate < 80) {
    return 3;
  }

  if (heartRate >= 80 && heartRate <= 99) {
    return 2;
  }

  if (heartRate > 99) {
    return 0;
  }

  return 0;
}

const getLungFunctionScore = (lungFunction: number) => {
  if (lungFunction >= 0 && lungFunction < 50) {
    return 0;
  }

  if (lungFunction >= 50 && lungFunction < 80) {
    return 3;
  }

  if (lungFunction >= 80) {
    return 5;
  }

  return 0;
}

const getHdlScore = (hdl: number) => {
  if (hdl < 0.8) {
    return 0;
  }

  if (hdl >= 0.8 && hdl <= 0.9) {
    return 2;
  }

  if (hdl > 0.9) {
    return 3;
  }

  return 0;
}

const getLdlScore = (ldl: number) => {
  if (ldl > 3) {
    return 0;
  }

  if (ldl >= 2 && ldl <= 3) {
    return 2;
  }

  if (ldl < 2) {
    return 3;
  }

  return 0;
}

const getTriglyceridesScore = (triglycerides: number) => {
  if (triglycerides > 2.5) {
    return 0;
  }

  if (triglycerides >= 2 && triglycerides <= 2.5) {
    return 2;
  }

  if (triglycerides < 2) {
    return 3;
  }

  return 0;
}

const getTotalCholesterolScore = (totalCholesterol: number) => {
  if (totalCholesterol > 6) {
    return 0;
  }

  if (totalCholesterol >= 4 && totalCholesterol <= 6) {
    return 2;
  }

  if (totalCholesterol < 4) {
    return 3;
  }

  return 0;
}

const getRatioCholesterolHdlScore = (ratioCholesterolHdl: number) => {
  if (ratioCholesterolHdl > 5) {
    return 0;
  }

  if (ratioCholesterolHdl >= 4 && ratioCholesterolHdl <= 5) {
    return 2;
  }

  if (ratioCholesterolHdl < 4) {
    return 3;
  }

  return 0;
}

export const questionnairesMaxScores = {
  life: 100,
  sleep: 10,
  obstructiveSleepApnea: 10,
  gratitude: 10,
  emotionalIndex: 30,
  mindfulness: 10,
  depressionAnxietyAndStress: 30,
  waistCircumference: 15,
  smoking: 15,
  alcohol: 15,
  lungFunction: 5,
  systolicDiastolic: 15,
  hbA1C: 15,
  restingHeartRate: 5,
  ratioCholesterolHdl: 3,
  hdl: 3,
  ldl: 3,
  triglycerides: 3,
  totalCholesterol: 3
}
