import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, TextField } from '@mui/material';
import { camelCase, get, orderBy } from 'lodash';

import { useOrganisation } from '../../hooks/organisations';

import { PageTitle } from '../../components/PageTitle';
import { OrganisationFilter } from '../../components/OrganisationFilter';

import { calculateCategoriesCompleteness } from '../../utils/calculateScore';
import { ScoreProps, User } from '../../models/user';
import { getUserAge } from '../../utils/getUserAge';
import { formatDate } from '../../utils/formatDate';

import {
  UserNameRow,
  Container,
  HealthChecksWrapper,
  HealthCheckCard,
  ScoreCard,
  ScoreWrapper,
  UpperCardInfo,
  FooterData,
  UpdatedAt,
  UserCompany,
  FilterWrapper
} from './styles';

import mindIcon from '../../assets/mind-icon.png';
import depressionIcon from '../../assets/depression.svg';
import anxietyIcon from '../../assets/anxiety.svg';
import stressIcon from '../../assets/stress.svg';

interface UserLightsProps {
  depression: string;
  anxiety: string;
  stress: string;
}

export const DasAlerts = () => {
  const { users, fetchSearchedUsers } = useOrganisation();
  const navigate = useNavigate();

  const [sortedUsers, setSortedUsers] = useState([] as User[]);
  const [filteredUsers, setFilteredUsers] = useState([] as User[]);
  const [dasFilter, setDasFilter] = useState<string | null>(null);

  useEffect(() => {
    fetchSearchedUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMemo(() => {
    const filteredCurrentUsers = orderBy(
      users.filter((user) => {
        const dasScore = get(user, 'scores.mind.depressionAnxietyAndStress');

        if (dasScore) {
          return true;
        }

        return false;
      }),
      ['scores.averageScore.mind'],
      ['asc']
    );

    setSortedUsers(filteredCurrentUsers);
    setFilteredUsers(filteredCurrentUsers);
  }, [users]);

  const { categoriesCompleteness, averageScore, age, updatedAt, lights } =
    useMemo(() => {
      const completenessObject = {} as Record<string, Record<string, number>>;
      const averageScoresObject = {} as Record<string, Record<string, number>>;
      const ageObject = {} as Record<string, string | number>;
      const updatedAtObject = {} as Record<string, string>;
      const lightsObject: Record<string, UserLightsProps> = {};

      sortedUsers.map((user) => {
        const userScore = get(user, 'scores') as any;
        const userScoreAverageScore = get(user, 'scores.averageScore', {});

        const userScoreObject = {} as Record<string, number>;

        userScoreObject.mind = get(userScoreAverageScore, 'mind', 0);

        const completeness = calculateCategoriesCompleteness(userScore);
        const userUpdatedAt: any = user.updatedAt || user.createdAt;

        completenessObject[user.id] = completeness;
        ageObject[user.id] = getUserAge(user.birthDate);
        updatedAtObject[user.id] = formatDate({
          date: userUpdatedAt
        });

        const userLights = {
          depression: '',
          anxiety: '',
          stress: ''
        };

        const dasScore = get(
          user,
          'scores.mind.depressionAnxietyAndStress',
          {}
        ) as Record<string, ScoreProps>;

        if (dasScore) {
          const { depressionScore } = dasScore;
          const { anxietyScore } = dasScore;
          const { stressScore } = dasScore;

          userLights.depression = depressionScore.color;
          userScoreObject.depression = depressionScore.currentScore;

          userLights.anxiety = anxietyScore.color;
          userScoreObject.anxiety = anxietyScore.currentScore;

          userLights.stress = stressScore.color;
          userScoreObject.stress = stressScore.currentScore;
        }

        averageScoresObject[user.id] = userScoreObject;
        lightsObject[user.id] = userLights;
      });

      return {
        categoriesCompleteness: completenessObject,
        averageScore: averageScoresObject,
        age: ageObject,
        updatedAt: updatedAtObject,
        lights: lightsObject
      };
    }, [sortedUsers]);

  const handleSeeDetails = useCallback(
    (user: User) => {
      navigate('details', {
        state: {
          client: user,
          showResumeCheckButton: false
        }
      });
    },
    [navigate]
  );

  const handleChangeDasFilter = useCallback(
    (questionnaire: string) => {
      if (!['All', 'Depression', 'Anxiety', 'Stress'].includes(questionnaire)) {
        setFilteredUsers(sortedUsers);
        setDasFilter('All');
        return;
      }

      if (questionnaire === 'All') {
        setFilteredUsers(sortedUsers);
      } else {
        const copyUsers = [...filteredUsers];

        copyUsers.sort((userA, userB) => {
          const camelCaseQuestionnaire = camelCase(questionnaire);

          const dasColorA = get(
            userA,
            // eslint-disable-next-line prettier/prettier
            `scores.mind.depressionAnxietyAndStress.${camelCaseQuestionnaire}Score.color`,
            ''
          ) as string;

          const dasColorB = get(
            userB,
            // eslint-disable-next-line prettier/prettier
            `scores.mind.depressionAnxietyAndStress.${camelCaseQuestionnaire}Score.color`,
            ''
          ) as string;

          if (!dasColorA || !dasColorB) return -1;

          return dasColorA > dasColorB ? 1 : -1;
        });

        copyUsers.sort((userA, userB) => {
          const camelCaseQuestionnaire = camelCase(questionnaire);

          const dasScoreA = get(
            userA,
            // eslint-disable-next-line prettier/prettier
            `scores.mind.depressionAnxietyAndStress.${camelCaseQuestionnaire}Score.currentScore`,
            0
          ) as number;

          const dasScoreB = get(
            userB,
            // eslint-disable-next-line prettier/prettier
            `scores.mind.depressionAnxietyAndStress.${camelCaseQuestionnaire}Score.currentScore`,
            0
          ) as number;

          if (!dasScoreA || !dasScoreB) return -1;

          return dasScoreA > dasScoreB ? 1 : -1;
        });

        setFilteredUsers(copyUsers);
      }

      localStorage.setItem('@KyndClinician:dasFilter', questionnaire);
      setDasFilter(questionnaire);
    },
    [sortedUsers, filteredUsers]
  );

  useEffect(() => {
    const storedValue = localStorage.getItem('@KyndClinician:dasFilter');
    if (storedValue) {
      handleChangeDasFilter(storedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <OrganisationFilter hideDivision />

      <PageTitle heading="DAS Alerts" />

      <FilterWrapper>
        <Autocomplete
          disablePortal
          fullWidth
          options={['All', 'Depression', 'Anxiety', 'Stress']}
          value={dasFilter}
          defaultValue={dasFilter}
          freeSolo={false}
          onChange={(e) => handleChangeDasFilter(e.currentTarget.innerHTML)}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Sort by questionnaire score"
            />
          )}
        />
      </FilterWrapper>

      <HealthChecksWrapper>
        {!filteredUsers.length && <h2>No data to show</h2>}

        {filteredUsers.map((user) => (
          <HealthCheckCard
            key={user.id}
            onClick={() => handleSeeDetails(user)}
            title="Click to open user scores summary"
          >
            <UserNameRow>
              <div>
                <p>{user.firstName.toUpperCase()}</p>
                <p>{user.lastName.toUpperCase()}</p>
                <p>({age[user.id]} yrs)</p>
              </div>
              <h5>{user.email || user.phone}</h5>
            </UserNameRow>

            <ScoreWrapper>
              <ScoreCard className="average">
                <img src={mindIcon} alt="Mind score" />
                <div>
                  <p>Mind Score</p>
                  <strong>{averageScore[user.id].mind}</strong>
                  <h6>({categoriesCompleteness[user.id].mind} %)</h6>
                </div>
              </ScoreCard>

              <ScoreCard className="body">
                <UpperCardInfo>
                  <img src={depressionIcon} alt="Depression score" />
                  <div>
                    <p>Depression</p>
                    <strong>{averageScore[user.id].depression}</strong>
                    <span className={`${lights[user.id].depression}`} />
                  </div>
                </UpperCardInfo>
              </ScoreCard>

              <ScoreCard className="mind">
                <UpperCardInfo>
                  <img src={anxietyIcon} alt="Mind score" />
                  <div>
                    <p>Anxiety</p>
                    <strong>{averageScore[user.id].anxiety}</strong>
                    <span className={`${lights[user.id].anxiety}`} />
                  </div>
                </UpperCardInfo>
              </ScoreCard>

              <ScoreCard className="life">
                <UpperCardInfo>
                  <img src={stressIcon} alt="Life score" />
                  <div>
                    <p>Stress</p>
                    <strong>{averageScore[user.id].stress}</strong>
                    <span className={`${lights[user.id].stress}`} />
                  </div>
                </UpperCardInfo>
              </ScoreCard>
            </ScoreWrapper>

            <FooterData>
              <UserCompany>
                <p>{user.organisation.name}:</p>
                <p>{user.division.name}</p>
              </UserCompany>

              <UpdatedAt>User updated at: {updatedAt[user.id]}</UpdatedAt>
            </FooterData>
          </HealthCheckCard>
        ))}
      </HealthChecksWrapper>
    </Container>
  );
};
