import * as Sentry from '@sentry/react';
import moment from 'moment';
import { get, isEmpty, set } from 'lodash';
import { User, UserQuestionnaireAnswer } from '../models/user';
import { firebaseSDK, firestore, isProduction } from '../services/firebase';
import {
  HazardAction,
  HearingVisionAlert,
  HearingVisionAlertsColor
} from '../models/jobHealthHazards/hearingVisionAlert';
import { getUserData } from './users';

const alertsCollection = firestore.collection('questionnaireAlerts');

export const getHearingVisionAlerts = async (orgId: string) => {
  const redFlaggedUsers: HearingVisionAlert[] = [];

  try {
    const users = await alertsCollection
      .where('organisation.id', '==', orgId)
      .get();

    users.docs.forEach((flaggedUser) => {
      const flagData = flaggedUser.data() as Omit<HearingVisionAlert, 'id'>;

      redFlaggedUsers.push({
        id: flaggedUser.id,
        ...flagData
      });
    });

    return redFlaggedUsers;
  } catch (error) {
    console.error(error);
    if (isProduction) {
      Sentry.captureException(error);
    }

    throw new Error('Fail to get Hearing and Vision alerts');
  }
};

interface UpdateObjectProps {
  actionTaken: HazardAction;
  status: string;
  hearingStatus?: string;
  visionStatus?: string;
}

export const updateAlert = async (
  alertId: string,
  updateProps: UpdateObjectProps
) => {
  try {
    const { status, actionTaken, hearingStatus, visionStatus } = updateProps;

    const updateObject = {
      actions: firebaseSDK.firestore.FieldValue.arrayUnion(actionTaken),
      status
    };

    if (hearingStatus) {
      set(updateObject, 'hearingStatus', hearingStatus);
    }
    if (visionStatus) {
      set(updateObject, 'visionStatus', visionStatus);
    }

    await alertsCollection.doc(alertId).update(updateObject);

    return;
  } catch (error) {
    console.error('Fail to update hearing and vision alert.', error);
    if (isProduction) {
      Sentry.captureException(error);
    }
    throw new Error('Fail to update Hearing and Vision alert');
  }
};

export async function getUserAlertsActive(userId: string) {
  const currentAlerts = await alertsCollection
    .where('user.id', '==', userId)
    .where('status', 'in', ['Open', 'In Progress'])
    .get();

  return currentAlerts;
}

export async function updateHaringVisionAlert(user: User, clinician: User) {
  try {
    const hearingVisionAlerts = checkHearingVisionRedAlerts(user);

    if (!hearingVisionAlerts.hearing && !hearingVisionAlerts.vision) return;

    const currentAlerts = await getUserAlertsActive(user.id);

    console.debug(
      `${currentAlerts?.docs?.length} open or in progress alerts found for user ${user.id}`
    );

    if (currentAlerts?.docs?.length > 0) return;

    const createdAlert = {
      questionnaireAlerts: hearingVisionAlerts,
      createdAt: moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_MS),
      status: 'Open',
      user: {
        id: user.id,
        email: user.email || '',
        firstName: user.firstName || '',
        lastName: user.lastName || ''
      },
      organisation: user.organisation,
      division: user.division,
      createdBy: {
        id: clinician.id,
        email: clinician.email || '',
        firstName: clinician.firstName || '',
        lastName: clinician.lastName || ''
      }
    };

    if (hearingVisionAlerts.hearing) {
      set(createdAlert, 'hearingStatus', 'Open');
    }

    if (hearingVisionAlerts.vision) {
      set(createdAlert, 'visionStatus', 'Open');
    }

    console.debug('Created alert: ', createdAlert);
    await alertsCollection.add(createdAlert);
  } catch (error) {
    console.error('Fail to update hearing and vision alert.', error);
    if (isProduction) {
      Sentry.captureException(error);
    }
    throw new Error('Fail to update Hearing and Vision alert');
  }
}

export async function getUserAlertStatus(
  healthCheckUser: User
): Promise<HearingVisionAlertsColor> {
  const upToDateUser = await getUserData(healthCheckUser.id);

  const hearingScreenAnswers = get(
    upToDateUser,
    'answers.body.jobHealthHazards.hearingScreen'
  );
  const visionScreenAnswers = get(
    upToDateUser,
    'answers.body.jobHealthHazards.visionScreen'
  );
  const hasHearingScreenAnswers = !isEmpty(hearingScreenAnswers);
  const hasVisionScreenAnswers = !isEmpty(visionScreenAnswers);

  const redAlerts = await checkExistingHearingVisionRedAlerts(healthCheckUser);
  const orangeAlerts = checkHearingVisionOrangeAlerts(
    upToDateUser,
    healthCheckUser
  );

  const alertsColors = {
    hearing: redAlerts.hearing
      ? 'red'
      : orangeAlerts.hearing
      ? 'orange'
      : 'green',
    vision: redAlerts.vision ? 'red' : orangeAlerts.vision ? 'orange' : 'green'
  } as HearingVisionAlertsColor;

  if (!hasHearingScreenAnswers) alertsColors.hearing = '';
  if (!hasVisionScreenAnswers) alertsColors.vision = '';

  return alertsColors;
}

export async function getUserAnswersAlerts(
  userId: string
): Promise<HearingVisionAlertsColor> {
  const upToDateUser = await getUserData(userId);

  const userHearingAnswers = get(
    upToDateUser,
    'answers.body.jobHealthHazards.hearingQuestionnaire',
    {}
  ) as UserQuestionnaireAnswer;
  const userVisionAnswers = get(
    upToDateUser,
    'answers.body.jobHealthHazards.visionQuestionnaire',
    {}
  ) as UserQuestionnaireAnswer;

  return {
    hearing: isEmpty(userHearingAnswers)
      ? ''
      : userAnswersHasAlert(userHearingAnswers)
      ? 'orange'
      : 'green',
    vision: isEmpty(userVisionAnswers)
      ? ''
      : userAnswersHasAlert(userVisionAnswers)
      ? 'orange'
      : 'green'
  };
}

async function checkExistingHearingVisionRedAlerts(healthCheckUser: User) {
  const dbUserCurrentAlerts = await getUserAlertsActive(healthCheckUser.id);
  const redAlerts = checkHearingVisionRedAlerts(healthCheckUser);

  if (dbUserCurrentAlerts?.docs?.length <= 0) return redAlerts;

  const userCurrentAlerts = dbUserCurrentAlerts?.docs[0].data();

  const hearingDbAlert = get(
    userCurrentAlerts,
    'questionnaireAlerts.hearing',
    false
  ) as boolean;

  const visionDbAlert = get(
    userCurrentAlerts,
    'questionnaireAlerts.vision',
    false
  ) as boolean;

  return {
    hearing: hearingDbAlert || redAlerts.hearing,
    vision: visionDbAlert || redAlerts.vision
  };
}

export function checkHearingVisionOrangeAlerts(
  user: User | undefined,
  clinician: User
): Record<'hearing' | 'vision', boolean> {
  const clinicianHearingAnswers = get(
    clinician,
    'answers.body.jobHealthHazards.hearingScreen',
    {}
  ) as UserQuestionnaireAnswer;

  const userHearingAnswers = get(
    user,
    'answers.body.jobHealthHazards.hearingQuestionnaire',
    {}
  ) as UserQuestionnaireAnswer;

  const clinicianVisionAnswers = get(
    clinician,
    'answers.body.jobHealthHazards.visionScreen',
    {}
  ) as UserQuestionnaireAnswer;

  const userVisionAnswers = get(
    user,
    'answers.body.jobHealthHazards.visionQuestionnaire',
    {}
  ) as UserQuestionnaireAnswer;

  return {
    hearing: userHasHearingVisionIssues(
      userHearingAnswers,
      clinicianHearingAnswers
    ),
    vision: userHasHearingVisionIssues(
      userVisionAnswers,
      clinicianVisionAnswers
    )
  };
}

export function checkHearingVisionRedAlerts(
  user: User
): Record<'hearing' | 'vision', boolean> {
  const hearingAnswers = get(
    user,
    'answers.body.jobHealthHazards.hearingScreen',
    {}
  ) as Pick<User, 'answers'>;

  const visionAnswers = get(
    user,
    'answers.body.jobHealthHazards.visionScreen',
    {}
  ) as Pick<User, 'answers'>;

  const criticalRole = get(
    user,
    'answers.body.jobHealthHazards.criticalRole[0].answer.value',
    'No'
  );

  return {
    hearing: isCriticalRoleAndHasExamIssue(hearingAnswers, criticalRole),
    vision: isCriticalRoleAndHasExamIssue(visionAnswers, criticalRole)
  };
}

function userHasHearingVisionIssues(
  userAnswers: UserQuestionnaireAnswer,
  clinicianAnswers: UserQuestionnaireAnswer
) {
  const lastKey =
    Object.keys(clinicianAnswers).filter((key) => key !== 'score').length - 1;
  const statusExam = get(clinicianAnswers, `[${lastKey}.answer.value]`);

  const hasUserAnsweredYes = userAnswersHasAlert(userAnswers);

  return hasUserAnsweredYes || statusExam === 'Sub-Optimal';
}

export function userAnswersHasAlert(userAnswers: UserQuestionnaireAnswer) {
  const hasUserAnsweredYes = isEmpty(userAnswers)
    ? false
    : Object.keys(userAnswers)?.some((key) => {
        if (key === 'score') return false;

        const answer: string = get(userAnswers, `[${key}].answer.value`, 'No');
        return answer === 'Yes';
      });
  return hasUserAnsweredYes;
}

function isCriticalRoleAndHasExamIssue(
  answers: Pick<User, 'answers'>,
  criticalRole: string
) {
  if (isEmpty(answers)) return false;

  // It's -2 because we want the last question but not the score. The last property is the score.
  const lastKey =
    Object.keys(answers).filter((key) => key !== 'score').length - 1;
  const statusExam = get(answers, `[${lastKey}.answer.value]`);

  return statusExam === 'Sub-Optimal' && criticalRole === 'Yes';
}

export const getJobHealthHazardsAlerts = async (userId: string) => {
  const alertsSnapshot = alertsCollection.where('user.id', '==', userId).get();

  const alertsArray = [] as HearingVisionAlert[];

  (await alertsSnapshot).docs.map((data) => {
    alertsArray.push({
      id: data.id,
      ...data.data()
    } as HearingVisionAlert);
  });

  return alertsArray;
};
