import styled from 'styled-components';

export const QuestionnaireWrapper = styled.main`
  border-radius: 5px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.05);
`;

export const QuestionnaireHeader = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;

  height: 50px;
`;

export const QuestionnaireIcon = styled.span`
  padding: 2px;
  background-color: #fff;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 30px;
    width: 30px;
  }
`;

export const QuestionnaireTitle = styled.h3`
  margin: 0;
`;

export const QuestionsWrapper = styled.div``;

// ========== Answer ==========
export const QuestionTitle = styled.div`
  margin-top: 5px;
  font-weight: 500;
`;

export const OptionsAnswerContainer = styled.div`
  display: flex;
  gap: 10px;
  padding-top: 10px;
  padding-left: 10px;
`;

export const ContainerRadio = styled.label`
  display: flex;
  gap: 5px;
  margin-bottom: 0.7rem;
`;
