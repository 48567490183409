import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container } from '@mui/material';
import lodash from 'lodash';
import { PhoneIphoneOutlined } from '@mui/icons-material';

import { useOrganisation } from '../../hooks/organisations';
import {
  OrganisationAverageScore,
  OrganisationCategoryScore,
  OrganisationScores
} from '../../models/organisation';

import { OrganisationFilter } from '../../components/OrganisationFilter';
import { AverageChart } from './components/AverageChart';

import {
  AverageScoreWrapper,
  CategoriesScoresWrapper,
  Completion,
  DashboardWrapper
} from './styles';

import iconBody from '../../assets/body-icon.png';
import iconLife from '../../assets/life-icon.png';
import iconMind from '../../assets/mind-icon.png';
import kyndIcon from '../../assets/logo-small.png';

interface CategoriesArrayProps {
  camelCase: 'body' | 'mind' | 'life';
  upperCase: 'Body' | 'Mind' | 'Life';
}

export const Dashboard: React.FC = () => {
  const { currentOrganisation, chosenDivisionId } = useOrganisation();

  const categoryIcons = useMemo(() => {
    return {
      body: iconBody,
      mind: iconMind,
      life: iconLife,
      kynd: kyndIcon
    } as Record<string, string>;
  }, []);

  const categories = useMemo(() => {
    return [
      {
        camelCase: 'body',
        upperCase: 'Body'
      },
      {
        camelCase: 'mind',
        upperCase: 'Mind'
      },
      {
        camelCase: 'life',
        upperCase: 'Life'
      }
    ] as CategoriesArrayProps[];
  }, []);

  const extractTotalLights = useCallback(
    (categoryScores: Record<string, OrganisationCategoryScore>) => {
      let totalGreen = 0;
      let totalOrange = 0;
      let totalRed = 0;

      const scoreValues = Object.values(categoryScores);

      scoreValues.map(({ green, orange, red }) => {
        totalGreen += !green || Number.isNaN(green) ? 0 : green;
        totalOrange += !orange || Number.isNaN(orange) ? 0 : orange;
        totalRed += !red || Number.isNaN(red) ? 0 : red;
      });

      return {
        green: totalGreen,
        orange: totalOrange,
        red: totalRed
      };
    },
    []
  );

  const { averageScore, totalLights } = useMemo(() => {
    const orgAverageScore = lodash.get(
      currentOrganisation,
      `scores.${chosenDivisionId}`
    ) as OrganisationScores;

    const totalLightsObject = {
      body: {
        red: 0,
        orange: 0,
        green: 0
      },
      mind: {
        red: 0,
        orange: 0,
        green: 0
      },
      life: {
        red: 0,
        orange: 0,
        green: 0
      }
    };

    if (orgAverageScore) {
      if (orgAverageScore.body) {
        const { red, orange, green } = extractTotalLights(orgAverageScore.body);
        const { body } = totalLightsObject;

        body.red = red;
        body.orange = orange;
        body.green = green;
      }

      if (orgAverageScore.mind) {
        const { red, orange, green } = extractTotalLights(orgAverageScore.mind);
        const { mind } = totalLightsObject;

        mind.red = red;
        mind.orange = orange;
        mind.green = green;
      }

      if (orgAverageScore.life) {
        const { red, orange, green } = extractTotalLights(orgAverageScore.life);
        const { life } = totalLightsObject;

        life.red = red;
        life.orange = orange;
        life.green = green;
      }

      return {
        totalLights: totalLightsObject,
        averageScore: orgAverageScore.averageScore
      };
    }

    return {
      totalLights: totalLightsObject,
      averageScore: {} as OrganisationAverageScore
    };
  }, [currentOrganisation, chosenDivisionId, extractTotalLights]);

  const totalEmployees = useMemo(() => {
    if (averageScore?.averageScore) {
      return averageScore?.activeUsers || 0;
    }

    return 0;
  }, [averageScore]);

  return (
    <DashboardWrapper>
      <OrganisationFilter hideUserSearch />

      <Helmet>
        <title>Summary</title>
      </Helmet>

      <Container>
        <AverageScoreWrapper>
          <img src={categoryIcons.kynd} alt="KYND Total Score" />
          <h1>KYND Total Score:</h1>
          <h1>{averageScore.averageScore || '-'}</h1>
        </AverageScoreWrapper>

        <AverageChart data={averageScore} totalLights={totalLights} />

        <Completion>
          <h1>Completion summary</h1>
        </Completion>

        <CategoriesScoresWrapper>
          <section>
            <h2>#Users</h2>

            <div>
              <PhoneIphoneOutlined />
              <h1>{totalEmployees}</h1>
            </div>

            <h6>Active users</h6>
          </section>

          {categories.map(({ camelCase, upperCase }) => {
            return (
              <Link
                to={`/dashboard/${camelCase}`}
                title={`Go to ${upperCase} statistics`}
                key={camelCase}
              >
                <h2>{upperCase}</h2>

                <div>
                  <img
                    alt={`${camelCase} icon`}
                    src={categoryIcons[camelCase]}
                  />
                  <h1>{averageScore[`${camelCase}Complete`] || '-'}</h1>
                </div>

                <h6>Users completed</h6>
              </Link>
            );
          })}
        </CategoriesScoresWrapper>
      </Container>
    </DashboardWrapper>
  );
};
