import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { camelCase, cloneDeep, get, isEmpty, orderBy } from 'lodash';

import { Autocomplete, TextField } from '@mui/material';
import { useOrganisation } from '../../hooks/organisations';
import { useQuestions } from '../../hooks/questions';

import { PageTitle } from '../../components/PageTitle';
import { OrganisationFilter } from '../../components/OrganisationFilter';

import { calculateCategoriesCompleteness } from '../../utils/calculateScore';
import { ScoreProps, User } from '../../models/user';
import { getUserAge } from '../../utils/getUserAge';
import { formatDate } from '../../utils/formatDate';

import {
  UserNameRow,
  Container,
  FilterWrapper,
  HealthChecksWrapper,
  HealthCheckCard,
  ScoreCard,
  ScoreWrapper,
  UpperCardInfo,
  CardColors,
  FooterData,
  UpdatedAt,
  UserCompany
} from './styles';

import averageScoreIcon from '../../assets/logo-small.png';
import bodyIcon from '../../assets/body-icon.png';
import mindIcon from '../../assets/mind-icon.png';
import lifeIcon from '../../assets/life-icon.png';

interface UserLightsProps {
  body: {
    red: number;
    orange: number;
    green: number;
  };
  mind: {
    red: number;
    orange: number;
    green: number;
  };
  life: {
    red: number;
    orange: number;
    green: number;
  };
}

export const Alerts = () => {
  const { users, fetchSearchedUsers } = useOrganisation();
  const { groupedQuestionnaires } = useQuestions();
  const navigate = useNavigate();

  const [questionnaireFilter, setQuestionnaireFilter] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([] as User[]);
  const [sortedCurrentUsers, setSortedCurrentUsers] = useState([] as User[]);

  useEffect(() => {
    fetchSearchedUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEmpty(questionnaireFilter)) return;
    localStorage.setItem('filters.alerts.questionnaire', questionnaireFilter);
  }, [questionnaireFilter]);

  const sortUsersByFilter = useCallback(
    (usersArray: User[], currentQuestionnaireFilter: string) => {
      const usersArrayCopy = cloneDeep(usersArray);
      const questionnaireData = groupedQuestionnaires.find(
        (data) => data?.questionnaire === currentQuestionnaireFilter
      );

      if (currentQuestionnaireFilter === 'All') {
        return orderBy(
          usersArrayCopy,
          ['scores.averageScore.averageScore'],
          ['asc']
        );
      }

      const category = camelCase(questionnaireData?.category);

      return orderBy(
        usersArrayCopy,
        [`scores.${category}.${questionnaireData?.camelCaseQuestionnaire}`],
        ['asc']
      );
    },
    [groupedQuestionnaires]
  );

  useEffect(() => {
    const questionnaireStoredFilter =
      localStorage.getItem('filters.alerts.questionnaire') || 'All';
    setSortedCurrentUsers(sortUsersByFilter(users, questionnaireStoredFilter));
    setFilteredUsers(sortUsersByFilter(users, questionnaireStoredFilter));
    setQuestionnaireFilter(questionnaireStoredFilter);
  }, [users, sortUsersByFilter]);

  const {
    categoriesCompleteness,
    averageScore,
    age,
    updatedAt,
    lights,
    hasLights
  } = useMemo(() => {
    const completenessObject = {} as Record<string, Record<string, number>>;
    const averageScoresObject = {} as Record<string, Record<string, number>>;
    const ageObject = {} as Record<string, string | number>;
    const updatedAtObject = {} as Record<string, string>;
    const lightsObject: Record<string, UserLightsProps> = {};
    const userHasLights: Record<string, boolean> = {};

    sortedCurrentUsers.map((user) => {
      const userScore = get(user, 'scores') as any;
      const userScoreAverageScore = get(user, 'scores.averageScore', {});

      const completeness = calculateCategoriesCompleteness(userScore);
      const userUpdatedAt: any = user.updatedAt || user.createdAt;

      completenessObject[user.id] = completeness;
      ageObject[user.id] = getUserAge(user.birthDate);
      updatedAtObject[user.id] = formatDate({
        date: userUpdatedAt
      });

      const userLights = {
        body: {
          red: 0,
          orange: 0,
          green: 0
        },
        mind: {
          red: 0,
          orange: 0,
          green: 0
        },
        life: {
          red: 0,
          orange: 0,
          green: 0
        }
      };

      const userScoreObject = {} as Record<string, number>;

      userScoreObject.averageScore = get(
        userScoreAverageScore,
        'averageScore',
        0
      );
      userScoreObject.body = get(userScoreAverageScore, 'body', 0);
      userScoreObject.mind = get(userScoreAverageScore, 'mind', 0);
      userScoreObject.life = get(userScoreAverageScore, 'life', 0);

      const bodyScore = get(user, 'scores.body') as Record<string, ScoreProps>;
      const mindScore = get(user, 'scores.mind') as Record<string, ScoreProps>;
      const lifeScore = get(user, 'scores.life') as Record<string, ScoreProps>;

      if (bodyScore) {
        const bodyValues = Object.values(bodyScore);

        bodyValues.map((data) => {
          const color = data.color as 'red' | 'orange' | 'green';
          userLights.body[color] += 1;
        });
      }

      if (mindScore) {
        const mindValues = Object.values(mindScore);

        mindValues.map((data) => {
          const color = data.color as 'red' | 'orange' | 'green';
          userLights.mind[color] += 1;
        });
      }

      if (lifeScore) {
        const lifeValues = Object.values(lifeScore);

        lifeValues.map((data) => {
          const color = data.color as 'red' | 'orange' | 'green';
          userLights.life[color] += 1;
        });
      }

      const lightsValues = Object.values(userLights);
      const bodyLights = Object.values(lightsValues[0]);
      const mindLights = Object.values(lightsValues[1]);
      const lifeLights = Object.values(lightsValues[2]);

      bodyLights.pop();
      mindLights.pop();
      lifeLights.pop();

      const allLights = [...bodyLights, ...mindLights, ...lifeLights];

      const hasAnyLight = allLights.some((light) => light > 0);

      averageScoresObject[user.id] = userScoreObject;
      lightsObject[user.id] = userLights;
      userHasLights[user.id] = hasAnyLight;
    });

    return {
      categoriesCompleteness: completenessObject,
      averageScore: averageScoresObject,
      age: ageObject,
      updatedAt: updatedAtObject,
      lights: lightsObject,
      hasLights: userHasLights
    };
  }, [sortedCurrentUsers]);

  const lightFilters = useMemo(() => {
    const questionnaires = groupedQuestionnaires.map(
      (data) => data?.questionnaire
    );

    const filteredQuestionnaires = questionnaires.filter(
      (questionnaire) =>
        questionnaire !== 'Weight' &&
        questionnaire !== 'Height' &&
        questionnaire !== 'Depression, Anxiety and Stress'
    );

    return ['All', ...filteredQuestionnaires];
  }, [groupedQuestionnaires]);

  useEffect(() => {
    const questionnaire =
      localStorage.getItem('@KyndClinician:alertsLightFilter') ?? 'All';
    handleChangeLightFilter(questionnaire);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeLightFilter = useCallback(
    (questionnaire: string) => {
      localStorage.setItem('@KyndClinician:alertsLightFilter', questionnaire);
      const usersSortedByFilter = sortUsersByFilter(
        sortedCurrentUsers,
        questionnaire
      );

      const questionnaireData = groupedQuestionnaires.find(
        (data) => data?.questionnaire === questionnaire
      );

      if (
        !questionnaireData ||
        questionnaire === 'All' ||
        !lightFilters.includes(questionnaire)
      ) {
        setQuestionnaireFilter('All');
        setFilteredUsers(usersSortedByFilter);
        return;
      }

      const category = camelCase(questionnaireData.category);

      const filteredUsersArray = usersSortedByFilter.filter((user) => {
        const userScore = get(
          user,
          `scores.${category}.${questionnaireData.camelCaseQuestionnaire}`,
          {}
        ) as ScoreProps;

        if (!userScore) return false;

        const { color } = userScore;

        if (color === 'red' || color === 'orange') return true;
      });

      setFilteredUsers(filteredUsersArray);
      setQuestionnaireFilter(questionnaire);
    },
    [sortUsersByFilter, sortedCurrentUsers, groupedQuestionnaires, lightFilters]
  );

  const handleSeeDetails = useCallback(
    (user: User) => {
      navigate('details', {
        state: {
          client: user,
          showResumeCheckButton: false
        }
      });
    },
    [navigate]
  );

  return (
    <Container>
      <OrganisationFilter hideDivision />

      <PageTitle heading="Alerts" />

      <FilterWrapper>
        <Autocomplete
          disablePortal
          fullWidth
          options={lightFilters}
          value={questionnaireFilter}
          defaultValue={questionnaireFilter}
          freeSolo={false}
          onChange={(e) => handleChangeLightFilter(e.currentTarget.innerHTML)}
          renderInput={(params) => (
            <TextField {...params} fullWidth label="Filter by questionnaire" />
          )}
        />
      </FilterWrapper>

      <HealthChecksWrapper>
        {!filteredUsers.length && <h2>No data to show</h2>}

        {filteredUsers.map(
          (user) =>
            hasLights[user.id] && (
              <HealthCheckCard
                key={user.id}
                onClick={() => handleSeeDetails(user)}
                title="Click to open user scores summary"
              >
                <UserNameRow>
                  <div>
                    <p>{user.firstName.toUpperCase()}</p>
                    <p>{user.lastName.toUpperCase()}</p>
                    <p>({age[user.id]} yrs)</p>
                  </div>
                  <h5>{user.email || user.phone}</h5>
                </UserNameRow>

                <ScoreWrapper>
                  <ScoreCard className="average">
                    <img src={averageScoreIcon} alt="Average score" />
                    <div>
                      <p>KYND Score</p>
                      <strong>{averageScore[user.id].averageScore}</strong>
                      <h6>({categoriesCompleteness[user.id].total} %)</h6>
                    </div>
                  </ScoreCard>

                  <ScoreCard className="body">
                    <UpperCardInfo>
                      <img src={bodyIcon} alt="Body score" />
                      <div>
                        <p>Body</p>
                        <strong>{averageScore[user.id].body}</strong>
                        <h6>({categoriesCompleteness[user.id].body} %)</h6>
                      </div>
                    </UpperCardInfo>

                    <CardColors>
                      <section>
                        <span className="red" />
                        <p>{lights[user.id].body.red}</p>
                      </section>

                      <section>
                        <span className="orange" />
                        <p>{lights[user.id].body.orange}</p>
                      </section>
                    </CardColors>
                  </ScoreCard>

                  <ScoreCard className="mind">
                    <UpperCardInfo>
                      <img src={mindIcon} alt="Mind score" />
                      <div>
                        <p>Mind</p>
                        <strong>{averageScore[user.id].mind}</strong>
                        <h6>({categoriesCompleteness[user.id].mind} %)</h6>
                      </div>
                    </UpperCardInfo>

                    <CardColors>
                      <section>
                        <span className="red" />
                        <p>{lights[user.id].mind.red}</p>
                      </section>

                      <section>
                        <span className="orange" />
                        <p>{lights[user.id].mind.orange}</p>
                      </section>
                    </CardColors>
                  </ScoreCard>

                  <ScoreCard className="life">
                    <UpperCardInfo>
                      <img src={lifeIcon} alt="Life score" />
                      <div>
                        <p>Life</p>
                        <strong>{averageScore[user.id].life}</strong>
                        <h6>({categoriesCompleteness[user.id].life} %)</h6>
                      </div>
                    </UpperCardInfo>

                    <CardColors>
                      <section>
                        <span className="red" />
                        <p>{lights[user.id].life.red}</p>
                      </section>

                      <section>
                        <span className="orange" />
                        <p>{lights[user.id].life.orange}</p>
                      </section>
                    </CardColors>
                  </ScoreCard>
                </ScoreWrapper>

                <FooterData>
                  <UserCompany>
                    <p>{user.organisation.name}:</p>
                    <p>{user.division.name}</p>
                  </UserCompany>

                  <UpdatedAt>User updated at: {updatedAt[user.id]}</UpdatedAt>
                </FooterData>
              </HealthCheckCard>
            )
        )}
      </HealthChecksWrapper>
    </Container>
  );
};
