import { Autocomplete, TextField } from '@mui/material';

interface AlertStatusFilterParams {
  selectedValue: 'Open' | 'In Progress' | 'Resolved';
  showLabel?: boolean;
  handleChange: (value: 'Open' | 'In Progress' | 'Resolved') => void;
}

const statusTypes = ['Open', 'In Progress', 'Resolved'];

export const AlertStatusFilter = ({
  selectedValue,
  showLabel = true,
  handleChange
}: AlertStatusFilterParams) => {
  return (
    <Autocomplete
      disablePortal
      id="hearing-vision-alert-status"
      options={statusTypes}
      value={selectedValue}
      freeSolo={false}
      disableClearable
      onChange={(event) =>
        handleChange(
          event.currentTarget.innerHTML as 'Open' | 'In Progress' | 'Resolved'
        )
      }
      renderInput={(params) => {
        const label = showLabel ? 'Alert Status' : '';
        return <TextField {...params} required fullWidth label={label} />;
      }}
    />
  );
};
