import { useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Card } from '@mui/material';
import { Hearing } from './Hearing';
import { Vision } from './Vision';
import { StructuralHealth } from './StructuralHealth';
import {
  QuestionnairesWrapper,
  QuestionnairesHeader,
  Container
} from './styles';

export function JobHealthHazards() {
  const [isShowingQuestionnaires, setIsShowingGallery] = useState(false);

  return (
    <Card style={{ marginTop: '20px' }}>
      <Container>
        <QuestionnairesHeader
          title="Click to toggle gallery"
          onClick={() => setIsShowingGallery(!isShowingQuestionnaires)}
        >
          {isShowingQuestionnaires ? (
            <KeyboardArrowDown />
          ) : (
            <KeyboardArrowUp />
          )}
          Job Health Hazards
        </QuestionnairesHeader>
        <QuestionnairesWrapper>
          {isShowingQuestionnaires && (
            <>
              <Vision />
              <Hearing />
              <StructuralHealth />
            </>
          )}
        </QuestionnairesWrapper>
      </Container>
    </Card>
  );
}
