import { upperFirst } from 'lodash';
import { useMemo } from 'react';
import { ScoreCard } from '../../..';
import { UserQuestionnaireAnswer } from '../../../../../../models/user';
import { aboutInfo } from '../../../../../../utils/aboutQuestionnaire';

interface HearingScoreProps {
  alertColor: 'green' | 'orange' | 'red' | '';
  answers?: UserQuestionnaireAnswer[] | undefined;
}

export const Hearing = ({ alertColor, answers }: HearingScoreProps) => {
  const questionnaire = 'Hearing Questionnaire';
  const about = useMemo(() => {
    const aboutArray = [] as string[];

    aboutInfo.hearingScreen.map((info) => {
      if (info.type === 'text') {
        aboutArray.push(info.content);
      }
    });

    return aboutArray.join(' | ');
  }, []);

  return (
    <ScoreCard
      key={questionnaire}
      questionnaire={questionnaire}
      color={alertColor}
      icon="hearing"
      score=""
      about={about}
      userAdvice={
        alertColor ? `hearingQuestionnaire${upperFirst(alertColor)}` : ''
      }
      clinicianAdvice={
        alertColor
          ? `hearingQuestionnaire${upperFirst(alertColor)}Clinician`
          : ''
      }
      timeStamp=""
      answers={answers}
    />
  );
};
