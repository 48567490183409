import { Link } from 'react-router-dom';

import { Drawer, Hidden } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { useSideBar } from '../../../hooks/sidebar';
import { useAuth } from '../../../hooks/auth';

import { SidebarWrapper, TopSection } from './styles';
import SidebarMenu from './SidebarMenu';

import Logo from '../../../assets/logo.png';

function Sidebar() {
  const { user } = useAuth();
  const { sidebarToggle, toggleSidebar } = useSideBar();
  const closeSidebar = () => toggleSidebar();

  return (
    <>
      {user && (
        <>
          <Hidden lgDown>
            <SidebarWrapper>
              <Scrollbars autoHide>
                <TopSection>
                  <Link to="/">
                    <img className="logo-img" src={Logo} alt="Logo" />
                  </Link>
                </TopSection>
                <SidebarMenu />
              </Scrollbars>
            </SidebarWrapper>
          </Hidden>
          <Hidden lgUp>
            <Drawer
              anchor="left"
              open={sidebarToggle}
              onClose={closeSidebar}
              variant="temporary"
              elevation={9}
            >
              <SidebarWrapper>
                <Scrollbars autoHide>
                  <TopSection>
                    <img className="logo-img" src={Logo} alt="Logo" />
                  </TopSection>
                  <SidebarMenu />
                </Scrollbars>
              </SidebarWrapper>
            </Drawer>
          </Hidden>
        </>
      )}
    </>
  );
}

export default Sidebar;
