import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 6;

  position: fixed;
  left: 0;
  top: 0;
  margin-left: auto;
  margin-right: auto;

  height: 100vh;
  width: 100vw;

  padding: 10px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.75);

  h2 {
    color: #fff;
  }
`;
