import styled from 'styled-components';

export const Container = styled.div`
  width: 80%;
  margin: 0 auto;
`;

export const UserSearchBox = styled.div`
  position: relative;
`;

export const SearchUsersButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;

  border-radius: 0 6px 6px 0;
  width: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #8a94a6;
  border-left: none;
  background-color: #eee;

  transition: background-color 0.1s;

  &:hover {
    background-color: #bbb;
  }

  svg {
    color: #14a79c;
  }
`;

export const PropFilterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  position: absolute;
  right: 40px;
  top: 0;
  height: 53px;
`;

interface PropFilterBoxProps {
  isActive: boolean;
}

export const PropFilterBox = styled.button<PropFilterBoxProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 26.5px;
  border: 1px solid #8a94a6;

  svg {
    color: #14a79c;
  }

  background-color: ${(props) => (props.isActive ? '#bbb' : '#eee')};

  transition: filter 0.1s;

  &:hover {
    filter: brightness(1.1);
  }
`;
