import moment from 'moment';
import { cloneDeep, get, set, unset } from 'lodash';

import { Question } from '../models/question';

import { ScoreProps, AverageScore, User, UserAnswer } from '../models/user';

import {
  calculateScore,
  ReturnedScore,
  getCategoryScore
} from './calculateScore';

interface DasScoresObjectProps {
  depressionScore: ScoreProps;
  anxietyScore: ScoreProps;
  stressScore: ScoreProps;
}

interface BloodPressureObjectProps {
  systolicScore: ScoreProps;
  diastolicScore: ScoreProps;
}

export const generateUserScoreObject = (
  user: User,
  questions: Question[],
  answers: Array<string | number>,
  category: string,
  section: string,
  questionnaire: string,
  removeQuestionnaire: boolean
) => {
  let userQuestionnaire: any = get(
    user,
    `answers.${category}.${section}.${questionnaire}`
  );

  const questionnaireAlreadyExists = Boolean(userQuestionnaire);

  if (!questionnaireAlreadyExists) {
    userQuestionnaire = {} as any;

    if (questionnaire !== 'weight' && questionnaire !== 'height') {
      userQuestionnaire.score = 0;
    }

    answers.forEach((_, answerIndex) => {
      userQuestionnaire[answerIndex] = {
        question: {} as Question,
        answer: {} as UserAnswer
      };
    });
  }

  const calculatedAt = moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_MS);

  answers.forEach((answer, answerIndex) => {
    userQuestionnaire[answerIndex].question = questions[answerIndex];
    userQuestionnaire[answerIndex].answer = {
      answeredAt: calculatedAt,
      value: answer
    };
  });

  const invertedAnswers = questions.map(
    (question) => !!question.invertedAnswers
  );

  const dasScoresObject = {} as DasScoresObjectProps;
  if (questionnaire === 'depressionAnxietyAndStress') {
    let dasScore: ScoreProps | undefined = get(
      user,
      `scores.${category}.${questionnaire}`
    );

    if (!dasScore) {
      dasScore = {} as ScoreProps;
      dasScore.depressionScore = {} as ScoreProps;
      dasScore.anxietyScore = {} as ScoreProps;
      dasScore.stressScore = {} as ScoreProps;
    }

    const depressionAnswers: string[] = [];
    const anxietyAnswers: string[] = [];
    const stressAnswers: string[] = [];

    questions.forEach((question, questionIndex) => {
      if (question.subQuestionnaire === 'depression') {
        depressionAnswers.push(answers[questionIndex] as string);
      }

      if (question.subQuestionnaire === 'anxiety') {
        anxietyAnswers.push(answers[questionIndex] as string);
      }

      if (question.subQuestionnaire === 'stress') {
        stressAnswers.push(answers[questionIndex] as string);
      }
    });

    const depressionScoreObj = calculateScore(
      'depression',
      depressionAnswers,
      invertedAnswers
    );
    const anxietyScoreObj = calculateScore(
      'anxiety',
      anxietyAnswers,
      invertedAnswers
    );
    const stressScoreObj = calculateScore(
      'stress',
      stressAnswers,
      invertedAnswers
    );

    const { depressionScore, anxietyScore, stressScore } = dasScore;

    depressionScore.currentScore = get(depressionScoreObj, 'score', 0);
    depressionScore.color = get(depressionScoreObj, 'color', '');
    depressionScore.clinicianAdvice = get(
      depressionScoreObj,
      'clinicianAdvice',
      ''
    );
    depressionScore.userAdvice = get(depressionScoreObj, 'userAdvice', '');
    depressionScore.calculatedAt = calculatedAt;

    anxietyScore.currentScore = get(anxietyScoreObj, 'score', 0);
    anxietyScore.color = get(anxietyScoreObj, 'color', '');
    anxietyScore.clinicianAdvice = get(anxietyScoreObj, 'clinicianAdvice', '');
    anxietyScore.userAdvice = get(anxietyScoreObj, 'userAdvice', '');
    anxietyScore.calculatedAt = calculatedAt;

    stressScore.currentScore = get(stressScoreObj, 'score', 0);
    stressScore.color = get(stressScoreObj, 'color', '');
    stressScore.clinicianAdvice = get(stressScoreObj, 'clinicianAdvice', '');
    stressScore.userAdvice = get(stressScoreObj, 'userAdvice', '');
    stressScore.calculatedAt = calculatedAt;

    dasScoresObject.depressionScore = depressionScore;
    dasScoresObject.anxietyScore = anxietyScore;
    dasScoresObject.stressScore = stressScore;
  }

  const bloodPressureObject = {} as BloodPressureObjectProps;
  if (questionnaire === 'systolicDiastolic') {
    let bloodPressureScore: ScoreProps | undefined = get(
      user,
      `scores.${category}.${questionnaire}`
    );

    if (!bloodPressureScore) {
      bloodPressureScore = {} as ScoreProps;
      bloodPressureScore.systolicScore = {} as ScoreProps;
      bloodPressureScore.diastolicScore = {} as ScoreProps;
    }

    const systolicScoreObj = calculateScore(
      'systolic',
      [answers[0]],
      invertedAnswers
    );
    const diastolicScoreObj = calculateScore(
      'diastolic',
      [answers[1]],
      invertedAnswers
    );

    const { systolicScore, diastolicScore } = bloodPressureScore;

    systolicScore.currentScore = get(systolicScoreObj, 'score', 0);
    systolicScore.color = get(systolicScoreObj, 'color', '');
    systolicScore.clinicianAdvice = get(
      systolicScoreObj,
      'clinicianAdvice',
      ''
    );
    systolicScore.userAdvice = get(systolicScoreObj, 'userAdvice', '');
    systolicScore.calculatedAt = calculatedAt;

    diastolicScore.currentScore = get(diastolicScoreObj, 'score', 0);
    diastolicScore.color = get(diastolicScoreObj, 'color', '');
    diastolicScore.clinicianAdvice = get(
      diastolicScoreObj,
      'clinicianAdvice',
      ''
    );
    diastolicScore.userAdvice = get(diastolicScoreObj, 'userAdvice', '');
    diastolicScore.calculatedAt = calculatedAt;

    bloodPressureObject.systolicScore = systolicScore;
    bloodPressureObject.diastolicScore = diastolicScore;
  }

  let calculatedScore: ReturnedScore | undefined;

  if (questionnaire === 'depressionAnxietyAndStress') {
    const { depressionScore, anxietyScore, stressScore } = dasScoresObject;
    const groupedDasScores = [
      depressionScore.currentScore,
      anxietyScore.currentScore,
      stressScore.currentScore
    ];
    calculatedScore = calculateScore(
      questionnaire,
      groupedDasScores,
      invertedAnswers
    );
  } else if (questionnaire === 'systolicDiastolic') {
    const { systolicScore, diastolicScore } = bloodPressureObject;
    const groupedBloodPressureScores = [
      systolicScore.currentScore,
      diastolicScore.currentScore
    ];
    calculatedScore = calculateScore(
      questionnaire,
      groupedBloodPressureScores,
      invertedAnswers
    );
  } else {
    calculatedScore = calculateScore(
      questionnaire,
      answers,
      invertedAnswers,
      user.sex
    );
  }

  if (calculatedScore) {
    let userScore: ScoreProps | undefined = get(
      user,
      `scores.${category}.${questionnaire}`
    );

    const { score, color, clinicianAdvice, userAdvice } = calculatedScore;

    if (!userScore) {
      userScore = {} as ScoreProps;
    }

    userScore.currentScore = score;
    userScore.color = color;
    userScore.clinicianAdvice = clinicianAdvice;
    userScore.userAdvice = userAdvice;
    userScore.calculatedAt = calculatedAt;

    if (questionnaire === 'depressionAnxietyAndStress') {
      userScore.depressionScore = dasScoresObject.depressionScore;
      userScore.anxietyScore = dasScoresObject.anxietyScore;
      userScore.stressScore = dasScoresObject.stressScore;
    } else if (questionnaire === 'systolicDiastolic') {
      userScore.systolicScore = bloodPressureObject.systolicScore;
      userScore.diastolicScore = bloodPressureObject.diastolicScore;
    }

    userQuestionnaire.score = userScore;

    set(
      user,
      `answers.${category}.${section}.${questionnaire}`,
      userQuestionnaire
    );

    set(user, `scores.${category}.${questionnaire}`, userScore);

    if (removeQuestionnaire) {
      unset(user, `answers.${category}.${section}.${questionnaire}`);
      unset(user, `scores.${category}.${questionnaire}`);
    }

    let userAverageScore: any = get(user, `scores.averageScore`);

    if (!userAverageScore) {
      userAverageScore = {} as AverageScore;
    }

    const categoryScore = getCategoryScore({
      category,
      scores: user.scores
    });

    /* eslint-disable default-case */
    switch (category) {
      case 'life':
        userAverageScore.life = categoryScore;
        userAverageScore.lifeCalculatedAt = calculatedAt;
        break;
      case 'body':
        userAverageScore.body = categoryScore;
        userAverageScore.bodyCalculatedAt = calculatedAt;
        break;
      case 'mind':
        userAverageScore.mind = categoryScore;
        userAverageScore.mindCalculatedAt = calculatedAt;
        break;
    }

    const { life, mind, body } = userAverageScore;

    const lifeScore = life || 0;
    const mindScore = mind || 0;
    const bodyScore = body || 0;

    const averageScore = (lifeScore + bodyScore + mindScore) / 3;

    userAverageScore.averageScore = Math.round(averageScore);

    userAverageScore.life = lifeScore;
    userAverageScore.mind = mindScore;
    userAverageScore.body = bodyScore;
    userAverageScore.averageCalculatedAt = calculatedAt;

    set(user, `scores.averageScore`, userAverageScore);
  } else {
    set(
      user,
      `answers.${category}.${section}.${questionnaire}`,
      userQuestionnaire
    );
  }

  return cloneDeep(user);
};
