import { camelCase, get, unset } from 'lodash';
import {
  GetQuestionnaireAnswersProps,
  UserQuestionnaireAnswer
} from '../models/user';

export const getQuestionnaireAnswers = ({
  category,
  section,
  questionnaire,
  user
}: GetQuestionnaireAnswersProps) => {
  const camelCaseCategory = camelCase(category);
  const camelCaseSection = camelCase(section);
  const camelCaseQuestionnaire = camelCase(questionnaire);

  const answers = get(
    user,
    `answers.${camelCaseCategory}.${camelCaseSection}.${camelCaseQuestionnaire}`
  );

  if (!answers) return;

  unset(answers, 'score');

  const answersArray = Object.values(answers);

  return answersArray as UserQuestionnaireAnswer[];
};
