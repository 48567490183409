import { get } from 'lodash';
import { User } from '../models/user';

export const getAverageScoreSummary = (
  currentUser: User,
  untouchedUser: User
): number => {
  const currentUserAverageScore = get(
    currentUser,
    'scores.averageScore',
    {}
  ) as any;
  const untouchedUserAverageScore = get(
    untouchedUser,
    'scores.averageScore',
    {}
  ) as any;

  if (
    currentUserAverageScore.mind === 0 ||
    currentUserAverageScore.life === 0
  ) {
    return untouchedUserAverageScore.averageScore;
  }

  return currentUserAverageScore.averageScore;
};
