import styled from 'styled-components';

export const Container = styled.main``;

export const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: #f2f5f9;
  width: 500px;
  max-height: 80vh;
  padding: 10px;
  border: 2px solid #fff;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  gap: 10px;
  flex: 1;

  a {
    color: #14a79c;
  }

  h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.05);
    width: 100%;
    border-radius: 5px;
    height: 40px;
  }

  @media (max-width: 450px) {
    width: 300px;
  }

  @media (max-width: 350px) {
    width: 250px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;

  background: transparent;
  border: none;
  border-radius: 50%;

  height: 20px;
  width: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0px 0px 10px #fff;
  }

  svg {
    color: #fff;
    pointer-events: none;
  }
`;

export const SubjectLine = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  width: 100%;
`;

export const MessageBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  width: 100%;
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const DismissButton = styled.button`
  background-color: #3dd4cbaa;
  color: #fff;
  border-radius: 10px;
  border: none;
  font-weight: 500;
  padding: 5px;
  margin-top: 10px;

  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;

export const ActionsWrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  padding: 10px;

  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  flex-grow: 1;
  overflow-y: scroll;
  min-height: 0;
  max-height: 300px;
`;

export const ActionWrapper = styled.div`
  background-color: #e6e9ec88;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
`;

export const ActionAuthor = styled.p``;

export const ActionTimestamp = styled.p``;

export const ActionStatus = styled.p``;

export const ActionMessage = styled.p`
  padding: 10px;
  border-radius: 10px;
  text-align: justify;
  background-color: #fff;
`;
