import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px;

  @media (max-width: 700px) {
    padding: 5px;
  }
`;

export const NotificationsWrapper = styled.main`
  border-radius: 10px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.05);

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 10px;

  @media (max-width: 700px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  @media (max-width: 350px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;
