export interface RangeDataProps {
  title: string;
  red: string;
  orange: string;
  green: string;
}

export const scoreRangesText: Record<string, Array<RangeDataProps>> = {
  waistCircumference: [
    {
      title: 'Range: Male',
      red: '> 100 cm',
      orange: '≥ 94 & ≤ 100 cm',
      green: '< 94 cm'
    },
    {
      title: 'Range: Female',
      red: '> 86 cm',
      orange: '≥ 80 & ≤ 86 cm',
      green: '< 80 cm'
    }
  ],
  smoking: [
    {
      title: 'Score range',
      red: 'Current smoker',
      orange: 'Ex-Smoker (last smoke < 1 year)',
      green: 'Never smoked or Ex-Smoker (last smoke > 1 year)'
    }
  ],
  alcohol: [
    {
      title: 'Range: Male',
      red: '> 15 drinks',
      orange: '> 0 & ≤ 15 drinks',
      green: '0 drinks'
    },
    {
      title: 'Range: Female',
      red: '> 10 drinks',
      orange: '> 0 & ≤ 10 drinks',
      green: '0 drinks'
    }
  ],
  systolic: [
    {
      title: 'Score range',
      red: '≥ 140 mmHg',
      orange: '> 120 & < 140 mmHg',
      green: '≤ 120 mmHg'
    }
  ],
  diastolic: [
    {
      title: 'Score range',
      red: '≥ 100 mmHg',
      orange: '> 91 & < 100 mmHg',
      green: '≤ 91 mmHg'
    }
  ],
  systolicDiastolic: [
    {
      title: 'Range: Systolic',
      red: '≥ 140 mmHg',
      orange: '> 120 & < 140 mmHg',
      green: '≤ 120 mmHg'
    },
    {
      title: 'Range: Diastolic',
      red: '≥ 100 mmHg',
      orange: '> 91 & < 100 mmHg',
      green: '≤ 91 mmHg'
    }
  ],
  restingHeartRate: [
    {
      title: 'Score range',
      red: '> 99 bpm',
      orange: '≥ 65 & ≤ 99 bpm',
      green: '< 65 bpm'
    }
  ],
  lungFunction: [
    {
      title: 'Score range',
      red: '< 50 %',
      orange: '≥ 50 & < 80 %',
      green: '≥ 80 %'
    }
  ],
  totalCholesterol: [
    {
      title: 'Score range',
      red: '> 6 mmol/L',
      orange: '≥ 4 & ≤ 6 mmol/L',
      green: '< 4 mmol/L'
    }
  ],
  hdl: [
    {
      title: 'Score range',
      red: '< 0.8 mmol/L',
      orange: '≥ 0.8 & ≤ 0.9 mmol/L',
      green: '> 0.9 mmol/L'
    }
  ],
  ldl: [
    {
      title: 'Score range',
      red: '> 3 mmol/L',
      orange: '≥ 2 & ≤ 3 mmol/L',
      green: '< 2 mmol/L'
    }
  ],
  triglycerides: [
    {
      title: 'Score range',
      red: '> 2.5 mmol/L',
      orange: '≥ 2 & ≤ 2.5 mmol/L',
      green: '< 2 mmol/L'
    }
  ],
  ratioCholesterolHdl: [
    {
      title: 'Score range',
      red: '> 5',
      orange: '≥ 4 & ≤ 5',
      green: '< 4'
    }
  ],
  hbA1C: [
    {
      title: 'Range: Diabetic',
      red: '≥ 55 mmol/mol',
      orange: '≥ 45 & < 55 mmol/mol',
      green: '< 45 mmol/mol'
    },
    {
      title: 'Range: Non-Diabetic',
      red: '≥ 50 mmol/mol',
      orange: '≥ 40 & < 50 mmol/mol',
      green: '< 40 mmol/mol'
    }
  ]
};
