import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 10px;
  box-shadow: 2px 2px 10px;
  padding: 10px;

  &.physical {
    grid-area: a;
  }

  &.smokingAndAlcohol {
    grid-area: b;
  }

  &.lungFunction {
    grid-area: c;
  }

  &.restingHeartRate {
    grid-area: d;
  }

  &.bloodPressure {
    grid-area: e;
  }

  &.cholesterol {
    grid-area: f;
  }

  &.hbA1C {
    grid-area: g;
  }
`;

export const SectionTitle = styled.strong`
  display: flex;
  flex: 1;

  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 3px;

  font-size: 18px;
  font-weight: 500;
`;

export const QuestionnairesWrapper = styled.main`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 10px;
  margin: 10px 0 0;

  @media (max-width: 1600px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
`;

interface QuestionnaireWrapperProps {
  isComplete: boolean;
}

export const QuestionnaireWrapper = styled.main<QuestionnaireWrapperProps>`
  border-radius: 5px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.05);

  border: 1px solid;
  border-color: ${({ isComplete }) => (isComplete ? '#8BE5E0' : '#FF6961')};
`;

export const QuestionnaireHeader = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;

  height: 50px;
`;

export const QuestionnaireIcon = styled.span`
  padding: 2px;
  background-color: #fff;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 30px;
    width: 30px;
  }
`;

export const QuestionnaireTitle = styled.h3`
  margin: 0;
`;

export const QuestionsWrapper = styled.div``;

export const RatioValue = styled.div`
  margin-top: 4px;

  h1 {
    text-align: center;
    background-color: #fff;
    border-radius: 4px;
    pointer-events: none;
    margin-top: 8px;
  }
`;
