import moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import lodash from 'lodash';
import { Helmet } from 'react-helmet';
import { ArrowBackOutlined } from '@mui/icons-material';

import { PageTitleWrapper } from '../../components/PageTitleWrapper';
import { PageTitle } from '../../components/PageTitle';
import { Spinner } from '../../components/Spinner';
import { AverageScore, HealthCheck, User } from '../../models/user';

import { calculateCategoriesCompleteness } from '../../utils/calculateScore';
import { getUserAge } from '../../utils/getUserAge';
import { formatDate, getUnixSeconds } from '../../utils/formatDate';
import { getHealthCheckMessages } from '../../utils/getHealthCheckMessages';

import { getUserHealthChecks } from '../../functions/users';

import {
  CheckUserName,
  ConclusionDate,
  Container,
  DateFilter,
  DateFilterWrapper,
  HeaderOptionsWrapper,
  HealthChecksWrapper,
  HealthCheckCard,
  ScoreCard,
  ScoreWrapper,
  UserBirth,
  UserDataWrapper
} from './styles';

import averageScoreIcon from '../../assets/logo-small.png';
import bodyIcon from '../../assets/body-icon.png';
import mindIcon from '../../assets/mind-icon.png';
import lifeIcon from '../../assets/life-icon.png';

export function UserHealthChecks() {
  const navigate = useNavigate();
  const location: any = useLocation();

  const [isLoadingHealthChecks, setIsLoadingHealthChecks] = useState(true);
  const [healthChecks, setHealthChecks] = useState([] as HealthCheck[]);
  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'month').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [currentChecksToShow, setCurrentChecksToShow] = useState(
    [] as HealthCheck[]
  );

  useEffect(() => {
    const clientState = location.state.client as User;
    const getHealthChecksAsync = async () => {
      const userHealthChecks = await getUserHealthChecks(clientState.id);

      const allHealthChecks: HealthCheck[] = [];

      if (!userHealthChecks.length) {
        setIsLoadingHealthChecks(false);
        return;
      }

      userHealthChecks.map((checkData) => {
        checkData.healthChecks.map((check) => {
          allHealthChecks.push(check);
        });
      });

      allHealthChecks.sort((checkA, checkB) =>
        getUnixSeconds(checkA.createdAt as any) >
        getUnixSeconds(checkB.createdAt as any)
          ? -1
          : 1
      );

      setHealthChecks(allHealthChecks);
      setCurrentChecksToShow(allHealthChecks);

      setIsLoadingHealthChecks(false);
    };

    getHealthChecksAsync();
  }, [location]);

  const handleSeeDetails = useCallback(
    (user: User, averageScore: AverageScore) => {
      const filteredUserHealthChecks = healthChecks.filter(
        (check) => check.user.id === user.id
      );

      const { clinicalObservations, userMessages } = getHealthCheckMessages(
        filteredUserHealthChecks
      );

      const checkDate = formatDate({ date: averageScore.averageCalculatedAt });

      navigate('details', {
        state: {
          client: user,
          clinicalObservations,
          userMessages,
          showResumeCheckButton: false,
          checkDate
        }
      });
    },
    [navigate, healthChecks]
  );

  useEffect(() => {
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const formatString = 'YYYY-MM-DD-THH:mm';
    const timezone = currentTimezone;

    const firstDate = moment.tz(`${startDate}T00:00`, formatString, timezone);
    const lastDate = moment.tz(`${endDate}T23:59`, formatString, timezone);

    const start = firstDate.clone().tz('GMT').format('YYYY-MM-DD');
    const end = lastDate.clone().tz('GMT').format('YYYY-MM-DD');

    const filteredCurrentChecks = healthChecks.filter(({ averageScore }) => {
      const submittedAt = moment(
        formatDate({ date: averageScore.averageCalculatedAt })
      ).format('YYYY-MM-DD');

      return submittedAt >= start && submittedAt <= end;
    });

    setCurrentChecksToShow(filteredCurrentChecks);
  }, [healthChecks, startDate, endDate]);

  const scoresCompleteness = useCallback((client: User) => {
    const completeness = {
      bodyCompleteness: '0 %',
      mindCompleteness: '0 %',
      lifeCompleteness: '0 %',
      totalCompleteness: '0 %'
    };

    const generalScores = lodash.get(client, 'scores') as any;

    if (generalScores) {
      const categoriesCompleteness =
        calculateCategoriesCompleteness(generalScores);

      const { body, mind, life, total } = categoriesCompleteness;

      completeness.bodyCompleteness = body + ' %';
      completeness.mindCompleteness = mind + ' %';
      completeness.lifeCompleteness = life + ' %';
      completeness.totalCompleteness = total + ' %';
    }

    return completeness;
  }, []);

  const handleFilterByDate = useCallback(
    (dateString: string, filterType: 'start' | 'end') => {
      if (filterType === 'start') {
        setStartDate(dateString);
      } else {
        setEndDate(dateString);
      }
    },
    []
  );

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Container>
      {isLoadingHealthChecks && <Spinner />}

      <Helmet>
        <title>User Health Checks</title>
      </Helmet>

      <PageTitleWrapper>
        <PageTitle heading="User Health Checks" />
      </PageTitleWrapper>

      <button
        className="back-button"
        title="Go back"
        type="button"
        onClick={handleGoBack}
        aria-label="Go back"
      >
        <ArrowBackOutlined />
      </button>

      <HeaderOptionsWrapper>
        <DateFilterWrapper>
          <div>
            <p>From</p>
            <DateFilter
              value={startDate}
              type="date"
              onChange={(e) => handleFilterByDate(e.target.value, 'start')}
            />
          </div>

          <div>
            <p>To</p>
            <DateFilter
              type="date"
              value={endDate}
              onChange={(e) => handleFilterByDate(e.target.value, 'end')}
            />
          </div>
        </DateFilterWrapper>
      </HeaderOptionsWrapper>

      <HealthChecksWrapper>
        {isLoadingHealthChecks && <h2>Loading health checks</h2>}
        {!currentChecksToShow.length && <h2>No data to show</h2>}

        {currentChecksToShow.map(({ user, averageScore }) => (
          <HealthCheckCard
            key={formatDate({
              date: averageScore.averageCalculatedAt,
              showHour: true
            })}
            onClick={() =>
              handleSeeDetails(user, user.scores.averageScore as any)
            }
            title="Click to open health check summary"
          >
            <UserDataWrapper>
              <CheckUserName>
                <div>
                  <p>{user.firstName.toUpperCase()}</p>
                  <p>{user.lastName.toUpperCase()}</p>
                </div>
                <h6>{user.email}</h6>
              </CheckUserName>

              <UserBirth>
                <p>DOB:</p>
                <h4>{formatDate({ date: user.birthDate, showHour: false })}</h4>
                <h4>({getUserAge(user.birthDate)} yrs)</h4>
              </UserBirth>

              <ConclusionDate>
                <p>Submitted</p>
                <h4>
                  {moment
                    .utc(
                      getUnixSeconds(
                        user.scores.averageScore.averageCalculatedAt
                      )
                    )
                    .local()
                    .format('DD MMM YYYY, hh:mm A')}
                </h4>
              </ConclusionDate>
            </UserDataWrapper>

            <ScoreWrapper>
              <ScoreCard className="average">
                <img src={averageScoreIcon} alt="Average score" />
                <div>
                  <p>KYND Score</p>
                  <strong>{user.scores.averageScore.averageScore}</strong>
                  <h6>{scoresCompleteness(user).totalCompleteness}</h6>
                </div>
              </ScoreCard>

              <ScoreCard className="body">
                <img src={bodyIcon} alt="Body score" />
                <div>
                  <p>Body</p>
                  <strong>{user.scores.averageScore.body}</strong>
                  <h6>{scoresCompleteness(user).bodyCompleteness}</h6>
                </div>
              </ScoreCard>

              <ScoreCard className="mind">
                <img src={mindIcon} alt="Mind score" />
                <div>
                  <p>Mind</p>
                  <strong>{user.scores.averageScore.mind}</strong>
                  <h6>{scoresCompleteness(user).mindCompleteness}</h6>
                </div>
              </ScoreCard>

              <ScoreCard className="life">
                <img src={lifeIcon} alt="Life score" />
                <div>
                  <p>Life</p>
                  <strong>{user.scores.averageScore.life}</strong>
                  <h6>{scoresCompleteness(user).lifeCompleteness}</h6>
                </div>
              </ScoreCard>
            </ScoreWrapper>
          </HealthCheckCard>
        ))}
      </HealthChecksWrapper>
    </Container>
  );
}
