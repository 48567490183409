import { useMemo } from 'react';
import { differenceInYears } from 'date-fns';
import { ArrowBackOutlined } from '@mui/icons-material';

import {
  getDay,
  getMonthAsNumber,
  getYear
} from '../../../../utils/formatDate';
import { useHealthCheck } from '../../../../hooks/healthCheck';

import {
  Container,
  HeaderButton,
  SubHeaderButton,
  MainHeader,
  SubHeader,
  UserName,
  ActionButtons,
  SaveHealthCheckButton,
  SubmitHealthCheckButton
} from './styles';

import scoreIcon from '../../../../assets/logo-small.png';
import bodyIcon from '../../../../assets/body-icon.png';
import mindIcon from '../../../../assets/mind-icon.png';
import lifeIcon from '../../../../assets/life-icon.png';
import mindfulness from '../../../../assets/mindfulness.svg';
import sleep from '../../../../assets/sleep.svg';
import sleepApnea from '../../../../assets/sleep-apnea.svg';
import gratitude from '../../../../assets/gratitude.svg';
import emotionalIndex from '../../../../assets/healthy-thinking.svg';

export function HealthCheckHeader() {
  const {
    user,
    currentHeaderTab,
    currentMindTab,
    setCurrentHeaderTab,
    setCurrentMindTab,
    handleGoBack,
    handleSubmitHealthCheck,
    handleSaveHealthCheck
  } = useHealthCheck();

  const mainHeaderData = useMemo(() => {
    return [
      {
        title: 'BODY',
        icon: bodyIcon,
        page: 'body'
      },
      {
        title: 'MIND',
        icon: mindIcon,
        page: 'mind'
      },
      {
        title: 'LIFE',
        icon: lifeIcon,
        page: 'life'
      },
      {
        title: 'SUMMARY',
        icon: scoreIcon,
        page: 'summary'
      }
    ];
  }, []);

  const mindHeaderData = useMemo(() => {
    return [
      {
        title: 'MINDFULNESS',
        icon: mindfulness,
        page: 'mindfulness'
      },
      {
        title: 'SLEEP',
        icon: sleep,
        page: 'sleep'
      },
      {
        title: 'OSA',
        icon: sleepApnea,
        page: 'obstructiveSleepApnea'
      },
      {
        title: 'GRATITUDE',
        icon: gratitude,
        page: 'gratitude'
      },
      {
        title: 'EMOTIONAL INDEX',
        icon: emotionalIndex,
        page: 'emotionalIndex'
      },
      {
        title: 'DEP/ANX/STRESS',
        icon: lifeIcon,
        page: 'depressionAnxietyAndStress'
      }
    ];
  }, []);

  const userAgeAsString = useMemo(() => {
    const birthDay = Number(getDay(user.birthDate));
    const birthMonth = Number(getMonthAsNumber(user.birthDate));
    const birthYear = Number(getYear(user.birthDate));

    const userBirthDate = new Date(birthYear, birthMonth - 1, birthDay);

    const userAge = differenceInYears(new Date(), userBirthDate);

    return userAge;
  }, [user.birthDate]);

  return (
    <Container>
      <UserName>
        <div>
          <button
            className="back-button"
            title="Go back"
            aria-label="Go back"
            type="button"
            onClick={handleGoBack}
          >
            <ArrowBackOutlined />
          </button>

          <p>PATIENT:</p>

          <span />
        </div>

        <div>
          {user.title && <p>{user.title.toUpperCase()}.</p>}
          {user.firstName && <p>{user.firstName.toUpperCase()}</p>}
          {user.lastName && <p>{user.lastName.toUpperCase()}</p>}
          <p>({userAgeAsString} yrs)</p>
        </div>

        <ActionButtons>
          <SaveHealthCheckButton type="button" onClick={handleSaveHealthCheck}>
            Save
          </SaveHealthCheckButton>

          <SubmitHealthCheckButton
            type="button"
            onClick={handleSubmitHealthCheck}
          >
            Submit Health Check
          </SubmitHealthCheckButton>
        </ActionButtons>
      </UserName>

      <MainHeader>
        {mainHeaderData.map(
          (data) =>
            data && (
              <HeaderButton
                key={data.page}
                type="button"
                onClick={() => setCurrentHeaderTab(data.page)}
                activeTab={currentHeaderTab === data.page}
              >
                <img src={data.icon} alt={data.title} />
                <p>{data.title}</p>
              </HeaderButton>
            )
        )}
      </MainHeader>

      {currentHeaderTab === 'mind' && (
        <SubHeader>
          {mindHeaderData.map(
            (data) =>
              data && (
                <SubHeaderButton
                  key={data.page}
                  type="button"
                  onClick={() => setCurrentMindTab(data.page)}
                  activeTab={currentMindTab === data.page}
                >
                  <img src={data.icon} alt={data.title} />
                  <p>{data.title}</p>
                </SubHeaderButton>
              )
          )}
        </SubHeader>
      )}
    </Container>
  );
}
