import { unset, set, cloneDeep, has } from 'lodash';

import { getUserHistories } from '../functions/users';

import { Question } from '../models/question';
import {
  AverageScore,
  ScoreProps,
  User,
  UserQuestionnaireAnswer
} from '../models/user';

interface UserAnswerHistory {
  answers: Array<{
    answeredAt: Date;
    value: string | number;
  }>;
  question: Question;
}

export interface AnswerHistoryProps {
  category: string;
  section: string;
  questionnaire: string;
  userId: string;
  history: Record<string, UserAnswerHistory>;
}

export interface ScoreHistoryProps {
  category: string;
  section: string;
  questionnaire: string;
  subQuestionnaire: string;
  userId: string;
  history: ScoreProps[];
}

interface HistoriesObjectProps {
  answersHistories: AnswerHistoryProps[];
  scoresHistories: ScoreHistoryProps[];
  averageScore?: AverageScore;
}

export const generateUserHistories = async (
  healthCheckUser: User,
  completeUser: User
) => {
  const userId = healthCheckUser.id;

  if (!userId || !completeUser.id) {
    console.error({ healthCheckUser, completeUser });
    throw new Error('Generate user history: Missing user id');
  }

  const userHistories = await getUserHistories(userId);

  const historiesToUpdate = {
    answersHistories: [] as AnswerHistoryProps[],
    scoresHistories: [] as ScoreHistoryProps[]
  } as HistoriesObjectProps;

  const userAnswers = cloneDeep(healthCheckUser.answers);
  const userScores = cloneDeep(completeUser.scores);

  if (userScores.averageScore) {
    const averageScore = userScores.averageScore as AverageScore;
    historiesToUpdate.averageScore = averageScore;
  }

  const categories = Object.keys(userAnswers);
  const categoriesValues = Object.values(userAnswers);

  categories.forEach((category, categoryIndex) => {
    const categoryValues = categoriesValues[categoryIndex];

    const sections = Object.keys(categoryValues);
    const sectionsValues = Object.values(categoryValues);

    sections.forEach((section, sectionIndex) => {
      const sectionValues = sectionsValues[sectionIndex];

      const questionnaires = Object.keys(sectionValues);
      const questionnairesValues = Object.values(sectionValues);

      questionnaires.forEach((questionnaire, questionnaireIndex) => {
        const questionnaireData = questionnairesValues[
          questionnaireIndex
        ] as Record<string | 'score', UserQuestionnaireAnswer | ScoreProps>;

        const answersPreviousHistory = userHistories.answers.find(
          (data) => data.questionnaire === questionnaire
        );
        const scoresPreviousHistory = userHistories.scores.find(
          (data) => data.questionnaire === questionnaire
        );

        if (has(questionnaireData, 'score')) {
          let scoreHistory = [] as ScoreProps[];

          if (scoresPreviousHistory) {
            scoreHistory = scoresPreviousHistory.history;
          }

          const score = questionnaireData.score as ScoreProps;

          scoreHistory.push(score);

          const scoreHistoryObj = {
            category,
            section,
            questionnaire,
            subQuestionnaire: '',
            userId,
            history: scoreHistory
          };

          historiesToUpdate.scoresHistories.push(scoreHistoryObj);
          unset(questionnaireData, 'score');
        }

        const answers = Object.values(
          questionnaireData
        ) as UserQuestionnaireAnswer[];

        let answersHistory = {} as Record<string, UserAnswerHistory>;
        answers.forEach(({ answer, question }, answerIndex) => {
          if (answersPreviousHistory) {
            answersHistory = answersPreviousHistory.history;

            answersHistory[answerIndex].answers.push(answer);
          } else {
            set(answersHistory, `${answerIndex}.question`, question);
            set(answersHistory, `${answerIndex}.answers`, [answer]);
          }
        });

        const answerHistoryObj = {
          category,
          section,
          questionnaire,
          userId,
          history: answersHistory
        };

        historiesToUpdate.answersHistories.push(answerHistoryObj);
      });
    });
  });

  return historiesToUpdate;
};
