import { OrganisationAverageScore } from '../../../../models/organisation';
import { GaugeScoreChart } from './components/GaugeChart';

import { ChartCard, ChartTitle, Container } from './styles';

interface AverageChartProps {
  data: OrganisationAverageScore;
  totalLights: Record<string, Record<string, number>>;
}

export const AverageChart: React.FC<AverageChartProps> = ({
  data,
  totalLights
}) => {
  return (
    <Container>
      <ChartCard>
        <ChartTitle>BODY</ChartTitle>

        <GaugeScoreChart value={data.body} totalLights={totalLights.body} />
      </ChartCard>

      <ChartCard>
        <ChartTitle>MIND</ChartTitle>

        <GaugeScoreChart value={data.mind} totalLights={totalLights.mind} />
      </ChartCard>

      <ChartCard>
        <ChartTitle>LIFE</ChartTitle>

        <GaugeScoreChart value={data.life} totalLights={totalLights.life} />
      </ChartCard>
    </Container>
  );
};
