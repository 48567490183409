import { FirstPage, LastPage, Search } from '@mui/icons-material';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { isEmpty, orderBy } from 'lodash';
import { useCallback } from 'react';
import { useOrganisation } from '../../hooks/organisations';

import {
  Container,
  PropFilterBox,
  PropFilterWrapper,
  SearchUsersButton,
  UserSearchBox
} from './styles';

interface OrganisationFilterProps {
  hideDivision?: boolean;
  hideUserSearch?: boolean;
  hideOrganisation?: boolean;
  hideAllOptionOrgFilter?: boolean;
  additionalFilters?: JSX.Element[];
}

export function OrganisationFilter({
  hideDivision = false,
  hideUserSearch = false,
  hideOrganisation = false,
  additionalFilters = [],
  hideAllOptionOrgFilter = false
}: OrganisationFilterProps) {
  const {
    industriesNames,
    organisationsNames,
    divisionsNames,
    chosenIndustry,
    chosenOrganisation,
    chosenDivision,
    handleChangeIndustry,
    handleChangeOrganisation,
    handleChangeDivision,
    userSearch,
    setUserSearch,
    fetchSearchedUsers,
    setPropToFilter,
    propToFilter
  } = useOrganisation();

  const acceptBlankSearch = true;

  const getOrganisationNames = useCallback(() => {
    if (!hideAllOptionOrgFilter) return orderBy(organisationsNames, [], 'asc');

    return orderBy(
      organisationsNames.filter((value) => value !== 'All'),
      [],
      'asc'
    );
  }, [organisationsNames, hideAllOptionOrgFilter]);

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Autocomplete
            disablePortal
            id="industries-combo-box"
            options={industriesNames}
            value={chosenIndustry}
            freeSolo={false}
            disableClearable
            onChange={(e) =>
              handleChangeIndustry((e.currentTarget as HTMLElement).innerText)
            }
            renderInput={(params) => (
              <TextField {...params} required fullWidth label="Industry" />
            )}
          />
        </Grid>

        {!hideOrganisation && (
          <Grid item xs={12} sm={3}>
            <Autocomplete
              disablePortal
              id="organisations-combo-box"
              options={getOrganisationNames()}
              value={chosenOrganisation}
              freeSolo={false}
              disableClearable
              onChange={(e) => {
                handleChangeOrganisation(
                  (e.currentTarget as HTMLElement).innerText
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  label="Organisation"
                />
              )}
            />
          </Grid>
        )}

        {!hideDivision && (
          <Grid item xs={12} sm={3}>
            <Autocomplete
              disablePortal
              id="divisions-combo-box"
              options={orderBy(divisionsNames, [], 'asc')}
              value={chosenDivision}
              freeSolo={false}
              disableClearable
              onChange={(e) =>
                handleChangeDivision((e.currentTarget as HTMLElement).innerText)
              }
              renderInput={(params) => (
                <TextField {...params} required fullWidth label="Division" />
              )}
            />
          </Grid>
        )}

        {!hideUserSearch && (
          <Grid item xs={12} sm={3}>
            <UserSearchBox>
              <TextField
                fullWidth
                label="Search user"
                value={userSearch}
                onChange={(e) => setUserSearch(e.target.value)}
              />

              <PropFilterWrapper>
                <PropFilterBox
                  type="button"
                  onClick={() => setPropToFilter('lowerFirstName')}
                  isActive={propToFilter === 'lowerFirstName'}
                  title="Filter by first name"
                >
                  <FirstPage />
                </PropFilterBox>

                <PropFilterBox
                  type="button"
                  onClick={() => setPropToFilter('lowerLastName')}
                  isActive={propToFilter === 'lowerLastName'}
                  title="Filter by last name"
                >
                  <LastPage />
                </PropFilterBox>
              </PropFilterWrapper>

              <SearchUsersButton
                type="button"
                onClick={() => fetchSearchedUsers(acceptBlankSearch)}
                title="Search users - blank search to fetch all users at once (not recommended)"
              >
                <Search />
              </SearchUsersButton>
            </UserSearchBox>
          </Grid>
        )}
        {!isEmpty(additionalFilters) && additionalFilters}
      </Grid>
    </Container>
  );
}
