import styled from 'styled-components';

export const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: rgba(0, 0, 0, 0.7);
  width: 80%;
  max-height: 80vh;
  padding: 30px;
  border: 2px solid #fff;
  border-radius: 10px;

  overflow-y: scroll;
  a {
    color: #14a79c;
  }

  @media (max-width: 450px) {
    width: 300px;
  }

  @media (max-width: 350px) {
    width: 250px;
  }
`;

export const ModalTitle = styled.div`
  position: sticky;
  width: 100%;
  border-radius: 10px;
  text-align: center;

  h2 {
    width: 100%;
    line-height: 24px;
    text-align: center;
    color: #fff;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;

  background: transparent;
  border: none;
  border-radius: 50%;

  height: 20px;
  width: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0px 0px 10px #fff;
  }

  svg {
    color: #fff;
    pointer-events: none;
  }
`;

export const WhiteLine = styled.div`
  background-color: #fff;
  width: 90%;
  height: 2px;
  margin: 10px auto;
  border-radius: 10px;
`;

export const AnswerCardsWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
`;
