import styled from 'styled-components';

export const Title = styled.h4`
  width: 100%;
  margin: 0 0 3px;
  color: #333333cc;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 3px;
  margin-top: 10px;
  font-size: 14px;
  text-transform: uppercase;
`;
