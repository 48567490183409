import { createContext, useState, useContext, useEffect } from 'react';
import { camelCase } from 'lodash';
import { getQuestions } from '../functions/questions';
import { Question } from '../models/question';
import { aboutInfo } from '../utils/aboutQuestionnaire';
import { useAuth } from './auth';

export const JOB_HEALTH_HAZARDS_ADDITIONAL_QUESTIONNAIRES = [
  'hearingScreen',
  'visionScreen',
  'criticalRole'
];
interface QuestionsContext {
  questions: Question[];
  bodyQuestions: Question[];
  mindQuestions: Question[];
  lifeQuestions: Question[];
  groupedQuestionnaires: QuestionnaireProps[];
  bodyQuestionnaires: QuestionnaireProps[];
  mindQuestionnaires: QuestionnaireProps[];
  lifeQuestionnaires: QuestionnaireProps[];
}

export interface QuestionnaireProps {
  questionnaire: string;
  camelCaseQuestionnaire: string;
  category: string;
  section: string;
  icon: string;
  about: string;
  questions: Question[];
}

const QuestionsContext = createContext<QuestionsContext>(
  {} as QuestionsContext
);

export const QuestionsProvider: React.FC = ({ children }) => {
  const { userIsLogged } = useAuth();

  const [questions, setQuestions] = useState([] as Question[]);
  const [bodyQuestions, setBodyQuestions] = useState([] as Question[]);
  const [mindQuestions, setMindQuestions] = useState([] as Question[]);
  const [lifeQuestions, setLifeQuestions] = useState([] as Question[]);
  const [groupedQuestionnaires, setGroupedQuestionnaires] = useState(
    [] as QuestionnaireProps[]
  );
  const [bodyQuestionnaires, setBodyQuestionnaires] = useState(
    [] as QuestionnaireProps[]
  );

  const [mindQuestionnaires, setMindQuestionnaires] = useState(
    [] as QuestionnaireProps[]
  );
  const [lifeQuestionnaires, setLifeQuestionnaires] = useState(
    [] as QuestionnaireProps[]
  );

  useEffect(() => {
    if (!userIsLogged) return;

    const getQuestionsAsync = async () => {
      const allQuestions = await getQuestions();

      allQuestions.sort((questionA, questionB) =>
        questionA.section > questionB.section ? -1 : 1
      );

      const body = allQuestions.filter(
        (question) =>
          question.category === 'Body' &&
          (JOB_HEALTH_HAZARDS_ADDITIONAL_QUESTIONNAIRES.includes(
            camelCase(question?.questionnaire)
          ) ||
            question.section !== 'Job Health Hazards')
      );

      const mind = allQuestions.filter(
        (question) => question.category === 'Mind'
      );
      const life = allQuestions.filter(
        (question) => question.category === 'Life'
      );

      life.sort((a, b) => (a.order > b.order ? 1 : -1));

      const filteredQuestionnaires = [] as string[];
      allQuestions.map((question) => {
        const questionnaireAlreadyAdded = filteredQuestionnaires.includes(
          question?.questionnaire
        );

        if (!questionnaireAlreadyAdded) {
          filteredQuestionnaires.push(question?.questionnaire);
        }
      });

      const aboutQuestionnaire = aboutInfo as Record<
        string,
        { type: string; content: string }[]
      >;

      const questionnairesObjects = (categoryQuestions: Question[]) => {
        const mountedObjects = [] as QuestionnaireProps[];

        filteredQuestionnaires.map((questionnaire) => {
          const camelCaseQuestionnaire = camelCase(questionnaire);

          const hasSameCategory = categoryQuestions.some(
            (question) => question?.questionnaire === questionnaire
          );

          if (!hasSameCategory) return;

          let about = '';

          if (aboutQuestionnaire[camelCaseQuestionnaire]) {
            const aboutArray = [] as string[];

            aboutQuestionnaire[camelCaseQuestionnaire].map((info) => {
              if (info.type === 'text') {
                aboutArray.push(info.content);
              }
            });

            about = aboutArray.join(' | ');
          }

          const filteredQuestions = categoryQuestions.filter((question) => {
            return question?.questionnaire === questionnaire;
          });
          const questionnaireData = filteredQuestions[0];

          const { icon, section, category } = questionnaireData;

          mountedObjects.push({
            questionnaire,
            camelCaseQuestionnaire,
            section,
            category,
            icon: icon || 'life',
            about,
            questions: filteredQuestions
          });
        });

        return mountedObjects;
      };

      const bodyQuestionnaireObjects = questionnairesObjects(body);
      const mindQuestionnaireObjects = questionnairesObjects(mind);
      const lifeQuestionnaireObjects = questionnairesObjects(life);

      setQuestions(allQuestions);
      setBodyQuestions(body);
      setMindQuestions(mind);
      setLifeQuestions(life);
      setBodyQuestionnaires(bodyQuestionnaireObjects);
      setMindQuestionnaires(mindQuestionnaireObjects);
      setLifeQuestionnaires(lifeQuestionnaireObjects);
      setGroupedQuestionnaires([
        ...bodyQuestionnaireObjects,
        ...mindQuestionnaireObjects,
        ...lifeQuestionnaireObjects
      ]);
    };

    getQuestionsAsync();
  }, [userIsLogged]);

  return (
    <QuestionsContext.Provider
      value={{
        questions,
        bodyQuestions,
        mindQuestions,
        lifeQuestions,
        groupedQuestionnaires,
        bodyQuestionnaires,
        mindQuestionnaires,
        lifeQuestionnaires
      }}
    >
      {children}
    </QuestionsContext.Provider>
  );
};

export function useQuestions(): QuestionsContext {
  const context = useContext(QuestionsContext);

  return context;
}
