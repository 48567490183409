/* eslint-disable prettier/prettier */
import { useCallback, useEffect, useMemo } from 'react';

import { get } from 'lodash';
import { useHealthCheck } from '../../hooks/healthCheck';

import { HealthCheckHeader } from './components/HealthCheckHeader';
import { BodyQuestions } from './components/BodyQuestions';
import { MindQuestions } from './components/MindQuestions';
import { LifeQuestions } from './components/LifeQuestions';
import { DetailsScreen } from '../../components/UserDetails';

import {
  Container,
  MessageBody,
  MessageCard,
  MessagesWrapper,
  SubjectLine
} from './styles';
import { useOrganisation } from '../../hooks/organisations';

export function HealthCheck() {
  const {
    user,
    currentHeaderTab,
    userMessageSubject,
    setUserMessageSubject,
    userMessage,
    setUserMessage,
    clinicalObservation,
    setClinicalObservation,
    answeredMindQuestions,
    answeredLifeQuestions,
    mindAnswers,
    lifeAnswers,
    isUserDetailsPage,
    setIsUserDetailsPage,
    untouchedUser
  } = useHealthCheck();

  const { organizationHasHearingVisionV2Access } = useOrganisation();

  useEffect(() => {
    setIsUserDetailsPage(false);
  }, [setIsUserDetailsPage]);

  useEffect(() => {
    if (!organizationHasHearingVisionV2Access) return;

    const userAnswers = get(untouchedUser, 'answers.body.jobHealthHazards');
    const hearingQuestionnaire = get(userAnswers, 'hearingQuestionnaire');
    const visionQuestionnaire = get(userAnswers, 'visionQuestionnaire');

    const userHasHearingAnswered = !!hearingQuestionnaire;
    const userHasVisionAnswered = !!visionQuestionnaire;
    const hasAnsweredBothQuestionnaires = userHasHearingAnswered && userHasVisionAnswered;
    const hasNotAnsweredBoth = !userHasHearingAnswered && !userHasVisionAnswered;
    const hasAnsweredOnlyHearing = userHasHearingAnswered && !userHasVisionAnswered;
    const hasAnsweredOnlyVision = !userHasHearingAnswered && userHasVisionAnswered;

    if (hasAnsweredBothQuestionnaires) return;
    if (hasNotAnsweredBoth) return window.alert('User HAS NOT yet answered BOTH HEARING and VISION questionnaires!');
    if (hasAnsweredOnlyVision) return window.alert('User HAS NOT yet answered HEARING Questionnaire ONLY.');
    if (hasAnsweredOnlyHearing) return window.alert('User HAS NOT yet answered VISION Questionnaire ONLY.');
  }, [organizationHasHearingVisionV2Access, untouchedUser]);

  const handleChangeUserMessageSubject = useCallback(
    (subject: string) => {
      setUserMessageSubject(subject);
    },
    [setUserMessageSubject]
  );

  const handleChangeUserMessage = useCallback(
    (message: string) => {
      setUserMessage(message);
    },
    [setUserMessage]
  );

  const handleChangeClinicalObservation = useCallback(
    (observation: string) => {
      setClinicalObservation(observation);
    },
    [setClinicalObservation]
  );

  const allAnsweredQuestions = useMemo(() => {
    const grouppedAnswers = {
      ...mindAnswers,
      ...lifeAnswers
    };

    const grouppedBooleanAnswers = {
      ...answeredMindQuestions,
      ...answeredLifeQuestions
    };

    const incompleQuestionnaires = {} as Record<string, boolean>;

    const arrayOfQuestionnaires = Object.keys(grouppedAnswers);
    const arrayOfAnswers = Object.values(grouppedAnswers);

    arrayOfAnswers.map((questionnaireAnswers, index) => {
      const isNumericAnswer = questionnaireAnswers.some(
        (answer) => typeof answer === 'number'
      );

      if (isNumericAnswer) return;

      const hasFilledSomething = questionnaireAnswers.some(
        (answer) => answer !== ''
      );

      const questionnaire = arrayOfQuestionnaires[index];

      incompleQuestionnaires[questionnaire] =
        hasFilledSomething && !grouppedBooleanAnswers[questionnaire];
    });

    return incompleQuestionnaires;
  }, [answeredMindQuestions, answeredLifeQuestions, mindAnswers, lifeAnswers]);

  return (
    <Container>
      <HealthCheckHeader />

      {currentHeaderTab === 'body' && <BodyQuestions />}
      {currentHeaderTab === 'mind' && <MindQuestions />}
      {currentHeaderTab === 'life' && <LifeQuestions />}
      {currentHeaderTab === 'summary' && (
        <DetailsScreen
          showDetails={isUserDetailsPage}
          userData={user}
          answeredQuestionnaires={allAnsweredQuestions}
        />
      )}

      <MessagesWrapper>
        <MessageCard>
          <h3>{`Message to ${user.firstName} ${user.lastName}`}</h3>

          <SubjectLine>
            <strong>Subject</strong>
            <input
              value={userMessageSubject}
              onChange={(e) => handleChangeUserMessageSubject(e.target.value)}
              type="text"
            />
          </SubjectLine>

          <MessageBody>
            <strong>Message</strong>
            <textarea
              value={userMessage.replace(/<br\s?\/?>/g, '\n')}
              onChange={(e) => handleChangeUserMessage(e.target.value)}
              rows={4}
            />
          </MessageBody>
        </MessageCard>

        <MessageCard>
          <h3>Clinical observations (visible only to clinicians)</h3>

          <MessageBody>
            <strong>Observations</strong>
            <textarea
              value={clinicalObservation.replace(/<br\s?\/?>/g, '\n')}
              onChange={(e) => handleChangeClinicalObservation(e.target.value)}
              rows={8}
            />
          </MessageBody>
        </MessageCard>
      </MessagesWrapper>
    </Container>
  );
}
