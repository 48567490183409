import { Component, ErrorInfo, ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import { Link } from 'react-router-dom';
import { SentimentDissatisfied } from '@mui/icons-material';

import { Container, ErrorContainer } from './styles';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error);
    console.error(errorInfo);
    Sentry.captureException(error);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    const refreshPage = () => {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    };

    if (hasError) {
      return (
        <Container>
          <ErrorContainer>
            <h3>SOMETHING WENT WRONG</h3>

            <SentimentDissatisfied />

            <p>
              If you see this screen multiple times, please consider sending us
              a feedback by going to the left menu of the mobile app and
              clicking on &apos;Contact us&apos;
            </p>

            <Link to="/" onClick={refreshPage}>
              REFRESH PAGE
            </Link>
          </ErrorContainer>
        </Container>
      );
    }

    return children;
  }
}
