import styled from 'styled-components';

export const ZoomButtonsContainer = styled.div`
  position: absolute;
  z-index: 999;
  left: 2%;
  top: 2%;
  button {
    margin: 2px;
    padding: 5px;
    font-weight: 900;
  }
`;

export const OpenedGalleryImage = styled.img`
  max-width: 100%;
  height: auto;
`;
