import { RouteObject, Navigate } from 'react-router-dom';

import { SidebarLayout } from '../layout/SidebarLayout';
import { BaseLayout } from '../layout/BaseLayout';

import { NotFound } from '../pages/NotFound';
import { SignIn } from '../pages/SignIn';
import { Users } from '../pages/Users';
import { Clients } from '../pages/Clients';
import { HealthCheck } from '../pages/HealthCheck';
import { DetailsScreen } from '../components/UserDetails';
import { ViewHealthChecks } from '../pages/ViewHealthChecks';
import { UserHealthChecks } from '../pages/UserHealthChecks';
import { ListNotifications } from '../pages/Notifications/ListNotifications';
import { CreateNotification } from '../pages/Notifications/CreateNotification';
import { Dashboard } from '../pages/Dashboard';
import { DasAlerts } from '../pages/DasAlerts';
import { Alerts } from '../pages/Alerts';
import { Body } from '../pages/Dashboard/Body';
import { Life } from '../pages/Dashboard/Life';
import { Mind } from '../pages/Dashboard/Mind';
import { HearingVisionAlerts } from '../pages/HearingVisionAlerts';

export const publicRoutes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <SignIn />
      },
      {
        path: 'signin',
        element: <Navigate to="/" replace />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export const privateRoutes: RouteObject[] = [
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboard" replace />
      },
      {
        path: 'dashboard',
        element: <Dashboard />
      },
      {
        path: 'dashboard/body',
        element: <Body />
      },
      {
        path: 'dashboard/mind',
        element: <Mind />
      },
      {
        path: 'dashboard/life',
        element: <Life />
      },
      {
        path: 'users',
        element: <Users />
      },
      {
        path: 'users/details',
        element: (
          <DetailsScreen showDetails hideResumeButton isUserDetailsPage />
        )
      },
      {
        path: 'users/health-checks',
        element: <UserHealthChecks />
      },
      {
        path: 'users/health-checks/details',
        element: <DetailsScreen showDetails hideResumeButton />
      },
      {
        path: 'clients',
        element: <Clients />
      },
      {
        path: 'clients/details',
        element: <DetailsScreen showDetails hideResumeButton />
      },
      {
        path: 'clients/health-checks',
        element: <UserHealthChecks />
      },
      {
        path: 'clients/health-checks/details',
        element: <DetailsScreen showDetails hideResumeButton />
      },
      {
        path: 'new-health-check',
        element: <HealthCheck />
      },
      {
        path: 'all-health-checks',
        element: <ViewHealthChecks />
      },
      {
        path: 'all-health-checks/details',
        element: <DetailsScreen showDetails />
      },
      {
        path: 'alerts',
        element: <Alerts />
      },
      {
        path: 'alerts/details',
        element: <DetailsScreen showDetails hideResumeButton />
      },
      {
        path: 'das-alerts',
        element: <DasAlerts />
      },
      {
        path: 'das-alerts/details',
        element: <DetailsScreen showDetails hideResumeButton />
      },
      {
        path: 'messages',
        element: <ListNotifications />
      },
      {
        path: 'messages/create',
        element: <CreateNotification />
      },
      {
        path: 'hearing-vision-alerts',
        element: <HearingVisionAlerts />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export const Routes = () => {
  const user = localStorage.getItem('@KyndClinician:user');

  const isAuthenticated = !!user;

  return !isAuthenticated ? publicRoutes : privateRoutes;
};
