import styled from 'styled-components';

// ========== Images ==========

export const Container = styled.div``;

export const BoxSendImage = styled.div`
  margin: 10px;
  width: fit-content;
`;

export const ButtonSelectFile = styled.div`
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  background-color: transparent;

  border: solid 1px #3dd4cb;
  padding: 8px 10px;

  font-size: 15px;
  font-weight: 500;
  color: #333333;
  word-break: break-word;

  transition: background-color 0.2s;

  background-color: #f2f5f9;

  &:hover {
    background-color: #ffffff;
  }

  img {
    margin-right: 5px;
  }
`;

export const WrapsImages = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
  color: #333333cc;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 10px;
  width: 100%;
`;

export const WrapsImage = styled.div`
  position: relative;
  width: 50px;
  height: 50px;

  span {
    position: absolute;
    top: -3px;
    right: 5px;
    color: #000;
    font-weight: 500;
    text-align: center;
    font-size: 15px;
    text-shadow: 1px 0px #ffffff, -1px 0px #ffffff, 0px 1px #ffffff,
      0px -1px #ffffff;
  }

  svg {
    cursor: pointer;
    width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: 50% 15%;
    color: #333333;
  }
`;

export const FileContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90px;
  border-radius: 10px;
`;

export const OpenFile = styled.a`
  img {
    height: 100%;
  }
`;

export const FileName = styled.span`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
`;
