import { get, set } from 'lodash';
import moment from 'moment';

import { Division } from '../models/division';
import { OrganisationNotification } from '../models/notification';
import {
  ManagerProps,
  Organisation,
  OrganisationAverageScore,
  OrganisationCategoryScore
} from '../models/organisation';

export function calculateAllOrgsSummary(
  organisations: Organisation[],
  industry: string
): Organisation {
  const calculatedAt = moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_MS);

  const sumOrg = {
    id: 'summaryOrgId',
    name: 'Summary',
    address: '',
    phone: '',
    isActive: true,
    managers: [] as ManagerProps[],
    employees: [] as string[],
    divisions: [] as Division[],
    industry,
    createdAt: calculatedAt,
    notifications: [] as OrganisationNotification[]
  } as Organisation;

  sumOrg.notifications = [];
  sumOrg.scores = {
    all: {
      averageScore: {
        activeUsers: 0,
        averageScore: 0,
        body: 0,
        bodyComplete: 0,
        calculatedAt,
        history: [],
        life: 0,
        lifeComplete: 0,
        mind: 0,
        mindComplete: 0,
        totalComplete: 0
      } as OrganisationAverageScore,
      body: {} as Record<string, OrganisationCategoryScore>,
      mind: {} as Record<string, OrganisationCategoryScore>,
      life: {} as Record<string, OrganisationCategoryScore>
    }
  };

  function isValid(number: number) {
    return !(!number || Number.isNaN(number));
  }

  organisations.map((org) => {
    const notifications = get(org, 'notifications');

    if (notifications) {
      sumOrg.notifications.push(...notifications);
    }

    const employees = get(org, 'employees');

    if (employees) {
      sumOrg.employees.push(...employees);
    }

    const managers = get(org, 'managers');

    if (managers) {
      sumOrg.managers.push(...managers);
    }

    const divisions = get(org, 'divisions');

    if (divisions) {
      sumOrg.divisions.push(...divisions);
    }

    const orgAverageScore = get(
      org,
      'scores.all.averageScore'
    ) as OrganisationAverageScore;

    if (orgAverageScore) {
      const {
        activeUsers,
        averageScore,
        body,
        bodyComplete,
        life,
        lifeComplete,
        mind,
        mindComplete,
        totalComplete
      } = orgAverageScore;

      const sumOrgAvgScore = sumOrg.scores.all.averageScore;

      sumOrgAvgScore.activeUsers += isValid(activeUsers) ? activeUsers : 0;
      sumOrgAvgScore.averageScore += isValid(averageScore) ? averageScore : 0;
      sumOrgAvgScore.body += isValid(body) ? body : 0;
      sumOrgAvgScore.bodyComplete += isValid(bodyComplete) ? bodyComplete : 0;
      sumOrgAvgScore.life += isValid(life) ? life : 0;
      sumOrgAvgScore.lifeComplete += isValid(lifeComplete) ? lifeComplete : 0;
      sumOrgAvgScore.mind += isValid(mind) ? mind : 0;
      sumOrgAvgScore.mindComplete += isValid(mindComplete) ? mindComplete : 0;
      sumOrgAvgScore.totalComplete += isValid(totalComplete)
        ? totalComplete
        : 0;
    }

    function addQuestionnaireData(
      questionnaire: string,
      entries: OrganisationCategoryScore,
      category: string
    ) {
      const isLifeQuestionnaire = !Number.isNaN(Number(questionnaire));

      let sumOrgQuestionnaire: OrganisationCategoryScore;

      sumOrgQuestionnaire = get(
        sumOrg,
        `scores.all.${category}.${questionnaire}`,
        {}
      ) as OrganisationCategoryScore;

      if (!sumOrgQuestionnaire) {
        sumOrgQuestionnaire = {} as OrganisationCategoryScore;
        sumOrgQuestionnaire.history = [];
        sumOrgQuestionnaire.calculatedAt = calculatedAt;
        sumOrgQuestionnaire.green = 0;
        sumOrgQuestionnaire.orange = 0;
        sumOrgQuestionnaire.red = 0;
        sumOrgQuestionnaire.totalUsers = 0;
        sumOrgQuestionnaire.question = '';
      }

      if (isLifeQuestionnaire) {
        sumOrgQuestionnaire.question += entries.question || '';
      }

      sumOrgQuestionnaire.green += isValid(entries.green) ? entries.green : 0;
      sumOrgQuestionnaire.orange += isValid(entries.orange)
        ? entries.orange
        : 0;
      sumOrgQuestionnaire.red += isValid(entries.red) ? entries.red : 0;
      sumOrgQuestionnaire.totalUsers += isValid(entries.totalUsers)
        ? entries.totalUsers
        : 0;

      set(
        sumOrg,
        `scores.all.${category}.${questionnaire}`,
        sumOrgQuestionnaire
      );
    }

    const orgBodyScore = get(org, 'scores.all.body') as Record<
      string,
      OrganisationCategoryScore
    >;

    if (orgBodyScore) {
      const questionnaireKeys = Object.keys(orgBodyScore);
      const questionnaireValues = Object.values(orgBodyScore);

      questionnaireKeys.map((questionnaire, index) => {
        addQuestionnaireData(questionnaire, questionnaireValues[index], 'body');
      });
    }

    const orgMindScore = get(org, 'scores.all.mind') as Record<
      string,
      OrganisationCategoryScore
    >;

    if (orgMindScore) {
      const questionnaireKeys = Object.keys(orgMindScore);
      const questionnaireValues = Object.values(orgMindScore);

      questionnaireKeys.map((questionnaire, index) => {
        addQuestionnaireData(questionnaire, questionnaireValues[index], 'mind');
      });
    }

    const orgLifeScore = get(org, 'scores.all.life') as Record<
      string,
      OrganisationCategoryScore
    >;

    if (orgLifeScore) {
      const questionnaireKeys = Object.keys(orgLifeScore);
      const questionnaireValues = Object.values(orgLifeScore);

      questionnaireKeys.map((questionnaire, index) => {
        addQuestionnaireData(questionnaire, questionnaireValues[index], 'life');
      });
    }
  });

  sumOrg.scores.all.averageScore.body =
    Math.round(sumOrg.scores.all.averageScore.body / organisations.length) || 0;
  sumOrg.scores.all.averageScore.mind =
    Math.round(sumOrg.scores.all.averageScore.mind / organisations.length) || 0;
  sumOrg.scores.all.averageScore.life =
    Math.round(sumOrg.scores.all.averageScore.body / organisations.length) || 0;
  sumOrg.scores.all.averageScore.averageScore =
    Math.round(sumOrg.scores.all.averageScore.body / organisations.length) || 0;

  return sumOrg;
}
